type DevedgeUsageDetailBe = {
    customer_name: string;
    channels: string;
    msisdn: string;
    product_id: string;
    end_time: number | null;
    start_time: number | null;
    transaction_timestamp: number | null;
    dno_app_id: string;
    offer_id: string;
    service_id: string;
    qod_profile: string;
    data_session_length: number | null;
    data_session_length_total: number | null;
    consumed_data_size: number | null;
    consumed_data_size_total: number | null;
};

export default class DevedgeUsageDetail {
    customerName: string;
    channels: string;
    msisdn: string;
    productId: string;
    endTime: number | null;
    startTime: number | null;
    transactionTimestamp: number | null;
    dnoAppId: string;
    offerId: string;
    serviceId: string;
    qodProfile: string;
    dataSessionLength: number | null;
    dataSessionLengthTotal: number | null;
    consumedDataSize: number | null;
    consumedDataSizeTotal: number | null;

    constructor(data: Partial<DevedgeUsageDetail> = {}) {
        this.customerName = data?.customerName || '';
        this.channels = data?.channels || '';
        this.msisdn = data?.msisdn || '';
        this.productId = data?.productId || '';
        this.endTime = data?.endTime || null;
        this.startTime = data?.startTime || null;
        this.transactionTimestamp = data?.transactionTimestamp || null;
        this.dnoAppId = data?.dnoAppId || '';
        this.offerId = data?.offerId || '';
        this.serviceId = data?.serviceId || '';
        this.qodProfile = data?.qodProfile || '';
        this.dataSessionLength = data?.dataSessionLength || null;
        this.dataSessionLengthTotal = data?.dataSessionLengthTotal || null;
        this.consumedDataSize = data?.consumedDataSize || null;
        this.consumedDataSizeTotal = data?.consumedDataSizeTotal || null;
    }

    static mapDevedgeUsageDetailFromBE(data: DevedgeUsageDetailBe): DevedgeUsageDetail {
        return new DevedgeUsageDetail({
            customerName: data.customer_name,
            channels: data.channels,
            msisdn: data.msisdn,
            productId: data.product_id,
            endTime: data.end_time,
            startTime: data.start_time,
            transactionTimestamp: data.transaction_timestamp,
            dnoAppId: data.dno_app_id,
            offerId: data.offer_id,
            serviceId: data.service_id,
            qodProfile: data.qod_profile,
            dataSessionLength: data.data_session_length,
            dataSessionLengthTotal: data.data_session_length_total,
            consumedDataSize: data.consumed_data_size,
            consumedDataSizeTotal: data.consumed_data_size_total,
        });
    }
}
