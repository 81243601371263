





















import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';
import * as Sentry from '@sentry/vue';

// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// Helpers
import { OSSMVNEService } from '@/__new__/services/dno/ossmvne/http/ossmvne';
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';
import Subscriber from '@/__new__/services/dno/user/models/Subscriber';
import UM_HIERARCHY from '@/common/userManagerHierarchy';
import { AUTH_ENUM_TOKEN_TYPE } from '@/__new__/features/customerCare/subscriber/common/authHelper';
import { SUBSCRIBER_STATE } from '@/__new__/features/customerCare/common/customerCareHelper';

export default Vue.extend({
    name: 'WholesalePricingSchemaTile',
    components: {
        AppMultiselectV3,
        AppButton,
    },
    props: {
        userManagerHierarchy: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            id: '',
            iccid: '',
            value: '',
            options: [] as string[],
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOSIATED_CHILD_ENTITIES_BY_TYPE_AND_ID]),
        ...mapGetters(Modules.userManagementSubscriber, [Getters.GET_SUBSCRIBER_INFO_BY_ID]),
        subscriber(): Subscriber {
            return new Subscriber(Subscriber.remapUserFromBe(this[Getters.GET_SUBSCRIBER_INFO_BY_ID](this.id)));
        },
        updateButtonDisabled(): boolean {
            return this.subscriber.state !== SUBSCRIBER_STATE.ACTIVE || this.iccid === '';
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        ...mapActions('userManagementSubscriber', ['getSubscriberInfo']),
        async fetchTileData() {
            this.$emit('isDataLoadingUpd', true);

            this.id = this.$route.params.id;

            try {
                const { data } = await OSSMVNEService.getOptions(this.id);
                this.options = data.wps;

                const {
                    data: {
                        profile_data: { iccid },
                    },
                } = await customerCareHTTP.getProfileInfo({
                    targetId: this.id,
                    targetType: UM_HIERARCHY.SUBSCRIBER,
                    tokenType: AUTH_ENUM_TOKEN_TYPE.TENANT,
                });
                this.iccid = iccid;
            } catch (error) {
                Sentry.captureException(error);
            }

            this.value = this.subscriber.wps || '';

            this.$emit('isDataLoadingUpd', false);
        },
        updateWPS() {
            this.isLoading = true;
            const { id, msisdn } = this.subscriber;
            this.$withProgressBar(
                async () => {
                    await OSSMVNEService.update(id, msisdn || '', this.iccid, this.value);
                    await this.getSubscriberInfo(id);
                    this.isLoading = false;
                    this.$alert(this.$t('customerCareSuite.wholesalePricingSchemaTile.alert.success'), {
                        type: this.$ALERT_TYPES.success,
                    });
                },
                {
                    errorHandler: () => {
                        this.isLoading = false;
                        this.$alert(this.$t('customerCareSuite.wholesalePricingSchemaTile.alert.error'));
                    },
                },
            );
        },
    },
});
