import {
    ACTION,
    SEARCH_INDEX,
    STATUS,
    CustomerLine as CustomerLineDno,
    QueryMsisdnResponse,
} from '@/__new__/services/dno/ossdevedge/models/QodMsisdnDno';
import {
    CustomerLine as CustomerLinePortal,
    COLUMN_TYPE_TO_COLUMN_DATA,
    COLUMN_DATA,
    API_TYPE_TO_CONFIG,
    API_TYPE_CONFIG,
} from '@/__new__/services/dno/ossdevedge/models/QodMsisdnPortal';
import type { AxiosResponse } from 'axios';
import { queryMsisdn } from '@/__new__/services/dno/developerLineAuthorization/http/developerLineAuthorization';
import i18n from '@/i18n';
import { DEVICE_LINE_AUTH_API_TYPE } from '../ossdevedge/models/DeveloperLineAuthorizationDno';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import * as Sentry from '@sentry/vue';

export interface CustomerLineEntitiesAndResponse {
    entities: CustomerLinePortal[];
    response: AxiosResponse<QueryMsisdnResponse>;
}

export const getCustomerLines = async (
    apiType: DEVICE_LINE_AUTH_API_TYPE,
    searchIndex: SEARCH_INDEX,
    searchString: string,
): Promise<CustomerLineEntitiesAndResponse> => {
    const response = await queryMsisdn(apiType, searchIndex, searchString);
    const entities: CustomerLinePortal[] = [];
    const entitiesDno: CustomerLineDno[] = response.data.customer_lines || [];
    let conversionErrorOccurred = false; // indicates atleast 1 conversion failed
    for (const customerLine of entitiesDno) {
        try {
            const customerLinePortal = convertCustomerLineDnoToPortal(customerLine);
            entities.push(customerLinePortal);
        } catch {
            conversionErrorOccurred = true;
        }
    }
    if (conversionErrorOccurred) {
        Sentry.captureException(
            new Error(`Failed to process some customer lines. Initial request id: ${response.data.request_id}`),
        );
    }
    return {
        entities,
        response,
    };
};

/**
 * Utility function to convert customer line data from DNO to Portal
 * @param record
 * @returns
 */
const convertCustomerLineDnoToPortal = (record: CustomerLineDno): CustomerLinePortal => {
    return {
        msisdn: record.msisdn,
        appClientId: record.app_client_id,
        channel: record.channel,
        customerBan: record.customer_ban,
        payingCustomerId: record.paying_customer_id,
        commercialOfferName: record.commercial_offer_name,
        action: record.action,
        actionStr: convertActionToString(record.action),
        status: record.naas_provisioning_status,
        statusStr: convertNaasProvisioningStatusToString(record.naas_provisioning_status),
        lastUpdatedAt: record.update_timestamp,
        lastUpdatedBy: record.create_portal_id,
        bulkUploadId: record.bulk_upload_id,
        customerName: record.customer_name ?? '',
        qodProfiles: record.qod_profiles?.join(';') ?? '',
        approverName: record.approver_name ?? '',
        serviceIds: record.service_ids?.join(';') ?? '',
    };
};

const convertActionToString = (action: ACTION): string => {
    switch (action) {
        case ACTION.ADD:
            return i18n.t('generic.add').toString();
        case ACTION.REMOVE:
            return i18n.t('generic.remove').toString();
        default:
            return '';
    }
};

const convertNaasProvisioningStatusToString = (status: STATUS): string => {
    switch (status) {
        case STATUS.ACCEPTED_RECORD:
            return i18n.t('qodNumberManagement.acceptedRecord').toString();
        case STATUS.NAAS_REJECT_REQUEST:
            return i18n.t('qodNumberManagement.rejected').toString();
        case STATUS.NAAS_ACCEPTED_REQUEST:
            return i18n.t('qodNumberManagement.inProgress').toString();
        case STATUS.CALLBACK_WITH_FAILED_STATUS:
            return i18n.t('generic.error').toString();
        case STATUS.CALLBACK_WITH_SUCCESS_STATUS:
            return i18n.t('qodNumberManagement.provisioned').toString();
        case STATUS.CALLBACK_NEVER_HAPPENED:
            return i18n.t('qodNumberManagement.timeout').toString();
        default:
            return '';
    }
};

/**
 * Gets list of all possible naas strings
 * Why? Iterating over a large number of records to determine all options is expensive
 */
export const getAllNaasStatusStrings = (): string[] => [
    convertNaasProvisioningStatusToString(STATUS.ACCEPTED_RECORD),
    convertNaasProvisioningStatusToString(STATUS.NAAS_REJECT_REQUEST),
    convertNaasProvisioningStatusToString(STATUS.NAAS_ACCEPTED_REQUEST),
    convertNaasProvisioningStatusToString(STATUS.CALLBACK_WITH_FAILED_STATUS),
    convertNaasProvisioningStatusToString(STATUS.CALLBACK_WITH_SUCCESS_STATUS),
    convertNaasProvisioningStatusToString(STATUS.CALLBACK_NEVER_HAPPENED),
];

/**
 * Get an ordered list of `COLUMN_DATA` for a given `apiType`
 * @param apiType
 * @returns
 */
export const getColumnData = (apiType: DEVICE_LINE_AUTH_API_TYPE): COLUMN_DATA[] => {
    const config = API_TYPE_TO_CONFIG.get(apiType);
    if (!config) {
        return [];
    }
    return config.columns
        .map(columnType => COLUMN_TYPE_TO_COLUMN_DATA.get(columnType))
        .filter(columnData => columnData !== undefined) as COLUMN_DATA[];
};

/**
 * Given an API type, builds the columnData information needed for the AppTable component.
 * @param apiType
 * @returns
 */
export const getAppTableColumnsData = (apiType: DEVICE_LINE_AUTH_API_TYPE): TableColumn[] => {
    const columnData = getColumnData(apiType);
    return columnData.map(data => ({
        name: data.label,
        key: data.key,
        field: data.key,
        filterType: data.options ? tableColumnType.TEXT_LIMITED_OPTIONS : tableColumnType.GENERAL_TEXT,
        limitedOptions: data.options,
    }));
};

export const getApiTypeConfig = (apiType: DEVICE_LINE_AUTH_API_TYPE): API_TYPE_CONFIG => {
    const apiTypeConfig = API_TYPE_TO_CONFIG.get(apiType);
    if (!apiTypeConfig) {
        throw new Error(`Config undefined for API type ${apiType}`);
    }
    return apiTypeConfig;
};
