








































// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';

// http
import osstmofiberHTTP from '@/__new__/services/dno/osstmofiber/http/osstmofiber';

// Helpers
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import Button from '@/common/button/Button';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Vue from 'vue';

export default Vue.extend({
    name: 'ResetSSIDAndPassphraseModal',
    components: {
        AppDialogV2,
        AppInputV3,
    },
    props: {
        value: {
            required: true,
            type: Boolean,
        },
        accountId: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            ssid: '',
            passphrase: '',
        };
    },
    validations() {
        return {
            ssid: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(64),
            },
            passphrase: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(63),
            },
        };
    },
    created() {
        this.$withProgressBar(
            async () => {
                const wifiCredentialsResponse = await osstmofiberHTTP.getWifiCredentials(
                    this.accountId,
                    USER_MANAGER_HIERARCHY.ACCOUNT,
                );
                this.ssid = wifiCredentialsResponse?.data?.external_response?.wifi_ssid || '';
            },
            {
                errorHandler: () =>
                    this.$showErrorAlert({
                        message: this.$i18n.t('alertMessage.somethingWentWrong'),
                    }),
            },
        );
    },
    methods: {
        close() {
            this.$emit('close');
        },
        onSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.submit();
        },
        submit() {
            const button = new Button({
                label: this.$i18n.t('generic.confirm'),
                alertType: ALERT_TYPES.warning,
                handler: () => {
                    this.resetSSIDAndPassphrase();
                },
            });
            this.$alert(this.$i18n.t('customerCareSuite.resetSSIDAndPassphraseModal.resetSSIDandPassphraseConfirm'), {
                type: ALERT_TYPES.warning,
                buttons: [button],
            });
        },
        resetSSIDAndPassphrase() {
            this.$withProgressBar(
                async () => {
                    await osstmofiberHTTP.updateWifiCredential(
                        this.accountId,
                        USER_MANAGER_HIERARCHY.ACCOUNT,
                        this.ssid,
                        this.passphrase,
                    );
                    this.$alert(
                        this.$i18n.t('customerCareSuite.resetSSIDAndPassphraseModal.resetSSIDandPassphraseSuccess'),
                        {
                            type: ALERT_TYPES.success,
                        },
                    );
                    this.close();
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.somethingWentWrong'),
                        }),
                },
            );
        },
    },
});
