





















































































































































import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

// Components
import AbstractTableTile, {
    type TableDefaultSort,
} from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';

// Helpers
import { TranslateResult } from 'vue-i18n';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import {
    DailyUsage,
    DailyUsageConstructorArgs,
    TABLES_TABS_IDS,
    MAP_TABLES_TABS_IDS_TO_RECORD_CATEGORY,
} from '@/__new__/features/customerCareSuite/common/serviceTransactionsHelper';

// HTTP
import ossmvneHTTP from '@/__new__/services/dno/ossmvne/http/ossmvne';
import Subscriber from '@/__new__/services/dno/user/models/Subscriber';
import { USER_MANAGER_HIERARCHY } from '../../customerCare/common/customerCareHelper';

type TableConfig = {
    columns: TableColumn[];
    entities: DailyUsage[];
    defaultSort: TableDefaultSort | null;
};

export default {
    name: 'ServiceTransactionsTile',
    components: {
        AbstractTableTile,
        AppAditionalSidebar,
    },
    data() {
        return {
            TABLES_TABS_IDS,
            activeTableId: TABLES_TABS_IDS.SUMMARY,
            dailyUsageData: [] as DailyUsage[],
            usageSummaryData: {} as any,
            apiResponse: {} as any,
            nextPageToken: '',
            displayTable: true,
            selectedEntity: null as DailyUsage | null,
            isSidebarVisible: false,
        };
    },
    computed: {
        ...mapGetters(Modules.userManagementSubscriber, [Getters.GET_SUBSCRIBER_INFO_BY_ID]),
        subscriber(): Subscriber {
            return new Subscriber(
                Subscriber.remapUserFromBe(this[Getters.GET_SUBSCRIBER_INFO_BY_ID](this.$route.params.id)),
            );
        },
        tablesTabs(): Array<{ id: TABLES_TABS_IDS; title: TranslateResult }> {
            return [
                {
                    id: TABLES_TABS_IDS.SUMMARY,
                    title: this.$i18n.t('generic.summary'),
                },
                {
                    id: TABLES_TABS_IDS.VOICE_SMS_USAGE,
                    title: this.$i18n.t('customerCare.serviceTransactionsTile.voiceSmsUsage'),
                },
                {
                    id: TABLES_TABS_IDS.DATA_USAGE,
                    title: this.$i18n.t('customerCare.serviceTransactionsTile.dataUsage'),
                },
            ];
        },
        tableConfig(): TableConfig {
            if (this.activeTableId === TABLES_TABS_IDS.SUMMARY)
                return {
                    columns: this.summaryColumnsData as TableColumn[],
                    entities: this.usageSummaryEntries,
                    defaultSort: {
                        key: 'order',
                        type: 'asc',
                    },
                };

            if (this.activeTableId === TABLES_TABS_IDS.VOICE_SMS_USAGE)
                return {
                    columns: this.voiceSmsColumnsData as TableColumn[],
                    entities: this.dailyUsageData,
                    defaultSort: null,
                };

            return {
                columns: this.dataColumnsData as TableColumn[],
                entities: this.dailyUsageData,
                defaultSort: null,
            };
        },
        voiceSmsColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.usageType'),
                    key: 'usageType',
                    field: 'usageType',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.subscriberMobileNumber'),
                    key: 'msisdn',
                    field: 'msisdn',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.startTimeOfTheUsageType'),
                    key: 'startTimeOfUsage',
                    field: 'startTimeOfUsage',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                    mapper: entity =>
                        this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.startTimeOfUsage),
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.terminatingNumberCalled'),
                    key: 'callTerminatingNumber',
                    field: 'callTerminatingNumber',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.voiceDurationRoundedMin'),
                    key: 'durationRoundedMin',
                    field: 'durationRoundedMin',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.usageCallDirection'),
                    key: 'callDirection',
                    field: 'callDirection',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.roamedCountry'),
                    key: 'callTerminatingLocation',
                    field: 'callTerminatingLocation',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        dataColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.usageType'),
                    key: 'usageType',
                    field: 'usageType',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.subscriberMobileNumber'),
                    key: 'msisdn',
                    field: 'msisdn',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.startTimeOfTheUsageType'),
                    key: 'startTimeOfUsage',
                    field: 'startTimeOfUsage',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                    mapper: entity =>
                        this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.startTimeOfUsage),
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.totalVolumeMb'),
                    key: 'totalVolume',
                    field: 'totalVolume',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.onNetUsage'),
                    key: 'onNetUsages',
                    field: 'onNetUsages',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.mmsIndicator'),
                    key: 'mmsIndicator',
                    field: 'mmsIndicator',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.roamedCountry'),
                    key: 'countryName',
                    field: 'countryName',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        summaryColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.usageSummary.columnCategory'),
                    key: 'category',
                    field: 'category',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.usageSummary.columnRemaining'),
                    key: 'remaining',
                    field: 'remaining',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.serviceTransactionsTile.usageSummary.columnBucketValue'),
                    key: 'bucket',
                    field: 'bucket',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        usageSummaryEntries() {
            const sortingOrder = {
                cat_voice_local: 1,
                cat_sms_local: 2,
                cat_data_local: 3,
                cat_voice_international: 4,
                cat_sms_international: 5,
                cat_data_international: 6,
                cat_voice_roaming: 7,
                cat_sms_roaming: 8,
                cat_data_roaming: 9,
            };
            return Object.entries(this.usageSummaryData).map(([key, value]) => {
                const isUnlimited = Boolean(value.bucket_value > 9999999);
                return {
                    order: sortingOrder[key as keyof typeof sortingOrder] || 99,
                    category: this.$te(`customerCare.serviceTransactionsTile.usageSummary.${key}`)
                        ? this.$t(`customerCare.serviceTransactionsTile.usageSummary.${key}`)
                        : key,
                    bucket: isUnlimited ? this.$t('generic.unlimitedCapital') : (value.bucket_value / 1024).toString(),
                    remaining: isUnlimited ? this.$t('generic.unlimitedCapital') : (value.remaining / 1024).toString(),
                };
            });
        },
        showSidebar(): boolean {
            return this.isSidebarVisible && Boolean(this.selectedEntity);
        },
    },
    watch: {
        subscriber() {
            this.fetchTileData();
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        fetchTileData() {
            this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    const { msisdn } = this.subscriber as Subscriber;

                    if (!msisdn) {
                        this.$emit('isDataLoadingUpd', false);
                        return;
                    }

                    if (this.activeTableId === TABLES_TABS_IDS.SUMMARY) {
                        await this.fetchUsageSummary();
                    } else {
                        await this.fetchDailyUsage(msisdn);
                    }
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$emit('isDataLoadingUpd', false);
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                    },
                },
            );
        },
        async fetchDailyUsage(msisdn: string) {
            this.apiResponse = await ossmvneHTTP.getDailyUsage(
                msisdn,
                MAP_TABLES_TABS_IDS_TO_RECORD_CATEGORY[this.activeTableId],
                this.nextPageToken || undefined,
            );
            this.nextPageToken = this.apiResponse?.data?.page_token || '';
            this.dailyUsageData = Array.isArray(this.apiResponse?.data?.records)
                ? this.apiResponse.data.records.map((el: DailyUsageConstructorArgs) => new DailyUsage(el))
                : [];
        },
        async fetchUsageSummary() {
            this.apiResponse = await ossmvneHTTP.getUsage(this.subscriber.id, USER_MANAGER_HIERARCHY.SUBSCRIBER);
            this.usageSummaryData = this.apiResponse?.data?.usage_report || {};
        },
        onTabSelected(tabId: TABLES_TABS_IDS): void {
            this.dailyUsageData = [];
            this.usageSummaryData = {};
            this.selectedEntity = null;
            this.isSidebarVisible = false;
            this.activeTableId = tabId;
            this.forceRerender();
            this.fetchTileData();
        },
        async forceRerender() {
            // force table columns to reset when tab changes
            this.displayTable = false;
            await this.$nextTick();
            this.displayTable = true;
        },
        rowSelected(rowid: string): void {
            if (this.activeTableId === TABLES_TABS_IDS.SUMMARY) return;
            this.selectedEntity = this.dailyUsageData.find(el => el.id === rowid) || null;
            this.isSidebarVisible = true;
        },
        onSidebarClose(): void {
            this.selectedEntity = null;
            this.isSidebarVisible = false;
        },
        isCurrentTableActive(tabId: TABLES_TABS_IDS): boolean {
            return this.activeTableId === tabId;
        },
    },
};
