



































import Vue from 'vue';

// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// Helpers
import {
    TRANSACTION_STATUS_TO_COLOR,
    TRANSACTION_STATUS_TO_LABEL,
    USER_MANAGER_HIERARCHY,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import * as Sentry from '@sentry/vue';
import tableColumnType from '@/common/filterTable';
import isEmpty from 'lodash/isEmpty';
import { FLAG_NAME_MAP, FLAG_STATUS_TO_LABEL, FLAG_STATUS_TO_COLOR } from '@/__new__/services/dno/user/models/Flag';
import { ICON_TYPES } from '@/common/iconHelper';
import { SENSITIVE_OPERATION_TYPES } from '@/__new__/features/customerCareSuite/common/endUserAuthorizationHelper';
import {
    CHANNEL,
    CHANNEL_STATES_TO_STATUS_NAME_MAP,
    SENSITIVE_TRANSACTIOS_USE_CASE,
    SERVICE_TYPE,
} from '@/__new__/features/customerCareSuite/common/SensitiveTransactionHistoryHelper';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

// HTTP
import RouteNames from '@/router/routeNames';
import customerCareHttp from '@/__new__/services/dno/user/http/customer-care';

// eslint-disable-next-line no-shadow
enum FLAG_CHANGES_TABLE {
    CHANGES_HISTORY = 'changesHistory',
    VERIFICATION_HISTORY = 'verificationHistory',
}

export default Vue.extend({
    name: 'FlagChangesHistoryTile',
    components: {
        AbstractTableTile,
        AppLabel,
        AppButton,
    },
    props: {
        userManagerHierarchy: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            displayTable: true,
            flagChangesHistory: [],
            flagChangesHistoryApiResponse: {},
            flagChangesVerificationHistory: [],
            flagChangesVerificationHistoryApiResponse: {},
            activeTab: FLAG_CHANGES_TABLE.CHANGES_HISTORY,

            BUTTON_TYPES,
            ICON_TYPES,
            isUserAllowed,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        entityData() {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: this.userManagerHierarchy,
                targetId: this.$route.params.id,
            });
        },
        tableKey() {
            return `flag-changes-table-${this.activeTab}`;
        },
        isChangeHistory() {
            return this.activeTab === FLAG_CHANGES_TABLE.CHANGES_HISTORY;
        },
        isVerificationHistory() {
            return this.activeTab === FLAG_CHANGES_TABLE.VERIFICATION_HISTORY;
        },
        entities() {
            if (this.isChangeHistory && !isEmpty(this.flagChangesHistory)) {
                return this.flagChangesHistory;
            }
            if (this.isVerificationHistory && !isEmpty(this.flagChangesVerificationHistory)) {
                return this.flagChangesVerificationHistory;
            }
            return [];
        },
        apiResponse() {
            if (this.isChangeHistory) {
                return this.flagChangesHistoryApiResponse;
            }
            if (this.isVerificationHistory) {
                return this.flagChangesVerificationHistoryApiResponse;
            }
            return {};
        },
        columnsData() {
            if (this.isChangeHistory) {
                return this.changesHistoryColumnsData;
            }
            if (this.isVerificationHistory) {
                return this.changesVerificationHistoryColumnsData;
            }
            return [];
        },
        changesHistoryColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.startTime'),
                    key: 'startTimeFormatted',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.startTime),
                    sortBy: entity => entity.startTime,
                    field: 'startTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.endTime'),
                    key: 'endTimeFormatted',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.endTime),
                    sortBy: entity => entity.endTime,
                    field: 'endTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    key: 'channel',
                    name: this.$i18n.t('generic.channel'),
                },
                {
                    key: 'status',
                    name: this.$i18n.t('generic.status'),
                },
                {
                    key: 'flagUpdated',
                    name: this.$i18n.t('generic.flagUpdated'),
                },
                {
                    key: 'targetEntity',
                    name: this.$i18n.t('generic.targetEntity'),
                },
            ];
        },
        changesVerificationHistoryColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.time'),
                    key: 'timeFormatted',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.time),
                    sortBy: entity => entity.time,
                    field: 'time',
                    filterType: tableColumnType.DATE,
                },
                {
                    key: 'channel',
                    name: this.$i18n.t('generic.channel'),
                },
                {
                    key: 'method',
                    name: this.$i18n.t('generic.method'),
                },
                {
                    key: 'status',
                    name: this.$i18n.t('generic.status'),
                },
                {
                    key: 'id',
                    name: this.$i18n.t('generic.id'),
                },
                {
                    key: 'userId',
                    name: this.$i18n.t('customerCare.userId'),
                },
                {
                    key: 'portalUserId',
                    name: this.$i18n.t('generic.portalUserId'),
                },
            ];
        },
        tabs() {
            return [
                {
                    id: FLAG_CHANGES_TABLE.CHANGES_HISTORY,
                    title: this.$i18n.t('customerCareSuite.flagChangesHistoryTile.changesHistory'),
                },
                {
                    id: FLAG_CHANGES_TABLE.VERIFICATION_HISTORY,
                    title: this.$i18n.t('customerCareSuite.flagChangesHistoryTile.verificationHistory'),
                },
            ];
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        fetchTileData() {
            this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    await this.getFlagChangesHistory();
                    await this.getFlagChangesVerificationHistory();
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: error => {
                        this.flagChangesHistoryApiResponse = error.response;
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                        Sentry.captureException(error);
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        async getFlagChangesHistory() {
            const response = await customerCareHttp.getUserSensitiveTransactionHistory(
                this.$route.params.id,
                this.userManagerHierarchy,
                SENSITIVE_TRANSACTIOS_USE_CASE?.UM_FLAGS,
            );
            this.flagChangesHistoryApiResponse = response;
            const transactions = !isEmpty(response?.data?.transactions) ? response.data.transactions : [];

            for (const transaction of transactions) {
                if (typeof transaction.flags === 'object' && transaction.flags !== null) {
                    for (const [key, value] of Object.entries(transaction.flags)) {
                        this.flagChangesHistory.push({
                            startTime: transaction.start_time,
                            endTime: transaction.end_time,
                            channel: CHANNEL_STATES_TO_STATUS_NAME_MAP.get(transaction.channel),
                            status: value,
                            flagUpdated: FLAG_NAME_MAP[key],
                            targetEntity: transaction.target_id,
                        });
                    }
                }
            }
        },
        async getFlagChangesVerificationHistory() {
            this.flagChangesVerificationHistoryApiResponse = await customerCareHttp.fetchServiceBlockerHistory(
                this.entityData?.userId,
                USER_MANAGER_HIERARCHY.USER,
                SERVICE_TYPE.END_USER_AUTHORIZATION,
            );
            const otpAttempts = !isEmpty(this.flagChangesVerificationHistoryApiResponse?.data?.otp_attempts)
                ? this.flagChangesVerificationHistoryApiResponse.data.otp_attempts
                : [];

            this.flagChangesVerificationHistory = otpAttempts
                ?.filter(el => el?.properties?.transaction_type === SENSITIVE_TRANSACTIOS_USE_CASE.UM_FLAGS)
                .map(el => {
                    return {
                        time: el.event_time,
                        channel: CHANNEL_STATES_TO_STATUS_NAME_MAP.get(
                            el?.properties?.portal_id ? CHANNEL.PORTAL : CHANNEL.CLIENT,
                        ),
                        method: el?.properties?.method || '',
                        status: el.event_type,
                        id: el?.properties?.external_id || '',
                        userId: el?.identifier || '',
                        portalUserId: el?.properties?.portal_id || '',
                    };
                });
        },
        getStatusMapping(entity) {
            if (this.isChangeHistory) {
                return {
                    title: FLAG_STATUS_TO_LABEL.get(entity.status),
                    color: FLAG_STATUS_TO_COLOR.get(entity.status),
                };
            }
            if (this.isVerificationHistory) {
                return {
                    title: TRANSACTION_STATUS_TO_LABEL.get(entity.status),
                    color: TRANSACTION_STATUS_TO_COLOR.get(entity.status),
                };
            }
            return {};
        },
        flagChange() {
            this.$router.push({
                name: RouteNames.END_USER_AUTHORIZATION,
                params: {
                    targetId: this.entityData?.accountId || '',
                    targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                    operationType: SENSITIVE_OPERATION_TYPES.FLAG_CHANGE,
                    operationTargetType: this.userManagerHierarchy,
                    operationTargetId: this.$route.params.id,
                },
            });
        },
        onTabSelected(id) {
            this.activeTab = id;
            this.forceRerender();
        },
        async forceRerender() {
            // Seems like table sets columns only on created or so
            this.displayTable = false;
            await this.$nextTick();
            this.displayTable = true;
        },
    },
});
