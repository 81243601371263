import http from '@/http';
import download from 'downloadjs';
import type { AxiosPromise } from 'axios';
import type {
    DATAFLOW_API_TYPE,
    RegisteredEntities,
    EntityDetailsByName,
} from '@/__new__/services/dno/progressTracker/progressTrackerHelper';

type GetSignedUrlResponse = {
    data: {
        signed_url: string;
        required_signed_url_headers?: {
            host: string;
        };
    };
};

export async function getDownloadUrl(
    fileName: string,
    dataflowId: DATAFLOW_API_TYPE,
): AxiosPromise<GetSignedUrlResponse> {
    return http({
        method: 'GET',
        url: '/v3operations/signedurl/download',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            entity_name: fileName,
            dataflow_id: dataflowId,
        },
    });
}

export async function downloadSignedUrlFile(fileName: string, dataflowId: DATAFLOW_API_TYPE): Promise<void> {
    const {
        data: {
            data: { signed_url: url, required_signed_url_headers: headers },
        },
    } = await getDownloadUrl(fileName, dataflowId);
    const fileRes = await fetch(url, { headers });
    const file = await fileRes.text();

    if (!fileRes.ok) {
        throw new Error(`Fetch to endpoint ${url} to download ${fileName} failed.`);
    }

    download(file, fileName, 'text/plain');
}

export function getRegisteredEntities(
    dataflowId: DATAFLOW_API_TYPE,
    page = 1,
    size = 500,
): AxiosPromise<RegisteredEntities> {
    return http({
        method: 'GET',
        url: '/v3operations/registeredentities',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            dataflow_id: dataflowId,
            page,
            size,
        },
    });
}

export async function getEntityDetailsByName(
    name: string,
    dataflowId: DATAFLOW_API_TYPE,
): AxiosPromise<EntityDetailsByName> {
    return http({
        method: 'GET',
        url: '/v3operations/entitydetailsbyname',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            dataflow_id: dataflowId,
            name,
        },
    });
}
export async function getEntityDetailsByCategory(
    name: string,
    dataflowId: DATAFLOW_API_TYPE,
    category: string,
    page = 1,
    size = 500,
): AxiosPromise<any> {
    return http({
        method: 'GET',
        url: '/v3operations/entitydetailsbycategory',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            dataflow_id: dataflowId,
            name,
            category,
            page,
            size,
        },
    });
}
