<template>
    <AbstractEditPageWrapper
        :sidebarPosition="sidebarPosition"
        :shouldDisplaySidebar="isFieldsConfigStep || isCohortExpressionStep"
        :sidebarWidth="30"
        class="no-vertical-scroll"
    >
        <template #header>
            <AppHeader :pageTitle="pageTitle" />
        </template>

        <template #stepper>
            <AppStepper
                data-test-id="stepper"
                :steps="enabledSteps"
                :activeStepIndex="activeStepIndex"
                @stepClick="onStepClick"
            />
        </template>

        <template #sideBar>
            <!--columns sidebar-->
            <div
                v-if="isFieldsConfigStep"
                class="py-3 pr-4"
            >
                <AppEntitiesManagementBlock
                    data-test-id="properties-sidebar"
                    :searchEnabled="true"
                    :title="$i18n.t('generic.columns')"
                    :subtitle="$i18n.t('sinkConfigs.managementBlockSubtitle')"
                    :entities="columnsConfigData"
                    :addBtnLabel="managementBlockAddFieldLabel"
                    :addAllBtnLabel="managementBlockAddAllFieldsLabel"
                    showAddAllEntitiesBtn
                    :isDraggable="allowColumnsDragging"
                    :activeEntityId="activeColumnId"
                    :allowEditing="allowFieldsEditing"
                    :invalidEntitiesIds="invalidColumnsIds"
                    @activeEntityChange="changeActiveColumn"
                    @addEntity="isAddColPopupVisible = true"
                    @addAllEntities="onAddAllColumns"
                    @deleteEntity="onDeleteColumn"
                />
            </div>
            <div
                v-if="isCohortExpressionStep"
                class="py-3 pr-4"
            >
                <AppEntitiesManagementBlock
                    :title="$t('sinkConfigs.triggerEvents')"
                    :subtitle="$t('sinkConfigs.triggerEventsLabel')"
                    :entities="triggerRelatedEvents"
                    :activeEntityId="activeTriggerRelatedEventId"
                    :allowEditing="false"
                    @activeEntityChange="id => (activeTriggerRelatedEventId = id)"
                />
            </div>
        </template>

        <template #content>
            <div class="editor-section">
                <div class="editor-section-content">
                    <!--general step content-->
                    <div
                        v-show="isGeneralStep"
                        class="mb-5"
                    >
                        <div class="editor-section-heading">
                            {{ title }}
                        </div>
                        <div>
                            <template v-for="(component, index) in dynamicComponents">
                                <component
                                    :is="component.type"
                                    v-if="component.visibleForAll"
                                    :key="component.name"
                                    :data-test-id="`dynamic-${component.name}`"
                                    v-bind="component.propsData"
                                    :disabled="!shouldAllowApprovedEditingByFieldName[component.name]"
                                    class="mb-3 editor-input-largest"
                                    :errorMessage="component.errorMsg"
                                    :invalid="!dynamicComponents[index].isValid"
                                    :error="!dynamicComponents[index].isValid"
                                    :formatter="getFormattingFunction(component)"
                                    :hasError="!dynamicComponents[index].isValid"
                                    @input="value => onDynamicComponentInput(value, index)"
                                    @tag="value => onDynamicComponentTagAdd(value, index)"
                                />
                            </template>
                        </div>
                        <div
                            v-show="isAdvancedConfigVisible"
                            class="editor-section-heading mt-5"
                        >
                            {{ $i18n.t('events.advancedConfiguration') }}
                        </div>
                        <div>
                            <template v-for="(component, index) in dynamicComponents">
                                <component
                                    :is="component.type"
                                    v-if="!component.visibleForAll"
                                    v-show="isAdvancedConfigVisible"
                                    :key="component.name"
                                    :data-test-id="`dynamic-${component.name}`"
                                    v-bind="component.propsData"
                                    :disabled="!shouldAllowApprovedEditingByFieldName[component.name]"
                                    class="mb-3 editor-input-largest"
                                    :errorMessage="component.errorMsg"
                                    :invalid="!dynamicComponents[index].isValid"
                                    :error="!dynamicComponents[index].isValid"
                                    :formatter="getFormattingFunction(component.formattingFunction)"
                                    @input="value => onDynamicComponentInput(value, index)"
                                    @tag="value => onDynamicComponentTagAdd(value, index)"
                                />
                            </template>
                            <AppButton
                                v-if="shouldShowAdvancedConfigBtn"
                                class="mt-3"
                                :label="advancedConfigBtnLabel"
                                @click="isAdvancedConfigVisible = !isAdvancedConfigVisible"
                            />
                        </div>
                    </div>

                    <!--triggers step content-->
                    <div
                        v-show="isEventSelectionStep"
                        class="pb-4 mr-5"
                    >
                        <div class="editor-section-heading">
                            {{ $i18n.t('events.applyConditions') }}
                        </div>
                        <Filters
                            data-test-id="filtersBlock"
                            :stats="[]"
                            :selectFilterInvalid="selectFilterInvalid"
                            :showStats="false"
                            topLevelCombinator="or"
                            entityType="Trigger"
                            :hideHeader="true"
                            :conditionInstancesJson="initialConditionsJson"
                            :conditionDefinitionsById="triggerDefinitions"
                            @updatedConditionInstancesJson="updatedConditionInstancesJson"
                            @onConditionsValidationError="onConditionsValidationError"
                        />

                        <AppToggle
                            v-if="updatedConditionsJson.length"
                            :value="isCohortsStepEnabled"
                            :label="$t('sinkConfigs.cohorts.cohortTogglerLabel')"
                            :small="true"
                            class="mt-4"
                            @input="handleCohortTogglerChange"
                        />
                    </div>

                    <!--columns step content-->
                    <div v-if="isFieldsConfigStep">
                        <SinkConfigColumnPopup
                            data-test-id="sink-config-column-popup"
                            :dontUseSnakeCaseInName="uiSettings.dontUseSnakeCaseInColumnNames"
                            :isVisible="isAddColPopupVisible"
                            :uiLabels="configColumnUILabels"
                            :events="triggerRelatedEvents"
                            @close="isAddColPopupVisible = false"
                            @save="onAddColumn"
                        />
                        <template v-if="columnsConfigData.length">
                            <SinkConfigColumn
                                v-if="!jsonPathAPIisLoading"
                                :columnData="activeColumn"
                                data-test-id="configColumn"
                                :eventOptions="triggerRelatedEvents"
                                :defaultValueTypes="defaultValueTypes"
                                :availableFormatterNames="availableFormatterNames"
                                :availableTypeCasters="availableTypeCasters"
                                :timezoneLabel="timezoneValue"
                                :fieldNameFormattingFunction="
                                    getFormattingFunction(uiSettings.fieldNameFormattingFunction)
                                "
                                class="mb-3"
                                :uiLabels="configColumnUILabels"
                                :showSumLatest="uiSettings.showSumLatest"
                                :isDraftState="isDraftState"
                                @deleteRow="onDeleteRow"
                                @jsonPathToggle="jsonPathToggle"
                                @addRow="onAddRow"
                                @eventNameSelect="onEventNameSelect"
                                @jsonPathTypeSelect="jsonPathTypeSelect"
                                @jsonPathSelect="jsonPathSelect"
                                @eventPropertySelect="onEventPropertySelect"
                                @formatterNameChange="onFormatterNameChange"
                                @toggleFormatterBtnClick="onToggleFormatterBtnClick"
                                @typeCasterChange="onTypeCasterChange"
                                @toggleTypeCasterBtnClick="onToggleTypeCasterBtnClick"
                            />
                            <div
                                v-else
                                class="pt-4 columns-preloader position-relative"
                            >
                                <AppSpinner :isVisible="true" />
                            </div>
                        </template>
                        <div
                            v-else
                            class="empty-columns-box text-center"
                        >
                            <img src="@/assets/icons/empty-table.svg" />
                            <div class="empty-columns-state-text m-auto mt-2">
                                {{ $i18n.t('sinkConfigs.emptyColumnsText') }}
                            </div>
                        </div>
                    </div>

                    <!--cohort expression step content-->
                    <div v-if="isCohortExpressionStep">
                        <SinkConfigCohorts
                            class="sink-cohorts"
                            :triggerRelatedEvent="activeTriggerRelatedEvent"
                            :cohortsData="cohortsDataByEventId[activeTriggerRelatedEventId]"
                            @cohortsDataChange="changeCohortData"
                        />
                    </div>

                    <!-- overview step content-->
                    <div v-if="isReviewStep">
                        <OverviewHeaderV2
                            :entityNameLength="140"
                            :entityName="configName"
                            :entityId="editableConfig ? editableConfig.id : ''"
                        >
                            <template slot="statusIndicator">
                                <SinkConfigStatusIndicator :status="configStatusIndicator" />
                            </template>
                        </OverviewHeaderV2>

                        <div class="editor-input-largest">
                            <AppOverviewBlock
                                class="mt-4"
                                :isRowTitleCounterVisible="false"
                                :maxItems="100"
                                :maxItemRows="100"
                                :items="dynamicComponentsOverviewList"
                            />

                            <FiltersRenderer
                                class="my-4"
                                :title="$t('events.eventFilter')"
                                topLevelCombinator="or"
                                :filters="updatedConditionsJson"
                                :conditionDefinitionsById="triggerDefinitions"
                            />

                            <SinkConfigCohortsOverview
                                v-if="isCohortsStepEnabled"
                                class="my-4"
                                :cohortsDataByEventId="cohortsDataByEventId"
                            />

                            <div
                                v-if="isSendRawEnabled"
                                class="empty-columns-box text-center"
                            >
                                <img src="@/assets/icons/empty-table.svg" />
                                <div class="empty-columns-state-text m-auto mt-2">
                                    {{ $i18n.t('sinkConfigs.rawFormatText') }}
                                </div>
                            </div>
                            <div v-else>
                                <AppOverviewBlock
                                    :isRowTitleCounterVisible="false"
                                    :title="columnsOverviewTitle"
                                    :items="columnsOverview"
                                    :maxItemRows="100"
                                    :maxItems="100"
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="dynamicComponents.length"
                        class="mt-4"
                    />
                </div>
            </div>
        </template>

        <template #controls>
            <AppButton
                :label="$i18n.t('generic.cancel')"
                @click="onCancel"
            />
            <AppButton
                v-if="shouldShowSaveDraftBtn"
                class="ml-5"
                data-test-id="save-draft-btn"
                :isLoading="isDraftBtnLoading"
                :buttonType="BUTTON_TYPES.SECONDARY"
                :label="$i18n.t('generic.saveDraft')"
                @click="saveDraft"
            />
            <AppButton
                class="ml-5"
                :isLoading="isSaveBtnLoading"
                :buttonType="BUTTON_TYPES.PRIMARY"
                :label="proceedNextBtnLabel"
                :iconType="proceedNextBtnIcon"
                :isIconRight="true"
                data-test-id="proceed-next-btn"
                @click="proceedNext"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
// components
import AppHeader from '@/components/layout/AppHeader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppSpinner from '@/components/partials/AppSpinner.vue';
import AbstractEditPageWrapper, { EDITOR_PAGE_SIDEBAR_POSITION } from '@/components/layout/AbstractEditPageWrapper.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import Filters from '@/components/partials/filters/Filters.vue';
import AppEntitiesManagementBlock from '@/components/partials/AppEntitiesManagementBlock.vue';
import SinkConfigColumn from '@/__new__/features/sinkConfigs/SinkConfigColumn.vue';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import SinkConfigStatusIndicator from '@/__new__/features/sinkConfigs/SinkConfigStatusIndicator.vue';
import FiltersRenderer from '@/components/partials/filters/FiltersRenderer.vue';
import AppStepper from '@/components/partials/AppStepper.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import CronExpressionInput from '@/__new__/features/sinkConfigs/CronExpressionInput.vue';
import SinkConfigCohorts from '@/__new__/features/sinkConfigs/SinkConfigCohorts.vue';
import CohortExpression from '@/components/cohortExpression/CohortExpression.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import SinkConfigCohortsOverview from '@/__new__/features/sinkConfigs/SinkConfigCohortsOverview.vue';
import SinkConfigColumnPopup from '@/__new__/features/sinkConfigs/SinkConfigColumnPopup.vue';

// helpers
import keyBy from 'lodash/keyBy';
import { getRandomLetter, stringToSnakeCase } from '@/common/formatting';
import { eventRequiredFieldsFromJson, sinkFormattingFunctions } from '@/common/events/eventsHelper';
import { cloneDeep, isEqual, difference, uniqBy, isEmpty } from 'lodash';
import * as Sentry from '@sentry/vue';
import RouteNames from '@/router/routeNames';
import entityEditorMixin from '@/common/entityEditorMixin';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import {
    SinkConfig,
    sinkValidationFunctions,
    specificConfigurationJson,
    STEP_NAMES,
    validateFieldsConfigStep,
    isCohortExpressionsStepValid,
} from '@/__new__/services/dno/sinkConfigs/models/SinkConfig';
import permissionsService from '@/services/permissions/permissions.service';
import {
    SINK_CONFIG_STATUS_SERVER_CODE_MAP,
    SINK_CONFIG_STATUS,
} from '@/__new__/services/dno/sinkConfigs/models/SinkConfigStatus';
import { entityTypeDisplayName } from '@/common/utils';
import Button from '@/common/button/Button';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import {
    configColumnsToJson,
    mapColumnsForOverview,
    SinkConfigCol,
} from '@/__new__/services/dno/sinkConfigs/models/SinkConfigCol';
import { ICON_TYPES } from '@/common/iconHelper';
import { CDP_PROPERTY_JSON_PATH_TYPES } from '@/__new__/services/dno/events/models/EventProp';
import Actions from '@/store/mutation-types';
import { mapActions } from 'vuex';
import { Modules } from '@/store/store';
import {
    createEmptyCohortExpressionForSink,
    createEmptyCohortExpressionsForSink,
} from '@/common/cohortExpressionHelper';

export default {
    name: 'SinkConfigEditor',
    components: {
        SinkConfigCohortsOverview,
        SinkConfigCohorts,
        CohortExpression,
        AppOverviewBlock,
        SinkConfigColumnPopup,
        AppToggle,
        OverviewList,
        AppEntitiesManagementBlock,
        AppStepper,
        AppHeader,
        AppButton,
        AppInputV3,
        AppSpinner,
        AbstractEditPageWrapper,
        Filters,
        AppMultiselectV3,
        SinkConfigColumn,
        OverviewHeaderV2,
        SinkConfigStatusIndicator,
        FiltersRenderer,
        AppTextareaV3,
        CronExpressionInput,
    },
    mixins: [entityEditorMixin],
    props: {
        entityType: {
            type: String,
            required: true,
            default: ENTITY_TYPES.ORD_CONFIG,
        },
        createFunction: {
            type: Function,
            required: true,
        },
        updateFunction: {
            type: Function,
            required: false,
            default: () => undefined,
        },
        saveDraftFunction: {
            type: Function || null,
            default: null,
        },
        uiSettings: {
            type: Object,
            required: true,
        },
        typeCasters: {
            type: Object || null,
            default: null,
        },
        dashboardPageName: {
            type: String,
            required: true,
            default: RouteNames.ORD_CONFIG_DASHBOARD,
        },
        editableConfig: {
            type: Object,
            required: false,
            default: undefined,
        },
        events: {
            type: Array,
            required: true,
        },
        sinkRequiredColumns: {
            type: Array,
            default: () => [],
        },
        formatters: {
            type: Array,
            default: () => [],
        },
        triggerDefinitions: { type: Object, default: () => undefined },
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            saveInProcess: false,
            dynamicComponents: [],
            title: '',
            isAdvancedConfigVisible: false,
            columnsConfigData: [],
            invalidColumnsIds: [],
            activeStepIndex: 0,
            jsonPathAPIisLoading: false,
            activeColumn: {},
            areColumnsInitialized: false,
            selectFilterInvalid: null,
            initialConditionsJson: [],
            updatedConditionsJson: [],
            isAddColPopupVisible: false,
            isDraftBtnLoading: false,
            isSaveBtnLoading: false,

            steps: [
                {
                    name: STEP_NAMES.GENERAL,
                    title: this.$t('generic.general'),
                    isCompleted: Boolean(this.editableConfig),
                    shouldShow: true,
                },
                {
                    name: STEP_NAMES.EVENT_SELECTION,
                    title: this.$t('events.eventSelection'),
                    isCompleted: Boolean(this.editableConfig?.filters.length),
                    shouldShow: true,
                },
                {
                    name: STEP_NAMES.COHORT_EXPRESSION,
                    title: this.$t('sinkConfigs.cohorts.cohortTogglerLabel'),
                    isCompleted: !isEmpty(this.editableConfig?.cohortsData),
                    shouldShow: false,
                },

                {
                    name: STEP_NAMES.FIELDS_CONFIG,
                    title: `${entityTypeDisplayName(this.entityType)} ${this.$t('generic.output').toLowerCase()}`,
                    isCompleted: Boolean(this.editableConfig?.fieldsConfiguration.length),
                    shouldShow: true,
                },
                {
                    name: STEP_NAMES.REVIEW,
                    title: `${entityTypeDisplayName(this.entityType)} ${this.$t('generic.review').toLowerCase()}`,
                    isCompleted: this.editableConfig?.filters.length && this.editableConfig?.fieldsConfiguration.length,
                    shouldShow: true,
                },
            ],

            activeTriggerRelatedEventId: '',
            cohortsDataByEventId: {},
        };
    },

    computed: {
        enabledSteps() {
            return this.steps.filter(step => step.shouldShow);
        },
        isCohortsStepEnabled() {
            return this.steps.find(s => s.name === STEP_NAMES.COHORT_EXPRESSION).shouldShow;
        },
        isGeneralStep() {
            return this.enabledSteps[this.activeStepIndex].name === STEP_NAMES.GENERAL;
        },
        isEventSelectionStep() {
            return this.enabledSteps[this.activeStepIndex].name === STEP_NAMES.EVENT_SELECTION;
        },
        isFieldsConfigStep() {
            return this.enabledSteps[this.activeStepIndex].name === STEP_NAMES.FIELDS_CONFIG;
        },
        isCohortExpressionStep() {
            return this.enabledSteps[this.activeStepIndex].name === STEP_NAMES.COHORT_EXPRESSION;
        },
        isReviewStep() {
            return this.enabledSteps[this.activeStepIndex].name === STEP_NAMES.REVIEW;
        },
        isSendRawEnabled() {
            return this.dynamicComponents.find(comp => comp.name === 'should_send_raw_event')?.propsData.value;
        },
        configColumnUILabels() {
            return this.uiSettings.fieldsLabels || {};
        },
        managementBlockAddFieldLabel() {
            return this.configColumnUILabels.addFieldBtnLabel || this.$i18n.t('events.addColumn');
        },
        managementBlockAddAllFieldsLabel() {
            return this.configColumnUILabels.addAllFieldsBtnLabel || this.$i18n.t('events.addAllColumns');
        },
        timezoneValue() {
            const timezone = this.dynamicComponents.find(comp => comp.name === 'timezone');
            return timezone?.propsData.value || '';
        },
        availableFormatterNames() {
            return this.formatters
                .filter(({ columnType }) => columnType === this.activeColumn.defaultValueType)
                .map(({ name }) => name);
        },
        availableTypeCasters() {
            const columnCurrentType =
                this.columnsConfigData[this.activeColumnIndex].formatter?.conversionType ||
                this.columnsConfigData[this.activeColumnIndex].defaultValueType;
            return this.typeCasters[columnCurrentType];
        },
        configName() {
            return this.dynamicComponents.find(component => component.name === 'name').propsData.value || '';
        },
        proceedNextBtnIcon() {
            return this.isReviewStep ? ICON_TYPES.CHECK : ICON_TYPES.ARROW_RIGHT;
        },
        columnsOverviewTitle() {
            return `${entityTypeDisplayName(this.entityType)} ${this.$i18n.t('generic.fields').toLowerCase()}`;
        },
        configStatusIndicator() {
            return this.editableConfig?.state || SINK_CONFIG_STATUS.UNAPPROVED;
        },
        allowFieldsEditing() {
            if (this.isCloning || this.isDraftState) return true;

            return this.isEditing ? !this.uiSettings.fieldsEditRules.forbidFieldAddition : true;
        },
        allowColumnsDragging() {
            const approvedState = SINK_CONFIG_STATUS_SERVER_CODE_MAP[SINK_CONFIG_STATUS.APPROVED];
            if (this.isEditing && this.editableConfig.state === approvedState) {
                return (
                    this.uiSettings.fieldsEditRules.allowColumnsDragDrop &&
                    this.uiSettings.fieldsEditRules.forbidFieldReordering
                );
            }
            return true;
        },
        shouldShowSaveDraftBtn() {
            return [SINK_CONFIG_STATUS.APPROVED, SINK_CONFIG_STATUS.UNAPPROVED, SINK_CONFIG_STATUS.ENABLED].includes(
                this.editableConfig?.state,
            )
                ? false
                : !!this.saveDraftFunction;
        },
        isDraftState() {
            return this.editableConfig?.state === SINK_CONFIG_STATUS.DRAFT;
        },
        columnsOverview() {
            return mapColumnsForOverview(this.columnsConfigData);
        },
        dynamicComponentsOverviewList() {
            const rows = this.dynamicComponents.map(comp => ({
                name: comp.propsData.label || comp.propsData.additionalLabel,
                value: comp.propsData.value || this.$i18n.t('generic.notSpecified'),
            }));
            return [
                {
                    name: this.$t('generic.general'),
                    isCollapsed: false,
                    rows,
                },
            ];
        },
        activeColumnId() {
            return this.activeColumn?.id || '';
        },
        activeColumnIndex() {
            return this.columnsConfigData.indexOf(this.activeColumn);
        },
        defaultValueTypes() {
            return CDP_PROPERTY_JSON_PATH_TYPES;
        },
        triggerRelatedEvents() {
            const events = [];
            if (this.updatedConditionsJson.length) {
                const uniqueTriggers = uniqBy(this.updatedConditionsJson, trigger => trigger.filterDefinitionId);

                uniqueTriggers.forEach(trigger => {
                    const eventIdToFind = trigger.filterDefinitionId;
                    const triggerRelatedEvent = this.events?.find(ev => ev.id === eventIdToFind);
                    events.push({
                        name: triggerRelatedEvent?.name || '',
                        eventType: triggerRelatedEvent?.eventType || '',
                        id: triggerRelatedEvent?.id || '',
                        properties: triggerRelatedEvent?.properties || [],
                    });
                });
            }
            return events;
        },
        activeTriggerRelatedEvent() {
            return this.triggerRelatedEvents.find(ev => ev.id === this.activeTriggerRelatedEventId);
        },
        proceedNextBtnLabel() {
            return (
                this.enabledSteps[this.activeStepIndex + 1]?.title ||
                (this.isEditing ? this.$i18n.t('generic.update') : this.$i18n.t('generic.save'))
            );
        },
        sidebarPosition() {
            return this.isFieldsConfigStep || this.isCohortExpressionStep
                ? EDITOR_PAGE_SIDEBAR_POSITION.LEFT
                : EDITOR_PAGE_SIDEBAR_POSITION.RIGHT;
        },
        editableColumnsConfig() {
            if (this.editableConfig?.fieldsConfiguration.length) {
                //  we add required columns since editable config might not contain it (i.e draft)
                return uniqBy(this.editableConfig.fieldsConfiguration.concat(this.requiredColumns), col => col.name);
            }
            return null;
        },
        // is applicable only to 1st and 2nd steps
        shouldForbidEditing() {
            if (this.isCloning) return false;
            return (
                this.isEditing &&
                [SINK_CONFIG_STATUS.APPROVED, SINK_CONFIG_STATUS.ENABLED].includes(this.editableConfig.state)
            );
        },
        shouldAllowApprovedEditingByFieldName() {
            const componentsNames = this.shouldForbidEditing
                ? this.uiSettings.allowApprovedFieldsEdit
                : this.dynamicComponents.map(comp => comp.name);
            return keyBy(
                this.isEditing ? difference(componentsNames, this.uiSettings.forbidFieldsEdit) : componentsNames,
            );
        },
        advancedConfigBtnLabel() {
            return this.isAdvancedConfigVisible ? this.$t('events.hideAdvConfig') : this.$t('events.showAdvConfig');
        },
        shouldShowAdvancedConfigBtn() {
            const configHasAdvancedSettings = this.dynamicComponents?.some(
                component => component.visibleForAll === false,
            );
            return permissionsService.isUserLotusFlareAdmin() && configHasAdvancedSettings;
        },

        requiredColumns() {
            return eventRequiredFieldsFromJson(this.sinkRequiredColumns, this.triggerRelatedEvents);
        },
    },
    watch: {
        updatedConditionsJson: {
            handler(newVal, oldVal) {
                if (newVal.length && this.isCohortsStepEnabled) {
                    // set data for cohort step
                    this.cohortsDataByEventId = {};
                    this.updatedConditionsJson.forEach((event, index) => {
                        const eventId = event.filterDefinitionId;
                        const alreadyExistedInEditableConfig = !!this.editableConfig?.cohortsData?.[eventId];
                        this.$set(
                            this.cohortsDataByEventId,
                            eventId,
                            alreadyExistedInEditableConfig
                                ? this.editableConfig.cohortsData[eventId]
                                : createEmptyCohortExpressionForSink(),
                        );
                        if (index === 0) {
                            this.activeTriggerRelatedEventId = eventId;
                        }
                    });
                }
                if (newVal.length && !this.areColumnsInitialized) {
                    this.areColumnsInitialized = true;
                    this.initializeColumns();
                } else if (this.areColumnsInitialized) {
                    const newConditionIds = newVal.map(condition => condition.filterDefinitionId);
                    const oldConditionIds = oldVal.map(condition => condition.filterDefinitionId);
                    if (!isEqual(newConditionIds, oldConditionIds)) {
                        this.removeUnusedTriggersFromColumns();
                        [this.activeColumn] = this.columnsConfigData;
                    }
                }
            },
        },
        columnsConfigData: {
            deep: true,
            handler() {
                this.resetInvalidColumnsIds();
                this.transformColumnsNames();
                this.$eventBus.$emit('closeAlertIfError');
            },
        },
    },
    created() {
        try {
            this.initUiSettings();
            if (this.isEditing || this.isCloning) {
                this.initEditPage();
            }
        } catch (err) {
            Sentry.captureException(err);
        }
    },
    methods: {
        ...mapActions(Modules.sinkConfigs, [Actions.LOAD_JSON_PATH_OPTIONS]),

        changeCohortData(data) {
            this.$set(this.cohortsDataByEventId, this.activeTriggerRelatedEventId, data);
        },
        shouldShowStep(stepName, value) {
            const step = this.steps.find(s => s.name === stepName);
            if (step) {
                step.shouldShow = value;
            }
        },
        updatedConditionInstancesJson(json) {
            this.updatedConditionsJson = json;
            this.selectFilterInvalid = json.length === 0;
            if (this.selectFilterInvalid) {
                this.$eventBus.$emit('showAlert', { message: this.filterValidationError });
            }
        },
        handleCohortTogglerChange(shouldShowCohortsStep) {
            this.shouldShowStep(STEP_NAMES.COHORT_EXPRESSION, shouldShowCohortsStep);

            if (shouldShowCohortsStep) {
                const triggerRelatedEventIds = this.triggerRelatedEvents.map(ev => ev.eventType);
                this.cohortsDataByEventId = createEmptyCohortExpressionsForSink(triggerRelatedEventIds);
                [this.activeTriggerRelatedEventId] = Object.keys(this.cohortsDataByEventId);
            } else {
                this.cohortsDataByEventId = {};
                this.activeTriggerRelatedEventId = '';
            }
        },
        onConditionsValidationError(conditionsValidationError) {
            if (conditionsValidationError && conditionsValidationError !== this.conditionsValidationError) {
                this.$eventBus.$emit('showAlert', { message: conditionsValidationError });
            }
            this.conditionsValidationError = conditionsValidationError;
        },
        resetActiveColumnFormatters() {
            this.$set(this.columnsConfigData[this.activeColumnIndex], 'formatter', null);
        },
        resetActiveColumnTypeCaster() {
            this.updateColumnsDefaultValueType();
            this.$set(
                this.columnsConfigData[this.activeColumnIndex],
                'typeCaster',
                this.availableTypeCasters?.isRequired ? this.availableTypeCasters.castingOptions[0] : null,
            );
        },
        transformColumnsNames() {
            this.columnsConfigData.forEach((column, columnIndex) => {
                let updatedColumnName = column.name.trim();
                if (!this.uiSettings.dontUseSnakeCaseInColumnNames) {
                    updatedColumnName = stringToSnakeCase(column.name);
                }
                this.$set(this.columnsConfigData[columnIndex], 'name', updatedColumnName);
            });
        },
        updateColumnsDefaultValueType() {
            this.columnsConfigData.forEach((column, index) => {
                // Must fallback to sourceType in order for formatting options to be displayed properly.
                const rowTypes = column.rowValues.map(row =>
                    row.isJsonPathEnabled ? row.jsonPathType : row.property.sourceType,
                );

                const areTheSameType = new Set(rowTypes).size === 1;
                if (areTheSameType) {
                    this.$set(this.columnsConfigData[index], 'defaultValueType', rowTypes[0]);
                }
            });
        },
        onDeleteColumn() {
            if (this.columnsConfigData.length === 1) {
                this.$alert(this.$t('events.alerts.cantDeleteLastColumnInConfig'));
                return;
            }

            const confirmationButton = new Button({
                label: this.$t('generic.yes'),
                dataTestId: 'delete-column',
                alertType: ALERT_TYPES.warning,
                handler: () => {
                    const columnsWithoutDeleted = this.columnsConfigData.filter(
                        (column, index) => index !== this.activeColumnIndex,
                    );
                    this.columnsConfigData = columnsWithoutDeleted;
                    this.changeActiveColumn(columnsWithoutDeleted[0].id);
                },
            });

            this.$alert(this.$t('alerts.areYouSure'), {
                type: ALERT_TYPES.warning,
                buttons: [confirmationButton],
            });
        },
        onAddColumn({ name, rowValues, popupIsStillVisible }) {
            const isDocTheSame = new Set(rowValues.map(row => row.doc)).size === 1;
            const shouldAddTypeCaster = this.typeCasters[rowValues[0]?.type]?.isRequired;

            const newCol = new SinkConfigCol({
                name,
                doc: isDocTheSame && rowValues[0].doc,
                isDefaultValueEnabled: !rowValues.length,
                defaultValueType: this.defaultValueTypes[0],
                forbidFieldRemoval: false,
                rowValues: rowValues.map(row => ({
                    eventType: row.eventType,
                    eventName: row.eventName,
                    property: row,
                })),
                typeCaster: shouldAddTypeCaster ? this.typeCasters[rowValues[0]?.type]?.castingOptions[0] : null,
            });

            this.activeColumn = newCol;
            this.columnsConfigData.push(newCol);
            this.isAddColPopupVisible = popupIsStillVisible;
            this.updateColumnsDefaultValueType();
        },
        onAddAllColumns() {
            const requiredColumnsByName = keyBy(this.requiredColumns || [], column => column.name);
            const initialColumnsByName = keyBy(this.columnsConfigData, column => column.name);

            const allColumnsByName = this.triggerRelatedEvents
                .flatMap(event => event.properties.map(property => ({ property, event })))
                .filter(({ property }) => !requiredColumnsByName[property.name])
                .reduce((columnsByName, { property, event }) => {
                    const rowValue = {
                        eventType: event.eventType,
                        eventName: event.name,
                        property,
                    };

                    if (!columnsByName[property.name]) {
                        const shouldAddTypeCaster = this.typeCasters[property.type]?.isRequired;
                        const newColumnName = this.uiSettings.dontUseSnakeCaseInColumnNames
                            ? property.name
                            : stringToSnakeCase(property.name);
                        columnsByName[newColumnName] = new SinkConfigCol({
                            name: property.name,
                            doc: property.doc,
                            defaultValueType: this.defaultValueTypes[0],
                            rowValues: [rowValue],
                            typeCaster: shouldAddTypeCaster ? this.typeCasters[property.type]?.castingOptions[0] : null,
                        });
                    } else {
                        const alreadyAddedRow = columnsByName[property.name].rowValues.find(
                            row => row.eventName === event.name && row.property.name === property.name,
                        );

                        if (!alreadyAddedRow) {
                            const isDocTheSame =
                                new Set(columnsByName[property.name].rowValues.map(row => row.property.doc)).size === 1;

                            columnsByName[property.name].rowValues.push(rowValue);
                            columnsByName[property.name].doc = isDocTheSame
                                ? columnsByName[property.name].rowValues[0].property.doc
                                : '';
                        }
                    }
                    return columnsByName;
                }, initialColumnsByName);

            const allColumns = Object.values(allColumnsByName);
            [this.activeColumn] = allColumns;
            // sort function is applied in order to display grouped fields at the top
            this.columnsConfigData =
                this.isEditing &&
                [SINK_CONFIG_STATUS.DRAFT, SINK_CONFIG_STATUS.UNAPPROVED].includes(this.editableConfig?.state)
                    ? this.sortColumnsByAmountOfTriggerEvents(allColumns)
                    : allColumns;
            this.updateColumnsDefaultValueType();
        },
        onEventNameSelect(newEventName, rowIndex) {
            this.resetActiveColumnFormatters();
            this.resetActiveColumnTypeCaster();
            this.$set(this.columnsConfigData[this.activeColumnIndex].rowValues[rowIndex], 'eventName', newEventName);
            const first = this.triggerRelatedEvents.find(event => event.name === newEventName);
            // reset property to the first if event is changed to another
            this.$set(
                this.columnsConfigData[this.activeColumnIndex].rowValues[rowIndex],
                'property',
                first.properties[0],
            );
            this.$set(this.columnsConfigData[this.activeColumnIndex].rowValues[rowIndex], 'eventType', first.eventType);
        },
        jsonPathTypeSelect(type, rowIndex) {
            this.$set(this.columnsConfigData[this.activeColumnIndex].rowValues[rowIndex], 'jsonPathType', type);
            this.resetActiveColumnTypeCaster();
        },
        jsonPathSelect(val, rowIndex) {
            this.$set(this.columnsConfigData[this.activeColumnIndex].rowValues[rowIndex], 'jsonPath', val);
            this.resetActiveColumnTypeCaster();
        },
        onEventPropertySelect(newPropValue, rowIndex) {
            this.resetActiveColumnFormatters();
            this.$set(this.columnsConfigData[this.activeColumnIndex].rowValues[rowIndex], 'property', newPropValue);
            if (
                rowIndex === 0 &&
                this.isEditing &&
                !this.columnsConfigData[this.activeColumnIndex].forbidFieldNameChange
            ) {
                this.$set(this.columnsConfigData[this.activeColumnIndex], 'name', newPropValue.name);
            }
            this.resetActiveColumnTypeCaster();
        },
        onFormatterNameChange(name) {
            const chosenFormatter = this.formatters.find(formatter => formatter.name === name);
            if (chosenFormatter) {
                this.$set(this.columnsConfigData[this.activeColumnIndex], 'formatter', cloneDeep(chosenFormatter));
            }
            this.resetActiveColumnTypeCaster();
        },
        onToggleFormatterBtnClick() {
            const isFormatterEnabled = Boolean(this.columnsConfigData[this.activeColumnIndex].formatter);
            if (isFormatterEnabled) {
                this.resetActiveColumnFormatters();
            } else {
                this.onFormatterNameChange(this.availableFormatterNames[0]);
            }
            this.resetActiveColumnTypeCaster();
        },
        onTypeCasterChange(typeCaster) {
            this.$set(this.columnsConfigData[this.activeColumnIndex], 'typeCaster', typeCaster);
        },
        onToggleTypeCasterBtnClick() {
            const isTypeCasterEmpty = isEmpty(this.columnsConfigData[this.activeColumnIndex].typeCaster);
            const defaultTypeCasterPerColumnType =
                this.typeCasters[this.activeColumn.defaultValueType]?.castingOptions[0];

            this.$set(
                this.columnsConfigData[this.activeColumnIndex],
                'typeCaster',
                isTypeCasterEmpty ? defaultTypeCasterPerColumnType : null,
            );
        },
        onAddRow() {
            const newRow = {
                eventName: this.triggerRelatedEvents[0].name,
                eventType: this.triggerRelatedEvents[0].eventType,
                property: this.triggerRelatedEvents[0].properties[0],
            };
            const newRowType = this.triggerRelatedEvents[0].properties[0].type;
            const shouldAddTypeCaster =
                !!this.typeCasters[newRowType] && isEmpty(this.columnsConfigData[this.activeColumnIndex].typeCaster);
            if (shouldAddTypeCaster) {
                this.$set(
                    this.columnsConfigData[this.activeColumnIndex],
                    'typeCaster',
                    this.typeCasters[newRowType]?.castingOptions[0],
                );
            }
            this.$set(
                this.columnsConfigData[this.activeColumnIndex].rowValues,
                this.columnsConfigData[this.activeColumnIndex].rowValues.length,
                newRow,
            );
        },
        resetInvalidColumnsIds() {
            this.invalidColumnsIds = [];
        },
        onDeleteRow(rowIndex) {
            const withoutDeleted = this.columnsConfigData[this.activeColumnIndex].rowValues.filter(
                (row, index) => index !== rowIndex,
            );
            this.$set(this.columnsConfigData[this.activeColumnIndex], 'rowValues', withoutDeleted);
            const numberOfRows = this.columnsConfigData[this.activeColumnIndex].rowValues.length;
            if (numberOfRows === 0) {
                this.resetActiveColumnFormatters();
                this.resetActiveColumnTypeCaster();
                this.$set(this.columnsConfigData[this.activeColumnIndex], 'isDefaultValueEnabled', true);
            }
        },
        jsonPathToggle(rowIndex) {
            const row = this.columnsConfigData[this.activeColumnIndex].rowValues[rowIndex];
            this.$set(row, 'isJsonPathEnabled', !row.isJsonPathEnabled);
            this.resetActiveColumnTypeCaster();
        },
        removeUnusedTriggersFromColumns() {
            const usedEventTypes = this.triggerRelatedEvents.map(ev => ev.eventType);
            this.columnsConfigData = this.columnsConfigData.map(col => {
                const requiredColumn = this.requiredColumns?.find(reqCol => reqCol.name === col.name);
                // totally reset required columns
                if (requiredColumn) return requiredColumn;
                return {
                    ...col,
                    rowValues: col.rowValues.filter(row => usedEventTypes.includes(row.eventType)),
                    formatter: null,
                };
            });
        },
        initializeColumns() {
            if (this.editableColumnsConfig) {
                this.columnsConfigData = cloneDeep(this.editableColumnsConfig);
                // eslint-disable-next-line prefer-destructuring
                this.activeColumn = this.columnsConfigData[0];
            } else if (this.requiredColumns.length) {
                // eslint-disable-next-line prefer-destructuring
                this.activeColumn = this.requiredColumns[0];
                this.columnsConfigData = this.requiredColumns;
            }
        },
        changeActiveColumn(id) {
            if (this.activeColumnId === id) return;
            this.activeColumn = this.columnsConfigData.find(config => config.id === id) || {};
        },
        validateStep() {
            if (this.isGeneralStep) {
                if (!this.areAllDynamicComponentsValid()) {
                    this.$alert(this.$i18n.t('events.alerts.configConfigurationFieldHasError'));
                    return false;
                }
            }
            if (this.isEventSelectionStep) {
                if (!this.updatedConditionsJson.length) {
                    this.$alert(this.$i18n.t('events.alerts.configTriggerIsRequired'));
                    return false;
                }

                // filter validation
                if (this.conditionsValidationError) {
                    this.$alert(this.conditionsValidationError);
                    return false;
                }
            }
            if (this.isCohortsStepEnabled && this.isCohortExpressionStep) {
                if (!isCohortExpressionsStepValid(this.cohortsDataByEventId)) {
                    this.$alert(this.$t('generic.cohorts.applicableFieldCouldNotBeEmpty'));
                    return false;
                }
            }
            if (this.isFieldsConfigStep) {
                // expects error object (or undefined in case if no errors)
                const fieldsStepValidationError = validateFieldsConfigStep(
                    this.columnsConfigData,
                    this.uiSettings,
                    this.editableConfig,
                );
                if (fieldsStepValidationError) {
                    this.$alert(fieldsStepValidationError.errMsg);
                    this.invalidColumnsIds = fieldsStepValidationError.errColumnIds;
                    return false;
                }
            }
            return true;
        },
        saveDraft() {
            const stepIsValid = this.validateStep();
            if (stepIsValid) {
                this.$eventBus.$emit('closeAlertIfError');
                this.saveOrUpdateConfig(true);
            }
        },
        proceedNext() {
            const stepIsValid = this.validateStep();
            if (stepIsValid) {
                this.$set(this.enabledSteps[this.activeStepIndex], 'isCompleted', true);
                this.$eventBus.$emit('closeAlertIfError');
                if (this.isReviewStep) {
                    this.saveOrUpdateConfig();
                } else {
                    this.activeStepIndex += 1;
                    if (this.isFieldsConfigStep) {
                        this.updatedConditionsJson.forEach(trigger => {
                            const eventId = Object.keys(trigger.values[0])[0];
                            this.jsonPathAPIisLoading = true;
                            this[Actions.LOAD_JSON_PATH_OPTIONS](eventId).then(() => {
                                this.jsonPathAPIisLoading = false;
                            });
                        });
                    }
                }
            }
        },
        onStepClick(stepIndex) {
            const previousStepIsCompleted = this.enabledSteps[stepIndex - 1]?.isCompleted;
            const isStepValid = this.validateStep(this.activeStepIndex);
            if (!isStepValid) return;
            if (previousStepIsCompleted || stepIndex === 0) {
                this.activeStepIndex = stepIndex;
            }
        },
        initSpecificConfigEdit() {
            const specificConfigEntries = Object.entries(this.editableConfig.specificConfig);
            specificConfigEntries.forEach(entry => {
                const dynamicComponentToUpdate = this.dynamicComponents.find(comp => comp.name === entry[0]);

                if (dynamicComponentToUpdate) {
                    const componentRelatedCloneRule = this.uiSettings.cloneRules.find(
                        rule => rule.field === dynamicComponentToUpdate.name,
                    );

                    if (this.isCloning && componentRelatedCloneRule) {
                        const modifiedValue = componentRelatedCloneRule.shouldAddRandomLetter
                            ? entry[1] + componentRelatedCloneRule.suffix + getRandomLetter()
                            : entry[1] + componentRelatedCloneRule.suffix;

                        this.$set(dynamicComponentToUpdate.propsData, 'value', modifiedValue);
                    } else {
                        this.$set(dynamicComponentToUpdate.propsData, 'value', entry[1]);
                    }
                }
            });
        },
        initEditPage() {
            this.initialConditionsJson = this.editableConfig.filters;

            this.initSpecificConfigEdit();

            if (this.isSendRawEnabled) {
                this.shouldShowStep(STEP_NAMES.FIELDS_CONFIG, false);
            }

            if (!isEmpty(this.editableConfig.cohortsData)) {
                this.shouldShowStep(STEP_NAMES.COHORT_EXPRESSION, true);
            }
            this.cohortsDataByEventId = this.editableConfig.cohortsData;
            this.activeTriggerRelatedEventId = this.isCohortsStepEnabled
                ? Object.keys(this.cohortsDataByEventId)[0]
                : '';
        },

        isDynamicComponentValid(component) {
            const allValidationsResult = [];

            component.validationFunctions.forEach(func => {
                const validationResult = sinkValidationFunctions[func].fn(component.propsData.value);

                allValidationsResult.push(validationResult);
                if (!validationResult) {
                    this.$set(component, 'errorMsg', sinkValidationFunctions[func].errorMsg);
                }
            });
            return allValidationsResult.every(result => result === true);
        },
        areAllDynamicComponentsValid() {
            this.dynamicComponents.forEach(component => {
                if (component.validationFunctions?.length) {
                    this.$set(component, 'isValid', this.isDynamicComponentValid(component));
                }
            });
            return this.dynamicComponents.every(component => component.isValid === true);
        },
        getFormattingFunction(fnName) {
            return fnName ? sinkFormattingFunctions[fnName] : null;
        },
        onDynamicComponentInput(value, index) {
            this.handleDynamicComponentInput(value, index);
            const isNameField = this.dynamicComponents[index].name === 'name';
            const isSendRawEventToggler = this.dynamicComponents[index].name === 'should_send_raw_event';

            if (isNameField) {
                const dependentFieldIndex = this.dynamicComponents?.findIndex(
                    comp => comp.dependsOnSinkEventName === true,
                );
                const dependentFieldName = this.dynamicComponents[dependentFieldIndex].name;
                const shouldModifyDependentField = this.isDraftState
                    ? true
                    : dependentFieldIndex >= 0 && !this.isEditing;
                const shouldModifyDependentFieldInEditMode =
                    dependentFieldIndex >= 0 &&
                    this.isEditing &&
                    this.uiSettings?.allowApprovedFieldsEdit?.includes(dependentFieldName);
                if (shouldModifyDependentField || shouldModifyDependentFieldInEditMode) {
                    this.handleDynamicComponentInput(stringToSnakeCase(value), dependentFieldIndex);
                }
            }
            if (isSendRawEventToggler) {
                this.shouldShowStep(STEP_NAMES.FIELDS_CONFIG, !value);
            }
        },
        handleDynamicComponentInput(value, index) {
            this.entityEditorMixin.dirtyFlag = true;
            const editableComponent = this.dynamicComponents[index];
            this.$set(editableComponent.propsData, 'value', value);
            if (editableComponent?.validationFunctions?.length) {
                this.$set(editableComponent, 'isValid', this.isDynamicComponentValid(editableComponent));
            }
        },
        onDynamicComponentTagAdd(value, index) {
            const editableComponent = this.dynamicComponents[index];
            // add tag to the options in dropdown
            this.$set(editableComponent.propsData.options, editableComponent.propsData.options.length, value);
            this.onDynamicComponentInput(value, index);
        },
        initUiSettings() {
            const clonedUiSettings = cloneDeep(this.uiSettings);
            this.dynamicComponents = clonedUiSettings.specificConfig;
            this.title = clonedUiSettings.title;
        },

        navigateToEventsDashboardPageWithTimeout() {
            setTimeout(() => {
                this.saveInProcess = false;
                this.$router.push({
                    name: this.dashboardPageName,
                    params: { companyId: this.$route.params.companyId },
                });
            }, 2000);
        },
        sortColumnsByAmountOfTriggerEvents(allColumns) {
            return allColumns.sort((a, b) => b.rowValues.length - a.rowValues.length);
        },

        async saveOrUpdateConfig(saveDraftBtnClicked) {
            if (this.saveInProcess) return;

            this.$Progress.start();
            this.saveInProcess = true;
            const currentConfig = new SinkConfig({
                name: this.configName,
                id: this.editableConfig?.id,
                filters: this.updatedConditionsJson,
                specificConfig: specificConfigurationJson(this.dynamicComponents),
                version: this.editableConfig?.version,
                state: saveDraftBtnClicked ? SINK_CONFIG_STATUS.DRAFT : SINK_CONFIG_STATUS.ENABLED,
                fieldsConfiguration: this.isSendRawEnabled
                    ? []
                    : configColumnsToJson(this.columnsConfigData, this.uiSettings.showSumLatest),
                cohortsData: this.isCohortsStepEnabled ? this.cohortsDataByEventId : undefined,
            });
            try {
                if (this.isEditing && !this.isDraftState) {
                    this.isSaveBtnLoading = true;
                    const payload = currentConfig.toJson(true);
                    await this.updateFunction(payload);
                } else if (saveDraftBtnClicked) {
                    this.isDraftBtnLoading = true;
                    const payload = currentConfig.toJson(this.isDraftState);
                    await this.saveDraftFunction(payload);
                } else {
                    this.isSaveBtnLoading = true;
                    await this.createFunction(currentConfig.toJson(this.isDraftState));
                }
                this.$eventBus.$emit('showAlert', {
                    message: this.successSaveMessage,
                    type: ALERT_TYPES.success,
                });
                this.$Progress.finish();
                this.entityEditorMixin.successfullySaved = true;
                this.navigateToEventsDashboardPageWithTimeout();
            } catch (error) {
                Sentry.captureException(error);
                this.$alert(error.msg || this.$i18n.t('events.alerts.failedToSaveConfig'));
                this.$Progress.fail();
                this.saveInProcess = false;
            } finally {
                this.isDraftBtnLoading = false;
                this.isSaveBtnLoading = false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette.scss';
@import '~@/assets/scss/consistency';

// Fix double scrollbars from AbstractEditPageWrapper
.no-vertical-scroll {
    overflow-y: hidden;
}
// Fix content scrollbar being always visible.
::v-deep .form-content-wrapper {
    overflow-y: auto;
}

.columns-overview {
    min-height: 100%;
}
.title {
    line-height: 1.86;
    font-size: $text-sm;
    font-weight: $medium-font-weight;
    color: $blue60;
}
.empty-columns-state-text {
    opacity: 0.5;
    font-size: 1rem;
    color: $blue;
    width: fit-content;
    font-weight: 600;
}
.empty-columns-box {
    pointer-events: none;
    padding-top: 5rem;
}

.sink-cohorts {
    //the same paddings as event-config-column class has in SinkConfigColumn.vue
    padding-left: 3rem;
    padding-right: 5rem;
}
.columns-preloader {
    height: 50vh;
}
</style>
