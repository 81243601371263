
import Vue from 'vue';

// Components
import BulkUploadPageLayout from '@/__new__/features/resources/BulkUploadPageLayout.vue';

// HTTP
import { getUploadHistory } from '@/__new__/services/dno/progressTracker/http/progressTrackerService';

// Helpers
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import { capitalize } from 'lodash';
import { getAllFileUploadStatusStrings, type UploadedFileDetails } from '@/common/fileUploadHelper';
import { type CollapsibleListItem } from '@/common/AppCollapsibleListHelper';
import { DATAFLOW_API_TYPE } from '@/__new__/services/dno/progressTracker/progressTrackerHelper';

export default Vue.extend({
    name: 'UpdateWps',
    components: {
        BulkUploadPageLayout,
    },
    data() {
        return {
            dataflow: DATAFLOW_API_TYPE.WPS_BULK,
            isHistoryDataLoading: false,
            uploadHistory: [] as UploadedFileDetails[],
            uploadHistoryDefaultSort: {
                key: 'createdAtStr',
                sortBy: (entity: UploadedFileDetails) => entity.createdAt,
                type: 'desc',
            },
            uploadColumns: [
                {
                    index: 1,
                    name: this.$t('generic.msisdn'),
                    description: '7064097571',
                },
                {
                    index: 2,
                    name: 'newWPS',
                    description: '50001000',
                },
            ],
        };
    },
    computed: {
        uploadHistoryColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$t('generic.uploadTime'),
                    key: 'createdAtStr',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.createdAt),
                    sortBy: entity => entity.createdAt,
                    field: 'createdAt',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$t('generic.filename'),
                    key: 'fileName',
                    field: 'fileName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('qodNumberManagement.recordCount'),
                    key: 'recordCount',
                    field: 'recordCount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$t('generic.status'),
                    key: 'fileUploadStatusStr',
                    field: 'fileUploadStatusStr',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: getAllFileUploadStatusStrings(),
                },
            ];
        },
    },
    created() {
        this.loadHistory();
    },
    methods: {
        mapSidebarData(entity: any): CollapsibleListItem[] {
            return [
                {
                    name: this.$t('generic.general'),
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$t('generic.filename'),
                            value: entity?.fileName ?? '',
                        },
                        {
                            name: this.$t('qodNumberManagement.bulkUploadId'),
                            value: entity?.bulkUploadId ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('qodNumberManagement.recordCount'),
                            value: entity?.recordCount ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('generic.uploadedBy'),
                            value: entity?.createdBy ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('generic.uploadTime'),
                            value: this.$localeLibrary.getFormattedDateAndTime(entity?.createdAt),
                        },
                    ],
                },
                {
                    name: this.$t('qodNumberManagement.provisionStatus'),
                    isCollapsed: false,
                    rows: Object.entries(entity?.eventCategoryCounters || {}).map(([status, count]) => ({
                        name: capitalize(String(status).replace(/_/g, ' ')),
                        value: `${count}/${entity?.recordCount}`,
                    })),
                },
            ];
        },
        async loadHistory() {
            await this.$withProgressBar(
                async () => {
                    this.isHistoryDataLoading = true;
                    this.uploadHistory = await getUploadHistory(DATAFLOW_API_TYPE.WPS_BULK);
                    this.isHistoryDataLoading = false;
                },
                {
                    errorHandler: () => {
                        this.isHistoryDataLoading = false;
                        this.$alert(this.$t('qodNumberManagement.loadingBulkUploadHistoryFailed'));
                    },
                },
            );
        },
    },
});
