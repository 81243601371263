

































































































































































import Vue from 'vue';

// Components
import AppHeader from '@/components/layout/AppHeader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppTable from '@/components/partials/AppTable.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppJSON from '@/components/partials/AppJSON.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import CollapsibleItem from '@/components/partials/CollapsibleItem.vue';
import GetExecutionDetails from '@/__new__/features/orchestrationengine/batchActions/GetExecutionDetails.vue';

// Validations
import { validationMixin } from 'vuelidate';
import { minLength, requiredIf, minValue } from 'vuelidate/lib/validators';

// HTTP
import {
    executePlanImmediately,
    multiRestartState,
    scheduleMultiExecution,
    getDetailsFromExecutions,
} from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';
import {
    batchUpdateScheduledExecutionTime,
    batchHaltExecutions,
} from '@/__new__/services/dno/orchestrationengine/http/batchActions';

// Helppers
import { TranslateResult } from 'vue-i18n';
import { ICON_TYPES } from '@/common/iconHelper';
import {
    BATCH_ACTIONS,
    BATCH_ACTION_TYPE,
    ScheduleMultiExecution,
    GetExecutionDetails as GetExecutionDetailsType,
    mapDataToBePayloadForGetDetailsFromExecutions,
} from '@/__new__/services/dno/orchestrationengine/batchActions';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import { PromiseAllSettledStatus, PromiseAllSettled, DnoResponseBase } from '@/http/index';
import luaErrors from '@/common/luaErrors';
import moment from 'moment';
import { ALERT_TYPES } from '@/common/alerts/Alert';

export default Vue.extend({
    name: 'BatchActionsEditorPage',
    components: {
        AppHeader,
        AppButton,
        AppTable,
        AppInputV3,
        AppTextareaV3,
        AppJSON,
        AppToggle,
        DateTimePicker,
        CollapsibleItem,
        GetExecutionDetails,
    },
    mixins: [validationMixin],
    props: {
        executions: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,
            BATCH_ACTIONS,
            BATCH_ACTION_TYPE,

            executionIds: '' as string,
            selectedAction: '' as BATCH_ACTION_TYPE,
            actionName: '' as string,
            actionDescription: '' as string,
            disableSaveButton: false,
            showExecutionLog: false,
            runExecutionJson: {},
            useShiftBy: false,
            scheduleTime: null as Date | null,
            schedulePlanId: '' as string,
            schedulePeriodS: null as Date | null,
            scheduleInputs: {} as any,
            scheduleInputsIsValid: false,
            showScheduleInputs: true,
            getExecutionDetailsData: [] as GetExecutionDetailsType[],
            showExecutionDetailsData: true,
        };
    },
    validations: {
        actionName: {
            // eslint-disable-next-line func-names
            required: requiredIf(function () {
                return this.actionNameInUse;
            }),
        },
        scheduleTime: {
            // eslint-disable-next-line func-names
            required: requiredIf(function () {
                return this.currentActionInUse(BATCH_ACTION_TYPE.POSTPONE_EXECUTION);
            }),
        },
        schedulePlanId: {
            // eslint-disable-next-line func-names
            required: requiredIf(function () {
                return this.currentActionInUse(BATCH_ACTION_TYPE.SCHEDULE_EXECUTION);
            }),
        },
        scheduleInputs: {
            // eslint-disable-next-line func-names
            required: requiredIf(function () {
                return this.currentActionInUse(BATCH_ACTION_TYPE.SCHEDULE_EXECUTION);
            }),
        },
        schedulePeriodS: {
            // eslint-disable-next-line func-names
            required: requiredIf(function () {
                return this.currentActionInUse(BATCH_ACTION_TYPE.SCHEDULE_EXECUTION);
            }),
        },
        getExecutionDetailsData: {
            // eslint-disable-next-line func-names
            required: requiredIf(function () {
                return this.currentActionInUse(BATCH_ACTION_TYPE.GET_EXECUTION_DETAILS);
            }),
            minLength: minLength(1),
            $each: {
                searchParameters: {
                    state: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.currentActionInUse(BATCH_ACTION_TYPE.GET_EXECUTION_DETAILS);
                        }),
                    },
                    parameters: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.currentActionInUse(BATCH_ACTION_TYPE.GET_EXECUTION_DETAILS);
                        }),
                        minLength: minLength(1),
                    },
                    iteration: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.currentActionInUse(BATCH_ACTION_TYPE.GET_EXECUTION_DETAILS);
                        }),
                        minValue: minValue(1),
                    },
                    retryNum: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.currentActionInUse(BATCH_ACTION_TYPE.GET_EXECUTION_DETAILS);
                        }),
                        minValue: minValue(1),
                    },
                },
            },
        },
    },
    computed: {
        pageTitle(): TranslateResult {
            return this.$route.params.id
                ? this.$i18n.t('orchestrationEngine.batchActions.editBatchAction')
                : this.$i18n.t('orchestrationEngine.batchActions.addBatchAction');
        },
        actionsColumns(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('generic.actions'),
                    key: 'name',
                    field: 'name',
                    classes: ['font-weight-bold'],
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        disableSaveBtn(): boolean {
            return !this.selectedAction || this.disableSaveButton;
        },
        getArrayOfExecutionIds(): string[] {
            return (
                this.executionIds
                    .replace(/\n/g, '')
                    .split(',')
                    .filter(id => id.trim()) || []
            );
        },
        dateTimePickerType(): string {
            return this.useShiftBy ? 'time' : 'datetime';
        },
        dateTimePickerLabel(): TranslateResult {
            return this.useShiftBy
                ? this.$i18n.t('orchestrationEngine.batchActions.shiftBy')
                : this.$i18n.t('orchestrationEngine.batchActions.scheduleDateTime');
        },
        formatScheduleTime(): number {
            if (!this.scheduleTime) {
                return 0;
            }

            if (this.useShiftBy) {
                return this.convertDateToSeconds(this.scheduleTime);
            }

            return moment(this.scheduleTime).unix();
        },
        dateTimePickerFormat(): string {
            return this.useShiftBy ? 'HH:mm' : 'DD-MM-YYYY HH:mm';
        },
        actionNameInUse(): boolean {
            return (
                this.currentActionInUse(BATCH_ACTION_TYPE.POSTPONE_EXECUTION) ||
                this.currentActionInUse(BATCH_ACTION_TYPE.HALT_EXECUTION)
            );
        },
        appJsonHeight(): string {
            switch (this.selectedAction) {
                case BATCH_ACTION_TYPE.SCHEDULE_EXECUTION:
                    return '35rem';
                case BATCH_ACTION_TYPE.HALT_EXECUTION:
                    return '41rem';
                case BATCH_ACTION_TYPE.POSTPONE_EXECUTION:
                    return '44rem';
                default:
                    return '46rem';
            }
        },
        disabledExecutionsIdsInput(): boolean {
            return this.currentActionInUse(BATCH_ACTION_TYPE.SCHEDULE_EXECUTION);
        },
    },
    watch: {
        getArrayOfExecutionIds: {
            deep: true,
            immediate: true,
            handler() {
                this.createGetExecutionDetailsObj();
            },
        },
    },
    created(): void {
        this.executionIds = this.executions;
    },
    methods: {
        onSelectedAction(actionId: BATCH_ACTION_TYPE): void {
            this.selectedAction = actionId;
            this.showExecutionLog = false;
            this.runExecutionJson = {};
        },
        convertDateToSeconds(date: Date): number {
            const time = moment(date);
            return time.hours() * 3600 + time.minutes() * 60;
        },
        onCancel(): void {
            this.$router.go(-1);
        },
        currentActionInUse(actionType: BATCH_ACTION_TYPE): boolean {
            return this.selectedAction === actionType;
        },
        validateIds(): boolean {
            if (!this.executionIds) {
                return false;
            }

            const idPattern = /^([a-zA-Z0-9]{32})(,\s*[a-zA-Z0-9]{32})*(,\s*)?\n?$/i;
            const idsArray = this.executionIds.split(',').map(id => id.trim());
            for (const id of idsArray) {
                if (!idPattern.test(id)) {
                    return false;
                }
            }

            return true;
        },
        onSave(): any {
            this.$v.$touch();
            if (this.$v.$invalid || this.disableSaveButton) {
                this.showGetExecutionDetailsError();
                return;
            }

            if (!this.disabledExecutionsIdsInput && !this.validateIds()) {
                this.$alert(this.$i18n.t('orchestrationEngine.batchActions.executionsIdsError'));
                return;
            }

            this.showExecutionLog = false;
            this.disableSaveButton = true;

            switch (this.selectedAction) {
                case BATCH_ACTION_TYPE.RUN_EXECUTION:
                    this.runExecution();
                    break;
                case BATCH_ACTION_TYPE.RESUME_EXECUTION:
                    this.resumeExecution();
                    break;
                case BATCH_ACTION_TYPE.POSTPONE_EXECUTION:
                    this.postponeExecution();
                    break;
                case BATCH_ACTION_TYPE.HALT_EXECUTION:
                    this.haltExecution();
                    break;
                case BATCH_ACTION_TYPE.SCHEDULE_EXECUTION:
                    this.scheduleExecution();
                    break;
                case BATCH_ACTION_TYPE.GET_EXECUTION_DETAILS:
                    this.getExecutionDetails();
                    break;
                default:
                    this.$alert(this.$i18n.t('orchestrationEngine.batchActions.batchActionsError'));
                    break;
            }

            this.disableSaveButton = false;
        },
        runExecution(): void {
            this.$withProgressBar(
                async () => {
                    const promises: any = [];
                    this.getArrayOfExecutionIds.forEach(id => {
                        promises.push(
                            executePlanImmediately({
                                execution_id: id.trim(),
                            }),
                        );
                    });

                    const data = await Promise.allSettled(promises);
                    data.forEach((res: any, index: number) => {
                        this.runExecutionJson = {
                            ...this.runExecutionJson,
                            [this.getArrayOfExecutionIds[index].trim()]: this.createResponceForRunExecution(res),
                        };
                    });

                    this.showExecutionLog = true;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'));
                    },
                },
            );
        },
        createResponceForRunExecution(data: PromiseAllSettled<DnoResponseBase>): TranslateResult {
            if (data.status === PromiseAllSettledStatus.FULFILLRF) {
                return this.$i18n.t('orchestrationEngine.batchActions.runExecutionSuccessful');
            }

            switch (data?.reason?.response?.data.code) {
                case luaErrors.ORCHESTRATION.RESERVATION_NOT_FOUND.code:
                    return this.$i18n.t('orchestrationEngine.batchActions.runExecutionErrScheduled');
                default:
                    return '';
            }
        },
        resumeExecution(): void {
            this.$withProgressBar(
                async () => {
                    /* eslint-disable camelcase */
                    const data: { execution_id: string }[] = this.getArrayOfExecutionIds.map(id => ({
                        execution_id: id,
                    }));

                    await multiRestartState(data);
                    this.$alert(this.$i18n.t('orchestrationEngine.batchActions.resumeExecutionSuccessful'), {
                        type: ALERT_TYPES.success,
                    });
                },
                {
                    errorHandler: (error: any) => {
                        if (error?.response?.data.code === luaErrors.ORCHESTRATION.STATE_NOT_RESTARTABLE.code) {
                            this.$alert(this.$i18n.t('orchestrationEngine.batchActions.cantResumeExecutionError'));
                        } else {
                            this.$alert(this.$i18n.t('orchestrationEngine.batchActions.resumeExecutionError'));
                        }
                    },
                },
            );
        },
        postponeExecution(): void {
            this.$withProgressBar(
                async () => {
                    const { data } = await batchUpdateScheduledExecutionTime({
                        execution_ids: this.getArrayOfExecutionIds,
                        name: this.actionName,
                        description: this.actionDescription,
                        [this.useShiftBy ? 'shift_by' : 'schedule_to_timestamp']: this.formatScheduleTime,
                    });
                    this.runExecutionJson = data;
                    this.showExecutionLog = true;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('orchestrationEngine.batchActions.postponeExecutionError'));
                    },
                },
            );
        },
        haltExecution(): void {
            this.$withProgressBar(
                async () => {
                    const { data } = await batchHaltExecutions(this.getArrayOfExecutionIds, this.actionName);
                    this.runExecutionJson = data;
                    this.showExecutionLog = true;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('orchestrationEngine.batchActions.haltExecutionError'));
                    },
                },
            );
        },
        scheduleExecution(): void {
            if (!this.scheduleInputsIsValid) {
                return;
            }
            this.$withProgressBar(
                async () => {
                    if (!this.schedulePeriodS) {
                        return;
                    }
                    const payload: ScheduleMultiExecution = {
                        plan_id: this.schedulePlanId,
                        inputs: [this.scheduleInputs],
                        period_s: this.convertDateToSeconds(this.schedulePeriodS),
                    };
                    const { data } = await scheduleMultiExecution(payload);

                    this.runExecutionJson = data.result;
                    this.showExecutionLog = true;
                    this.showScheduleInputs = false;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('orchestrationEngine.batchActions.scheduleExecutionError'));
                    },
                },
            );
        },
        hideScheduleExecutionRes(): void {
            this.showExecutionLog = false;
            this.runExecutionJson = {};
            this.showScheduleInputs = true;
        },
        createGetExecutionDetailsObj(): void {
            let isFirstEl = true;
            this.getExecutionDetailsData = this.getArrayOfExecutionIds.map((el: string) => {
                const obj: GetExecutionDetailsType = {
                    value: !isFirstEl,
                    title: el,
                    executionId: el,
                    error: false,
                    searchParameters: {
                        state: '',
                        parameters: [],
                        iteration: 1,
                        retryNum: 1,
                    },
                };

                isFirstEl = false;
                return obj;
            });
        },
        showGetExecutionDetailsError(): void {
            if (!this.currentActionInUse(BATCH_ACTION_TYPE.GET_EXECUTION_DETAILS)) return;
            this.getExecutionDetailsData.forEach((el: GetExecutionDetailsType, index: number) => {
                el.error = this.$v.getExecutionDetailsData.$each[index].$error;
            });
        },
        getExecutionDetails(): void {
            this.$withProgressBar(
                async () => {
                    const payload = {
                        data: mapDataToBePayloadForGetDetailsFromExecutions(this.getExecutionDetailsData),
                    };
                    const { data } = await getDetailsFromExecutions(payload);

                    this.runExecutionJson = data.execution_details;
                    this.showExecutionLog = true;
                    this.showExecutionDetailsData = false;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('orchestrationEngine.batchActions.executionDetailsError'));
                    },
                },
            );
        },
        hideExecutionDetails(): void {
            this.showExecutionLog = false;
            this.runExecutionJson = {};
            this.showExecutionDetailsData = true;
        },
    },
});
