import { AxiosPromise } from 'axios';
import http from '@/http/index';
import { QueryMsisdnResponse, SEARCH_INDEX } from '@/__new__/services/dno/ossdevedge/models/QodMsisdnDno';
import { DEVICE_LINE_AUTH_API_TYPE } from '@/__new__/services/dno/ossdevedge/models/DeveloperLineAuthorizationDno';

/**
 * @param msisdn
 * @returns {AxiosPromise}
 */
export function queryMsisdn(
    apiType: DEVICE_LINE_AUTH_API_TYPE,
    searchIndex: SEARCH_INDEX,
    searchString: string,
): AxiosPromise<QueryMsisdnResponse> {
    return http({
        method: 'POST',
        url: '/v3developerlineauthorization/querymsisdn',
        data: {
            api_type: apiType,
            search_field: searchIndex,
            search_value: searchString,
        },
    });
}

export default {};
