
























































































import Vue, { type PropType } from 'vue';

// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import AppTable from '@/components/partials/AppTable.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';

// Helpers
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import { type TableColumn } from '@/common/filterTable';
import { FILE_UPLOAD_STATUS_TO_COLOR_MAP, type UploadedFileDetails } from '@/common/fileUploadHelper';

export default Vue.extend({
    name: 'BulkUploadHistory',

    components: {
        AppButton,
        AppIcon,
        AppTable,
        EntityStatusIndicator,
        FilterTable,
        IconButton,
        SearchBox,
        TableFiltersRenderless,
        TableFiltersTags,
    },

    props: {
        // New props after revision
        entities: {
            type: Array,
            default: () => [],
        },
        tableColumns: {
            type: Array as PropType<TableColumn[]>,
            default: () => [],
        },
        sortBy: {
            type: Object as PropType<undefined | Record<string, any>>,
            default: () => undefined,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            FILE_UPLOAD_STATUS_TO_COLOR_MAP,
            BUTTON_TYPES,
            ICON_COLORS,
            ICON_TYPES,
            tableSearchInput: '',
            showHistory: false,
        };
    },

    methods: {
        onLoadHistory(): void {
            this.showHistory = true;
            this.$emit('loadHistory');
        },
        onUploadHistorySelected(entity: UploadedFileDetails): void {
            this.$emit('rowSelect', entity);
        },
    },
});
