




























































// components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// Mixins
import entityEditorMixin from '@/common/entityEditorMixin';
import mutationDialogMixin from '@/components/partials/mutations/mutationDialogMixin.vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';

// Validations
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

// https
import { enrichTemplate } from '@/__new__/services/dno/documents/http/documents';

// Helpers
import * as Sentry from '@sentry/vue';
import importMonacoHelper from '@/common/importMonacoHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_TYPES } from '@/common/iconHelper';

// helper for error "Property 'monaco'
// does not exist on type 'Window & typeof globalThis'"
declare const window: any;

export default {
    name: 'MessageTemplateTester',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppButton,
    },
    mixins: [validationMixin, entityEditorMixin, mutationDialogMixin, supportButtonMixin],
    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,
            inputHtmlString: '' as string,
            htmlEditor: null as any,
            jsonEditor: null as any,
            inputJsonString: '{}' as string,
            isDataLoading: false as boolean,
            enrichedTemplate: '' as string,
        };
    },
    computed: {},
    async mounted() {
        try {
            await importMonacoHelper.importMonaco();
            this.loadJsonEditor();
            this.loadHtmlEditor();
        } catch (e: any) {
            Sentry.captureException(e);
            this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
        }
    },
    created() {
        this.$withLoadingSpinner(
            async (): Promise<any> => {
                // todo add request for available helpers
            },
            {
                errorHandler: () => {
                    this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
                },
            },
        );
    },
    validations: {
        inputHtmlString: {
            required,
        },
    },
    methods: {
        loadJsonEditor(): void {
            this.htmlEditor = window.monaco.editor.create(document.getElementById('htmlEditor'), {
                value: this.inputHtmlString,
                language: 'html',
                automaticLayout: true,
            });
            this.htmlEditor.onDidChangeModelContent(() => {
                this.inputHtmlString = this.htmlEditor.getValue();
            });
        },
        loadHtmlEditor(): void {
            this.jsonEditor = window.monaco.editor.create(document.getElementById('jsonEditor'), {
                value: this.inputJsonString,
                language: 'json',
                automaticLayout: true,
            });
            this.jsonEditor.onDidChangeModelContent(() => {
                this.inputJsonString = this.jsonEditor.getValue();
            });
        },
        checkDataBeforeSave(): boolean {
            this.$v.$touch();
            return !this.$v.$invalid;
        },
        generateDocument() {
            if (!this.checkDataBeforeSave()) {
                return;
            }

            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('campaigns.renderingTemplate'),
                type: ALERT_TYPES.info,
            });

            this.$Progress.start();
            this.isDataLoading = true;

            enrichTemplate({
                payload: JSON.parse(this.inputJsonString),
                templateDefinition: this.inputHtmlString,
            }).then(
                response => {
                    this.enrichedTemplate = response.data.data.enriched.template_definition;

                    this.isDataLoading = false;
                },
                e => {
                    this.isDataLoading = false;
                    Sentry.captureException(e);
                    this.$Progress.fail();
                    this.$eventBus.$emit('showAlert', {
                        message:
                            e?.response?.data?.details?.template_definition ??
                            this.$i18n.t('campaigns.failedToRenderTemplate'),
                        type: ALERT_TYPES.error,
                    });
                },
            );
        },
    },
};
