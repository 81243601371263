<template>
    <AppDialogV2
        :title="$i18n.t('customerCare.subscriberTab.swapESIM')"
        :visible="esimSwapDialogVisible"
        @close="onCloseModal"
        @submit="dealSwapEsim"
    >
        <AppSpinner :isVisible="isPolling" />
        <div class="form-content">
            <p class="textSize">{{ $i18n.t('customerCare.subscriberTab.confirmSwapEsim') }} “{{ msisdn }}” ?</p>

            <div class="platformwrapper">
                <div class="platform-label">
                    <p class="textSize">{{ $i18n.t('customerCare.subscriberTab.platform') }}</p>
                </div>
                <div>
                    <div
                        v-for="option in platformOptions"
                        :key="option.name"
                        class="radio-item"
                    >
                        <AppRadioButton
                            :value="option.id"
                            :inputValue="option.id"
                            name="platform"
                            @input="onPlatformSelect"
                        >
                            <div class="label">
                                {{ option.id }}
                            </div>
                        </AppRadioButton>
                    </div>
                </div>
            </div>
        </div>
    </AppDialogV2>
</template>

<script>
// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppRadioButton from '@/components/partials/inputs/AppRadioButton.vue';
import AppSpinner from '@/components/partials/AppSpinner.vue';

import Ossgomo from '@/__new__/services/dno/ossgomo/http/ossgomo';
import { PLATFORM } from '@/common/platform';
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';

const totalDuration = 2 * 60 * 1000; // 2 minutes
const interval = 3000; // 3seconds

export default {
    name: 'ESimSwapModal',
    components: {
        AppDialogV2,
        AppRadioButton,
        AppSpinner,
    },
    props: {
        esimSwapDialogVisible: {
            type: Boolean,
            required: true,
        },
        msisdn: {
            type: String,
            required: true,
        },
        iccidBeforeUpdate: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            platformOptions: [
                {
                    id: PLATFORM.ANDROID,
                    name: this.$i18n.t('customerCare.platform.andorid'),
                },
                {
                    id: PLATFORM.SMARTDEVICE,
                    name: this.$i18n.t('customerCare.platform.smartdevice'),
                },
                {
                    id: PLATFORM.IOS,
                    name: this.$i18n.t('customerCare.platform.ios'),
                },
            ],
            selectedPlatForm: '',
            isPolling: false,
            pollingInterval: '',
        };
    },
    created() {
        this.selectedPlatForm = this.platformOptions[this.platformOptions.length - 1].id;
    },
    methods: {
        async dealSwapEsim() {
            await this.$withProgressBar(
                async () => {
                    await Ossgomo.swapEsimManually(this.msisdn, this.selectedPlatForm);
                    this.$showSuccessAlert({ message: this.$i18n.t('customerCare.subscriberTab.esimSwapTips') });
                    this.isPolling = true;
                    this.pollingFetch();
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('generic.somethingWentWrong'));
                        this.onCloseModal();
                    },
                },
            );
        },
        pollingFetch() {
            const totalIterations = totalDuration / interval;
            let iterationCount = 0;
            this.isPolling = true;
            this.pollingInterval = setInterval(() => {
                this.getProfile();
                iterationCount += 1;
                if (iterationCount >= totalIterations) {
                    clearInterval(this.pollingInterval);
                    this.isPolling = false;
                    this.onCloseModal();
                    this.$alert(this.$i18n.t('generic.somethingWentWrong'));
                }
            }, interval);
        },
        getProfile() {
            customerCareHTTP
                .getProfileInfo({
                    targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    targetId: this.$route.params.id,
                })
                .then(({ data }) => {
                    const { iccid } = data.profile_data;
                    if (iccid !== this.iccidBeforeUpdate) {
                        this.$alert(this.$i18n.t('customerCare.subscriberTab.esimSwapCompeted'), {
                            type: ALERT_TYPES.success,
                        });
                        this.onCloseModal();
                        clearInterval(this.pollingInterval);
                        this.isPolling = false;
                        this.$emit('updateIccid', iccid);
                    }
                });
        },
        onPlatformSelect(val) {
            this.selectedPlatForm = val;
        },
        onCloseModal() {
            this.$emit('closeModal');
        },
    },
};
</script>

<style lang="scss" scoped>
.platform-label {
    margin-right: 1rem;
}
.radio-item {
    margin-bottom: 0.25rem;
}
.label {
    margin-left: 0.15rem;
}
.textSize {
    font-size: 0.875rem;
}
</style>
