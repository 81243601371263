
























































































// components
import AppHeader from '@/components/layout/AppHeader.vue';
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import IconButton from '@/components/partials/IconButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';

// Helpers
import {
    updateOETemplate,
    addOETemplate,
    getOETemplates,
} from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';
import * as Sentry from '@sentry/vue';
import RouteNames from '@/router/routeNames';
import importMonacoHelper from '@/common/importMonacoHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import luaErrorCodes from '@/common/luaErrors';
import { ICON_TYPES } from '@/common/iconHelper';
import Button from '@/common/button/Button';
import { required } from 'vuelidate/lib/validators';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { getBeautifulBoolean } from '@/common/formatting';

// helper for error "Property 'monaco' does not exist on type 'Window & typeof globalThis'"
declare const window: any;

interface ToolbarAction {
    labelKey: string;
    icon: string;
    actionHandler: () => void;
}

export default {
    name: 'OrchestrationEngineTemplateEditor',
    components: {
        AppHeader,
        AbstractEditPageWrapper,
        AppButton,
        IconButton,
        AppInputV3,
    },
    mixins: [supportButtonMixin],
    data() {
        return {
            templateName: '' as string,
            templateType: '' as string,
            inputJsonString: '' as string,
            version: 1 as number,
            editor: null as any,
            isCodeHighlightEnabled: true as boolean,
            isTemplateForceUpdated: true as boolean,
            toolbarActions: {} as { [key: string]: ToolbarAction },
            isDefault: false as boolean,

            BUTTON_TYPES: BUTTON_TYPES as any,
            ICON_TYPES: ICON_TYPES as any,
        };
    },
    computed: {
        pageTitle(): string {
            return this.isTemplateEditor
                ? this.$i18n.t('orchestrationEngine.templates.editTemplate')
                : this.$i18n.t('orchestrationEngine.templates.addTemplate');
        },
        isTemplateEditor(): boolean {
            return Boolean(this.$route.params.id);
        },
        isDefaultDisplayedText() {
            return getBeautifulBoolean(this.isDefault);
        },
        writeEnabled(): boolean {
            return (
                isUserAllowed('OrchestrationTemplatesWrite') && permissionsService.orchestrationEngineTemplatesEnabled()
            );
        },
    },
    async mounted() {
        try {
            await importMonacoHelper.importMonaco();
            this.editor = window.monaco.editor.create(document.getElementById('templateEditor'), {
                value: this.inputJsonString,
                language: 'json',
                automaticLayout: true,
            });
            this.editor.getModel().updateOptions({ tabSize: 2 });
            this.editor.onDidChangeModelContent(() => {
                this.inputJsonString = this.editor.getValue();
            });
        } catch (e: any) {
            Sentry.captureException(e);
            this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
        }
    },
    created() {
        this.toolbarActions = {
            Undo: {
                labelKey: 'orchestrationEngine.editor.undo',
                icon: ICON_TYPES.UNDO,
                actionHandler: () => {
                    this.editor.getModel().undo();
                },
            },
            Redo: {
                labelKey: 'orchestrationEngine.editor.redo',
                icon: ICON_TYPES.REDO,
                actionHandler: () => {
                    this.editor.getModel().redo();
                },
            },
            FontZoomIn: {
                labelKey: 'orchestrationEngine.editor.fontZoomIn',
                icon: ICON_TYPES.FONT_ZOOM_IN,
                actionHandler: () => {
                    this.editor.getAction('editor.action.fontZoomIn').run();
                },
            },
            FontZoomOut: {
                labelKey: 'orchestrationEngine.editor.fontZoomOut',
                icon: ICON_TYPES.FONT_ZOOM_OUT,
                actionHandler: () => {
                    this.editor.getAction('editor.action.fontZoomOut').run();
                },
            },
            FontZoomReset: {
                labelKey: 'orchestrationEngine.editor.fontZoomReset',
                icon: ICON_TYPES.FONT_ZOOM_RESET,
                actionHandler: () => {
                    this.editor.getAction('editor.action.fontZoomReset').run();
                },
            },
            FoldAll: {
                labelKey: 'orchestrationEngine.editor.foldAll',
                icon: ICON_TYPES.ARROW_RIGHT,
                actionHandler: () => {
                    this.editor.getAction('editor.foldAll').run();
                },
            },
            UnfoldAll: {
                labelKey: 'orchestrationEngine.editor.unfoldAll',
                icon: ICON_TYPES.ARROW_DOWN,
                actionHandler: () => {
                    this.editor.getAction('editor.unfoldAll').run();
                },
            },
            FormatDocument: {
                labelKey: 'orchestrationEngine.editor.formatCode',
                icon: ICON_TYPES.FORMATTER,
                actionHandler: () => {
                    this.editor.getAction('editor.action.formatDocument').run();
                },
            },
            Find: {
                labelKey: 'orchestrationEngine.editor.find',
                icon: ICON_TYPES.SEARCH,
                actionHandler: () => {
                    this.editor.getAction('actions.find').run();
                },
            },
            Replace: {
                labelKey: 'generic.replace',
                icon: ICON_TYPES.REPLACE,
                actionHandler: () => {
                    this.editor.getAction('editor.action.startFindReplaceAction').run();
                },
            },
        };
        if (this.isTemplateEditor) {
            this.$withLoadingSpinner(
                async (): Promise<any> => {
                    const result = await getOETemplates(this.$route.params.id);
                    // eslint-disable-next-line camelcase
                    const dataJSON = result?.data?.orchestration_template_by_id?.[this.$route.params.id]?.data || {};
                    this.inputJsonString = JSON.stringify(dataJSON?.content, null, '  ');
                    this.templateName = dataJSON?.name;
                    this.templateType = dataJSON.type || '';
                    this.isDefault = dataJSON?.is_default || false;

                    if (this.editor) {
                        this.editor.setValue(this.inputJsonString);
                    }
                    // eslint-disable-next-line camelcase
                    this.version = result?.data?.orchestration_template_by_id?.[this.$route.params.id]?.version || 1;
                },
                {
                    errorHandler: () => {
                        this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
                    },
                },
            );
        }
    },
    validations: {
        inputJsonString: {
            required,
            isJsonValid: value => {
                try {
                    JSON.parse(value);
                    return true;
                } catch (e) {
                    return false;
                }
            },
        },
        templateName: {
            required,
        },
    },
    methods: {
        async onSave(): Promise<any> {
            // Validate model
            this.validate();

            if (!this.$v.$invalid) {
                try {
                    this.$Progress.start();

                    const jsonAsObject = JSON.parse(this.inputJsonString);

                    const data = {
                        template: {
                            name: this.templateName,
                            content: jsonAsObject,
                            ...(this.templateType && { type: this.templateType }),
                            ...(this.isTemplateEditor && { is_default: this.isDefault }),
                        },
                        ...(this.isTemplateEditor && { version: this.version }),
                        ...(this.isTemplateEditor && this.isTemplateForceUpdated && { force_update: true }),
                    };

                    if (this.isTemplateEditor) {
                        await updateOETemplate(data);
                        this.$showSuccessAlert({
                            message: this.$i18n.t('orchestrationEngine.alerts.orchestrationEngineTemplateUpdated'),
                        });
                    } else {
                        await addOETemplate(data);
                        this.$showSuccessAlert({
                            message: this.$i18n.t('orchestrationEngine.alerts.orchestrationEngineTemplateCreated'),
                        });
                    }

                    setTimeout(() => {
                        this.redirectToOrchestrationTemplatesListPage();
                    }, 1000);

                    this.$Progress.finish();
                } catch (error: any) {
                    if (
                        error?.response?.data?.module === 'ORCHESTRATIONPLAN' &&
                        error?.response?.data?.code === luaErrorCodes.ORCHESTRATIONPLAN.BAD_ENTITY_STATE.code
                    ) {
                        this.forceUpdateTemplate();
                    } else {
                        Sentry.captureException(error);
                        this.$Progress.fail();

                        let alertMsg = this.$i18n.t('alertMessage.somethingWentWrong');
                        if (error?.response?.data?.msg) {
                            alertMsg = error.response.data.msg;
                        }

                        if (alertMsg === this.$i18n.t('alertMessage.somethingWentWrong')) {
                            this.showSupportAlert(alertMsg, ALERT_TYPES.error);
                        } else {
                            this.$eventBus.$emit('showAlert', {
                                message: alertMsg,
                            });
                        }
                    }
                }
            }
        },
        forceUpdateTemplate() {
            const confirmButton = new Button({
                label: this.$i18n.t('generic.yes'),
                alertType: ALERT_TYPES.warning,
            });

            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('orchestrationEngine.alerts.updateTemplateConflict'),
                type: ALERT_TYPES.warning,
                buttons: [confirmButton],
            });

            this.$eventBus.$once('buttonClicked', buttonId => {
                if (buttonId === confirmButton.id) {
                    this.isTemplateForceUpdated = true;
                    this.onSave();
                }
            });
        },
        redirectToOrchestrationTemplatesListPage(): void {
            this.$router.push({
                name: RouteNames.ORCHESTRATION_ENGINE_TEMPLATES_LIST_PAGE,
                params: { companyId: this.$route.params.companyId },
            });
        },
        validate(): void {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                });
            }
        },
    },
};
