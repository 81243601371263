export enum SIM_TYPE {
    PSIM = 'psimProfile',
    ESIM = 'esimProfile',
}

export const RESOURCE_TYPE = {
    [SIM_TYPE.ESIM]: 10,
    [SIM_TYPE.PSIM]: 20,
};

export enum REMOTE_CONFIG_KEYS {
    ESIM_BRANDS = 'esim_brands_list',
    INVENTORY_TAGS = 'inventory_tags',
}

export enum SIM_SWAP_FLOW {
    ESIM_TO_ESIM = 'EsimToEsim',
    ESIM_TO_PSIM = 'EsimToPsim',
    PSIM_TO_ESIM = 'PsimToEsim',
    PSIM_TO_PSIM = 'PsimToPsim',
}
