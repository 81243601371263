




































































// helpers
import { eq, groupBy, intersection, isEmpty, keys, mapValues, pick, uniq } from 'lodash';
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';
import { LOGICAL_OPERATORS } from '@/common/segments';
import {
    CampaignTargetType,
    CohortConfig,
    CohortGroupId,
    CohortListConfig,
    CohortType,
    TargetConfig,
} from '@/__new__/services/dno/campaigns/models/Campaign';
import { CohortList } from '@/__new__/services/dno/sinkConfigs/models/CohortExpression';
import { MessageTypes } from '@/common/CampaignMessage';
import { CAMPAIGN_COHORT_EXPRESSION_COMBINATIONS } from './common/campaignsHelper';
import * as Sentry from '@sentry/vue';
import { TranslateResult } from 'vue-i18n';

// components
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import CardListRadioInput from '@/components/partials/cards/CardListRadioInput.vue';
import { isUserAllowed } from '@/services/permissions/permissions.service';

type CardOption = {
    id: `${CampaignTargetType}`;
    description: TranslateResult;
    label: TranslateResult;
};

export default {
    name: 'CampaignTargetTypeSelect',
    components: {
        AppMultiselectV3,
        AppToggle,
        CardListRadioInput,
    },
    props: {
        value: {
            type: Object as PropType<TargetConfig>,
            default: null,
        },
        campaign: {
            type: Object as PropType<{ delivery: { sendToUnregisteredUsers: boolean } }>,
            default: null,
        },
        messageType: {
            type: String,
            default: MessageTypes.PUSH,
        },
    },
    data() {
        return {
            isEmpty,
            CohortList,
            CampaignTargetType,
            LOGICAL_OPERATORS,
            cohortTypeOptions: Object.keys(CampaignTargetType).map((id: string) => ({
                ...this.$t(`campaigns.applicableto.${id}`),
                id,
            })),
        };
    },
    computed: {
        ...mapGetters({
            segments: `${Modules.segments}/${Getters.CACHED_SEGMENTS}`,
            groupedSegments: `${Modules.segments}/${Getters.GROUPED_SEGMENTS_BY_ID_TYPE}`,
            segmentsById: `${Modules.segments}/${Getters.CACHED_SEGMENTS_BY_ID}`,
        }),
        targetType(): CampaignTargetType {
            return Object.keys(this.value)[0] as CampaignTargetType;
        },
        targetValue(): Record<CohortList, CohortListConfig> {
            return this.value[this.targetType];
        },
        dropdownValues(): Partial<Record<CohortList, CohortGroupId[]>> {
            return mapValues({ whitelist: [], blacklist: [] }, (_, list: CohortList) =>
                Object.entries(pick(this.targetValue[list], Object.values(CohortType)) as Record<CohortType, string[]>)
                    .map(([groupType, ids]) =>
                        ids.map((id: string) => ({
                            id,
                            groupType,
                            name: this.segmentsById[id]?.name || `${id} ${this.$t('generic.deleted')}`,
                        })),
                    )
                    .flat(),
            );
        },
        dropdownOptions(): Partial<Record<CohortList, any[]>> {
            return mapValues({ whitelist: this.allowedGroupsWL, blacklist: this.allowedGroupsBL }, allowedGroups =>
                this.groupedSegments.filter(({ groupValues }) => allowedGroups.includes(groupValues[0].groupType)),
            );
        },
        selectedGroups(): CohortType[] {
            return keys(pick(this.targetValue[CohortList.WHITELIST], Object.values(CohortType))) as CohortType[];
        },
        allowedGroupsWL(): CohortType[] {
            const isUnregistered = this.campaign.delivery.sendToUnregisteredUsers ? 'true' : 'false';
            return uniq(
                CAMPAIGN_COHORT_EXPRESSION_COMBINATIONS[isUnregistered][this.messageType]
                    .filter((types: CohortType[]) =>
                        eq(intersection(types, this.selectedGroups).length, this.selectedGroups.length),
                    )
                    .flat(),
            );
        },
        allowedGroupsBL(): CohortType[] {
            return uniq(
                [
                    CohortType.USER,
                    this.selectedGroups.length < 2 ? this.selectedGroups : [],
                    this.messageType === MessageTypes.SMS ? CohortType.MSISDN : [],
                    this.messageType === MessageTypes.EMAIL ? CohortType.EMAIL : [],
                ].flat(),
            );
        },
    },
    mounted() {
        if (isUserAllowed('SegmentsRead', 'SegmentsWrite')) {
            if (this.segments.length < 1) {
                this[Actions.FETCH_SEGMENTS]()
                    .then(() => {
                        if (isEmpty(this.segments)) {
                            this.excludeCohortTargetType();
                        }
                    })
                    .catch((e: any) => {
                        Sentry.captureException(e);
                        this.$alert(this.$t('segments.alerts.failedToLoadSegments'));
                    });
            }
        } else {
            this.excludeCohortTargetType();
        }
    },
    methods: {
        ...mapActions(Modules.segments, [Actions.FETCH_SEGMENTS]),
        excludeCohortTargetType() {
            this.cohortTypeOptions = this.cohortTypeOptions.filter(
                (o: CardOption) => o.id !== CampaignTargetType.CohortTarget,
            );
            this.setTargetType(CampaignTargetType.AllRegistered);
        },
        saveCohortsList(list: CohortList, cohorts: CohortGroupId[]) {
            const groups = groupBy(cohorts, cohort => cohort.groupType);
            this.saveCohorts({
                [list]: {
                    logical_operator: this.targetValue[list].logical_operator,
                    ...mapValues(groups, group => group.map(({ id }) => id)),
                },
            });
        },
        toggleLogicalOperator(list: CohortList) {
            this.saveCohorts({
                [list]: {
                    ...this.targetValue[list],
                    logical_operator:
                        this.targetValue[list].logical_operator === LOGICAL_OPERATORS.or
                            ? LOGICAL_OPERATORS.and
                            : LOGICAL_OPERATORS.or,
                },
            });
        },
        saveCohorts(patch: Partial<CohortConfig>) {
            this.$emit('input', { [this.targetType]: { ...this.targetValue, ...patch } });
        },
        setTargetType(targetType: CampaignTargetType) {
            this.$emit('input', {
                [targetType]:
                    targetType === CampaignTargetType.AllRegistered
                        ? pick(this.targetValue, CohortList.BLACKLIST)
                        : {
                              [CohortList.WHITELIST]: { logical_operator: LOGICAL_OPERATORS.or },
                              [CohortList.BLACKLIST]: this.targetValue[CohortList.BLACKLIST],
                          },
            });
        },
    },
};
