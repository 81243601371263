


















































import { PropType } from 'vue';
import { uniq } from 'lodash';
import filters from '@/common/filters';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { MessageTypes } from '@/common/CampaignMessage';
import Campaign, { CampaignFeaturesByType, DeliveryTypeNames } from '@/__new__/services/dno/campaigns/models/Campaign';
import { cohortExpressionsFromJson } from '@/common/cohortExpressionHelper';
import { EntityType } from '@/components/partials/filters/FiltersRenderer.vue';

import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import CampaignSummary from '@/__new__/features/campaigns/CampaignSummary.vue';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import CampaignStatusIndicator from '@/__new__/features/campaigns/CampaignStatusIndicator.vue';
import CampaignDeliveryAnalytics from '@/__new__/features/campaigns/CampaignDeliveryAnalytics.vue';
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

export default {
    name: 'CampaignOverview',
    components: {
        EntityOverview,
        CampaignSummary,
        OverviewHeaderV2,
        CampaignStatusIndicator,
        CampaignDeliveryAnalytics,
    },
    filters,
    props: {
        campaign: {
            type: Object as PropType<Campaign>,
            required: true,
        },
        deliveryAnalytics: { type: Object, default: () => ({}) },
        conditionDefinitionsById: { type: Object, default: () => ({}) },
    },
    data() {
        return {
            EntityType,
            MessageTypes,
            entityType: ENTITY_TYPES.CAMPAIGN,
        };
    },
    computed: {
        ...mapGetters(Modules.segments, {
            groupedSegments: Getters.GROUPED_SEGMENTS_BY_ID_TYPE,
        }),
        campaignExtended() {
            return this.campaign?.editableData();
        },
        campaignType(): number {
            return this.campaign.campaignType;
        },
        campaignFeatures() {
            return CampaignFeaturesByType[this.campaignType];
        },
        selectedCohorts(): any {
            return cohortExpressionsFromJson(
                Object.values(this.campaign.target)[0],
                this.groupedSegments.map(group => group.groupValues).flat(),
            );
        },
        tabsList() {
            return this.campaign.deliveryType.type === DeliveryTypeNames.Scheduled
                ? [
                      {
                          id: 1,
                          name: this.$t('generic.overview'),
                          scrollContent: true,
                      },
                      {
                          id: 2,
                          name: this.$t('generic.performance'),
                          scrollContent: false,
                      },
                  ]
                : [];
        },
        messageTypes() {
            return uniq((this.campaign.messages || []).map(msgs => msgs.map(msg => msg.type)).flat());
        },
        triggerConditionFilters() {
            return this.campaignExtended?.triggerCondition?.filters || [];
        },
    },
};
