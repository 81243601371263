var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('OperateApisListPageLayout',{attrs:{"pageTitle":_vm.$i18n.t('operateAPIs.apiProductOrders'),"entityType":_vm.OPERATE_APIS_TYPES.API_PRODUCT_ORDER,"entityResponse":_vm.apiProductOrdersApiResponse,"entityData":_vm.entityData,"addButtonLabel":_vm.$i18n.t('operateAPIs.addNewApiProductOrder'),"writePermission":_vm.isUserAllowed('OperateAPIsProductOrdersWrite'),"addNewRouteName":_vm.addNewRouteName},on:{"onSelectEntity":_vm.onSelectApiProductOrder,"onSelectPartyRole":_vm.onSelectPartyRole}},[_c('template',{slot:"sidebarOverview"},[_c('OverviewList',{attrs:{"lists":_vm.detailsSections}}),(_vm.isUserInternal())?[_c('h2',{staticClass:"lf-table-title mb-2 mt-4"},[_vm._v(" "+_vm._s(_vm.$i18n.t('operateAPIs.relatedExecutions'))+" ")]),_c('AppTable',{attrs:{"entities":_vm.executionsForEntity,"columnsData":_vm.tableColumnsData,"isDataLoading":_vm.executionsTableLoading,"canSelectColumns":true},scopedSlots:_vm._u([{key:"executionId",fn:function(ref){
var entity = ref.entity;
return [(entity.executionId)?_c('div',{staticClass:"truncate-text"},[_c('router-link',{attrs:{"to":{
                                name: _vm.RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                                params: {
                                    id: 'create_application',
                                    execId: entity.executionId,
                                },
                            },"target":"_blank"}},[_vm._v(" "+_vm._s(entity.executionId)+" ")])],1):_vm._e()]}},{key:"status",fn:function(ref){
                            var entity = ref.entity;
return [(entity.status)?_c('ExecutionStatusIndicator',{attrs:{"status":entity.status}}):_vm._e()]}},{key:"customRowButtons",fn:function(ref){
                            var entity = ref.entity;
return [(_vm.displayCustomRowButtons(entity))?_c('div',{staticClass:"d-flex"},[_c('IconButton',{staticClass:"mr-2",attrs:{"label":_vm.$i18n.t('generic.approve'),"icon":_vm.ICON_TYPES.CHECK},on:{"iconClick":function($event){return _vm.approveRejectApiProductOrder(entity, true)}}}),_c('IconButton',{staticClass:"mr-2",attrs:{"label":_vm.$i18n.t('generic.reject'),"icon":_vm.ICON_TYPES.CLOSE},on:{"iconClick":function($event){return _vm.approveRejectApiProductOrder(entity, false)}}})],1):_vm._e()]}}],null,false,2881661318)})]:_vm._e(),(Object.keys(_vm.lastMappedExecution).length)?[_c('div',{staticClass:"d-flex"},[_c('AppButton',{staticClass:"mr-2",attrs:{"buttonType":_vm.BUTTON_TYPES.SECONDARY,"label":_vm.$i18n.t('generic.approve')},on:{"click":function($event){return _vm.approveRejectApiProductOrder(_vm.lastMappedExecution, true)}}}),_c('AppButton',{attrs:{"buttonType":_vm.BUTTON_TYPES.SECONDARY,"label":_vm.$i18n.t('generic.reject')},on:{"click":function($event){return _vm.approveRejectApiProductOrder(_vm.lastMappedExecution, false)}}})],1)]:_vm._e()],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }