









































import Vue, { PropType } from 'vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import { CSV_COLUMN_DATA } from '@/common/csvHelper';

export default Vue.extend({
    name: 'CsvFormatModal',
    components: { AppDialogV2 },
    props: {
        columns: {
            type: Array as PropType<CSV_COLUMN_DATA[]>,
            default: () => [],
        },
        visible: {
            type: Boolean,
            required: true,
        },
        subTitle: {
            type: String,
            default: '',
        },
    },
    computed: {
        title(): string {
            const baseTitle = this.$t('partials.csvFormatModal.csvFormat');
            return this.subTitle ? `${baseTitle} (${this.subTitle})` : baseTitle;
        },
    },
});
