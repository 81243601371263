<template>
    <div id="app">
        <!-- Indicator bar at the top of the page -->
        <vue-progress-bar />

        <div
            v-if="!authorized"
            class="unauthorized-container"
        >
            <router-view />
        </div>

        <div
            v-else-if="authorized && getIsConfigLoaded"
            class="authorized-container"
        >
            <AuthorizedContainer />
        </div>
    </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Modules } from '@/store/store';
import { Mutations } from '@/store/mutation-types';
import RouteNames from '@/router/routeNames';

// Vue Components
import AuthorizedContainer from '@/components/layout/AuthorizedContainer.vue';

// Models
import Account from '@/models/Account';

// HTTP
import { getOperators } from '@/__new__/services/portal/operators/http/operators';
import userManagementAJAX from '@/__new__/services/dno/user/http/user-management';

// Permissions
import { isViewEnabled } from '@/services/permissions/permissions.service';

// Amazon Connect
import { ContactClient } from '@amazon-connect/contact';
import { AmazonConnectApp } from '@amazon-connect/app';

export default {
    name: 'App',
    components: {
        AuthorizedContainer,
    },
    computed: {
        ...mapGetters(Modules.config, ['getIsConfigLoaded']),
        authorized() {
            return Boolean(Account.authenticated());
        },
    },
    watch: {
        getIsConfigLoaded() {
            if (!this.$route.params.companyId && Account?.storedInstance?.companyId) {
                this.$router.push({
                    name: RouteNames.HOME_VIEW,
                    params: { companyId: Account.storedInstance.companyId },
                });
            }
        },
        $route: {
            async handler(to, from) {
                // Reload the session context if the URL indicates that the company has
                // changed.
                //
                const toCompanyId = to?.params.companyId;
                const fromCompanyId = from?.params.companyId;

                if (toCompanyId && fromCompanyId && toCompanyId !== fromCompanyId) {
                    await this.loadSessionContext();
                }
            },
        },
    },
    mounted() {
        if (!this.authorized) {
            return;
        }

        AmazonConnectApp.init({
            onCreate: async event => {
                try {
                    const contactClient = new ContactClient();
                    const { contactId } = event.context.contactScope;

                    const token1FA = await contactClient.getAttribute(contactId, 'token_1fa');
                    if (token1FA?.value) {
                        try {
                            const verifyTokenApiName = '/api/v3/authorization/verify_token';
                            const { data } = await userManagementAJAX.verifyToken(token1FA.value, verifyTokenApiName);
                            if (data?.verified) {
                                this[Mutations.SET_AUTH_TOKEN_1FA](token1FA.value);
                            }
                        } catch (error) {
                            this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                        }
                    }

                    const userIdAttribute = await contactClient.getAttribute(contactId, 'user_id');
                    const operatorNameAttribute = await contactClient.getAttribute(contactId, 'operator_name');
                    const operatorsResponse = await getOperators();

                    if (operatorNameAttribute && operatorsResponse?.data) {
                        const companyId = Object.keys(operatorsResponse.data).find(
                            id => operatorsResponse.data[id].dnoIdentifier === operatorNameAttribute.value,
                        );

                        if (userIdAttribute) {
                            this.$router.push({
                                name: RouteNames.CCS_USER_PAGE,
                                params: {
                                    companyId,
                                    id: userIdAttribute.value,
                                },
                            });
                        } else {
                            this.$router.push({
                                name: RouteNames.CCS_SEARCH,
                                params: {
                                    companyId,
                                },
                            });
                        }
                    } else {
                        this.$router.push({
                            name: RouteNames.CCS_SEARCH,
                        });
                    }
                } catch (err) {
                    this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                }
            },
        });
    },
    async created() {
        await this.loadSessionContext();

        // if route manages loading progress by himself - don't setup before/after hooks
        if (this.$router.currentRoute.meta.selfProgressManagement) {
            return;
        }

        this.$Progress.start();
        this.$router.beforeEach((to, from, next) => {
            if (to.meta.progress !== undefined) {
                const meta = to.meta.progress;
                this.$Progress.parseMeta(meta);
            }
            this.$Progress.start();
            next();
        });

        this.$router.afterEach(() => {
            this.$Progress.finish();
        });
    },
    methods: {
        ...mapActions(Modules.config, ['loadConfig', 'loadAccountData', 'loadEnvData', 'loadGrafanaHost']),
        ...mapMutations(Modules.endUserAuthorization, [Mutations.SET_AUTH_TOKEN_1FA]),
        async loadSessionContext() {
            if (this.authorized) {
                await this.loadAccountData();
                await this.loadConfig(this.$route.params.companyId ?? Account.storedInstance.companyId);
                await this.loadEnvData();
                if (isViewEnabled('GrafanaGraphs')) {
                    await this.loadGrafanaHost();
                }
            }
        },
    },
};
</script>

<style lang="scss">
@import 'assets/scss/bootstrap/custom-bootstrap';
@import 'assets/scss/base';
@import 'assets/scss/external-components';
@import 'vue-json-pretty/lib/styles';

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $white;

    height: 100%;
    width: 100%;

    .unauthorized-container {
        height: 100%;
        width: 100%;
    }

    .authorized-container {
        height: 100%;
        width: 100%;
    }
}
</style>
