









































































































import Vue from 'vue';

// vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import DocumentTemplateRequestDataForm from '@/__new__/features/documents/DocumentTemplateRequestDataForm.vue';

// Mixins
import entityEditorMixin from '@/common/entityEditorMixin';
import mutationDialogMixin from '@/components/partials/mutations/mutationDialogMixin.vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';

// Validations
import { validationMixin, type Validation } from 'vuelidate';
import { required, requiredIf, url } from 'vuelidate/lib/validators';

// http
import { addTemplate, getTemplate } from '@/__new__/services/dno/documents/http/templates';
import { previewDocument } from '@/__new__/services/dno/documents/http/documents';
import {
    getDocumentTriggerJob,
    getDocumentGenerationJob,
    createDocumentTriggerJob,
    createDocumentGenerationJob,
} from '@/__new__/services/dno/documents/http/documentJobs';

// Helpers
import * as Sentry from '@sentry/vue';
import importMonacoHelper, { type MonacoEditorMarkerData } from '@/common/importMonacoHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_TYPES } from '@/common/iconHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import isEmpty from 'lodash/isEmpty';
import RouteNames from '@/router/routeNames';
import Button from '@/common/button/Button';
import {
    DOCUMENT_CREATION_MODE,
    DOCUMENT_GENERATE_TYPES,
    DOCUMENT_JOB,
    DocumentTemplatesUrlData,
    DocumentRequestDataFormParams,
} from '@/__new__/services/dno/documents/models/DocumentInterfaces';
import type { JobResponse } from '@/__new__/features/settings/common/applicationManagerHelper';

// helper for error "Property 'monaco'
// does not exist on type 'Window & typeof globalThis'"
declare const window: any;

const MAX_NUMBER_OF_ITERATIONS = 12;

export default Vue.extend({
    name: 'DocumentTemplateEditor',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppInputV3,
        AppButton,
        DocumentTemplateRequestDataForm,
    },
    mixins: [validationMixin, entityEditorMixin, mutationDialogMixin, supportButtonMixin],

    provide(): Record<'$v', Validation> {
        return {
            // Pass vuelidate to child components
            $v: this.$v,
        };
    },

    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,
            templateName: '' as string,
            inputHtmlString: '' as string,
            htmlEditor: null as any,
            jsonEditor: null as any,
            jsonEditorError: undefined as MonacoEditorMarkerData | undefined,
            inputJsonString: '{}' as string,
            isHtmlCodeHighlightEnabled: true as boolean,
            isJsonCodeHighlightEnabled: true as boolean,
            entityType: ENTITY_TYPES.DOCUMENT_TEMPLATES as string,
            documenttPollers: {} as any,
            numberOfIterations: 0 as number,
            isDataLoading: false as boolean,
            isDocGenerated: false as boolean,
            confirmButton: new Button({
                label: this.$t('generic.save'),
                handler: this.saveConfirmed.bind(this),
            }),
            requestForm: {
                mode: DOCUMENT_CREATION_MODE.API as DOCUMENT_CREATION_MODE,
                eventId: '',
                documentId: '',
                callbackUrl: '',
                version: undefined,
            } as DocumentRequestDataFormParams,
            jobs: {
                [DOCUMENT_JOB.TRIGGER]: undefined,
                [DOCUMENT_JOB.CALLBACK]: undefined,
            } as Record<DOCUMENT_JOB, JobResponse['revision_number'] | undefined>,
        };
    },

    computed: {
        ...mapGetters(Modules.documents, [Getters.GET_DOCUMENT_TEMPLATE_BY_ID]),
        pageTitle(): string {
            return this.isTemplateEdited ? this.$t('documents.editTemplate') : this.$t('documents.addNewTemplate');
        },

        templateNameErrorMessage(): string {
            return this.$v.templateName.validator === false
                ? this.$t('documents.templateNameErrorMessage')
                : this.$t('generic.validations.fieldIsRequired');
        },
        doesDocumentTemplateNameExists(): boolean {
            return Boolean(this.templateName && !isEmpty(this[Getters.GET_DOCUMENT_TEMPLATE_BY_ID](this.templateName)));
        },
        isTemplateEdited(): boolean {
            const { id, clone } = this.$route.params;
            return id && clone === undefined;
        },
        isEventDriven(): boolean {
            return this.requestForm?.mode === DOCUMENT_CREATION_MODE.EVENT;
        },
    },

    watch: {
        inputHtmlString(val: string, oldVal: string): void {
            if (val !== oldVal) {
                this.isDocGenerated = false;
            }
        },
        inputJsonString(val: string, oldVal: string): void {
            if (val !== oldVal) {
                this.isDocGenerated = false;
            }
        },
        isDataLoading(isLoading: boolean): void {
            this.htmlEditor?.updateOptions({ readOnly: isLoading });
            this.jsonEditor?.updateOptions({ readOnly: isLoading });
        },
    },

    async mounted() {
        await this.$withLoadingSpinner(
            async () => {
                await importMonacoHelper.importMonaco();
                this.loadHtmlEditor();
                this.loadJsonEditor();
                // Editors must be imported before data can be passed to it.
                await this.fetchData();
            },
            {
                errorHandler: () => {
                    this.showSupportAlert(this.$t('alertMessage.somethingWentWrong'));
                },
            },
        );
    },

    validations: {
        templateName: {
            required,
            validator: name => /^[a-zA-Z0-9_]+$/.test(name),
        },
        inputHtmlString: {
            required,
        },
        requestForm: {
            eventId: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return this.isEventDriven;
                }),
            },
            documentId: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return this.isEventDriven && this.isDocGenerated;
                }),
            },
            callbackUrl: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return this.isEventDriven && this.isDocGenerated;
                }),
                url,
            },
        },
    },
    methods: {
        ...mapActions(Modules.documents, [Actions.REQUEST_DOCUMENT_TEMPLATES]),
        async fetchData() {
            await this[Actions.REQUEST_DOCUMENT_TEMPLATES]();
            const { id } = this.$route.params;

            if (id) {
                await this.renderDocumentTemplate(id);

                if (this.isEventDriven && this.isTemplateEdited) {
                    this.getDocumentTemplateJobs();
                }
            }
        },
        async renderDocumentTemplate(templateId: string): Promise<void> {
            try {
                this.isDataLoading = true;
                // eslint-disable-next-line prettier/prettier
                const { data: { data: { template_definition: templateDefinition = '', metadata } }} = await getTemplate(templateId);
                const previewPayload = metadata?.properties?.preview_payload;

                this.templateName = this.$route.params.clone ? `${templateId}_cloned` : templateId;
                this.requestForm = metadata?.properties?.request_form || this.requestForm;

                this.htmlEditor.setValue(templateDefinition);
                if (previewPayload) {
                    this.updateJsonEditor(previewPayload);
                }
                this.isDataLoading = false;
            } catch (e: any) {
                this.isDataLoading = false;
                Sentry.captureException(e);
                this.$alert(e?.response?.data?.message ?? this.$t('documents.loadError'));
            }
        },
        async getDocumentTemplateJobs() {
            await this.$withProgressBar(
                async () => {
                    this.isDataLoading = true;
                    const [triggerJob, generateJob] = await Promise.all([
                        getDocumentTriggerJob(this.templateName),
                        getDocumentGenerationJob(this.templateName),
                    ]);

                    this.jobs[DOCUMENT_JOB.TRIGGER] = triggerJob?.data?.data?.revision_number;
                    this.jobs[DOCUMENT_JOB.CALLBACK] = generateJob?.data?.data?.revision_number;

                    this.requestForm.version = this.jobs[DOCUMENT_JOB.TRIGGER] || this.jobs[DOCUMENT_JOB.TRIGGER];
                    this.isDataLoading = false;
                },
                {
                    errorHandler: () => {
                        this.isDataLoading = false;
                        this.$alert(this.$t('documents.templateJobFetchErrorMessage'));
                    },
                },
            );
        },
        loadHtmlEditor(): void {
            this.htmlEditor = window.monaco.editor.create(document.getElementById('htmlEditor'), {
                value: this.inputHtmlString,
                language: 'html',
                automaticLayout: true,
                readOnly: !!this.$route.params?.id,
            });
            this.htmlEditor.onDidChangeModelContent(() => {
                this.inputHtmlString = this.htmlEditor.getValue();
            });
        },
        loadJsonEditor(): void {
            this.jsonEditor = window.monaco.editor.create(document.getElementById('jsonEditor'), {
                value: this.inputJsonString,
                language: 'json',
                automaticLayout: true,
                readOnly: !!this.$route.params?.id,
            });

            this.jsonEditor.onDidChangeModelContent(() => {
                this.inputJsonString = this.jsonEditor.getValue();
            });

            this.jsonEditor.onDidBlurEditorWidget(() => {
                this.jsonEditor.getAction('editor.action.formatDocument').run();

                if (this.jsonEditorError) {
                    this.jsonEditor.trigger('showMarker', 'editor.action.marker.next');

                    this.jsonEditor.setSelection(this.jsonEditorError);
                    this.jsonEditor.revealLineInCenter(this.jsonEditorError.startLineNumber);
                }
            });

            this.jsonEditor.onDidChangeModelDecorations(() => {
                const [marker]: MonacoEditorMarkerData[] = window.monaco.editor.getModelMarkers(
                    this.jsonEditor.getModel().id,
                );

                if (this.jsonEditorError?.startLineNumber !== marker?.startLineNumber) {
                    this.jsonEditorError = marker;

                    if (!marker) {
                        this.jsonEditor.trigger('', 'closeMarkersNavigation');
                    }
                }
            });
        },
        checkDataBeforeSave(): boolean {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }
            if (this.doesDocumentTemplateNameExists && !this.isTemplateEdited) {
                this.$alert(this.$t('documents.templateNameUniqueErrorMessage'));
                return false;
            }

            return true;
        },
        onSave(): Promise<void> | undefined {
            if (!this.checkDataBeforeSave()) {
                return;
            }

            this.$alert(this.$t('documents.confirmSave'), {
                type: ALERT_TYPES.warning,
                buttons: [this.confirmButton],
            });
        },
        async saveConfirmed(): Promise<void> {
            await this.$withProgressBar(
                async () => {
                    const jsonInput = JSON.parse(this.inputJsonString);
                    this.isDataLoading = true;

                    if (this.isEventDriven) {
                        // Validate and then create.
                        await this.createDocumentJobs({ validate: true });
                        await this.createDocumentJobs();
                    }

                    await addTemplate({
                        templateId: this.templateName,
                        templateDefinition: this.inputHtmlString,
                        previewPayload: !isEmpty(jsonInput) ? jsonInput : undefined,
                        requestForm: this.isEventDriven ? this.requestForm : undefined,
                    });

                    this.$showSuccessAlert({
                        message: this.isTemplateEdited
                            ? this.$t('documents.templateUpdated')
                            : this.$t('documents.templateAdded'),
                    });

                    this.isDataLoading = false;
                    this.entityEditorMixin.successfullySaved = true;
                    setTimeout(
                        () =>
                            this.$router.push({
                                name: RouteNames.DOCUMENT_TEMPLATES,
                                params: { companyId: this.$route.params.companyId },
                            }),
                        1000,
                    );
                },
                {
                    errorHandler: (e: any) => {
                        this.isDataLoading = false;
                        this.$alert(e?.response?.data?.details?.error || this.$t('documents.saveError'));
                    },
                },
            );
        },
        async generateDocument(): Promise<void> {
            if (!this.checkDataBeforeSave()) {
                return;
            }

            this.$showInfoAlert({ message: this.$t('documents.documentGenerated') });

            this.isDataLoading = true;

            await this.$withProgressBar(
                async () => {
                    this.numberOfIterations += 1;

                    const res = await previewDocument({
                        payload: JSON.parse(this.inputJsonString),
                        fileName: this.templateName,
                        templateDefinition: this.inputHtmlString,
                    });

                    const { status, url_data: urlData = {} as DocumentTemplatesUrlData } = res.data.data;

                    if (status !== DOCUMENT_GENERATE_TYPES.COMPLETED && isEmpty(urlData)) {
                        const poller = setInterval(() => {
                            if (this.numberOfIterations >= MAX_NUMBER_OF_ITERATIONS) {
                                this.clearDocumentInterval();
                                this.$alert(this.$t('documents.maxNumberOfIterations'));
                                return;
                            }

                            this.checkAvailabilityOfDocument();
                        }, 5000);
                        this.$set(this.documenttPollers, this.templateName, poller);
                    } else {
                        this.documentGenerationSucceeded(urlData);
                    }
                },
                {
                    errorHandler: () => {
                        this.isDataLoading = false;
                        this.isDocGenerated = false;
                        this.clearDocumentInterval();
                    },
                },
            );
        },
        async checkAvailabilityOfDocument(): Promise<void> {
            try {
                this.numberOfIterations += 1;
                const res = await previewDocument({
                    payload: JSON.parse(this.inputJsonString),
                    fileName: this.templateName,
                    templateDefinition: this.inputHtmlString,
                });

                const { status, url_data: urlData = {} as DocumentTemplatesUrlData } = res.data.data;

                if (status === DOCUMENT_GENERATE_TYPES.COMPLETED && !isEmpty(urlData)) {
                    this.documentGenerationSucceeded(urlData);
                }
            } catch (e: any) {
                this.clearDocumentInterval();
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$alert(e?.response?.data?.message ?? this.$t('documents.documentGeneratedError'));
            }
        },
        async createDocumentJobs({ validate = false } = {}): Promise<void> {
            // Job creation requests must be sent in sequence due to lock(there can be only one)!
            await createDocumentTriggerJob(
                {
                    documentId: this.requestForm.documentId,
                    templateId: this.templateName,
                    eventId: this.requestForm.eventId,
                    version: this.jobs[DOCUMENT_JOB.TRIGGER],
                },
                validate,
            );
            await createDocumentGenerationJob(
                {
                    templateId: this.templateName,
                    callbackUrl: this.requestForm.callbackUrl,
                    version: this.jobs[DOCUMENT_JOB.CALLBACK],
                },
                validate,
            );
        },
        clearDocumentInterval(): void {
            clearInterval(this.documenttPollers[this.templateName]);
            this.$delete(this.documenttPollers, this.templateName);
            this.isDataLoading = false;
            this.numberOfIterations = 0;
        },
        documentGenerationSucceeded(urlData: DocumentTemplatesUrlData): void {
            this.clearDocumentInterval();
            this.isDocGenerated = true;
            window.open(urlData.signed_url, '_blank');
        },
        updateJsonEditor(jsonData: Record<string, any>): void {
            this.jsonEditor.setValue(JSON.stringify(jsonData, null, 4));
        },
        onRequestFormInput(formData: DocumentRequestDataFormParams) {
            this.requestForm = formData;
            this.isDocGenerated = false;
        },
    },
});
