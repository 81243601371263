
























































import Vue from 'vue';

// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppTable from '@/components/partials/AppTable.vue';
import { fetchResource } from '@/__new__/services/dno/sim/http/inventory';

import { SIM_TYPES_NAME_MAP } from '@/__new__/features/customerCare/subscriber/common/simTypes';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import { SIM_STATE_NAME_MAP, SIMStateColorMap } from '@/__new__/features/resources/sim/simState';

export default Vue.extend({
    name: 'SimProfileUtilities',

    components: {
        AppMultiselectV3,
        AppButton,
        AppInputV3,
        AppTable,
        EntityStatusIndicator,
    },
    props: {
        utilityTypes: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            utilities: {
                type: null,
                value: '',
            },
            BUTTON_TYPES,
            ICON_TYPES,
            searchResult: [],
            tableColumnsData: [
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'resourceType',
                    field: 'resourceType',
                },
                {
                    name: this.$i18n.t('customerCare.imsi'),
                    key: 'imsi',
                    field: 'imsi',
                    formatter: this.formatOwnership,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    field: 'state',
                },
            ],
            SIM_STATE_NAME_MAP,
            SIMStateColorMap,
        };
    },
    computed: {
        utilityValuePlaceholder() {
            const entityType = this.utilities?.type?.i18n ? this.$i18n.t(this.utilities.type.i18n) : '';
            return this.$i18n.t('operator.addEntity', { entityType });
        },
        utilityDescription() {
            const entityType = this.utilities?.type?.i18n ? this.$i18n.t(this.utilities.type.i18n) : '';
            return this.$i18n.t('operator.getInfoForEntity', { entityType });
        },
    },
    methods: {
        async searchUtility() {
            await this.$withProgressBar(
                async () => {
                    const { data } = await fetchResource({
                        resource_id: this.utilities.value,
                        skip_validation: true,
                    });
                    const simeProfile = data.resource;
                    this.searchResult = [
                        {
                            resourceType: SIM_TYPES_NAME_MAP[simeProfile.resource_type],
                            imsi: simeProfile.imsi,
                            state: simeProfile.state,
                        },
                    ];
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('operator.somethingWentWrong'));
                    },
                },
            );
        },
    },
});
