import EventProp from '@/__new__/services/dno/events/models/EventProp';

export class EventCompositionKey {
    eventName: string;
    eventType: string;
    selectedProperty: EventProp;
    allProperties: EventProp[];
    constructor(eventName: string, eventType: string, selectedProperty: EventProp, allProperties: EventProp[]) {
        this.eventName = eventName;
        this.eventType = eventType;
        this.selectedProperty = selectedProperty;
        this.allProperties = allProperties;
    }
}
