import i18n from '@/i18n';
import UserManagerBaseModel from '@/__new__/services/dno/user/models/UserManagerBaseModel';
import { formatAmountBasedOnCurrency } from '@/common/formatting';
import localeLibrary from '@/common/locale/localeLibrary';

// type imports
import {
    ORDER_ENTITY_STATES,
    ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
} from '@/__new__/features/customerCare/common/orderStateHelper';
import { OrderBE, OrderId, CurrentlyActiveOrder } from '@/__new__/features/customerCare/common/ordersHelper';

interface OrderConstructorArgs {
    orderId: OrderId;
    createTimestamp: number | string;
    chargedAmount: number | null;
    chargedTo: string;
    currency: string | null;
    friendlyOrderId: string | null;
    entities: OrderBE['entities'];
    subOrders: OrderBE['sub_orders'];
    offer: string;
    state: ORDER_ENTITY_STATES | null;
    walletType: string;
    iccid: string;
    imei: string;
    totalAmount: string;
    subscriberId: string;
    isPreorder: boolean;
    replaceInfo: OrderBE['replace_info'] | null;
    orderStateHistory: ReturnType<typeof Order.mapOrderStateHistory>;
    buyerFirstName: string;
    buyerLastName: string;
    buyerAccountId: string;
    buyerMSISDN: string;
    expireDate: number | string;
    currentlyActivateOrder: CurrentlyActiveOrder | null;
}

export default class Order extends UserManagerBaseModel {
    orderId;

    createTimestamp;

    chargedAmount;

    chargedTo;

    currency;

    entities;

    subOrders;

    offer;

    walletType;

    friendlyOrderId;

    iccid;

    imei;

    state;

    stateLabel;

    replaceInfo;

    amountFormatted;

    totalAmount;

    totalAmountFormatted;

    orderStateHistory;

    subscriberId;

    isPreorder;

    type;

    buyerFirstName;

    buyerLastName;

    buyerAccountId;

    buyerMSISDN;

    expireDate;

    currentlyActivateOrder;

    constructor({
        orderId,
        createTimestamp,
        chargedAmount,
        chargedTo,
        currency,
        entities,
        subOrders,
        offer,
        walletType,
        state,
        friendlyOrderId,
        imei,
        iccid,
        replaceInfo,
        totalAmount,
        orderStateHistory,
        subscriberId,
        isPreorder,
        buyerFirstName,
        buyerLastName,
        buyerAccountId,
        buyerMSISDN,
        expireDate,
        currentlyActivateOrder,
    }: OrderConstructorArgs) {
        // Call constructor of UserManagerBaseModel class for creating new object with unique invalidKeys Map
        super();
        this.orderId = this.validateString('orderId', orderId) ? orderId : '';
        this.friendlyOrderId = this.validateString('friendlyOrderId', friendlyOrderId) ? friendlyOrderId : '';
        this.createTimestamp = this.validateDay('createTimestamp', createTimestamp) ? (createTimestamp as number) : '';
        this.chargedAmount = this.validateNumberInteger('chargedAmount', chargedAmount)
            ? (chargedAmount as number)
            : '';
        this.chargedTo = this.validateString('chargedTo', chargedTo) ? chargedTo : '';
        this.currency = this.validateString('currency', currency) ? currency : '';
        this.entities = entities || {};
        this.subOrders = subOrders || {};
        this.orderStateHistory = orderStateHistory || [];
        this.offer = this.validateString('offer', offer) ? offer : '';
        this.walletType = this.validateString('walletType', walletType) ? walletType : '';
        this.imei = this.validateString('imei', imei) ? imei : '';
        this.iccid = this.validateString('iccid', iccid) ? iccid : '';
        this.totalAmount = this.validateString('totalAmount', totalAmount) ? totalAmount : '';
        this.subscriberId = this.validateString('subscriberId', subscriberId) ? subscriberId : '';
        this.isPreorder = isPreorder || false;
        this.state = this.validateNumberInteger('state', state) ? (state as ORDER_ENTITY_STATES) : '';
        this.stateLabel =
            ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP.get(state as ORDER_ENTITY_STATES) || i18n.t('generic.N/A');
        this.replaceInfo = replaceInfo || null;
        this.amountFormatted = formatAmountBasedOnCurrency(this.chargedAmount, this.currency as string);
        this.totalAmountFormatted = formatAmountBasedOnCurrency(this.totalAmount, this.currency as string);
        this.type = isPreorder
            ? i18n.t('customerCare.orderHistory.preOrder')
            : i18n.t('customerCare.orderHistory.order');
        this.buyerFirstName = this.validateString('buyerFirstName', buyerFirstName) ? buyerFirstName : '';
        this.buyerLastName = this.validateString('buyerLastName', buyerLastName) ? buyerLastName : '';
        this.buyerAccountId = this.validateString('buyerAccountId', buyerAccountId) ? buyerAccountId : '';
        this.buyerMSISDN = this.validateString('buyerMSISDN', buyerMSISDN) ? buyerMSISDN : '';
        this.expireDate = this.validateDay('expireDate', expireDate) ? expireDate : '';
        this.currentlyActivateOrder = currentlyActivateOrder || null;
    }

    static mapOrderStateHistory(orderStateHistory: OrderBE['order_state_history']): {
        state: ORDER_ENTITY_STATES;
        date: ReturnType<typeof localeLibrary.getFormattedDate>;
        time: ReturnType<typeof localeLibrary.getFormattedTime>;
    }[] {
        if (orderStateHistory?.length) {
            return orderStateHistory.map(({ update_time_ms: updateTimeMs, state }) => ({
                state,
                date: localeLibrary.getFormattedDate(updateTimeMs),
                time: localeLibrary.getFormattedTime(updateTimeMs),
            }));
        }
        return [];
    }

    static remapOrderFromBe(order: Partial<OrderBE>): OrderConstructorArgs {
        // Refactor as part of POR-5895.
        const isPostpaid = order.order_data?.delayed_charges?.[0]?.data.subscription_model === 'postpaid';

        return {
            orderId: order?.order_id || '',
            createTimestamp: order?.create_timestamp || order?.order_data?.order_creation_time || '',
            chargedAmount: isPostpaid
                ? Order.getPostpaidTotalAmount(order)
                : order?.charged_amount || order?.order_data?.charged_amount || null,
            chargedTo: order?.charged_to || order?.order_data?.charged_to || '',
            currency: order?.order_data?.currency || Order.remapOrderCurrencyFromBe(order),
            friendlyOrderId: order?.order_data?.friendly_order_id || null,
            entities: order?.entities || {},
            subOrders: order?.sub_orders || {},
            offer: order?.order_data?.entity_name || '',
            state: order?.state || null,
            walletType: order?.wallet_type || '',
            iccid: order?.order_data?.iccid || '',
            imei: order?.order_data?.imei || '',
            totalAmount: isPostpaid
                ? Order.getPostpaidTotalAmount(order).toString()
                : order?.order_data?.total_amount || '',
            subscriberId: order?.order_data?.subscriber_id || '',
            isPreorder: order?.order_data?.is_preorder || false,
            replaceInfo: order?.replace_info || null,
            orderStateHistory: Order.mapOrderStateHistory(order?.order_state_history),
            buyerFirstName: order?.order_data?.buyer_info?.first_name || '',
            buyerLastName: order?.order_data?.buyer_info?.last_name || '',
            buyerAccountId: order?.order_data?.buyer_info?.account_id || '',
            buyerMSISDN: order?.order_data?.buyer_info?.msisdn || '',
            expireDate: order?.order_data?.expiry_time || '',
            currentlyActivateOrder: order?.order_data?.currently_activate_order || null,
        };
    }

    // remove after GOMO-3207 is done
    static remapOrderCurrencyFromBe(order: Partial<OrderBE>): string | null {
        if (order?.order_data?.order_items) {
            // There can be only one order in 'order.order_data.order_items'!
            return Object.values(order.order_data.order_items)[0]?.currency;
        }

        return null;
    }

    static getPostpaidTotalAmount(order: Partial<OrderBE>) {
        return order.order_data?.delayed_charges?.reduce((acc, charge) => acc + Number(charge.data.amount), 0) || 0;
    }
}
