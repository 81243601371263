

























































































































































































































































































// components
import AppHeader from '@/components/layout/AppHeader.vue';
import Breadcrumbs from '@/components/partials/Breadcrumbs.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import MultipleTablesTabs from '@/components/partials/MultipleTablesTabs.vue';
import AppTable, { tableSizes } from '@/components/partials/AppTable.vue';
import ExecutionStatusIndicator from '@/__new__/features/orchestrationengine/ExecutionStatusIndicator.vue';
import {
    EXECUTION_STATES,
    EXECUTION_ID_TO_STATUS,
} from '@/__new__/features/orchestrationengine/common/executionStatusIndicatorHelper';
import PlanDefinition from '@/__new__/features/orchestrationengine/PlanDefinition.vue';
import ScheduleExecutionModal from '@/__new__/features/orchestrationengine/ScheduleExecutionModal.vue';
import RunExecutionModal from '@/__new__/features/orchestrationengine/RunExecutionModal.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import AppPaginationLoadMore from '@/components/partials/AppPaginationLoadMore.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import ResponseModalOrchestrationEngine from '@/components/partials/ResponseModalOrchestrationEngine.vue';
import StopExecutionModal from '@/__new__/features/orchestrationengine/StopExecutionModal.vue';
import UpdateScheduledTimeModal from '@/__new__/features/orchestrationengine/UpdateScheduledTimeModal.vue';
import ExportExecutionsDialog from '@/__new__/features/orchestrationengine/plans/ExportExecutionsDialog.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

// Mixins
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';

// vuex stuff
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

// helpers
import tableColumnType, { optionsEnum } from '@/common/filterTable';
import { isValidUuid } from '@/common/uuidHelper';
import luaErrorCodes from '@/common/luaErrors';
import { dateToEpoch } from '@/common/formatting';
import { TranslateResult } from 'vue-i18n';
import { DisplayedExecution } from '@/__new__/services/dno/orchestrationengine/models/Execution';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import {
    TableFilter,
    CurrentPlan,
    EQUALITY,
    CutomTableColumn,
} from '@/__new__/features/orchestrationengine/common/orchestrationEngineEditorHelper';
import moment from 'moment';

// error handling
import * as Sentry from '@sentry/vue';
import RouteNames from '@/router/routeNames';

// http
import { getExecution, getPlanDetails } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

export default {
    name: 'OrchestrationEngineEditor',
    components: {
        AppHeader,
        Breadcrumbs,
        AppButton,
        IconButton,
        MultipleTablesTabs,
        AppTable,
        ExecutionStatusIndicator,
        PlanDefinition,
        ScheduleExecutionModal,
        RunExecutionModal,
        AppInputV3,
        AppPaginationLoadMore,
        FilterTable,
        TableFiltersTags,
        ResponseModalOrchestrationEngine,
        StopExecutionModal,
        UpdateScheduledTimeModal,
        ExportExecutionsDialog,
        AppMultiselectV3,
    },
    mixins: [FilterTableMixin],
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            EXECUTION_STATES: EXECUTION_STATES as any,
            ICON_TYPES: ICON_TYPES as any,
            BUTTON_TYPES: BUTTON_TYPES as any,
            RouteNames: RouteNames as any,

            tableSizes: tableSizes as any,
            pageSize: tableSizes[1],
            scheduleExecutionModalVisible: false as boolean,
            runExecutionModalVisible: false as boolean,
            searchQuery: '' as string,
            activeTableIndex: 0 as number,
            isExecutionsDataLoading: false as boolean,
            executionsDefaultSortObj: null as null | any[],
            tablesNames: [] as TranslateResult[],
            reachedLastPage: false as boolean,
            currentPageIndex: 0 as number,
            executionID: '' as string,
            goToExecutionButtonClicked: false as boolean,
            allFilters: [] as TableFilter[] | [],
            displayedExecutions: [] as DisplayedExecution[] | [],
            responseModalVisible: false as boolean,
            executionsApiResponse: {} as object,
            selectedExecutionId: '' as string,
            stopExecutionModalVisible: false as boolean,
            isUpdateScheduledTimeSidebarVisible: false as boolean,
            isExportExecutionsDialogVisible: false as boolean,
            currentPlan: {} as CurrentPlan,
            planDetails: {} as any,
        };
    },
    computed: {
        ...mapGetters('orchestrationengine', {
            getExecutionObject: Getters.GET_OE_EXECUTION_OBJECT,
            getExecutions: Getters.GET_OE_EXECUTIONS_BY_PLAN_ID,
            getExecutionsApiResponse: Getters.GET_OE_EXECUTIONS_API_RESPONSE,
            getPlan: Getters.GET_OE_PLAN_BY_ID,
        }),
        currentPlanData(): { name: TranslateResult; submittedDate: TranslateResult } {
            const currentPlan = this.getPlan(this.id);
            return {
                name: (currentPlan && currentPlan.plan_id) || this.$i18n.t('generic.N/A'),
                submittedDate:
                    (currentPlan &&
                        currentPlan.submitted_at &&
                        this.$localeLibrary.getFormattedDateAndTime(currentPlan.submitted_at)) ||
                    this.$i18n.t('generic.N/A'),
            };
        },
        getExecutionsByPlanId(): DisplayedExecution[] {
            return this.getExecutions(this.id);
        },
        breadcrumbList(): Array<{ name: TranslateResult; link?: string }> {
            return [
                {
                    name: this.$i18n.t('orchestrationEngine.orchestrationEngine'),
                    link: '/orchestration-engine/plans',
                },
                {
                    name: `${this.id}`,
                },
            ];
        },
        executionsTableFilterOptions(): CutomTableColumn[] {
            const columnsData: CutomTableColumn[] = this.executionsColumnsData;
            return columnsData.filter(column => column.key !== 'id');
        },
        executionsColumnsData(): CutomTableColumn[] {
            return [
                {
                    name: this.$i18n.t('generic.id'),
                    key: 'id',
                    field: 'id',
                    fieldNameOnBE: 'execution_id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.startTimestamp'),
                    key: 'startDateFormatted',
                    field: 'startDateMoment',
                    fieldNameOnBE: 'started_at',
                    sortBy: (entity: DisplayedExecution) => entity.startDate,
                    filterType: tableColumnType.DATETIME,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.endTimestamp'),
                    key: 'endDateFormatted',
                    field: 'endDateMoment',
                    fieldNameOnBE: 'finished_at',
                    sortBy: (entity: DisplayedExecution) => entity.endDate,
                    filterType: tableColumnType.DATETIME,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.schedulingTimestamp'),
                    key: 'schedulingDateFormatted',
                    field: 'schedulingDateMoment',
                    fieldNameOnBE: 'scheduled_at',
                    sortBy: (entity: DisplayedExecution) => entity.schedulingDate,
                    filterType: tableColumnType.DATETIME,
                    tooltipText: this.$i18n.t('orchestrationEngine.schedulingTimestampTooltip'),
                },
                {
                    name: this.$i18n.t('orchestrationEngine.scheduledTimestamp'),
                    key: 'scheduledDateFormatted',
                    field: 'scheduledDateMoment',
                    fieldNameOnBE: 'scheduled_for',
                    sortBy: (entity: DisplayedExecution) => entity.scheduledDate,
                    filterType: tableColumnType.DATETIME,
                    tooltipText: this.$i18n.t('orchestrationEngine.scheduledTimestampTooltip'),
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    field: 'statusFilterFormatted',
                    fieldNameOnBE: 'status',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Object.values(EXECUTION_ID_TO_STATUS),
                },
            ];
        },
        hasMorePages(): boolean {
            const remainingExecutions =
                (this.getExecutionObject?.total_hits?.value || 0) - this.getExecutions(this.id).length;
            const secondCondition = remainingExecutions > 0;

            return (
                this.currentPageIndex + 1 < Math.ceil(this.getExecutions(this.id).length / this.pageSize.value) ||
                (!this.isExecutionsDataLoading && secondCondition)
            );
        },
        writeEnabled(): boolean {
            return isUserAllowed('OrchestrationPlansWrite') && permissionsService.orchestrationEnginePlansEnabled();
        },
        disableExportButton(): boolean {
            return Boolean(this.allFilters.length && this.displayedExecutions.length) && !this.isExecutionsDataLoading;
        },
        setTableRangeAmount(): { start: number; end: number } {
            return {
                start: 1,
                end: this.pageSize.value,
            };
        },
    },
    created() {
        this.$withLoadingSpinner(async () => {
            this.tablesNames = [
                this.$i18n.t('orchestrationEngine.executions'),
                this.$i18n.t('orchestrationEngine.definition'),
            ];

            await Promise.all([this.fetchExecutions(), this.fetchPlans(), this.fetchPlanDetails()]);
            this.currentPlan = this.getPlan(this.id);
        });
    },
    methods: {
        ...mapActions('orchestrationengine', {
            requestExecutions: Actions.REQUEST_OE_EXECUTIONS,
            requestPlans: Actions.REQUEST_OE_PLANS,
        }),
        setReachedLastPage(val: any): void {
            this.reachedLastPage = val.isReached;
        },
        formatTimestampValue(value: moment.MomentInput): TranslateResult | number {
            return this.$localeLibrary.normalizeTimestamp(dateToEpoch(value));
        },
        resetCurrentPageIndex(): void {
            this.currentPageIndex = 0;
        },
        async goToExecution(): Promise<void> {
            try {
                this.goToExecutionButtonClicked = true;
                if (!isValidUuid(this.executionID)) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('orchestrationEngine.alerts.goToExecutionValidation'),
                        type: ALERT_TYPES.warning,
                    });
                    return;
                }
                this.$Progress.start();

                const response = await getExecution(this.executionID);

                this.$Progress.finish();

                // eslint-disable-next-line camelcase
                if (response?.status === 200 && response?.data?.execution?.plan_id === this.id) {
                    this.$router.push({
                        name: RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                        params: {
                            id: this.id,
                            execId: this.executionID,
                            companyId: this.$route.params.companyId,
                        },
                    });
                } else {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('orchestrationEngine.alerts.executionDoesNotExistWithinCurrentPlan', {
                            plan: this.id,
                        }),
                        type: ALERT_TYPES.error,
                    });
                }
            } catch (e) {
                this.$Progress.fail();
                if (e?.response?.data?.code === luaErrorCodes.ORCHESTRATION.PLAN_EXECUTION_MISSING.code) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('orchestrationEngine.alerts.executionDoesNotExist'),
                    });
                } else {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.somethingWentWrong'),
                    });
                }
            } finally {
                this.goToExecutionButtonClicked = false;
            }
        },
        addFilter(filter: { column: string; condition: any; values: string }): void {
            this.resetCurrentPageIndex();
            this.onFilterAdded(filter);
            let operator = filter.condition.key;

            // since BE for now doesn't support between filter as one filter, we need to separate it in two filters
            if (operator === optionsEnum.isBetween.key) {
                const beforeFilter = {
                    field_name: filter.column.fieldNameOnBE,
                    operator: 'is_before',
                    value: this.formatTimestampValue(filter.values.endValue),
                    isBetween: true,
                };

                const afterFilter = {
                    field_name: filter.column.fieldNameOnBE,
                    operator: 'is_after',
                    value: this.formatTimestampValue(filter.values.value),
                    isBetween: true,
                };

                this.allFilters.push(beforeFilter, afterFilter);
            } else {
                let { value } = filter.values;

                // converting date format into timestamp
                if ([optionsEnum.isBefore.key, optionsEnum.isAfter.key].includes(operator)) {
                    value = this.formatTimestampValue(value);
                }

                // format filter for status field
                if (filter.column.fieldNameOnBE === 'status') {
                    const statusNumber = Object.keys(EXECUTION_ID_TO_STATUS).find(
                        key => EXECUTION_ID_TO_STATUS[key] === value,
                    );
                    if (operator === optionsEnum.is.key) {
                        operator = EQUALITY.IS_EQUAL_TO;
                    } else if (operator === optionsEnum.isNot.key) {
                        operator = EQUALITY.IS_NOT_EQUAL_TO;
                    }
                    value = parseInt(statusNumber, 10);
                }

                const oneFilter = {
                    field_name: filter.column.fieldNameOnBE,
                    operator,
                    value,
                };

                this.allFilters.push(oneFilter);
            }

            this.fetchExecutions();
        },
        onRemoveFilter(index: number): void {
            this.resetCurrentPageIndex();

            // call method from mixin to remove filter
            this.removeFilter(index);

            const filterForDelete = this.allFilters[index];

            // remove filter from allFilters which is passed to API
            this.allFilters.splice(index, 1);

            // if filter which we are deleting is 'between', then it has his pair object which we have created
            // in 'addFilter' method and we need to filter out that object too
            if (filterForDelete.isBetween) {
                const indexOfPair = this.allFilters.findIndex(
                    filter => filter.field_name === filterForDelete.field_name && filter.isBetween,
                );
                this.allFilters.splice(indexOfPair, 1);
            }

            this.fetchExecutions();
        },
        onRemoveAllFilters(): void {
            this.removeAllFilters();

            this.allFilters = [];
            this.resetCurrentPageIndex();

            this.fetchExecutions();
        },
        async fetchExecutions(pageIndex = 0): Promise<void> {
            this.currentPageIndex = pageIndex;

            try {
                this.isExecutionsDataLoading = true;
                this.$Progress.start();

                const filters = [
                    {
                        field_name: 'plan_id',
                        operator: 'is',
                        value: this.id,
                    },
                ];

                if (this.allFilters.length) {
                    this.allFilters.forEach(filter =>
                        filters.push({
                            field_name: filter.field_name,
                            operator: filter.operator,
                            value: filter.value,
                        }),
                    );
                }

                await this.requestExecutions({
                    planId: this.id,
                    pageSize: this.pageSize.value,
                    fromPage: this.currentPageIndex * this.pageSize.value,
                    filters,
                    sortBy: [
                        {
                            field_name: 'scheduled_at',
                            operator: 'desc',
                        },
                    ],
                });

                this.executionsApiResponse = this.getExecutionsApiResponse;
                this.displayedExecutions = this.getExecutionsByPlanId;
                this.setExecutionsDefaultSortObj();
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                });

                // empty table of executions if request is wrong
                this.displayedExecutions = [];
            } finally {
                this.isExecutionsDataLoading = false;
            }
        },
        async fetchPlanDetails(): Promise<void> {
            try {
                this.$Progress.start();
                const getPlanResponse = await getPlanDetails({
                    id: this.id,
                });

                this.planDetails = getPlanResponse?.data?.orchestration_plan_by_id?.[this.id]?.data || {};
                this.$Progress.finish();
            } catch (error: any) {
                this.$Progress.fail();
                Sentry.captureException(error);
            }
        },
        async fetchPlans(): Promise<void> {
            try {
                this.$Progress.start();
                await this.requestPlans();
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                });
            }
        },
        shouldShowStopIcon(execution: DisplayedExecution): boolean {
            return ![EXECUTION_STATES.FAILED, EXECUTION_STATES.STOPPED, EXECUTION_STATES.SUCCESS].includes(
                execution.status,
            );
        },
        onStopExecutionClick(execution: DisplayedExecution): void {
            this.stopExecutionModalVisible = true;
            this.selectedExecutionId = execution.id;
        },
        onOpenScheduledTimestampSidebarClick(execution: { id: string }): void {
            this.isUpdateScheduledTimeSidebarVisible = true;
            this.selectedExecutionId = execution.id;
        },
        executionDetailsAction(entity: DisplayedExecution): void {
            this.$router.push({
                name: RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                params: {
                    id: this.id,
                    execId: entity.id,
                    companyId: this.$route.params.companyId,
                },
            });
        },
        editWorkflow(): void {
            this.$router.push({
                name: RouteNames.PLAN_EDITOR,
                params: {
                    id: this.id,
                    companyId: this.$route.params.companyId,
                },
            });
        },
        setExecutionsDefaultSortObj(): void {
            let isAnyScheduledTimestamp = false;

            for (let i = 0; i < this.getExecutionsByPlanId.length; i += 1) {
                if (this.getExecutionsByPlanId[i].scheduledDate > 0) {
                    isAnyScheduledTimestamp = true;
                    i = this.getExecutionsByPlanId.length;
                }
            }

            this.executionsDefaultSortObj = [];
            this.executionsDefaultSortObj.push({
                key: 'startDateFormatted',
                sortBy: (entity: DisplayedExecution) => entity.startDate,
                type: 'desc',
            });
            if (isAnyScheduledTimestamp) {
                this.executionsDefaultSortObj.push({
                    key: 'scheduledDateFormatted',
                    sortBy: (entity: DisplayedExecution) => entity.scheduledDate,
                    type: 'desc',
                });
            }
        },
        stopExecution(): void {
            this.closeStopExecutionModal();
            this.fetchExecutions();
        },
        closeStopExecutionModal(): void {
            this.stopExecutionModalVisible = false;
        },
        updateScheduledTime(): void {
            this.isUpdateScheduledTimeSidebarVisible = false;
            this.fetchExecutions();
        },
        updatePageSize(): void {
            this.fetchExecutions();
        },
    },
};
