
import Vue from 'vue';

// components
import BulkUploadPageLayout from '@/__new__/features/resources/BulkUploadPageLayout.vue';
import BulkUploadTabs, { type Tab } from '@/__new__/features/resources/BulkUploadTabs.vue';
import DeveloperLineSearch from '@/__new__/features/resources/developerLineAuthorization/DeveloperLineSearch.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

// helpers
import { DEVICE_LINE_AUTH_API_TYPE } from '@/__new__/services/dno/ossdevedge/models/DeveloperLineAuthorizationDno';
import { API_TYPE_TO_CONFIG, type API_TYPE_CONFIG } from '@/__new__/services/dno/ossdevedge/models/QodMsisdnPortal';
import tableColumnType, { type TableColumn, type TableSortFilter } from '@/common/filterTable';
import type { UploadColumnLegend } from '@/__new__/features/resources/BulkUploadDialog.vue';
import { formatEventCategory, getAllFileUploadStatusStrings } from '@/common/fileUploadHelper';
import type { CollapsibleListItem } from '@/common/AppCollapsibleListHelper';
import { isUserAllowed } from '@/services/permissions/permissions.service';

// http
import { getColumnData } from '@/__new__/services/dno/developerLineAuthorization/developerLineAuthorizationService';
import { getUploadHistory } from '@/__new__/services/dno/progressTracker/http/progressTrackerService';

export default Vue.extend({
    name: 'DeveloperLineAuthorization',
    components: {
        BulkUploadPageLayout,
        BulkUploadTabs,
        DeveloperLineSearch,
        AppMultiselectV3,
    },
    data() {
        return {
            apiType: undefined as Tab | undefined,
            tab: '' as Tab['id'],
            subTab: '' as Tab['id'],
            subTabs: [
                {
                    id: 'default',
                    label: this.$t('operations.bulkUpload.uploadHistory'),
                },
                {
                    id: 'search',
                    label: this.$t('qodNumberManagement.customerLineSearch'),
                },
            ] as Tab[],
            entities: {
                [DEVICE_LINE_AUTH_API_TYPE.QOD]: [],
                [DEVICE_LINE_AUTH_API_TYPE.DEVICE_STATUS]: [],
                [DEVICE_LINE_AUTH_API_TYPE.SIM_SWAP]: [],
                [DEVICE_LINE_AUTH_API_TYPE.LOCATION_VERIFICATION]: [],
                [DEVICE_LINE_AUTH_API_TYPE.SD_WAN]: [],
            } as Record<DEVICE_LINE_AUTH_API_TYPE, any>,
            tableColumns: [
                {
                    name: this.$t('generic.uploadTime'),
                    key: 'createdAtStr',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.createdAt),
                    sortBy: entity => entity.createdAt,
                    field: 'createdAt',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$t('generic.filename'),
                    key: 'fileName',
                    field: 'fileName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.uploadedBy'),
                    key: 'createdBy',
                    field: 'createdBy',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('qodNumberManagement.recordCount'),
                    key: 'recordCount',
                    field: 'recordCount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$t('generic.status'),
                    key: 'fileUploadStatusStr',
                    field: 'fileUploadStatusStr',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: getAllFileUploadStatusStrings(),
                },
            ] as TableColumn[],
            defaultSortBy: {
                key: 'createdAtStr',
                type: 'desc',
            } as TableSortFilter,
            isHistoryDataLoading: false,
            writePermissions: [
                'QoDNumberManagementWrite',
                'DeviceStatusNumberManagementWrite',
                'LocationVerificationNumberManagementWrite',
                'SDWANNumberManagementWrite',
                'SIMSwapNumberManagementWrite',
                'NetworkAuthenticationNumberManagementWrite',
            ],
        };
    },
    computed: {
        tabs(): Array<
            Tab & {
                id: DEVICE_LINE_AUTH_API_TYPE;
                dataflow: API_TYPE_CONFIG['dataflow'];
            }
        > {
            const options = [];
            for (const [api, config] of API_TYPE_TO_CONFIG) {
                const { dataflow, permissions } = config;
                if (isUserAllowed(permissions.read, permissions.write)) {
                    let { label } = config;
                    if (config.labelVerbose) {
                        label += ` (${config.labelVerbose})`;
                    }
                    options.push({
                        id: api,
                        label,
                        dataflow,
                    });
                }
            }
            return options;
        },
        exampleColumns(): UploadColumnLegend[] {
            const cols = getColumnData(this.tab as DEVICE_LINE_AUTH_API_TYPE).map((it, i) => ({
                ...it,
                index: i + 1,
                name: it.label,
            }));
            return cols;
        },
        dataflow(): Tab['dataflow'] {
            return this.apiType?.dataflow;
        },
        isSearchSelected(): boolean {
            return this.subTab === 'search';
        },
    },
    async created() {
        await this.loadHistory();
    },
    methods: {
        async loadHistory(): Promise<void> {
            await this.$withProgressBar(
                async () => {
                    this.isHistoryDataLoading = true;

                    await Promise.all(
                        Array.from(this.tabs, async tab => {
                            this.entities[tab.id] = await getUploadHistory(tab.dataflow);
                        }),
                    );

                    this.isHistoryDataLoading = false;
                },
                {
                    errorHandler: () => {
                        this.isHistoryDataLoading = false;
                        this.$alert(this.$t('qodNumberManagement.loadingBulkUploadHistoryFailed'));
                    },
                },
            );
        },
        mapSidebarData(entity: any): CollapsibleListItem[] {
            if (this.isSearchSelected) {
                return [];
            }
            return [
                {
                    name: this.$t('generic.general'),
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$t('generic.filename'),
                            value: entity?.fileName ?? '',
                        },
                        {
                            name: this.$t('qodNumberManagement.bulkUploadId'),
                            value: entity?.bulkUploadId ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('qodNumberManagement.recordCount'),
                            value: entity?.recordCount ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('generic.uploadedBy'),
                            value: entity?.createdBy ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('generic.uploadTime'),
                            value: this.$localeLibrary.getFormattedDateAndTime(entity?.createdAt),
                        },
                    ],
                },
                {
                    name: this.$t('qodNumberManagement.provisionStatus'),
                    isCollapsed: false,
                    rows: Object.entries(entity?.eventCategoryCounters || {}).length
                        ? Object.entries(entity?.eventCategoryCounters || {}).map(([status, count]) => ({
                              name: formatEventCategory(status),
                              value: `${count}/${entity?.recordCount}`,
                          }))
                        : [{ name: this.$t('generic.unknown') }],
                },
            ];
        },
        onTabSelect(tabId: Tab['id']) {
            this.tab = tabId;
            this.apiType = this.tabs.find(({ id }) => id === tabId);
        },
        onApiTypeChange(tab: Tab) {
            this.tab = tab.id;
            this.apiType = tab;
        },
    },
});
