
import { PropType } from 'vue';
import Actions, { Getters } from '@/store/mutation-types';

// Components
import CardListRadioInput from '@/components/partials/cards/CardListRadioInput.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';

// Helpers
import { TOOLTIP_POSITION } from '@/common/tooltip';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { Modules } from '@/store/store';
import ProductCatalogOfferModel from '@/__new__/services/dno/pc/models/ProductCatalogOfferModel';
import CategorizedOffers from '@/components/partials/inputs/CategorizedOffers.vue';

// eslint-disable-next-line no-shadow
enum RECCURENCE_CADENCE {
    NONE = 'NONE',
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
    ANNUALLY = 'ANNUALLY',
}

/* eslint-disable camelcase */
interface DnoObligationPropsData {
    subscription_model: number;
    first_charge_at: number;
    activation_type: number;
    override_first_charge_at: Record<string, string[]> | null;
    continue_recurrence: boolean;
    override_activation_type: Record<string, string[]> | null;
    recurrence_cadence: string;
    recurrence_limit: number;
}
/* eslint-enable camelcase */

export default {
    name: 'DnoObligation',
    components: {
        AppInputV3,
        AppToggle,
        CardListRadioInput,
        CategorizedOffers,
    },
    props: {
        value: {
            type: Object as PropType<DnoObligationPropsData>,
            default: () => ({
                subscription_model: 1,
                first_charge_at: 1,
                activation_type: 1,
                override_first_charge_at: {},
                continue_recurrence: false,
                override_activation_type: {},
                recurrence_cadence: RECCURENCE_CADENCE.MONTHLY,
                recurrence_limit: 1,
            }),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            offersLoading: false,

            subscriptionModelOptions: [
                { id: 1, label: this.$t('productCatalog.offers.dnoObligation.subscriptionModelPrepaid') },
                { id: 2, label: this.$t('productCatalog.offers.dnoObligation.subscriptionModelPostpaid') },
            ],
            recurrenceCadenceOptions: [
                {
                    id: RECCURENCE_CADENCE.NONE,
                    label: this.$t('productCatalog.offers.dnoObligation.recurrenceCadenceNone'),
                },
                {
                    id: RECCURENCE_CADENCE.MONTHLY,
                    label: this.$t('productCatalog.offers.dnoObligation.recurrenceCadenceMonthly'),
                },
                {
                    id: RECCURENCE_CADENCE.QUARTERLY,
                    label: this.$t('productCatalog.offers.dnoObligation.recurrenceCadenceQuarterly'),
                },
                {
                    id: RECCURENCE_CADENCE.ANNUALLY,
                    label: this.$t('productCatalog.offers.dnoObligation.recurrenceCadenceAnnually'),
                },
            ],
            overrideFirstChargeAtCategories: [
                { id: 1, label: this.$t('productCatalog.offers.dnoObligation.overrideFirstChargeAtImmediate') },
                { id: 2, label: this.$t('productCatalog.offers.dnoObligation.overrideFirstChargeAtOnActivation') },
                { id: 3, label: this.$t('productCatalog.offers.dnoObligation.overrideFirstChargeAtOnBill') },
            ],
            overrideActivationTypeCategories: [
                { id: 1, label: this.$t('productCatalog.offers.dnoObligation.overrideActivationTypeOnBillCycle') },
                { id: 2, label: this.$t('productCatalog.offers.dnoObligation.overrideActivationTypeOnDemand') },
                { id: 3, label: this.$t('productCatalog.offers.dnoObligation.overrideActivationTypeImmediate') },
                { id: 4, label: this.$t('productCatalog.offers.dnoObligation.overrideActivationTypeIfCycleExists') },
            ],

            // Proxy
            TOOLTIP_POSITION,
        };
    },
    computed: {
        offers(): ProductCatalogOfferModel[] {
            return this.$store.getters[`${Modules.productcatalog}/${Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED}`](
                ENTITY_TYPES.OFFER,
            ) as ProductCatalogOfferModel[];
        },
    },
    created() {
        Promise.all([this.fetchOffers()]);
    },
    methods: {
        async fetchOffers() {
            this.offersLoading = true;
            try {
                await this.$store.dispatch(`${Modules.productcatalog}/${Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS}`, {
                    entityType: ENTITY_TYPES.OFFER,
                });
            } catch (error) {
                this.$alert(this.$t('alertMessage.failedToLoadNecessaryData'));
            }
            this.offersLoading = false;
        },
        emitValue(prop: string, value: any) {
            this.$emit('input', { ...this.value, [prop]: value });
        },
    },
};
