








































import Vue, { PropType } from 'vue';

// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// Helpers
import { type TableColumn } from '@/common/filterTable';
import { DisplayedExecution } from '@/__new__/services/dno/orchestrationengine/models/Execution';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import RouteNames from '@/router/routeNames';
import { isUserAllowed } from '@/services/permissions/permissions.service';

type ExportOptionsObj = {
    name: string;
    field: string;
    disabled: boolean;
    value: boolean;
};

export default Vue.extend({
    name: 'ExportExecutionsDialog',
    components: {
        AppDialogV2,
        AppCheckbox,
        AppTextareaV3,
        AppButton,
    },
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
        tableColumns: {
            type: Array as PropType<TableColumn[]>,
            default: () => [],
        },
        displayedExecutions: {
            type: Array as PropType<DisplayedExecution[]>,
            default: () => [],
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            PLURALIZATION,

            exportOptions: [] as ExportOptionsObj[],
            exportData: '' as string,
        };
    },
    computed: {
        showBatchActionsBtn(): boolean {
            return isUserAllowed('BatchActionsWrite');
        },
    },
    watch: {
        exportOptions: {
            deep: true,
            handler() {
                this.exportData = this.createCSV(this.displayedExecutions, this.exportOptions);
            },
        },
    },
    created() {
        this.exportOptions = this.tableColumns.map((el: TableColumn) => ({
            name: el.name,
            field: el.key,
            disabled: el.key === 'id',
            value: el.key === 'id',
        }));
        this.exportData = this.createCSV(this.displayedExecutions, this.exportOptions);
    },
    methods: {
        createCSV(data: DisplayedExecution[], columns: ExportOptionsObj[]): string {
            const enabledColumns = columns.filter(col => col.value);
            const headers = enabledColumns.map(col => col.name);
            const fieldNames = enabledColumns.map(col => col.field);
            const csvRows = [headers.join(', ')];

            data.forEach(item => {
                const row = fieldNames.map(fieldName =>
                    item?.[fieldName] !== this.$i18n.t('generic.N/A') ? item[fieldName] : this.$i18n.t('generic.empty'),
                );
                csvRows.push(row.join(', '));
            });

            return csvRows.join('\n');
        },
        copyToClipboard(): void {
            try {
                navigator.clipboard.writeText(this.exportData);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.copySuccess'),
                    type: ALERT_TYPES.success,
                });
            } catch (e) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.copyFail'),
                });
            }
        },
        goToBatchActions(): void {
            const executionsIds = this.displayedExecutions.reduce((data, current, index) => {
                return data + (index !== 0 ? ', \n' : '') + current.id;
            }, '');

            this.$router.push({
                name: RouteNames.ORCHESTRATION_ENGINE_BATCH_ACTIONS_EDITOR,
                params: {
                    executions: executionsIds,
                },
            });
        },
    },
});
