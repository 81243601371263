





























































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

// Layout
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppTable from '@/components/partials/AppTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import tableColumnType from '@/common/filterTable';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import IconButton from '@/components/partials/IconButton.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';

// Types
import { ALERT_TYPES } from '@/common/alerts/Alert';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { ICON_TYPES } from '@/common/iconHelper';
import { EntityState } from '@/http';

// Pricing Rules
import {
    approvePricingRule,
    deletePricingRule,
    getPricingRules,
} from '@/__new__/services/dno/pricing/pricingRulesService';
import { PricingRuleEntity } from '@/__new__/services/dno/pricing/pricingRulesPortal';

// Permissions
import { isUserAllowed, isViewConfig, isViewEnabled } from '@/services/permissions/permissions.service';

// Misc
import RouteNames from '@/router/routeNames';
import Button from '@/common/button/Button';
import { EDITOR_MODE } from '@/common/entities/entityHelper';
import { capitalize } from 'lodash';
import { Modules } from '@/store/store';
import CohortExpressionOverview from '@/components/partials/CohortExpressionOverview.vue';
import {
    rawCohortExpressionToCohortExpressionOverviewGroup,
    shouldShowCohortOverviewBlock,
} from '@/common/cohortExpressionHelper';

export default Vue.extend({
    name: 'PricingRulesListPage',
    components: {
        CohortExpressionOverview,
        AbstractListPageWrapper,
        AppButton,
        AppTable,
        EntityStatusIndicator,
        FilterTable,
        IconButton,
        TableFiltersTags,
        ResponseModalButton,
        EntityOverview,
        OverviewList,
        OverviewHeader,
        LanguageSwitcher,
    },
    mixins: [FilterTableMixin],
    data() {
        return {
            ALERT_TYPES,
            BUTTON_TYPES,
            ICON_TYPES,
            ENTITY_TYPES,
            EDITOR_MODE,
            EntityState,
            pricingRules: [] as PricingRuleEntity[],
            pricingRulesResponse: {},
            searchQueryForTable: '',
            isDataLoading: false,
            selectedEntity: null,
            isOverviewEnabled: false,
        };
    },
    computed: {
        ...mapGetters('operators', {
            selectedLanguage: Getters.languageDefault,
        }),
        ...mapGetters(Modules.segments, {
            groupedSegments: Getters.GROUPED_SEGMENTS_BY_ID_TYPE,
            segmentsById: Getters.CACHED_SEGMENTS_BY_ID,
        }),
        arrayCohortsAll() {
            return this.groupedSegments.map(group => group.groupValues).flat();
        },
        cohortExpressionOverviewGroups() {
            return rawCohortExpressionToCohortExpressionOverviewGroup(
                this.selectedEntity?.cohortExpression,
                this.segmentsById,
            );
        },
        shouldShowCohortOverviewBlock() {
            return shouldShowCohortOverviewBlock(this.selectedEntity?.cohortExpression);
        },
        // Transform PricingRuleEntity into display format
        displayPricingRules(): PricingRuleEntity[] {
            return this.pricingRules.filter(e => e.state !== EntityState.DELETED);
        },
        entityOverviewDetails() {
            if (!this.selectedEntity) {
                return [];
            }
            // General details
            const details = [
                {
                    name: this.$i18n.t('generic.details'),
                    properties: [
                        {
                            label: this.$i18n.t('generic.view'),
                            value: this.selectedEntity.viewLabel,
                        },
                        {
                            label: this.$i18n.t('generic.id'),
                            value: this.selectedEntity.id,
                        },
                        {
                            label: this.$i18n.t('generic.name'),
                            value: this.selectedEntity.name,
                        },
                        {
                            label: this.$i18n.t('generic.description'),
                            value: this.selectedEntity.description,
                        },
                        {
                            label: this.$i18n.t('generic.priority'),
                            value: this.selectedEntity.priority,
                        },
                        {
                            label: this.$i18n.t('generic.state'),
                            value: capitalize(this.selectedEntity.stateLabel),
                        },
                        {
                            label: this.$i18n.t('generic.lastUpdatedTime'),
                            value: this.$localeLibrary.getFormattedDate(this.selectedEntity.updateTimeEpoch),
                        },
                    ],
                },
            ];
            // Add Misc section if it exists
            if (this.selectedEntity.misc) {
                details.push({
                    name: this.$i18n.t('generic.misc'),
                    properties: Object.entries(this.selectedEntity.misc).map(entry => ({
                        label: entry[0],
                        value: entry[1],
                    })),
                });
            }
            return details;
        },
        /**
         * Applies table filters (via mixin) on our pricing rules
         */
        filteredPricingRules() {
            return this.filteredEntitiesMixin(this.displayPricingRules);
        },
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.description'),
                    key: 'description',
                    field: 'description',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    field: 'stateLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.displayPricingRules.map(e => e.stateLabel))),
                },
                {
                    name: this.$i18n.t('generic.priority'),
                    key: 'priority',
                    field: 'priority',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.lastUpdatedTime'),
                    key: 'updateTime',
                    mapper: entity => this.$localeLibrary.getFormattedDate(entity.updateTimeEpoch),
                    sortBy: entity => entity.updateTimeEpoch,
                    field: 'updateTimeEpoch',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.view'),
                    key: 'viewLabel',
                    field: 'viewLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.displayPricingRules.map(e => e.viewLabel))),
                },
            ].filter(column => column);
        },
        userHasWriteAccess() {
            return isViewConfig() && isViewEnabled('PricingRules') && isUserAllowed('PricingRulesWrite');
        },
    },
    async created() {
        await this.$store.dispatch(`${Modules.segments}/${Actions.FETCH_SEGMENTS}`);
        await this.loadPricingRules();
    },
    methods: {
        async approveEntity(entity) {
            await this.$withProgressBar(
                async () => {
                    await approvePricingRule(entity);
                    this.$alert(this.$i18n.t('alertMessage.pricingAndFees.successfullyApprovedPricingRule'), {
                        type: ALERT_TYPES.success,
                    });
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.pricingAndFees.failedToApprovePricingRule'));
                    },
                },
            );
        },
        async deleteEntity(entity) {
            await this.$withProgressBar(
                async () => {
                    await deletePricingRule(entity);
                    this.$alert(this.$i18n.t('alertMessage.pricingAndFees.successfullyDeletedPricingRule'), {
                        type: ALERT_TYPES.success,
                    });
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.pricingAndFees.failedToDeletePricingRule'));
                    },
                },
            );
        },
        async loadPricingRules() {
            await this.$withProgressBar(
                async () => {
                    this.isDataLoading = true;

                    // Get Pricing Rules
                    const data = await getPricingRules(this.selectedLanguage, this.arrayCohortsAll);
                    this.pricingRulesResponse = data.response;
                    this.pricingRules = data.pricingRules;

                    this.isDataLoading = false;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.pricingAndFees.failedToGetPricingRules'));
                        this.isDataLoading = false;
                    },
                },
            );
        },
        goToEditor() {
            this.$router.push({
                name: RouteNames.PRICING_RULES_EDITOR,
                params: {
                    companyId: this.$route.params.companyId,
                    clone: false,
                    mode: EDITOR_MODE.EDIT,
                },
            });
        },
        onEntitySelected(entity: PricingRuleEntity) {
            this.selectedEntity = entity;
            this.isOverviewEnabled = true;
        },
        openEditor(entity: PricingRuleEntity, clone: boolean, mode: string) {
            this.$router.push({
                name: RouteNames.PRICING_RULES_EDITOR,
                params: {
                    id: entity.id,
                    entity,
                    companyId: this.$route.params.companyId,
                    clone,
                    mode,
                },
            });
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
        showApproveEntityConfirmationAlert(entity) {
            // Create approval button
            const button = new Button({
                label: this.$i18n.t('generic.approve'),
                alertType: ALERT_TYPES.warning,
                handler: async () => {
                    await this.approveEntity(entity);
                    await this.loadPricingRules(); // reload rules
                },
            });

            // Approval Confirmation Alert
            this.$alert(this.$i18n.t('alerts.areYouSureApprove'), {
                type: ALERT_TYPES.warning,
                buttons: [button],
            });
        },
        showDeleteEntityConfirmationAlert(entity) {
            // Create delete button
            const button = new Button({
                label: this.$i18n.t('generic.delete'),
                alertType: ALERT_TYPES.warning,
                handler: async () => {
                    await this.deleteEntity(entity);
                    await this.loadPricingRules(); // reload rules
                },
            });

            // Deletion Confirmation Alert
            this.$alert(
                this.$i18n.t('alerts.areYouSureDeleteEntity', {
                    entityName: entity.name,
                }),
                {
                    type: ALERT_TYPES.warning,
                    buttons: [button],
                },
            );
        },
    },
});
