
// components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import IconButton from '@/components/partials/IconButton.vue';
import Tag from '@/components/partials/inputs/Tag.vue';
// helpers
import { cloneDeep } from 'lodash';
import { ICON_TYPES } from '@/common/iconHelper';
import { EventCompositionKey as EventCompositionKeyClass } from '@/__new__/services/dno/sinkConfigs/EventCompositionConfig';
import { PropType } from 'vue';

export default {
    name: 'EventCompositionKey',
    components: { IconButton, Tag, AppMultiselectV3 },
    props: {
        shouldShowDeleteBtn: {
            type: Boolean,
            default: false,
        },
        keyIndex: {
            type: Number,
            default: 1,
        },
        compositionKey: {
            type: Array as PropType<EventCompositionKeyClass[]>,
            required: true,
        },
    },
    data() {
        return {
            ICON_TYPES,
        };
    },
    methods: {
        onPropertySelect(val: EventCompositionKeyClass, index: number) {
            const clonedKey = cloneDeep(this.compositionKey);
            clonedKey[index].selectedProperty = val;
            this.$emit('keyUpdate', clonedKey);
        },
    },
};
