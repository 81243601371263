import { render, staticRenderFns } from "./BulkUploadCounts.vue?vue&type=template&id=c2bc4954&scoped=true"
import script from "./BulkUploadCounts.vue?vue&type=script&lang=ts"
export * from "./BulkUploadCounts.vue?vue&type=script&lang=ts"
import style0 from "./BulkUploadCounts.vue?vue&type=style&index=0&id=c2bc4954&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2bc4954",
  null
  
)

export default component.exports