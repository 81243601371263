





















// vuex stuff
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

// components
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import OrchestrationListPageLayout from '@/__new__/features/orchestrationengine/OrchestrationListPageLayout.vue';
import Button from '@/common/button/Button';

// helpers
import moment from 'moment';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import RouteNames from '@/router/routeNames';
import tableColumnType from '@/common/filterTable';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { EntityStateMapping } from '@/common/entityStateMapper';
import { ALERT_TYPES } from '@/common/alerts/Alert';

// error handling
import * as Sentry from '@sentry/vue';

// Http
import { updatePlanState } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

export default {
    name: 'OrchestrationEngineListPage',
    components: {
        OrchestrationListPageLayout,
    },
    mixins: [FilterTableMixin],
    data() {
        return {
            pageTitle: this.$i18n.tc('generic.plan', PLURALIZATION.PLURAL) as string,
            entityType: ENTITY_TYPES.ORCHESTRATION_ENGINE_PLAN as any,
            addNewRouteName: RouteNames.PLAN_EDITOR as any,
            detailsRouteName: RouteNames.ORCHESTRATION_ENGINE_EDITOR as any,
            editRouteName: RouteNames.PLAN_EDITOR as any,
            isDataLoading: false as boolean,
            keyName: 'planId' as string,
            editId: 'planId' as string,
        };
    },
    computed: {
        ...mapGetters('orchestrationengine', {
            plans: Getters.GET_OE_PLANS,
            plansApiResponse: Getters.GET_OE_PLANS_API_RESPONSE,
        }),
        ...mapGetters('operators', [Getters.languageDefault]),
        formattedEntities(): any[] {
            return this.plans.map(plan => ({
                planId: plan.plan_id,
                planName: plan.plan_id,
                submissionTimestamp: plan.submitted_at,
                submissionDate: this.$localeLibrary.getFormattedDateAndTime(plan.submitted_at),
                submissionDateMoment: moment(this.$localeLibrary.normalizeTimestamp(plan.submitted_at)),
                version: plan.version || 1,
                planOwner: plan?.plan_owner || '',
            }));
        },
        tableColumnsData(): any[] {
            return [
                {
                    name: this.$i18n.t('planName'),
                    key: 'planName',
                    field: 'planName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.planOwner'),
                    key: 'planOwner',
                    field: 'planOwner',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.submissionTimestamp'),
                    key: 'submissionDate',
                    sortBy: entity => entity.submissionTimestamp,
                    field: 'submissionDateMoment',
                    filterType: tableColumnType.DATETIME,
                },
            ];
        },
        writeEnabled(): boolean {
            return isUserAllowed('OrchestrationPlansWrite') && permissionsService.orchestrationEnginePlansEnabled();
        },
    },
    async created(): Promise<any> {
        // fetching plans
        this.isDataLoading = true;
        await this.fetchPlans();
        this.isDataLoading = false;
    },
    methods: {
        ...mapActions('orchestrationengine', {
            requestPlans: Actions.REQUEST_OE_PLANS,
        }),
        async fetchPlans(): Promise<any> {
            try {
                this.$Progress.start();
                await this.requestPlans();
                this.$Progress.finish();
            } catch (error: any) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                });
            }
        },
        onDeletePlan(entity: any): Promise<any> {
            const deleteButton = new Button({
                label: this.$i18n.t('generic.delete'),
                alertType: ALERT_TYPES.warning,
            });

            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('orchestrationEngine.alerts.deletePlanFromList'),
                type: ALERT_TYPES.warning,
                buttons: [deleteButton],
            });

            this.$eventBus.$once('buttonClicked', (buttonId: any) => {
                if (buttonId === deleteButton.id) {
                    this.$withProgressBar(
                        async () => {
                            await updatePlanState(entity.version, entity.planId, EntityStateMapping.DELETED);

                            this.$router.go();
                        },
                        {
                            errorHandler: () => {
                                this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), {
                                    type: ALERT_TYPES.error,
                                });
                            },
                        },
                    );
                }
            });
        },
    },
};
