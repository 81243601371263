<template>
    <div class="card-container">
        <!-- More Section (Kebab Menu) -->
        <!-- Hidden if appointment has been cancelled or completed (to prevent editing and deletion) -->
        <div
            v-if="showMoreSection"
            v-click-outside="closeMoreDropdown"
            class="more-section"
            data-test-id="more-section"
        >
            <IconButton
                :label="$i18n.t('generic.more')"
                :icon="ICON_TYPES.MORE"
                :disabled="!allowEditActions"
                class="more-btn"
                @iconClick="toggleMoreDropdown"
            />
            <div
                v-show="isMoreOpened"
                class="more-dropdown"
            >
                <div
                    v-if="allowedActions.includes(APPT_ACTION.EDIT)"
                    class="dropdown-row lf-all-caps ac-edit-btn"
                    @click="emitEditAppointment"
                >
                    <AppIcon
                        :type="ICON_TYPES.EDIT"
                        :color="ICON_COLORS.BLUE"
                    />
                    <span>{{ $i18n.t('generic.edit') }}</span>
                </div>
                <div
                    v-if="allowedActions.includes(APPT_ACTION.DELETE)"
                    class="dropdown-row lf-all-caps ac-delete-btn"
                    @click="emitDeleteAppointment"
                >
                    <AppIcon
                        :type="ICON_TYPES.DELETE"
                        :color="ICON_COLORS.BLUE"
                    />
                    <span>{{ $i18n.t('generic.delete') }}</span>
                </div>
            </div>
        </div>
        <div class="d-flex w-100">
            <div class="date-section">
                <div class="date-number">
                    {{ date }}
                </div>
                <div class="date-month lf-all-caps">
                    {{ month }}
                </div>
            </div>
            <div class="main-section">
                <div class="date-day-of-week lf-labels">
                    {{ dayOfWeek }}
                </div>
                <div class="timeframe lf-primary-text">
                    {{ timeframe }}
                    <span v-if="isEnrichedAppointment">({{ appointmentTimezoneName }})</span>
                </div>
                <div>
                    <span> {{ $i18n.t('appointments.card.status') }}: </span>
                    <span :class="appointmentStateClass">
                        {{ appointmentStateValue }}
                    </span>
                    <span v-if="isEnrichedAppointment">| {{ $i18n.t('generic.partner') }}: </span>
                    <span
                        v-if="isEnrichedAppointment"
                        :class="appointmentStateClass"
                    >
                        {{ appointmentExternalStatusLabel }}
                    </span>
                </div>
                <div
                    v-if="isEnrichedAppointment && appointmentReasonCodeLabel"
                    class="d-flex"
                >
                    <span class="flex-shrink-0 mr-1">
                        {{ $i18n.t('customerCare.cancelDeactivateServiceModal.reasonCode') }}:
                    </span>
                    <span
                        :title="appointmentReasonCodeLabel"
                        class="overflow-ellipsis pr-1"
                    >
                        {{ appointmentReasonCodeLabel }}
                    </span>
                </div>
                <div v-if="appointmentSource">
                    <span> {{ $i18n.t('appointments.card.origin') }}: </span>
                    <span>
                        {{ appointmentOriginLabel }}
                    </span>
                </div>
                <div v-if="isEnrichedAppointment">
                    <span> {{ $i18n.t('qodNumberManagement.partnerId') }}: </span>
                    <span>
                        {{ appointmentPartnerIdLabel }}
                    </span>
                </div>
                <div v-if="isEnrichedAppointment">
                    <span> {{ $i18n.t('generic.updatedAt') }}: </span>
                    <span>
                        {{ appointmentUpdatedAtLabel }}
                    </span>
                </div>
                <AppTooltip
                    v-if="appointment.memoAgent"
                    :offset="50"
                    :tooltipPosition="noteTooltipPosition"
                    :contentWidth="contentWidth"
                    class="note-tooltip-wrapper"
                >
                    <template slot="label">
                        <div class="read-note-text">
                            {{ $i18n.t('appointments.card.readNote') }}
                        </div>
                    </template>

                    <template slot="content">
                        <div class="lf-all-caps date-and-time-tooltip">
                            {{ dateAndTimeLabel }}
                        </div>
                        <div
                            class="lf-primary-text note-tooltip"
                            :style="{ width: contentWidth }"
                        >
                            {{ appointment.memoAgent }}
                        </div>
                    </template>
                </AppTooltip>
                <div
                    v-if="!appointment.memoAgent"
                    class="note lf-placeholder"
                >
                    {{ $i18n.t('appointments.card.notePlaceholder') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import IconButton from '@/components/partials/IconButton.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import vClickOutside from 'v-click-outside';
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';
import {
    APPT_STATE,
    APPT_ACTION,
    getAppointmentStateLabel,
    getApptSourceLabel,
} from '@/__new__/features/customerCare/common/appointmentsHelper';
import { getOperatorConfigValue, isUserAllowed } from '@/services/permissions/permissions.service';

export default {
    name: 'AppointmentCard',
    components: {
        IconButton,
        AppTooltip,
        AppIcon,
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    props: {
        // {
        //     "appointmentId": "string",
        //     "timeSlot": {
        //         "timeFrom": 1545667505,
        //         "timeTo": 1545667505,
        //     },
        //     memoAgent: '',
        // }
        appointment: {
            required: true,
            type: Object,
        },
        allowedActions: {
            type: Array,
            default: () => [APPT_ACTION.DELETE, APPT_ACTION.EDIT],
        },
        noteTooltipPosition: {
            type: String,
            default: TOOLTIP_POSITION.right,
        },
        contentWidth: {
            type: String,
            default: '30rem',
        },
    },
    data() {
        return {
            isMoreOpened: false,
            APPT_ACTION,
            APPT_STATE,
            TOOLTIP_POSITION,
            ICON_TYPES,
            ICON_COLORS,
        };
    },
    computed: {
        timeFromMoment() {
            const { timeFrom } = this.appointment.timeSlot;
            return moment(timeFrom);
        },
        timeToMoment() {
            const { timeTo } = this.appointment.timeSlot;
            return moment(timeTo);
        },
        date() {
            const dateNum = this.timeFromMoment.date();
            return this.timeFromMoment.date(dateNum).format('DD');
        },
        month() {
            const monthNum = this.timeFromMoment.month();
            return this.timeFromMoment.month(monthNum).format('MMM');
        },
        dayOfWeek() {
            const weekdayNum = this.timeFromMoment.isoWeekday();
            return this.timeFromMoment.isoWeekday(weekdayNum).format('dddd');
        },
        timeframe() {
            const timeFrom = this.timeFromMoment.format('hh:mm A');
            const timeTo = this.timeToMoment.format('hh:mm A');

            return `${timeFrom} - ${timeTo}`;
        },
        dateAndTimeLabel() {
            const localizedDateLabel = this.$localeLibrary.getFormattedDate(this.appointment.timeSlot.timeFrom);
            const localizedStartTime = this.$localeLibrary.getFormattedTime(this.appointment.timeSlot.timeFrom);
            const localizedEndTime = this.$localeLibrary.getFormattedTime(this.appointment.timeSlot.timeTo);

            return `${localizedDateLabel}, ${localizedStartTime} - ${localizedEndTime}`;
        },
        isEnrichedAppointment() {
            return getOperatorConfigValue('enrichedAppointment');
        },
        appointmentState() {
            return this.appointment.state;
        },
        appointmentSource() {
            return this.appointment.appointmentSource;
        },
        appointmentOriginLabel() {
            return this.$i18n.t(getApptSourceLabel(this.appointmentSource));
        },
        appointmentExternalStatusLabel() {
            return this.appointment?.externalStatus || this.$i18n.t('generic.empty');
        },
        appointmentReasonCodeLabel() {
            return this.appointment?.reasonCode || '';
        },
        appointmentPartnerIdLabel() {
            return this.appointment?.appointmentId || this.$i18n.t('generic.empty');
        },
        appointmentUpdatedAtLabel() {
            return this.appointment?.updatedAt || this.$i18n.t('generic.empty');
        },
        appointmentTimezoneName() {
            return this.appointment?.timeSlot?.timeZone?.shortName || this.$i18n.t('generic.empty');
        },
        appointmentStateClass() {
            return {
                'appointment-state': true,
                'appointment-state-gray': this.appointmentState === APPT_STATE.NONE,
                'appointment-state-orange': this.appointmentState === APPT_STATE.PENDING,
                'appointment-state-blue': this.appointmentState === APPT_STATE.RESERVED,
                'appointment-state-green': this.appointmentState === APPT_STATE.COMPLETED,
                'appointment-state-red': this.appointmentState === APPT_STATE.CANCELED,
                'appointment-state-black':
                    typeof this.appointmentState !== 'number' || this.appointmentState === APPT_STATE.UNDEFINED,
            };
        },
        appointmentStateValue() {
            return typeof this.appointmentState === 'number'
                ? this.$i18n.t(getAppointmentStateLabel(this.appointmentState))
                : '';
        },
        allowEditActions() {
            return isUserAllowed('UMAccountAppointmentWrite');
        },
        showMoreSection() {
            return (
                this.allowedActions.length &&
                this.appointmentState !== APPT_STATE.CANCELED &&
                this.appointmentState !== APPT_STATE.COMPLETED
            );
        },
    },
    methods: {
        toggleMoreDropdown() {
            if (!this.allowEditActions) {
                return;
            }
            this.isMoreOpened = !this.isMoreOpened;
        },
        closeMoreDropdown() {
            this.isMoreOpened = false;
        },
        emitEditAppointment() {
            this.$emit('editAppointment', this.appointment);
            this.closeMoreDropdown();
        },
        emitDeleteAppointment() {
            this.$emit('deleteAppointment', this.appointment.appointmentId);
            this.closeMoreDropdown();
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@/assets/scss/palette';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/z-indexes';

.card-container {
    position: relative;
    width: 20rem;
    min-height: 6.25rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.375rem 0 rgb(51 81 149 / 30%);
    background-color: $white;

    display: flex;
}

.more-section {
    position: absolute;
    top: 0;
    right: 0;

    .more-dropdown {
        position: absolute;
        z-index: $overlap-smth-z-index;
        width: 8rem;
        padding: 0.125rem 0;
        background-color: $white;
        border-radius: 0.25rem;
        box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 81, 149, 0.3);

        .dropdown-row {
            display: flex;
            align-items: center;
            padding: 0.375rem 0.5rem;
            cursor: pointer;

            span {
                padding-left: 0.5rem;
            }

            &:hover {
                background-color: $blue15;
            }
        }
    }
}

.date-section {
    width: 5.3125rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.date-number {
    font-size: 2rem;
    color: $blue;
    font-weight: $medium-font-weight;
}

.date-month {
    color: $gray-blue;
    margin-top: -0.25rem;
}

.main-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
    padding: 0.625rem 0;
}

.date-day-of-week {
    margin-bottom: -0.25rem;
}

.read-note-text {
    cursor: pointer;
    font-size: $text-xs;
    color: $blue;

    &:hover {
        text-decoration: underline;
    }
}

.note {
    font-size: $text-xs;
    width: 10.375rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.card-hovered-container {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.date-and-time-tooltip {
    color: $gray-blue;

    margin-bottom: $spacing-s;
}

.note-tooltip-wrapper {
    width: 5rem;
}

.note-tooltip {
    word-break: break-word;
    line-break: auto;
}
.appointment-state {
    font-weight: bold;
}
.appointment-state-gray {
    color: $gray30;
}
.appointment-state-orange {
    color: $orange;
}
.appointment-state-blue {
    color: $blue40;
}
.appointment-state-green {
    color: $green40;
}
.appointment-state-red {
    color: $red30;
}
.appointment-state-black {
    color: $black;
}

.overflow-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
</style>
