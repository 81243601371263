<template>
    <AbstractListPageWrapper
        :pageTitle="pageTitle"
        :isOverviewEnabled="isOverviewEnabled"
        :entitiesCount="filteredEntities.length"
        class="page-wrapper overflow-hidden"
        @searchQueryChanged="setSearchQuery"
    >
        <template slot="button">
            <ResponseModalButton
                :response="entitiesApiResponse"
                :title="pageTitle"
            />
        </template>

        <template slot="filterTable">
            <FilterTable
                :columns="tableColumnsData"
                :multiselectWidth="{ width: '15rem' }"
                @filterAdded="onFilterAdded"
            />
        </template>

        <template slot="headerButtons">
            <template v-if="isUserAllowed(writePermission)">
                <ImportEntitiesModalButton
                    :entityType="entityType"
                    class="mr-3"
                    @finishImport="$emit('fetchConfigs')"
                />
                <AppButton
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :iconType="ICON_TYPES.PLUS"
                    :label="$i18n.t('generic.addNew')"
                    data-test-id="createNewConfigBtn"
                    @click="navigateToCreateNewSinkPage"
                />
            </template>
        </template>

        <template slot="allFilters">
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                @removeFilter="removeFilter"
                @removeAllFilters="removeAllFilters"
            />
        </template>

        <template slot="table">
            <AppTable
                data-test-id="event-config-table"
                :entities="filteredEntities"
                :selectedEntityId="selectedEntityId"
                :columnsData="tableColumnsData"
                :isDataLoading="isDataLoading"
                :canSelectColumns="false"
                :enableRowStateControls="isUserAllowed(writePermission)"
                :canSort="true"
                :tableKey="entityType"
                :defaultSort="defaultSort"
                :isSearchEnabled="true"
                :innerSearchQuery="searchQueryForTable"
                :entityType="entityType"
                @selectEntity="id => onSelectEntity(id)"
                @edit="id => goToEditPage(id, false)"
                @delete="deleteConfig"
                @approve="onApprove"
                @pause="onPause"
                @clone="id => goToEditPage(id, true)"
                @details="id => $refs.DetailsJsonModal.display(entitiesById[id])"
            >
                <template #state="{ entity }">
                    <SinkConfigStatusIndicator :status="entity.state" />
                </template>

                <template #updateTime="{ entity }">
                    <span> {{ updateTimeFormatterFn(entity.updateTime) }}</span>
                </template>
            </AppTable>

            <DetailsJsonModal ref="DetailsJsonModal" />
        </template>

        <template slot="overview">
            <SinkConfigOverview
                :sinkConfig="selectedEntity"
                :entityType="entityType"
                :uiParams="uiParams"
                :conditionDefinitionsById="conditionDefinitionsById"
                @closeOverview="isOverviewEnabled = false"
            />
        </template>
    </AbstractListPageWrapper>
</template>
<script>
// components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppTable from '@/components/partials/AppTable.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import SinkConfigOverview from '@/__new__/features/sinkConfigs/SinkConfigOverview.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import ImportEntitiesModalButton from '@/components/partials/ImportEntitiesModalButton.vue';
import DetailsJsonModal from '@/components/partials/DetailsJsonModal.vue';
import SinkConfigStatusIndicator from '@/__new__/features/sinkConfigs/SinkConfigStatusIndicator.vue';

// helpers
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType from '@/common/filterTable';
import i18n from '@/i18n';
import RouteNames from '@/router/routeNames';
import Actions, { State } from '@/store/mutation-types';
import { mapActions, mapState } from 'vuex';
import { Modules } from '@/store/store';
import Button from '@/common/button/Button';
import {
    SINK_CONFIG_STATUS_SERVER_CODE_MAP,
    SINK_CONFIG_STATUS,
} from '@/__new__/services/dno/sinkConfigs/models/SinkConfigStatus';
import { entityTypeToEntityLabel } from '@/common/entities/entityHelper';
import { keyBy, capitalize } from 'lodash';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { stringTimeToDefaultFormat } from '@/common/formatting';

export default {
    name: 'SinkConfigDashboard',
    components: {
        DetailsJsonModal,
        AbstractListPageWrapper,
        AppButton,
        AppTable,
        FilterTable,
        SinkConfigOverview,
        SinkConfigStatusIndicator,
        TableFiltersTags,
        ResponseModalButton,
        ImportEntitiesModalButton,
    },
    mixins: [FilterTableMixin],
    props: {
        isDataLoading: {
            type: Boolean,
            default: false,
        },
        uiParams: {
            required: true,
            default: () => undefined,
            type: Object,
        },
        entityType: {
            required: true,
            type: String,
            default: ENTITY_TYPES.ORD_CONFIG,
        },
        pageTitle: {
            required: true,
            type: String,
            default: i18n.t('analytics.reportsConfig'),
        },
        entities: {
            required: true,
            type: Array,
            default: () => [],
        },
        entitiesApiResponse: {
            type: [Array, Object],
            default: null,
        },
        editRouteName: {
            type: String,
            required: true,
            default: RouteNames.ORD_CONFIG_EDITOR,
        },
        writePermission: {
            type: String,
            required: true,
        },
        updateTimeFormatterFn: {
            type: Function,
            default: stringTimeToDefaultFormat,
        },
        tableFilters: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            RouteNames,
            searchQueryForTable: '',
            selectedEntityId: null,
            isOverviewEnabled: false,
        };
    },

    computed: {
        ...mapState(Modules.triggers, { conditionDefinitionsById: State.TRIGGER_DEFINITIONS_BY_ID }),
        entitiesById() {
            return keyBy(this.entities, entity => entity.id);
        },
        defaultSort() {
            return {
                sortBy: entity => entity.updateTime,
                type: 'desc',
            };
        },
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.entities.map(e => e.state))),
                },
                {
                    name: this.$i18n.t('productCatalog.offers.table.updatedTime'),
                    key: 'updateTime',
                    field: 'updateTime',
                    filterType: tableColumnType.DATE,
                },
            ];
        },
        filteredEntities() {
            return this.filteredEntitiesMixin(this.entities);
        },
        selectedEntity() {
            return this.entities.find(ent => ent.id === this.selectedEntityId) || null;
        },
    },
    created() {
        if (this.$route.params?.id) {
            this.onSelectEntity(this.$route.params.id);
        }

        this.tableFilters.forEach(filter =>
            this.onFilterAdded({
                ...filter,
                column: this.tableColumnsData.find(({ field }) => field === filter.column),
            }),
        );
    },
    methods: {
        ...mapActions('sinkConfigs', [Actions.UPDATE_REDSHIFT_CONFIG_STATE, Actions.UPDATE_KAFKA_SINK_CONFIG_STATE]),
        ...mapActions(Modules.triggers, [Actions.LOAD_TRIGGER_DEFINITIONS]),
        capitalize,
        goToEditPage(entityId, isClone) {
            this.$router.push({
                name: this.editRouteName,
                params: {
                    entityType: this.entityType,
                    id: entityId,
                    clone: isClone,
                    companyId: this.$route.params.companyId,
                },
            });
        },
        onSelectEntity(id) {
            this.selectedEntityId = id;
            this.isOverviewEnabled = true;
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
        navigateToCreateNewSinkPage() {
            this.$router.push({
                name: this.editRouteName,
                params: { companyId: this.$route.params.companyId },
            });
        },
        onApprove({ id, data }) {
            this.$alert(this.$i18n.t('alerts.areYouSureApprove'), {
                type: ALERT_TYPES.warning,
                buttons: [
                    new Button({
                        label: this.$i18n.t('generic.approve'),
                        alertType: ALERT_TYPES.warning,
                        id: 1,
                        handler: () => {
                            this.$withProgressBar(
                                async () => {
                                    const payload = {
                                        id,
                                        version: data?.version,
                                        state: SINK_CONFIG_STATUS_SERVER_CODE_MAP[SINK_CONFIG_STATUS.APPROVED],
                                    };
                                    if (this.entityType === ENTITY_TYPES.REDSHIFT_CONFIG) {
                                        await this[Actions.UPDATE_REDSHIFT_CONFIG_STATE](payload);
                                    } else if (this.entityType === ENTITY_TYPES.KAFKA_SINK_CONFIG) {
                                        await this[Actions.UPDATE_KAFKA_SINK_CONFIG_STATE](payload);
                                    }
                                    this.$alert(
                                        this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                                            entityName: data.name,
                                            action: this.$i18n.t('generic.stateMap.approved'),
                                        }),
                                        {
                                            type: this.$ALERT_TYPES.success,
                                        },
                                    );
                                },
                                {
                                    errorHandler: () => {
                                        this.$alert(this.$i18n.t('entityStatesControl.couldNotApprove'), {
                                            type: this.$ALERT_TYPES.error,
                                        });
                                    },
                                },
                            );
                        },
                    }),
                ],
            });
        },
        onPause({ id, version }) {
            this.$alert(this.$i18n.t('alerts.areYouSurePause'), {
                type: ALERT_TYPES.warning,
                buttons: [
                    new Button({
                        label: this.$i18n.t('generic.pause'),
                        alertType: ALERT_TYPES.warning,
                        handler: () => {
                            this.$withProgressBar(
                                async () => {
                                    if (this.entityType === ENTITY_TYPES.REDSHIFT_CONFIG) {
                                        await this[Actions.UPDATE_REDSHIFT_CONFIG_STATE]({
                                            id,
                                            version,
                                            state: SINK_CONFIG_STATUS_SERVER_CODE_MAP[SINK_CONFIG_STATUS.PAUSED],
                                        });
                                    }
                                    this.$alert(
                                        this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                                            entityName: entityTypeToEntityLabel[this.entityType],
                                            action: this.$i18n.t('generic.stateMap.paused'),
                                        }),
                                        {
                                            type: this.$ALERT_TYPES.success,
                                        },
                                    );
                                },
                                {
                                    errorHandler: () => {
                                        this.$alert(this.$i18n.t('entityStatesControl.couldNotPause'), {
                                            type: this.$ALERT_TYPES.error,
                                        });
                                    },
                                },
                            );
                        },
                    }),
                ],
            });
        },
        deleteConfig(id) {
            const config = this.entities.find(entity => entity.id === id);
            this.$emit('deleteConfig', config);
        },
        isUserAllowed,
    },
};
</script>

<style lang="scss" scoped>
// Fix double scrollbars from AbstractListPageWrapper
.page-wrapper {
    height: 100vh !important;
}
</style>
