var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-3"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"lf-subtitle heading mb-0"},[_vm._v(" "+_vm._s(_vm.$t('qodNumberManagement.bulkUpdateHistory'))+" ")]),_c('AppButton',{staticClass:"position-relative",attrs:{"buttonType":_vm.BUTTON_TYPES.PRIMARY,"label":_vm.$t('qodNumberManagement.loadHistory'),"isLoading":_vm.isLoading,"data-test-id":"load-history-button"},on:{"click":_vm.onLoadHistory}})],1),(_vm.showHistory)?_c('TableFiltersRenderless',{staticClass:"mt-5",attrs:{"entities":_vm.entities},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var allFilters = ref.allFilters;
var filteredEntities = ref.filteredEntities;
var onFilterAdded = ref.onFilterAdded;
var removeFilter = ref.removeFilter;
var removeAllFilters = ref.removeAllFilters;
return [_c('div',[_c('div',{staticClass:"d-flex align-items-center justify-content-end position-relative mb-1"},[_c('SearchBox',{staticClass:"search-box",attrs:{"smallSearchIcon":true},model:{value:(_vm.tableSearchInput),callback:function ($$v) {_vm.tableSearchInput=$$v},expression:"tableSearchInput"}}),_c('FilterTable',{attrs:{"columns":_vm.tableColumns,"multiselectWidth":{ width: '12rem' }},on:{"filterAdded":onFilterAdded},scopedSlots:_vm._u([{key:"button",fn:function(slotProps){return [_c('IconButton',{attrs:{"label":_vm.$t('generic.filter'),"icon":_vm.ICON_TYPES.FILTER},on:{"iconClick":slotProps.clickHandler}})]}}],null,true)})],1),_c('TableFiltersTags',{staticClass:"mt-1 mb-3",attrs:{"filterTableMixin":{ allFilters: allFilters }},on:{"removeFilter":removeFilter,"removeAllFilters":removeAllFilters}}),_c('AppTable',{attrs:{"enableRowStateControls":false,"entities":filteredEntities,"isPaginationEnabled":true,"canSelectColumns":true,"isDefaultHoverEnabled":false,"newDesign":true,"columnsData":_vm.tableColumns,"isSearchEnabled":true,"innerSearchQuery":_vm.tableSearchInput,"isDataLoading":_vm.isLoading,"defaultSort":_vm.sortBy,"tableKey":"bulk-upload-history","data-test-id":"bulk-upload-history"},on:{"selectedEntityModel":_vm.onUploadHistorySelected},scopedSlots:_vm._u([{key:"fileUploadStatusStr",fn:function(ref){
var entity = ref.entity;
return [(entity.fileUploadStatusStr)?_c('EntityStatusIndicator',{attrs:{"status":entity.fileUploadStatusStr,"stateMapColor":_vm.FILE_UPLOAD_STATUS_TO_COLOR_MAP}}):_c('AppIcon',{staticClass:"ml-2 spinning",attrs:{"type":_vm.ICON_TYPES.LOADER,"color":_vm.ICON_COLORS.BLUE}})]}}],null,true)})],1)]}}],null,false,1956750331)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }