// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Actions, { Getters, Mutations, State } from '@/store/mutation-types';
import { createSinkConfigFromResponse } from '@/__new__/services/dno/sinkConfigs/models/SinkConfig';
import ApiInvokerSinkConfig from '@/__new__/services/dno/sinkConfigs/models/ApiInvokerSinkConfig';
import ORDSinkConfig, { type OrdReportJobDefinition } from '@/__new__/services/dno/sinkConfigs/models/ORDSinkConfig';
import SinkFormatter, { sinkFormattersFromJson } from '@/__new__/services/dno/sinkConfigs/models/SinkFormatter';
import SinkConfigSettings from '@/__new__/services/dno/sinkConfigs/models/SinkConfigSettings';
// todo: should be imported as a module
import {
    deleteApiInvokerConfig,
    deleteKafkaConfig,
    deleteRedshiftConfig,
    deleteReportConfig,
    fetchApiInvokerSinkUIParams,
    fetchKafkaConfigUIParams,
    fetchRedshiftConfigUIParams,
    fetchReportConfigUIParams,
    fetchRedshiftConfigRequiredFields,
    getAllApiInvokerConfigs,
    getAllKafkaConfigs,
    getAllRedshiftConfigs,
    getAllReportConfigs,
    getFormatters,
    updateKafkaConfigState,
    updateRedshiftConfigState,
    getAllRedshiftDrafts,
    getRedshiftFormatters,
    getAllKafkaDrafts,
    deleteKafkaDraft,
    deleteRedshiftDraft,
    getEventJsonPaths,
    getTypeCasters,
    getRedshiftTypeCasters,
    getEventCompositionConfigs,
    fetchEventCompositionUIParams,
    deleteEventCompositionConfig,
} from '@/__new__/services/dno/sinkConfigs/http/sinkConfigs';
import { Modules } from '@/store/store';
import { unionBy, isEmpty, mapValues } from 'lodash';
import { ActionContext, Module } from 'vuex';
import { AxiosResponse } from 'axios';

import SinkTypeCaster from '@/__new__/services/dno/sinkConfigs/models/SinkTypeCasters';
import { uuidV4 } from '@/common/utils';

type SinkJobDefinition = {
    id: string;
    name: string;
    description: string;
    cluster_name: string;
    sink_entity_properties: Record<string, any>;
    topic: string;
};

export type JobDefinitionItem<TJobDefinition> = {
    created_at: number;
    job_definition: TJobDefinition;
    last_updated: number;
    revision_number: number;
    state: string;
};

type GetApiInvokerConfigsResponseBody<TJobDefinition> = {
    data: {
        items: JobDefinitionItem<TJobDefinition>[];
        page_number: number;
        size: number;
        total_elements: number;
        total_pages: number;
    };
};

/* todo replace "any" type (CEP-4634) */
interface ModuleState {
    [State.SINK_FORMATTERS]: SinkFormatter[];
    [State.REDSHIFT_FORMATTERS]: SinkFormatter[];
    [State.SINK_TYPE_CASTERS]: SinkTypeCaster;
    [State.REDSHIFT_SINK_TYPE_CASTERS]: SinkTypeCaster;
    [State.JSON_PATH_OPTIONS]: any;
    // report
    [State.ORD_CONFIGS]: ORDSinkConfig[];
    [State.ORD_CONFIGS_API_RESPONSE]: AxiosResponse | null;
    [State.ORD_CONFIGS_SETTINGS]: any;
    // redshift
    [State.REDSHIFT_CONFIGS]: any[];
    [State.REDSHIFT_CONFIGS_API_RESPONSE]: AxiosResponse | null;
    [State.REDSHIFT_CONFIGS_SETTINGS]: any;
    [State.REDSHIFT_REQUIRED_PROPERTIES]: any[];
    // kafka
    [State.KAFKA_SINK_CONFIGS]: any[];
    [State.KAFKA_SINK_CONFIGS_API_RESPONSE]: AxiosResponse | null;
    [State.KAFKA_SINK_CONFIGS_SETTINGS]: any;
    // api invoker
    [State.API_INVOKER_SINK_CONFIGS]: any[];
    [State.API_INVOKER_SINK_CONFIGS_API_RESPONSE]: AxiosResponse | null;
    [State.API_INVOKER_SINK_CONFIGS_SETTINGS]: any;

    [State.EVENT_COMPOSITION_CONFIGS]: any[];
    [State.EVENT_COMPOSITION_CONFIGS_API_RESPONSE]: AxiosResponse | null;
    [State.EVENT_COMPOSITION_CONFIGS_SETTINGS]: any;
}

const store: Module<ModuleState, unknown> = {
    namespaced: true,
    state: {
        [State.SINK_FORMATTERS]: [],
        [State.SINK_TYPE_CASTERS]: [],
        [State.JSON_PATH_OPTIONS]: {},
        // report
        [State.ORD_CONFIGS]: [],
        [State.ORD_CONFIGS_API_RESPONSE]: null,
        [State.ORD_CONFIGS_SETTINGS]: {},
        // redshift
        [State.REDSHIFT_CONFIGS]: [],
        [State.REDSHIFT_SINK_TYPE_CASTERS]: [],
        [State.REDSHIFT_CONFIGS_API_RESPONSE]: null,
        [State.REDSHIFT_CONFIGS_SETTINGS]: {},
        [State.REDSHIFT_REQUIRED_PROPERTIES]: [],
        // kafka
        [State.KAFKA_SINK_CONFIGS]: [],
        [State.KAFKA_SINK_CONFIGS_API_RESPONSE]: null,
        [State.KAFKA_SINK_CONFIGS_SETTINGS]: {},
        // api invoker
        [State.API_INVOKER_SINK_CONFIGS]: [],
        [State.API_INVOKER_SINK_CONFIGS_API_RESPONSE]: null,
        [State.API_INVOKER_SINK_CONFIGS_SETTINGS]: {},
        // event composition
        [State.EVENT_COMPOSITION_CONFIGS]: [],
        [State.EVENT_COMPOSITION_CONFIGS_API_RESPONSE]: null,
        [State.EVENT_COMPOSITION_CONFIGS_SETTINGS]: {},
    },
    mutations: {
        [Mutations.SET_REDSHIFT_REQUIRED_PROPERTIES]: (_state, fields) => {
            _state[State.REDSHIFT_REQUIRED_PROPERTIES] = fields;
        },
        [Mutations.SET_REDSHIFT_SINK_TYPE_CASTERS]: (_state, typeCasters) => {
            _state[State.REDSHIFT_SINK_TYPE_CASTERS] = typeCasters;
        },
        [Mutations.SET_SINK_TYPE_CASTERS]: (_state, typeCasters) => {
            _state[State.SINK_TYPE_CASTERS] = typeCasters;
        },
        [Mutations.SET_JSON_PATH_OPTIONS]: (_state, { eventType, options }) => {
            _state[State.JSON_PATH_OPTIONS][eventType] = options;
        },
        [Mutations.SET_SINK_FORMATTERS]: (_state, formatters) => {
            _state[State.SINK_FORMATTERS] = formatters;
        },
        // report
        [Mutations.SET_ORD_CONFIGS]: (_state, types) => {
            _state[State.ORD_CONFIGS] = types;
        },
        [Mutations.SET_ORD_CONFIGS_API_RESPONSE]: (_state, response: AxiosResponse) => {
            _state[State.ORD_CONFIGS_API_RESPONSE] = response;
        },
        [Mutations.SET_ORD_CONFIGS_SETTINGS]: (_state, settings) => {
            _state[State.ORD_CONFIGS_SETTINGS] = settings;
        },
        // redshift
        [Mutations.SET_REDSHIFT_FORMATTERS]: (_state, formatters) => {
            _state[State.REDSHIFT_FORMATTERS] = formatters;
        },
        [Mutations.SET_REDSHIFT_CONFIGS]: (_state, types) => {
            _state[State.REDSHIFT_CONFIGS] = types;
        },
        [Mutations.SET_REDSHIFT_CONFIGS_API_RESPONSE]: (_state, response: AxiosResponse) => {
            _state[State.REDSHIFT_CONFIGS_API_RESPONSE] = response;
        },
        [Mutations.SET_REDSHIFT_CONFIGS_SETTINGS]: (_state, settings) => {
            _state[State.REDSHIFT_CONFIGS_SETTINGS] = settings;
        },
        // kafka
        [Mutations.SET_KAFKA_SINK_CONFIGS]: (_state, types) => {
            _state[State.KAFKA_SINK_CONFIGS] = types;
        },
        [Mutations.SET_KAFKA_SINK_CONFIGS_API_RESPONSE]: (_state, response: AxiosResponse) => {
            _state[State.KAFKA_SINK_CONFIGS_API_RESPONSE] = response;
        },
        [Mutations.SET_KAFKA_SINK_CONFIGS_SETTINGS]: (_state, settings) => {
            _state[State.KAFKA_SINK_CONFIGS_SETTINGS] = settings;
        },
        // api invoker
        [Mutations.SET_API_INVOKER_SINK_CONFIGS]: (_state, types) => {
            _state[State.API_INVOKER_SINK_CONFIGS] = types;
        },
        [Mutations.SET_API_INVOKER_SINK_CONFIGS_API_RESPONSE]: (_state, response: AxiosResponse) => {
            _state[State.API_INVOKER_SINK_CONFIGS_API_RESPONSE] = response;
        },
        [Mutations.SET_API_INVOKER_SINK_CONFIGS_SETTINGS]: (_state, settings) => {
            _state[State.API_INVOKER_SINK_CONFIGS_SETTINGS] = settings;
        },
        // event composition

        [Mutations.SET_EVENT_COMPOSITION_CONFIGS]: (_state, types) => {
            _state[State.EVENT_COMPOSITION_CONFIGS] = types;
        },
        [Mutations.SET_EVENT_COMPOSITION_CONFIGS_API_RESPONSE]: (_state, response: AxiosResponse) => {
            _state[State.EVENT_COMPOSITION_CONFIGS_API_RESPONSE] = response;
        },
        [Mutations.SET_EVENT_COMPOSITION_CONFIGS_SETTINGS]: (_state, settings) => {
            _state[State.EVENT_COMPOSITION_CONFIGS_SETTINGS] = settings;
        },
    },
    actions: {
        async [Actions.LOAD_SINK_FORMATTERS]({ state, commit }: ActionContext<ModuleState, any>) {
            if (!isEmpty(state[State.SINK_FORMATTERS])) return;
            const response = await getFormatters();

            commit(Mutations.SET_SINK_FORMATTERS, sinkFormattersFromJson(response));
        },

        async [Actions.LOAD_SINK_TYPE_CASTERS]({ state, commit }: ActionContext<ModuleState, any>) {
            if (!isEmpty(state[State.SINK_TYPE_CASTERS])) return;
            const response = await getTypeCasters();

            commit(Mutations.SET_SINK_TYPE_CASTERS, SinkTypeCaster.fromJson(response));
        },

        // report
        async [Actions.LOAD_ORD_CONFIGS_SETTINGS]({ state, commit }: ActionContext<ModuleState, any>) {
            if (!isEmpty(state[State.ORD_CONFIGS_SETTINGS])) return;
            const response = await fetchReportConfigUIParams();
            const configSettings = SinkConfigSettings.mapFromResponse(response);
            commit(Mutations.SET_ORD_CONFIGS_SETTINGS, configSettings);
        },
        async [Actions.LOAD_ORD_CONFIGS]({ commit }: ActionContext<ModuleState, any>) {
            try {
                const response: AxiosResponse<GetApiInvokerConfigsResponseBody<OrdReportJobDefinition>> =
                    await getAllReportConfigs();
                const entities = response.data.data?.items?.map(entity => ORDSinkConfig.mapFromReportsResponse(entity));

                commit(Mutations.SET_ORD_CONFIGS_API_RESPONSE, response);
                commit(Mutations.SET_ORD_CONFIGS, entities);
            } catch (error: any) {
                commit(Mutations.SET_ORD_CONFIGS_API_RESPONSE, error.response);
                throw error;
            }
        },

        async [Actions.DELETE_ORD_CONFIG]({ dispatch }: ActionContext<ModuleState, any>, { id, version }) {
            await deleteReportConfig({ id, version });
            await dispatch(Actions.LOAD_ORD_CONFIGS);
        },

        // redshift
        async [Actions.LOAD_REDSHIFT_FORMATTERS]({ state, commit }) {
            if (!isEmpty(state[State.REDSHIFT_FORMATTERS])) return;
            const response = await getRedshiftFormatters();
            commit(Mutations.SET_REDSHIFT_FORMATTERS, sinkFormattersFromJson(response));
        },
        async [Actions.LOAD_REDSHIFT_SINK_TYPE_CASTERS]({ state, commit }: ActionContext<ModuleState, any>) {
            if (!isEmpty(state[State.REDSHIFT_SINK_TYPE_CASTERS])) return;
            const response = await getRedshiftTypeCasters();

            commit(Mutations.SET_REDSHIFT_SINK_TYPE_CASTERS, SinkTypeCaster.fromJson(response));
        },

        async [Actions.LOAD_REDSHIFT_REQUIRED_PROPERTIES]({ state, commit }) {
            if (state[State.REDSHIFT_REQUIRED_PROPERTIES].length) return;
            const response = await fetchRedshiftConfigRequiredFields();
            const requiredProperties = response.data.required_fields;
            commit(Mutations.SET_REDSHIFT_REQUIRED_PROPERTIES, requiredProperties);
        },
        async [Actions.LOAD_REDSHIFT_CONFIGS_SETTINGS]({ state, commit }) {
            if (!isEmpty(state[State.REDSHIFT_CONFIGS_SETTINGS])) return;
            const response = await fetchRedshiftConfigUIParams();
            const configSettings = SinkConfigSettings.mapFromResponse(response);
            commit(Mutations.SET_REDSHIFT_CONFIGS_SETTINGS, configSettings);
        },
        [Actions.LOAD_REDSHIFT_CONFIGS]({ rootState, rootGetters, commit }: ActionContext<ModuleState, any>) {
            const events = rootGetters[`${[Modules.events]}/${[Getters.GET_MAPPED_EVENTS]}`];
            const formatters = rootState[Modules.sinkConfigs][State.REDSHIFT_FORMATTERS];
            const uiSettings = rootState[Modules.sinkConfigs][State.REDSHIFT_CONFIGS_SETTINGS];
            const segments = rootGetters[`${Modules.segments}/${Getters.GROUPED_SEGMENTS_BY_ID_TYPE}`]
                .map((group: any) => group.groupValues)
                .flat();

            return Promise.all([getAllRedshiftConfigs(), getAllRedshiftDrafts()])
                .then(response => {
                    const unitedEntities = unionBy(
                        response[0].data.entities,
                        response[1].data.entities,
                        ({ id }) => id,
                    );
                    commit(Mutations.SET_REDSHIFT_CONFIGS_API_RESPONSE, response[0]);

                    const entities = createSinkConfigFromResponse(
                        unitedEntities,
                        events,
                        formatters,
                        uiSettings,
                        segments,
                    );
                    commit(Mutations.SET_REDSHIFT_CONFIGS, entities);
                })
                .catch(error => {
                    commit(Mutations.SET_REDSHIFT_CONFIGS_API_RESPONSE, error.response);
                    throw error;
                });
        },

        async [Actions.DELETE_REDSHIFT_CONFIG]({ dispatch }, { id, version, isInDraftState }) {
            if (isInDraftState) {
                await deleteRedshiftDraft({ id, version });
            } else {
                await deleteRedshiftConfig({ id, version });
            }
            await dispatch(Actions.LOAD_REDSHIFT_CONFIGS);
        },
        async [Actions.UPDATE_REDSHIFT_CONFIG_STATE]({ dispatch }, { id, version, state }) {
            await updateRedshiftConfigState({ id, version, state });
            dispatch(Actions.LOAD_REDSHIFT_CONFIGS);
        },
        // kafka
        async [Actions.LOAD_KAFKA_SINK_CONFIGS_SETTINGS]({ state, commit }) {
            if (!isEmpty(state[State.KAFKA_SINK_CONFIGS_SETTINGS])) return;

            const response = await fetchKafkaConfigUIParams();
            const configSettings = SinkConfigSettings.mapFromResponse(response);

            commit(Mutations.SET_KAFKA_SINK_CONFIGS_SETTINGS, configSettings);
        },
        [Actions.LOAD_KAFKA_SINK_CONFIGS]({ rootState, rootGetters, commit }: ActionContext<ModuleState, any>) {
            const events = rootGetters[`${[Modules.events]}/${[Getters.GET_MAPPED_EVENTS]}`];
            const formatters = rootState[Modules.sinkConfigs][State.SINK_FORMATTERS];
            const uiSettings = rootState[Modules.sinkConfigs][State.KAFKA_SINK_CONFIGS_SETTINGS];
            const segments = rootGetters[`${Modules.segments}/${Getters.GROUPED_SEGMENTS_BY_ID_TYPE}`]
                .map((group: any) => group.groupValues)
                .flat();

            return Promise.all([getAllKafkaConfigs(), getAllKafkaDrafts()])
                .then(response => {
                    const unitedEntities = unionBy(
                        response[0].data.entities,
                        response[1].data.entities,
                        ({ id }) => id,
                    );
                    commit(Mutations.SET_KAFKA_SINK_CONFIGS_API_RESPONSE, response[0]);

                    const entities = createSinkConfigFromResponse(
                        unitedEntities,
                        events,
                        formatters,
                        uiSettings,
                        segments,
                    );
                    commit(Mutations.SET_KAFKA_SINK_CONFIGS, entities);
                })
                .catch(error => {
                    commit(Mutations.SET_KAFKA_SINK_CONFIGS_API_RESPONSE, error.response);
                    throw error;
                });
        },

        async [Actions.DELETE_KAFKA_SINK_CONFIG]({ dispatch }, { id, version, isInDraftState }) {
            if (isInDraftState) {
                await deleteKafkaDraft({ id, version });
            } else {
                await deleteKafkaConfig({ id, version });
            }
            await dispatch(Actions.LOAD_KAFKA_SINK_CONFIGS);
        },
        async [Actions.UPDATE_KAFKA_SINK_CONFIG_STATE]({ dispatch }, { id, version, state }) {
            await updateKafkaConfigState({ id, version, state });
            dispatch(Actions.LOAD_KAFKA_SINK_CONFIGS);
        },
        // api invoker
        async [Actions.DELETE_API_INVOKER_SINK_CONFIG]({ dispatch }, { id }) {
            await deleteApiInvokerConfig({ id });
            await dispatch(Actions.LOAD_API_INVOKER_SINK_CONFIGS);
        },
        async [Actions.LOAD_API_INVOKER_SINK_CONFIGS_SETTINGS]({ state, commit }) {
            if (!isEmpty(state[State.API_INVOKER_SINK_CONFIGS_SETTINGS])) return;

            const response = await fetchApiInvokerSinkUIParams();
            const configSettings = SinkConfigSettings.mapFromResponse(response);

            commit(Mutations.SET_API_INVOKER_SINK_CONFIGS_SETTINGS, configSettings);
        },
        async [Actions.LOAD_API_INVOKER_SINK_CONFIGS]({ commit }: ActionContext<ModuleState, any>) {
            let response: AxiosResponse<GetApiInvokerConfigsResponseBody<SinkJobDefinition>>;
            try {
                response = await getAllApiInvokerConfigs();
                // todo discuss possible enum with platform team as api invoker does not support EntityLib states yet
                const entities = response.data.data.items.map(entity =>
                    ApiInvokerSinkConfig.mapFromApiConfigResponse(entity),
                );

                commit(Mutations.SET_API_INVOKER_SINK_CONFIGS, entities);
            } catch (error: any) {
                response = error.response;
                throw error;
            } finally {
                commit(Mutations.SET_API_INVOKER_SINK_CONFIGS_API_RESPONSE, response);
            }
        },
        [Actions.UPDATE_API_INVOKER_SINK_CONFIG_STATE]() {
            // not supported yet
        },
        // event composition
        async [Actions.LOAD_EVENT_COMPOSITION_CONFIGS]({ commit }: ActionContext<ModuleState, any>) {
            let response: null;
            try {
                response = await getEventCompositionConfigs();
                const entities = [];

                commit(Mutations.SET_EVENT_COMPOSITION_CONFIGS, entities);
            } catch (error: any) {
                response = error.response;
                throw error;
            } finally {
                commit(Mutations.SET_EVENT_COMPOSITION_CONFIGS_API_RESPONSE, response);
            }
        },
        async [Actions.DELETE_EVENT_COMPOSITION_CONFIG]({ dispatch }, { id }) {
            await deleteEventCompositionConfig({ id });
            await dispatch(Actions.LOAD_EVENT_COMPOSITION_CONFIGS);
        },
        async [Actions.LOAD_EVENT_COMPOSITION_CONFIGS_SETTINGS]({ state, commit }) {
            if (!isEmpty(state[State.EVENT_COMPOSITION_CONFIGS_SETTINGS])) return;
            const response = await fetchEventCompositionUIParams();
            const configSettings = SinkConfigSettings.mapFromResponse(response);

            commit(Mutations.SET_EVENT_COMPOSITION_CONFIGS_SETTINGS, configSettings);
        },

        async [Actions.LOAD_JSON_PATH_OPTIONS]({ rootGetters, commit }: ActionContext<ModuleState, any>, eventType) {
            const optionsStore = rootGetters[`${[Modules.sinkConfigs]}/${[Getters.GET_JSON_PATH_OPTIONS]}`];
            if (!optionsStore[eventType]) {
                const response = await getEventJsonPaths(eventType);

                const payload = {
                    // just add ids in order to track, since names could be not unique
                    eventType: response.data.eventType,
                    options: mapValues(response.data.jsonPathsByPropertyName, (properties, propName) => {
                        return [
                            ...properties.map(property => {
                                return {
                                    ...property,
                                    id: uuidV4(),
                                };
                            }),
                        ];
                    }),
                };
                commit(Mutations.SET_JSON_PATH_OPTIONS, payload);
            }
        },
    },
    getters: {
        [Getters.GET_SINK_FORMATTERS]: state => {
            // without this hack state is not reactive
            const stateCopy = { ...state };
            return stateCopy[State.SINK_FORMATTERS] || [];
        },
        [Getters.GET_REDSHIFT_FORMATTERS]: state => {
            // without this hack state is not reactive
            const stateCopy = { ...state };
            return stateCopy[State.REDSHIFT_FORMATTERS] || [];
        },
        [Getters.GET_SINK_TYPE_CASTERS]: state => state[State.SINK_TYPE_CASTERS] || [],
        [Getters.GET_REDSHIFT_SINK_TYPE_CASTERS]: state => state[State.REDSHIFT_SINK_TYPE_CASTERS] || [],
        [Getters.GET_JSON_PATH_OPTIONS]: state => state[State.JSON_PATH_OPTIONS] || {},
        // report
        [Getters.GET_ORD_CONFIGS]: state => state[State.ORD_CONFIGS] || [],
        [Getters.GET_ORD_CONFIGS_API_RESPONSE]: state => state[State.ORD_CONFIGS_API_RESPONSE],
        [Getters.GET_ORD_CONFIGS_SETTINGS]: state => state[State.ORD_CONFIGS_SETTINGS] || {},
        [Getters.GET_ORD_CONFIG_BY_ID]: state => (id: string) =>
            state[State.ORD_CONFIGS].find(config => config.id === id),
        // redshift
        [Getters.GET_REDSHIFT_CONFIGS]: state => state[State.REDSHIFT_CONFIGS] || [],
        [Getters.GET_REDSHIFT_CONFIGS_API_RESPONSE]: state => state[State.REDSHIFT_CONFIGS_API_RESPONSE],
        [Getters.GET_REDSHIFT_CONFIGS_SETTINGS]: state => state[State.REDSHIFT_CONFIGS_SETTINGS] || {},
        [Getters.GET_REDSHIFT_CONFIG_BY_ID]: state => (id: string) =>
            state[State.REDSHIFT_CONFIGS].find(config => config.id === id),
        [Getters.GET_REDSHIFT_REQUIRED_PROPERTIES]: state => state[State.REDSHIFT_REQUIRED_PROPERTIES] || [],
        // kafka
        [Getters.GET_KAFKA_SINK_CONFIGS]: state => state[State.KAFKA_SINK_CONFIGS] || [],
        [Getters.GET_KAFKA_SINK_CONFIGS_API_RESPONSE]: state => state[State.KAFKA_SINK_CONFIGS_API_RESPONSE],
        [Getters.GET_KAFKA_SINK_CONFIGS_SETTINGS]: state => state[State.KAFKA_SINK_CONFIGS_SETTINGS] || {},
        [Getters.GET_KAFKA_SINK_CONFIG_BY_ID]: state => (id: string) =>
            state[State.KAFKA_SINK_CONFIGS].find(config => config.id === id),
        // api invoker
        [Getters.GET_API_INVOKER_SINK_CONFIGS]: state => state[State.API_INVOKER_SINK_CONFIGS] || [],
        [Getters.GET_API_INVOKER_SINK_CONFIGS_API_RESPONSE]: state =>
            state[State.API_INVOKER_SINK_CONFIGS_API_RESPONSE],
        [Getters.GET_API_INVOKER_SINK_CONFIGS_SETTINGS]: state => state[State.API_INVOKER_SINK_CONFIGS_SETTINGS] || {},
        [Getters.GET_API_INVOKER_SINK_CONFIG_BY_ID]: state => (id: string) =>
            state[State.API_INVOKER_SINK_CONFIGS].find(config => config.id === id),
        // event composition
        [Getters.GET_EVENT_COMPOSITION_CONFIGS]: state => state[State.EVENT_COMPOSITION_CONFIGS] || [],
        [Getters.GET_EVENT_COMPOSITION_CONFIGS_API_RESPONSE]: state =>
            state[State.EVENT_COMPOSITION_CONFIGS_API_RESPONSE],
        [Getters.GET_EVENT_COMPOSITION_CONFIGS_SETTINGS]: state =>
            state[State.EVENT_COMPOSITION_CONFIGS_SETTINGS] || {},
        [Getters.GET_EVENT_COMPOSITION_CONFIG_BY_ID]: state => (id: string) =>
            state[State.EVENT_COMPOSITION_CONFIGS].find(config => config.id === id),
    },
};
export default store;
