







// components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';

// helpers
import moment from 'moment';
import * as Sentry from '@sentry/vue';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import HTTPCustomerCare from '@/__new__/services/dno/user/http/customer-care';
import { SERVICE_TYPE } from '@/__new__/features/customerCareSuite/common/SensitiveTransactionHistoryHelper';

const IDENTIFIER_TYPE_TO_TEXT = {
    3: 'USER ID',
};

const STATE_TO_TEXT = {
    1: 'generic.success',
    2: 'generic.stateMap.failed',
    3: 'generic.blocked',
};

const SERVICE_TYPE_TO_TEXT = {
    [SERVICE_TYPE.SIGN_IN]: 'generic.login',
    [SERVICE_TYPE.ALL]: 'generic.registration',
    [SERVICE_TYPE.CHALLENGE]: 'authorizationPage.otpChallengeVerification',
};

class AuthenticationHistoryRecord {
    dateAndTime: string;

    channel: string;

    status: string;

    serviceType: string;

    constructor(dateAndTime: string, channel: string, status: string, serviceType: string) {
        this.dateAndTime = dateAndTime;
        this.channel = channel;
        this.status = status;
        this.serviceType = serviceType;
    }
}

export default {
    name: 'AuthenticationHistoryTile',
    components: {
        AbstractTableTile,
    },
    data() {
        return {
            entities: [],
        };
    },
    computed: {
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.userAuthentication.dateAndTime'),
                    field: 'dateAndTime',
                    key: 'dateAndTime',
                },
                {
                    name: this.$i18n.t('customerCare.userAuthentication.channel'),
                    key: 'channel',
                    field: 'channel',
                },
                {
                    name: this.$i18n.t('customerCare.userAuthentication.status'),
                    key: 'status',
                    field: 'status',
                },
                {
                    name: this.$i18n.t('customerCareSuite.serviceTransactionsTile.serviceType'),
                    key: 'serviceType',
                    field: 'serviceType',
                },
            ];
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            try {
                this.$Progress.start();
                const { data } = await HTTPCustomerCare.fetchServiceBlockerHistory(
                    this.$route.params.id,
                    USER_MANAGER_HIERARCHY.USER,
                );
                this.entities = [];
                if (data?.otp_attempts) {
                    for (const elem of data.otp_attempts.filter(el =>
                        [SERVICE_TYPE.SIGN_IN, SERVICE_TYPE.ALL, SERVICE_TYPE.CHALLENGE].includes(el?.service_type),
                    )) {
                        const entity = new AuthenticationHistoryRecord(
                            moment(elem.event_time).format('YYYY-MM-DD HH:mm'),
                            IDENTIFIER_TYPE_TO_TEXT?.[elem.identifier_type] || '',
                            this.$i18n.t(STATE_TO_TEXT?.[elem.event_type] || ''),
                            this.$i18n.t(SERVICE_TYPE_TO_TEXT?.[elem.service_type] || ''),
                        );
                        this.entities.push(entity);
                    }
                }
                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
            } finally {
                this.$emit('isDataLoadingUpd', false);
            }
        },
    },
};
