
import Vue from 'vue';

// COMPONENTS
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTable from '@/components/partials/AppTable.vue';
import NumberStatusIndicator from '@/__new__/features/resources/NumberStatusIndicator.vue';

// Http
import { queryNumber } from '@/__new__/services/dno/number/http/numberManagement';

// helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { formatOwnership } from '@/__new__/services/dno/number/models/numberManagementDno';

export default Vue.extend({
    name: 'NumberSearch',
    components: {
        AppButton,
        AppMultiselectV3,
        AppInputV3,
        AppTable,
        NumberStatusIndicator,
    },
    props: {
        utilityTypes: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            utilities: {
                type: null,
                value: '',
            },
            searchResult: [] as any[],
            tableColumnsData: [
                {
                    name: this.$t('customerCare.subscriberId'),
                    key: 'subscriberId',
                    field: 'subscriberId',
                },
                {
                    name: this.$t('operations.numberManagement.ownership'),
                    key: 'ownership',
                    field: 'ownership',
                    formatter: formatOwnership,
                },
                {
                    name: this.$t('generic.state'),
                    key: 'state',
                    field: 'state',
                },
                {
                    name: this.$t('customerCare.iccid'),
                    key: 'iccid',
                    field: 'iccid',
                },
            ],
        };
    },
    computed: {
        title(): string {
            return `${this.$tc('operator.number')} ${this.$t('operator.utilities')}`;
        },
        utilitiesSubtext(): string {
            return this.$t('operator.getInfoForEntity', { entityType: this.$tc('operator.number') });
        },
        utilityValuePlaceholder(): string {
            const entityType = this.utilities?.type?.i18n ? this.$t(this.utilities.type.i18n) : '';
            return this.$t('operator.addEntity', { entityType });
        },
    },
    methods: {
        searchUtility() {
            return this.$withProgressBar(
                async () => {
                    const result = await queryNumber(this.utilities.value);
                    const numberData = result?.data?.result;

                    if (numberData) {
                        this.searchResult = [
                            {
                                subscriberId: numberData.mapping.subscriber_id,
                                ownership: numberData.ownership,
                                state: numberData.state,
                                iccid: numberData.mapping.iccid,
                            },
                        ];
                    }
                },
                {
                    errorHandler: () =>
                        this.$alert(
                            this.$t('alertMessage.errorDoingSmthTryAgain', {
                                action: this.$t('generic.fetching'),
                                entityName: this.$t('generic.number'),
                            }),
                        ),
                },
            );
        },
    },
});
