// http
import { getRegisteredEntities, getEntityDetailsByName } from '@/__new__/services/dno/sim/http/inventory';

// helpers
import {
    FILE_UPLOAD_STATUS,
    fileUploadStatusToStr,
    getFileUploadStatus,
    type UploadedFileDetails,
} from '@/common/fileUploadHelper';
import {
    type RegisteredEntityItem,
    type EntityDetailsByName,
} from '@/__new__/services/dno/ossdevedge/models/QodMsisdnDno';
import { last } from 'lodash';

export const getUploadHistory = async (): Promise<UploadedFileDetails[]> => {
    // eslint-disable-next-line prettier/prettier
    const { data: { data: { items }}} = await getRegisteredEntities(1, 500);
    return (items as RegisteredEntityItem[]).map(entity => uploadFileDetailsFromEntity(entity));
};
/**
 * Transforms backend `RegisteredEntityItem` into Portal friendly `UploadedFileDetails`
 */

const uploadFileDetailsFromEntity = (entity: RegisteredEntityItem): UploadedFileDetails => {
    /**
     * Build details object
     * Explicitly leaving fields undefined since:
     *  - they will be updated asynchronously
     *  - they need to be reactive
     */
    const fileDetails: UploadedFileDetails = {
        entityName: entity.entity_name,
        createdAt: entity.created_at,
        lastUpdated: entity.last_updated,
        fileName: last(entity.entity_name.split(':')),
        bulkUploadId: undefined,
        createdBy: undefined,
        recordCount: undefined,
    };

    /**
     * Determine file upload status
     * Attempt to load this async as we need an additional request
     */
    getEntityDetailsByName(fileDetails.entityName)
        .then((details: { data: EntityDetailsByName }) => {
            const recordCount = details.data.data.expected_count;
            const eventCategoryCounters = details.data.data.event_category_counters;
            fileDetails.recordCount = recordCount;
            fileDetails.eventCategoryCounters = eventCategoryCounters;
            fileDetails.fileUploadStatus = getFileUploadStatus(recordCount, eventCategoryCounters);
            fileDetails.fileUploadStatusStr = fileUploadStatusToStr(fileDetails.fileUploadStatus);
        })
        .catch(() => {
            fileDetails.recordCount = 0;
            fileDetails.fileUploadStatus = FILE_UPLOAD_STATUS.UNKNOWN;
            fileDetails.fileUploadStatusStr = fileUploadStatusToStr(fileDetails.fileUploadStatus);
        });

    return fileDetails;
};
