







































































































































































































































































































import Vue from 'vue';

// Vuex
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

// COMPONENTS
import AppCustomDialog from '@/components/partials/AppCustomDialog.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppLink from '@/components/partials/AppLink.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';

// HTTP
import userManagementAJAX from '@/__new__/services/dno/user/http/user-management';
import osstmofiberHTTP from '@/__new__/services/dno/osstmofiber/http/osstmofiber';
import {
    USER_MANAGER_HIERARCHY,
    USER_FIELDS,
    DOCUMENT_TYPE_OPTIONS,
    EKYC_STATUS,
    EKYC_STATUS_TO_I18N_LABEL,
    EKYC_DOCUMENT_TYPE_OPTIONS,
    EKYC_DOCUMENT_SUBTYPE_OPTIONS,
    type EkycStatusOption,
    SUBSCRIBER_STATE,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import {
    FLAG_MAP,
    type Flags,
    FLAG_TYPE,
    FLAG_IS_LOCALLY_TIED,
    FLAG_IS_LOCALLY_TIED_LABEL_MAP,
    type FlagIsLocallyTiedOption,
} from '@/__new__/services/dno/user/models/Flag';

// HELPERS
import * as Sentry from '@sentry/vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import permissionsService, {
    getOperatorConfigValue,
    getEkycConfig,
    isUserAllowed,
} from '@/services/permissions/permissions.service';
import { isEmpty, cloneDeep, isEqual, pick } from 'lodash';
// https://www.npmjs.com/package/iso-3166-1
import iso from 'iso-3166-1';
import User, { type NotificationIdentifier } from '@/__new__/services/dno/user/models/User';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';
import luaErrors from '@/common/luaErrors';
import type { HierarchyItem } from '@/__new__/features/customerCareSuite/common/umHierarhyMapperHelper';

const isoCountryList = iso.all();

export default Vue.extend({
    name: 'UserProfileEditor',
    components: {
        AppCustomDialog,
        AppInputV3,
        AppMultiselectV3,
        AppLink,
        AppButton,
        DateTimePicker,
        AppToggle,
    },
    mixins: [supportButtonMixin],
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        // instance of User class
        entity: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isEmpty,
            isEqual,
            BUTTON_TYPES,
            ALERT_TYPES: ALERT_TYPES as any,
            FLAG_MAP: FLAG_MAP as any,
            USER_FIELDS: USER_FIELDS as any,
            FLAG_IS_LOCALLY_TIED: FLAG_IS_LOCALLY_TIED as any,
            user: new User(this.entity) as User,
            ekycStatus: {} as EkycStatusOption,
            fieldsEnabled: null as { [key: string]: boolean } | null,
            // if verify email has been sent
            isVerifyEmailSent: false as boolean,
            isEkycEnabled: (permissionsService.userManagementEKYCEnabled() && getEkycConfig()) as boolean,
            flagsToRender: {} as Flags,
            flagsForUpdate: {} as Flags<number>,
            isLocallyTied: {} as FlagIsLocallyTiedOption,
            userHierarchy: null as HierarchyItem | null,
            isPasswordResetEnabled: getOperatorConfigValue('isPasswordResetEnabled') as boolean,
            isForgetMeFlowEnabled: isUserAllowed('UMUserEditUserTriggerForgetMeFlow') as boolean,
            isNotificationIdClearEnabled:
                permissionsService.userManagementEditVerificationIdsClearEnabled() &&
                isUserAllowed('UMUserEditUserVerificationIdClear'),
            isNotificationIdSetEnabled:
                permissionsService.userManagementEditVerificationIdsSetEnabled() &&
                isUserAllowed('UMUserEditUserVerificationIdSet'),
            notificationIdsForUpdate: {} as Record<NotificationIdentifier['identifier_type'], NotificationIdentifier>,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_UM_ENTITY_HIERARCHY_BY_TYPE_AND_ID]),
        ...mapGetters('userManagementUser', {
            getEkycStatusById: 'getEkycStatusById',
        }),
        initialEkycStatus(): EKYC_STATUS {
            return this.$route.params.id ? this.getEkycStatusById(this.$route.params.id) : '';
        },
        enableEkycEditWhenEkycFailed(): boolean {
            return this.isEkycEnabled && this.initialEkycStatus === EKYC_STATUS.FAILED;
        },
        documentTypeOptions(): any[] {
            return this.isEkycEnabled
                ? Object.values(EKYC_DOCUMENT_TYPE_OPTIONS)
                : Object.values(DOCUMENT_TYPE_OPTIONS);
        },
        documentSubtypeOptions(): any[] {
            return this.user.documentType && Object.keys(EKYC_DOCUMENT_SUBTYPE_OPTIONS).includes(this.user.documentType)
                ? Object.values(EKYC_DOCUMENT_SUBTYPE_OPTIONS[this.user.documentType])
                : [];
        },
        ekycStatusOptions(): EkycStatusOption[] {
            return [...EKYC_STATUS_TO_I18N_LABEL].map(([key, label]) => ({ key, label }));
        },
        isLocallyTiedOptions(): any[] {
            return Object.values(FLAG_IS_LOCALLY_TIED)
                .filter(k => Number.isInteger(k))
                .map(key => ({
                    key,
                    label: FLAG_IS_LOCALLY_TIED_LABEL_MAP.get(key as FLAG_IS_LOCALLY_TIED),
                }));
        },
        nationalityOptions(): string[] {
            return isoCountryList.map(country => country.alpha3);
        },
        displayFlags(): boolean {
            return !isEmpty(this.flagsToRender);
        },
        isUpdateFieldsDisabled(): boolean {
            return !isUserAllowed('UMUserEditUserUpdateFields');
        },
        isUpdateFlagsDisabled(): boolean {
            return !isUserAllowed('UMUserEditUserToggleFlag');
        },
        isMSISDNEditDisabled(): boolean {
            return !(isUserAllowed('UMUserEditUserMSISDN') && permissionsService?.userManagementEditMSISDNEnabled());
        },
        isNotificationEmailDisabled(): boolean {
            return !(
                isUserAllowed('UMUserEditUserUpdateFields') && getOperatorConfigValue('fiberEditNotificationEmail')
            );
        },
    },
    watch: {
        'user.documentType': {
            handler(newVal) {
                // eslint-disable-next-line max-len
                this.user.documentType = newVal;
                if (
                    !Object.keys(EKYC_DOCUMENT_SUBTYPE_OPTIONS).includes(newVal) ||
                    // eslint-disable-next-line max-len
                    (this.isEkycEnabled &&
                        !Object.values(EKYC_DOCUMENT_SUBTYPE_OPTIONS[newVal]).includes(this.user.documentSubtype))
                ) {
                    this.user.documentSubtype = '';
                }
            },
        },
        entity: {
            handler(newVal) {
                if (this.value) {
                    this.user = User.clone(this.entity);
                    // keep birthday formatting
                    this.user.birthday = newVal.birthday ? this.$localeLibrary.toUTC(newVal.birthday) : null;
                    this.isLocallyTied = this.isLocallyTiedOptions.find(
                        o => o.key === this.user?.flags?.[FLAG_TYPE.IS_LOCALLY_TIED]?.originalValue,
                    );
                }
            },
            deep: true,
            immediate: true,
        },
    },
    async created() {
        this.fieldsEnabled = permissionsService.getOperatorsUserFields();
        try {
            const { id } = this.$route.params;
            if (this.isEkycEnabled) {
                if (!id) {
                    this.$emit('input', false);
                }
                await this.fetchEkycStatus(id);
                this.setEkycStatus();
            }

            this.setFlags();
            this.userHierarchy = this[Getters.GET_UM_ENTITY_HIERARCHY_BY_TYPE_AND_ID]({
                targetId: id,
                targetType: USER_MANAGER_HIERARCHY.USER,
            });
        } catch (e: any) {
            Sentry.captureException(e);
            this.$Progress.fail();
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                type: ALERT_TYPES.error,
            });
        }
    },
    methods: {
        ...mapActions('userManagementUser', ['getEkycStatus']),
        allowVerificationIdAction(isVerified: NotificationIdentifier['verified']): boolean {
            return isVerified ? this.isNotificationIdClearEnabled : this.isNotificationIdSetEnabled;
        },
        updateFlag(field: string, value: number): void {
            this.flagsForUpdate[field] = value;
        },
        updateNotificationIds(item: NotificationIdentifier): void {
            this.notificationIdsForUpdate[item.identifier_type] = item;
        },
        setEkycStatus(): void {
            this.ekycStatus = {
                key: this.initialEkycStatus,
                label: EKYC_STATUS_TO_I18N_LABEL.get(this.initialEkycStatus) || '',
            };
        },
        sendVerificationEmailEnabled(): boolean {
            // TODO: see if any operator should have this functionality
            return false;
        },
        setFlags(): void {
            const userFlagsConfig = getOperatorConfigValue('service_config.lf-user.flags.user_flags', []);
            const isFilteringPossible =
                Array.isArray(userFlagsConfig) && !getOperatorConfigValue('isFlagFilteringDisabled');

            const userFlags = isFilteringPossible ? pick(this.entity?.flags, userFlagsConfig) : this.entity?.flags;

            this.flagsToRender = cloneDeep(userFlags || {});
        },
        async sendVerificationEmail(): Promise<any> {
            try {
                this.$Progress.start();
                await userManagementAJAX.verifyEmailCreateChallenge(
                    this.$route.params.id,
                    USER_MANAGER_HIERARCHY.USER,
                    // use the old email here
                    this.entity.emailSecret,
                );
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.userInformation.alerts.verificationEmailSentSuccess'),
                    type: ALERT_TYPES.success,
                });
                this.$Progress.finish();
                this.isVerifyEmailSent = true;
            } catch (e: any) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.userInformation.alerts.verificationEmailSentFailure'),
                });
                Sentry.captureException(e);
                this.$Progress.fail();
            }
        },
        async resetPassword(): Promise<any> {
            try {
                this.$Progress.start();
                await userManagementAJAX.resetPasswordCreateChallenge(
                    this.$route.params.id,
                    USER_MANAGER_HIERARCHY.USER,
                    // use the old email here
                    this.entity.emailSecret,
                );
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.userInformation.alerts.resetPasswordSuccess'),
                    type: ALERT_TYPES.success,
                });
                this.$Progress.finish();
                this.isVerifyEmailSent = true;
            } catch (e: any) {
                this.showSupportAlert(
                    this.$i18n.t('customerCare.userInformation.alerts.resetPasswordFailure'),
                    ALERT_TYPES.error,
                );
                Sentry.captureException(e);
                this.$Progress.fail();
            }
        },
        triggerForgetMeFlow() {
            if (!this.validateState(this.userHierarchy)) {
                this.$showErrorAlert({
                    message: this.$i18n.t('customerCare.userInformation.alerts.forgetMeFlowErrChildrenActive'),
                });
                return;
            }

            const confirmButton = new Button({
                label: this.$i18n.t('generic.yes'),
                alertType: ALERT_TYPES.warning,
            });

            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('customerCare.userInformation.alerts.forgetMeFlowConfirm'),
                type: ALERT_TYPES.warning,
                buttons: [confirmButton],
            });

            this.$eventBus.$once('buttonClicked', (buttonId: string) => {
                if (buttonId === confirmButton.id) {
                    this.userForgetMeFlow();
                }
            });
        },
        userForgetMeFlow() {
            this.$withLoadingSpinner(
                async () => {
                    const { id: targetId } = this.$route.params;
                    await userManagementAJAX.userForgetMe(targetId, USER_MANAGER_HIERARCHY.USER);
                    this.$showSuccessAlert({
                        message: this.$i18n.t('customerCare.userInformation.alerts.forgetMeFlowSuccess'),
                    });
                },
                {
                    errorHandler: (error: any) => {
                        if (error.response?.data.code === luaErrors.CCS_USER_FORGET_ME_FLOW.CONFLICT.code) {
                            this.$eventBus.$emit('showAlert', {
                                message: this.$i18n.t(
                                    'customerCare.userInformation.alerts.forgetMeFlowErrChildrenActive',
                                ),
                            });
                        } else {
                            this.$eventBus.$emit('showAlert', {
                                message: this.$i18n.t('customerCare.userInformation.alerts.forgetMeFlowApiErr'),
                            });
                        }
                    },
                },
            );
        },
        validateState(data: HierarchyItem | null): boolean {
            return (
                [SUBSCRIBER_STATE.TERMINATED, null, undefined].includes(data?.state) &&
                (!data?.children || data.children.every(this.validateState))
            );
        },
        async onSave(): Promise<any> {
            try {
                this.$Progress.start();
                const promises: any[] = [];
                let successAlertMessage: string = this.$i18n.t('generic.success');
                if (this.isEkycEnabled) {
                    // UPDATE EKYC
                    const ekycPropertiesForUpdate: { [key: string]: any } = User.mapUserEKYCPropsForUpdate(
                        this.ekycStatus?.key,
                        this.isLocallyTied?.key,
                        this.user,
                        this.entity,
                    );

                    promises.push(
                        userManagementAJAX.updateEkycRequest(
                            this.$route.params.id,
                            USER_MANAGER_HIERARCHY.USER,
                            ekycPropertiesForUpdate,
                        ),
                    );
                } else {
                    // UPDATE INFO
                    const userPropertiesForUpdate: { [key: string]: any } = User.mapUserPropsForUpdate(
                        this.user,
                        this.entity,
                    );

                    promises.push(
                        userManagementAJAX.updateInfo(
                            this.$route.params.id,
                            USER_MANAGER_HIERARCHY.USER,
                            userPropertiesForUpdate,
                        ),
                    );
                }

                // EMAIL SECRET
                if (this.user.emailSecret !== this.entity.emailSecret) {
                    promises.push(
                        userManagementAJAX.updateEmailCreateChallenge(
                            this.$route.params.id,
                            USER_MANAGER_HIERARCHY.USER,
                            this.user.emailSecret,
                        ),
                    );
                    successAlertMessage += ` ${this.$i18n.t(
                        'customerCare.userInformation.alerts.resetPasswordSuccess',
                    )}`;
                }

                // FLAGS
                if (!isEmpty(this.flagsForUpdate)) {
                    promises.push(
                        userManagementAJAX.updateFlags(
                            this.$route.params.id,
                            USER_MANAGER_HIERARCHY.USER,
                            this.flagsForUpdate,
                        ),
                    );
                }
                // VERIFICATION IDENTIFIERS
                if (!isEmpty(this.notificationIdsForUpdate)) {
                    Object.values(this.notificationIdsForUpdate).forEach((n: NotificationIdentifier) => {
                        promises.push(
                            userManagementAJAX.verifyIdentifier({
                                userId: this.$route.params.id,
                                identifierType: n.identifier_type,
                                identifierValue: n.identifier,
                                isVerified: n.verified,
                            }),
                        );
                    });
                }

                // NOTIFICATION EMAIL
                if (
                    !this.isNotificationEmailDisabled &&
                    this.user.notificationEmail !== this.entity.notificationEmail
                ) {
                    promises.push(
                        osstmofiberHTTP.updateNotificationEmail(
                            this.$route.params.id,
                            USER_MANAGER_HIERARCHY.USER,
                            this.user.notificationEmail,
                        ),
                    );
                }

                await Promise.all(promises);
                this.$Progress.finish();

                // Show success only if request has been sent
                if (promises.length) {
                    this.$eventBus.$emit('showAlert', {
                        message: successAlertMessage,
                        type: ALERT_TYPES.success,
                    });
                }
                this.$emit('saveSuccess');
                this.$emit('input', false);
            } catch (e: any) {
                Sentry.captureException(e);
                this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
                this.$Progress.fail();
            }
        },
        onCancel(): void {
            this.$emit('input', false);
        },
        async fetchEkycStatus(id: string): Promise<any> {
            try {
                this.$Progress.start();
                await this.getEkycStatus(id);
                this.$Progress.finish();
            } catch (e: any) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    type: ALERT_TYPES.error,
                });
            }
        },
    },
});
