import moment, { Moment } from 'moment';
import { first, keyBy, keys, compact, omit } from 'lodash';
import Message, { CampaignMessage } from '@/__new__/services/dno/campaigns/models/Message';
import Delay, { CampaignDelay, ChronoUnit } from '@/__new__/services/dno/campaigns/models/Delay';
import { Repeat, RepeatTypes } from '@/__new__/services/dno/campaigns/models/Repeat';
import { DaysOfWeekNames } from '@/__new__/features/campaigns/common/DaysOfWeek';
import { formatTimeString, getMomentFromDateAndTimeString } from '@/common/formatting';
import { EntityActions } from '@/common/commonEntityStateMapper';
import { LABEL_COLOR } from '@/common/labelsHelper';
import { MessageTypes } from '@/common/CampaignMessage';
import { LOGICAL_OPERATORS } from '@/common/segments';
import { TranslateResult } from 'vue-i18n';
import i18n from '@/i18n';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { replaceIdByEventTypeInTriggerCondition } from '@/__new__/services/dno/triggers/TriggerCondition';
import { CAMPAIGN_PRIORITIES_LABELS } from '@/common/cepHelper';
import { CohortList } from '@/__new__/services/dno/sinkConfigs/models/CohortExpression';
import store, { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';
import { CampaignCategory } from '@/__new__/features/settings/categories/CampaignCategory';

/**
 * Sets UTC (+0) timezone for local date.
 */
function asUTC(date: Moment): Moment {
    return date.add(date.utcOffset(), 'm').utc();
}

/**
 * Sets local timezone for UTC (+0) date.
 */
export function asLocal(date: Moment): Moment {
    return date.subtract(date.utcOffset(), 'm');
}

const UTCTimeStringNow = formatTimeString(moment().utc());
const UTCDateNow = moment().utc().toDate();
const UTCDateTomorrow = moment().utc().add(1, 'days').toDate();

// values are mapped on server

export const CampaignType = {
    MarketingCampaign: 0,
    ServiceNotificationCampaign: 1,
    BackOfficeCampaign: 2,
};

export enum CampaignTargetType {
    CohortTarget = 'CohortTarget',
    AllRegistered = 'AllRegistered',
}

export const CampaignActions = {
    Start: 'start',
    Stop: 'stop',
    None: 'none',
};

export const DeliveryTypeNames = {
    Scheduled: 'Scheduled',
    ActionBased: 'Action-based',
    ApiTriggered: 'API triggered',
};

export const DeliveryTypeServerMapping = {
    [DeliveryTypeNames.Scheduled]: 0,
    [DeliveryTypeNames.ActionBased]: 1,
    [DeliveryTypeNames.ApiTriggered]: 2,
};

export const DeliveryTypeFromAnyMapping: Record<string | number, string> = {
    [DeliveryTypeServerMapping[DeliveryTypeNames.Scheduled]]: DeliveryTypeNames.Scheduled,
    [DeliveryTypeServerMapping[DeliveryTypeNames.ActionBased]]: DeliveryTypeNames.ActionBased,
    [DeliveryTypeServerMapping[DeliveryTypeNames.ApiTriggered]]: DeliveryTypeNames.ApiTriggered,
    [DeliveryTypeNames.Scheduled]: DeliveryTypeNames.Scheduled,
    [DeliveryTypeNames.ActionBased]: DeliveryTypeNames.ActionBased,
    [DeliveryTypeNames.ApiTriggered]: DeliveryTypeNames.ApiTriggered,
};

export const TargetingOptions = {
    Preferred: 'preferred',
    Exact: 'exact',
    All: 'all_identifiers',
};

export const TargetingStrategiesByMessageType = {
    [MessageTypes.SMS]: {
        options: [TargetingOptions.Preferred, TargetingOptions.Exact],
        unregistered: true,
    },
    [MessageTypes.EMAIL]: {
        options: [TargetingOptions.Preferred],
        unregistered: true,
    },
    [MessageTypes.PUSH]: {
        options: [TargetingOptions.All],
        unregistered: false,
    },
    [MessageTypes.OTT]: {
        options: [TargetingOptions.Preferred],
        unregistered: false,
    },
};

export class DeliveryType {
    type;
    ignoreGlobalMsgLimit;
    useLocalTimezone;
    immediate;
    recurring;
    repeat;
    delay;
    userReEligibleInDays;
    dryRun;
    respectQuietHoursRules;
    sendToUnregisteredUsers;
    carrierAppIds;
    advancedTargeting;
    sendAfterQuietHoursEnd;
    respectUserLocalTimezone;
    selectedCategory;
    quietHoursProfiles;

    constructor(
        type: string,
        ignoreGlobalMsgLimit: boolean,
        useLocalTimezone: boolean,
        immediate: boolean,
        recurring: boolean,
        repeat: Repeat | null,
        delay: Delay | null,
        userReEligibleInDays: boolean | null,
        dryRun: boolean,
        respectQuietHoursRules: boolean,
        sendToUnregisteredUsers: boolean,
        carrierAppIds: string[],
        advancedTargeting: keyof typeof TargetingOptions,
        sendAfterQuietHoursEnd: boolean,
        respectUserLocalTimezone: boolean,
        selectedCategory: CampaignCategory | null,
        quietHoursProfiles: any,
    ) {
        this.type = type;
        this.ignoreGlobalMsgLimit = ignoreGlobalMsgLimit;
        this.useLocalTimezone = useLocalTimezone;
        this.immediate = immediate;
        this.recurring = recurring;
        this.repeat = repeat;
        this.delay = delay;
        this.userReEligibleInDays = userReEligibleInDays;
        this.dryRun = dryRun;
        this.respectQuietHoursRules = respectQuietHoursRules;
        this.sendToUnregisteredUsers = sendToUnregisteredUsers;
        this.carrierAppIds = carrierAppIds;
        this.advancedTargeting = advancedTargeting;
        this.sendAfterQuietHoursEnd = sendAfterQuietHoursEnd;
        this.respectUserLocalTimezone = respectUserLocalTimezone;
        this.selectedCategory = selectedCategory;
        this.quietHoursProfiles = quietHoursProfiles;
    }

    buildJson() {
        return {
            type: DeliveryTypeServerMapping[this.type],
            ignoreGlobalMsgLimit: this.ignoreGlobalMsgLimit,
            useLocalTimezone: this.useLocalTimezone,
            immediate: this.immediate,
            recurring: this.recurring,
            repeat: this.repeat ? this.repeat.buildJson() : null,
            delay: this.delay ? this.delay.buildJson() : null,
            userReEligibleInDays: this.userReEligibleInDays,
            dryRun: this.dryRun,
            respectQuietHoursRules: this.respectQuietHoursRules,
            sendToUnregisteredUsers: this.sendToUnregisteredUsers,
            carrierAppIds: this.carrierAppIds.length ? this.carrierAppIds : null,
            advancedTargeting: this.advancedTargeting,
            sendAfterQuietHoursEnd: this.respectQuietHoursRules ? this.sendAfterQuietHoursEnd : false,
            respectUserLocalTimezone: this.respectQuietHoursRules ? this.respectUserLocalTimezone : false,
            campaignCategoryId: this.selectedCategory?.id,
            quietHoursProfiles: this.quietHoursProfiles?.map((qhp: any) => qhp.id),
        };
    }

    static fromJson(json: Record<string, any>) {
        if (!(json.type in DeliveryTypeFromAnyMapping)) {
            throw new Error(`Invalid delivery type ${json.type}`);
        }
        const categories = store.getters[`${Modules.campaigns}/${Getters.GET_CAMPAIGN_CATEGORIES}`];
        const selectedCategory = categories.find((c: CampaignCategory) => c.id === json.campaignCategoryId) || null;

        const newDeliveryType = new DeliveryType(
            DeliveryTypeFromAnyMapping[json.type],
            json.ignoreGlobalMsgLimit,
            json.useLocalTimezone,
            json.immediate,
            json.recurring,
            json.repeat ? Repeat.fromJson(json.repeat) : null,
            json.delay ? Delay.fromJson(json.delay) : null,
            json.userReEligibleInDays,
            json.dryRun,
            json.respectQuietHoursRules,
            json.sendToUnregisteredUsers,
            json.carrierAppIds ? json.carrierAppIds : [],
            json.advancedTargeting,
            json.sendAfterQuietHoursEnd,
            json.respectUserLocalTimezone,
            selectedCategory || json.selectedCategory, // for import functionality
            json.quietHoursProfiles,
        );

        return newDeliveryType;
    }

    toString() {
        return DeliveryType.toString(this.type, this.deliveryConditionString());
    }

    deliveryConditionString() {
        if (this.type === DeliveryTypeNames.Scheduled) {
            if (this.immediate) {
                return 'at start time';
            }
            if (this.recurring) {
                return 'recurring';
            }
        }
        if (this.type === DeliveryTypeNames.ActionBased) {
            if (this.immediate) {
                return 'immediately';
            }
            if (this.delay) {
                return 'delayed';
            }
        }
        return '';
    }

    static toString(type: string, deliveryTime: unknown) {
        if (deliveryTime != null) {
            return type === DeliveryTypeNames.Scheduled
                ? `Scheduled delivery: ${deliveryTime}`
                : `Action-based delivery: ${deliveryTime}`;
        }
        return '';
    }
}

export class CampaignStatus {
    title: TranslateResult;

    cssClass: string;

    constructor(title: TranslateResult, cssClass: string) {
        this.title = title;
        this.cssClass = cssClass;
    }
}

export const CampaignStatuses = {
    Running: new CampaignStatus('Running', 'green'),
    Upcoming: new CampaignStatus('Upcoming', 'yellow'),
    Finished: new CampaignStatus('Finished', 'red'),
    Stopped: new CampaignStatus('Stopped', 'gray'),
    Deleted: new CampaignStatus('Deleted', 'gray'),
    Unpublished: new CampaignStatus('Unpublished', 'blue'),
    Started: new CampaignStatus('Started', ''), // todo add some color
    Draft: new CampaignStatus('Draft', 'gray'),
};

export const CAMPAIGN_STATUS_TO_COLOR_MAPPING = new Map([
    [CampaignStatuses.Running.title, LABEL_COLOR.green],
    [CampaignStatuses.Upcoming.title, LABEL_COLOR.blue],
    [CampaignStatuses.Finished.title, LABEL_COLOR.red],
    [CampaignStatuses.Stopped.title, LABEL_COLOR.gray],
    [CampaignStatuses.Deleted.title, LABEL_COLOR.gray],
    [CampaignStatuses.Unpublished.title, LABEL_COLOR.blue],
    [CampaignStatuses.Started.title, LABEL_COLOR.green],
    [CampaignStatuses.Draft.title, LABEL_COLOR.gray],
]);

// is used in table on list page
export const mapCampaignActions = (campaign: Campaign) => {
    const actions = [EntityActions.DETAILS, EntityActions.CLONE];
    switch (campaign.status) {
        case CampaignStatuses.Finished:
        case CampaignStatuses.Stopped:
        case CampaignStatuses.Unpublished:
            return [...actions, EntityActions.EDIT, EntityActions.START, EntityActions.DELETE];
        case CampaignStatuses.Running:
        case CampaignStatuses.Upcoming:
            const shouldEditRunning = campaign.deliveryType?.type === DeliveryTypeNames.ActionBased;
            return compact([...actions, shouldEditRunning ? EntityActions.EDIT : null, EntityActions.STOP]);
        default:
            return [...actions, EntityActions.EDIT, EntityActions.DELETE];
    }
};

export const CampaignStatusesServerMapping: Record<string, number> = {
    [CampaignStatuses.Running.title as string]: 0,
    [CampaignStatuses.Upcoming.title as string]: 1,
    [CampaignStatuses.Finished.title as string]: 2,
    [CampaignStatuses.Stopped.title as string]: 3,
    [CampaignStatuses.Unpublished.title as string]: 4,
    [CampaignStatuses.Deleted.title as string]: 5,
    [CampaignStatuses.Started.title as string]: 6,
    [CampaignStatuses.Draft.title as string]: 7,
};

export const CampaignStatusesFromServerMapping: Record<number, CampaignStatus> = {
    [CampaignStatusesServerMapping[CampaignStatuses.Running.title as string]]: CampaignStatuses.Running,
    [CampaignStatusesServerMapping[CampaignStatuses.Upcoming.title as string]]: CampaignStatuses.Upcoming,
    [CampaignStatusesServerMapping[CampaignStatuses.Finished.title as string]]: CampaignStatuses.Finished,
    [CampaignStatusesServerMapping[CampaignStatuses.Stopped.title as string]]: CampaignStatuses.Stopped,
    [CampaignStatusesServerMapping[CampaignStatuses.Unpublished.title as string]]: CampaignStatuses.Unpublished,
    [CampaignStatusesServerMapping[CampaignStatuses.Deleted.title as string]]: CampaignStatuses.Deleted,
    [CampaignStatusesServerMapping[CampaignStatuses.Started.title as string]]: CampaignStatuses.Started,
    [CampaignStatusesServerMapping[CampaignStatuses.Draft.title as string]]: CampaignStatuses.Draft,
};

export const Steps = {
    delivery: 'Delivery',
    compose: 'Compose',
    targetUsers: i18n.t('campaigns.targetAudience'),
    confirm: 'Confirm',
};

export const campaignCreationStepNameToNumbersMap = new Map([
    [Steps.delivery, 1],
    [Steps.compose, 2],
    [Steps.targetUsers, 3],
    [Steps.confirm, 5],
]);

export const CampaignTypes = {
    [CampaignType.MarketingCampaign]: {
        value: CampaignType.MarketingCampaign,
        label: 'Marketing Campaign',
        isAllowed: () => isUserAllowed('MarketingCampaignsWrite'),
    },
    [CampaignType.ServiceNotificationCampaign]: {
        value: CampaignType.ServiceNotificationCampaign,
        label: 'Service Campaign',
        isAllowed: () => isUserAllowed('ServiceCampaignsWrite'),
    },
    [CampaignType.BackOfficeCampaign]: {
        value: CampaignType.BackOfficeCampaign,
        label: 'Backoffice Campaign',
        isAllowed: () => isUserAllowed('BackofficeCampaignsWrite'),
    },
};

export const CampaignFeaturesByType = {
    [CampaignType.MarketingCampaign]: {
        messageTypes: [MessageTypes.PUSH, MessageTypes.SMS, MessageTypes.EMAIL, MessageTypes.OTT],
        backofficeTargetsEnabled: false,
        ignoreGlobalMsgLimitDefault: false,
        quietHoursRulesEnabled: true,
        steps: {
            enabled: [Steps.delivery, Steps.compose, Steps.targetUsers, Steps.confirm],
        },
        campaignTimeline: {
            enabled: false,
        },
        target: {
            enabled: [CampaignTargetType.CohortTarget],
        },
        deliveryStats: {
            enabled: true,
        },
    },
    [CampaignType.ServiceNotificationCampaign]: {
        messageTypes: [MessageTypes.PUSH, MessageTypes.SMS, MessageTypes.EMAIL, MessageTypes.OTT],
        backofficeTargetsEnabled: false,
        ignoreGlobalMsgLimitDefault: true,
        quietHoursRulesEnabled: true,
        steps: {
            enabled: [Steps.delivery, Steps.compose, Steps.targetUsers, Steps.confirm],
        },
        campaignTimeline: {
            enabled: false,
        },
        target: {
            enabled: false,
        },
        deliveryStats: {
            enabled: false,
        },
    },
    [CampaignType.BackOfficeCampaign]: {
        messageTypes: [MessageTypes.SMS, MessageTypes.EMAIL],
        backofficeTargetsEnabled: true,
        ignoreGlobalMsgLimitDefault: true,
        quietHoursRulesEnabled: false,
        steps: {
            enabled: [Steps.delivery, Steps.compose, Steps.confirm],
        },
        campaignTimeline: {
            enabled: false,
        },
        target: {
            enabled: false,
        },
        deliveryStats: {
            enabled: false,
        },
    },
};

export const LaunchTimeTypes = {
    immediately: 'immediately',
    scheduled: 'scheduled',
};

export interface CohortGroupId {
    id: string;
    groupType: string;
    name: string;
}
export enum CohortType {
    USER = 'USER',
    SUBSCRIBER = 'SUBSCRIBER',
    SUBSCRIBER_ID = 'SUBSCRIBER_ID',
    USER_ID = 'USER_ID',
    ACCOUNT = 'ACCOUNT',
    ACCOUNT_ID = 'ACCOUNT_ID',
    MSISDN = 'MSISDN',
    EMAIL = 'EMAIL',
    EXTERNAL_IDP_ID = 'EXTERNAL_IDP_ID',
}
export type CohortListConfig = Record<CohortType, string[]> & {
    logical_operator: 'OR' | 'AND';
};
export type CohortConfig = Record<CohortList, CohortListConfig>;
export type TargetConfig = Record<CampaignTargetType, CohortConfig>;

interface CampaignDeliveryRepeat {
    type: number;
    hours: number;
    minutes: number;
    every: number | null;
    dayOfWeek: keyof typeof DaysOfWeekNames | null;
    dayOfMonth: number | null;
    lastDayOfMonth: boolean;
}

interface CampaignTriggerCondition {
    filters: Array<{ filterDefititionId: string; values: any }>;
    idType: number | null;
}

export interface BackendCampaign {
    version: number;
    id: string;
    update_time: number;
    data: {
        name: string;
        operatorName: string;
        status: number;
        creationDate: number;
        updateTimestamp: number;
        priority: number;
        deliveryType: {
            type: number;
            ignoreGlobalMsgLimit: boolean;
            useLocalTimezone: boolean;
            immediate: boolean;
            recurring: boolean;
            firstDeliveryTime: number | null;
            repeat: CampaignDeliveryRepeat | null;
            delay: CampaignDelay | null;
            userReEligibleInDays: number | null;
            dryRun: boolean;
            respectQuietHoursRules: boolean;
            carrierAppIds: string[] | null;
            sendToUnregisteredUsers: boolean;
            advancedTargeting: string;
            sendAfterQuietHoursEnd: boolean;
            respectUserLocalTimezone: boolean;
            quietHoursProfiles: any;
        };
        targetType: TargetConfig | null;
        messages: CampaignMessage[][];
        startTime: number;
        endTime: number | null;
        lastRunTimestamp: number;
        campaignType: number;
        triggerCondition: CampaignTriggerCondition | null;
        conversionEvent: null;
    };
}

export default class Campaign {
    id;
    name;
    creationDate;
    startTime;
    endTime;
    deliveryType;
    target;
    messages; // [msgsInTimeline[msgsOfDiffentTypes]]
    status;
    segmentIds;
    campaignType;
    triggerCondition;
    lastRunTimestamp;
    updateTimestamp;
    version;
    selectedPriority;

    constructor(
        id: string | undefined,
        name: string,
        creationDate: number,
        startTime: number | undefined,
        endTime: number | null | undefined,
        deliveryType: DeliveryType,
        target: TargetConfig,
        messages: Message[][],
        status: CampaignStatus | null,
        segmentIds: string[] | null,
        campaignType: number,
        triggerCondition: any,
        lastRunTimestamp = 0,
        updateTimestamp: number,
        version: number,
        selectedPriority: number | TranslateResult,
    ) {
        this.id = id;
        this.name = name;
        this.creationDate = creationDate;
        this.startTime = startTime;
        this.endTime = endTime;
        this.deliveryType = deliveryType;
        this.target = target;
        this.messages = messages;
        this.status = status;
        this.segmentIds = segmentIds;
        this.campaignType = campaignType;
        this.triggerCondition = triggerCondition;
        this.lastRunTimestamp = lastRunTimestamp;
        this.updateTimestamp = updateTimestamp;
        this.version = version;
        this.selectedPriority = selectedPriority;
    }

    /** Action that can be executed on this campaign (e.g. start, stop) */
    actionAllowed() {
        if (this.status === CampaignStatuses.Draft) {
            return CampaignActions.None;
        }
        if (
            this.status === CampaignStatuses.Stopped ||
            this.status === CampaignStatuses.Finished ||
            this.status === CampaignStatuses.Unpublished
        ) {
            return CampaignActions.Start;
        }
        return CampaignActions.Stop;
    }

    buildJson() {
        return {
            campaignType: this.campaignType,
            name: this.name,
            startTime: this.startTime,
            endTime: this.endTime,
            deliveryType: this.deliveryType?.buildJson(),
            messages: this.messages.map(msgs => msgs.map(msg => msg.buildJson())),
            targetType: this.finalTargetType,
            triggerCondition:
                this.triggerCondition && this.triggerCondition.filters
                    ? {
                          filters: this.triggerCondition.filters.map((f: any) => omit(f, 'filterDefinitionId')),
                          idType: this.triggerCondition.idType,
                      }
                    : null,
            priority: this.selectedPriority,
        };
    }

    get isRecurring() {
        return this.deliveryType && !!this.deliveryType.recurring;
    }

    get targetType() {
        return first(keys(this.target)) as CampaignTargetType;
    }

    get finalTargetType() {
        const cohorts: CohortConfig = Object.values(this.target)[0];
        switch (this.campaignType) {
            case CampaignType.ServiceNotificationCampaign:
                const isEmptyCohorts =
                    this.targetType === CampaignTargetType.CohortTarget &&
                    Object.keys(cohorts[CohortList.WHITELIST] || {}).length <= 1 &&
                    Object.keys(cohorts[CohortList.BLACKLIST] || {}).length <= 1;
                return isEmptyCohorts ? null : this.target;
            case CampaignType.BackOfficeCampaign:
                return null;
            default:
                return this.target;
        }
    }

    static emptyData() {
        return {
            name: '',
            status: CampaignStatuses.Unpublished,
            campaignType: CampaignType.MarketingCampaign,
            selectedPriority: null,
            startDate: UTCDateNow,
            startTimeString: UTCTimeStringNow,

            hasEndTime: false,
            endDate: UTCDateTomorrow,
            endTimeString: UTCTimeStringNow,

            lastRunTimestamp: 0,

            messages: [],
            selectedMessageUniqueId: null,

            delivery: {
                selectedCategory: null,
                type: DeliveryTypeNames.Scheduled,
                immediate: true,
                sendAfterQuietHoursEnd: false,
                respectUserLocalTimezone: false,
                respectQuietHoursRules: false,
                quietHoursProfiles: [],
                delay: {
                    amount: 0,
                    unit: ChronoUnit.day,
                },

                recurring: false,
                repeat: {
                    type: RepeatTypes.daily,
                    every: null,
                    hours: moment.utc().hours(),
                    minutes: moment.utc().minutes(),
                    dayOfWeek: 1,
                    dayOfMonth: 1,
                    lastDayOfMonth: false,
                },

                userReEligibleInDays: null,

                ignoreGlobalMsgLimit: false,
                dryRun: false,
                sendToUnregisteredUsers: false,
                useLocalTimezone: false,

                advancedTargeting: TargetingOptions.Preferred,

                carrierAppIds: [],
            },
            triggerCondition: {
                filters: [],
                idType: null,
            },

            target: {
                [CampaignTargetType.CohortTarget]: {
                    whitelist: { logical_operator: LOGICAL_OPERATORS.or },
                    blacklist: { logical_operator: LOGICAL_OPERATORS.or },
                },
            },
        };
    }

    editableData() {
        const campaignDefaults = Campaign.emptyData();

        const { date: startDate, timeString: startTimeString } = this.startTime
            ? Campaign.deconstructDate(this.startTime)
            : { date: null, timeString: null };
        const { date: endDate, timeString: endTimeString } = this.endTime
            ? Campaign.deconstructDate(this.endTime)
            : { date: null, timeString: null };

        return {
            name: this.name,
            status: this.status,
            campaignType: this.campaignType,

            startDate,
            startTimeString,

            hasEndTime: !!this.endTime,
            endDate: endDate ?? campaignDefaults.endDate,
            endTimeString: endTimeString ?? campaignDefaults.endTimeString,

            lastRunTimestamp: this.lastRunTimestamp,

            delivery: {
                ...campaignDefaults.delivery,
                ...this.deliveryType,
                delay: this.deliveryType?.delay ?? campaignDefaults.delivery.delay,
                repeat: this.deliveryType?.repeat ?? campaignDefaults.delivery.repeat,
            },
            triggerCondition: this.triggerCondition ?? campaignDefaults.triggerCondition,

            messages: this.messages.map(msgs => keyBy(msgs, msg => msg.uniqueId)),
            selectedMessageUniqueId: this.messages[0][0].uniqueId,

            target: this.target,
            version: this.version,
            selectedPriority: this.selectedPriority,
        };
    }

    clone() {
        return {
            ...this.editableData(),
            name: `${this.name} (cloned)`,
            status: CampaignStatuses.Draft,
        };
    }

    static constructDate(date: Moment, timeString: string): number {
        return asUTC(getMomentFromDateAndTimeString(date, timeString)).unix();
    }

    static deconstructDate(unixtime: number): { date: Date; timeString: string } {
        const momentInstance = asLocal(moment(unixtime * 1000));
        return {
            date: momentInstance.toDate(),
            timeString: formatTimeString(momentInstance),
        };
    }

    static fromJson(json: BackendCampaign): Campaign {
        return new Campaign(
            json.id,
            json.data.name,
            json.data.creationDate,
            json.data.startTime,
            json.data.endTime,
            DeliveryType.fromJson(json.data.deliveryType),
            (json.data.targetType as TargetConfig) || Campaign.emptyData().target,
            json.data.messages.map((msgs: any[]) => msgs.map(Message.fromJson)),
            CampaignStatusesFromServerMapping[json.data.status as keyof typeof CampaignStatusesFromServerMapping],
            null,
            json.data.campaignType,
            json.data.triggerCondition ? replaceIdByEventTypeInTriggerCondition(json.data.triggerCondition) : null,
            json.data.lastRunTimestamp,
            json.update_time,
            json.version,
            CAMPAIGN_PRIORITIES_LABELS[json.data.priority as keyof typeof CAMPAIGN_PRIORITIES_LABELS],
        );
    }
}
