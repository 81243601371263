
import Vue, { type PropType } from 'vue';

// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import BulkUploadHistory from '@/__new__/features/resources/BulkUploadHistory.vue';
import BulkUploadTabs, { type Tab } from '@/__new__/features/resources/BulkUploadTabs.vue';
import FileUploaderV2, { type FileListItem } from '@/components/partials/inputs/FileUploaderV2.vue';

// Http
import { uploadToSignedURL } from '@/http/fileUploader';
import { getUploadURL } from '@/__new__/services/dno/objectStorageSigner/http/objectStorageSigner';
import {
    getEntityDetailsByName,
    getEntityDetailsByCategory,
} from '@/__new__/services/dno/progressTracker/http/progressTracker';

// Helpers
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import { EntityUploadStatus } from '@/__new__/services/dno/progressTracker/models/entity';
import type { EntityDetailsByName } from '@/__new__/services/dno/progressTracker/progressTrackerHelper';

// eslint-disable-next-line no-shadow
enum DIALOG_TAB {
    UPLOAD = 1,
    EXAMPLE = 2,
}

export type UploadColumnLegend = {
    index: number;
    name: string;
    description?: string;
};

export default Vue.extend({
    name: 'BulkUploadDialog',
    components: {
        AppDialogV2,
        AppButton,
        BulkUploadHistory,
        BulkUploadTabs,
        FileUploaderV2,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        dataflowId: {
            type: String,
            required: true,
        },
        metadata: {
            type: Object,
            default: undefined,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        config: {
            type: Object,
            default: () => ({
                getUploadURL,
                getEntityDetailsByName,
                getEntityDetailsByCategory,
            }),
        },
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        submitBtnLabel: {
            type: String,
            default: '',
        },
        exampleData: {
            type: Array as PropType<UploadColumnLegend[]>,
            default: () => [],
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            DIALOG_TAB,
            apiPollers: {} as Record<string, ReturnType<typeof setInterval>>,
            tabId: DIALOG_TAB.UPLOAD,
            fileList: [] as FileListItem[],
            fileUploadId: '',
            isUploading: false,
            exampleColumns: [
                {
                    name: this.$t('generic.column'),
                    key: 'index',
                    field: 'index',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.description'),
                    key: 'description',
                    field: 'description',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ] as TableColumn[],
        };
    },
    computed: {
        tabs(): Tab[] {
            const tabs = [
                {
                    id: DIALOG_TAB.UPLOAD,
                    label: this.$t('operator.upload'),
                },
            ];

            if (this.exampleData.length) {
                tabs.push({
                    id: DIALOG_TAB.EXAMPLE,
                    label: this.$t('generic.example'),
                });
            }

            return tabs;
        },
        isSubmitDisabled(): boolean {
            return !this.fileList.length || this.isUploading;
        },
    },
    beforeDestroy() {
        this.clearPollers();
    },
    methods: {
        clearPollers(key?: string): void {
            if (key) {
                clearInterval(this.apiPollers[key]);
                delete this.apiPollers[key];
                return;
            }

            Object.values(this.apiPollers).forEach(i => clearInterval(i));
            this.apiPollers = {};
            this.isUploading = false;
        },
        closeModal(): void {
            this.fileList = [];
            this.clearPollers();
            this.$emit('close');
        },
        onFileChange(fileList: FileListItem[]): void {
            this.fileList = fileList;
            this.$emit('input', this.fileList);
        },
        trackFileUploadPercentage(fileItem: FileListItem): void {
            this.apiPollers[fileItem.file.name] = setInterval(() => {
                if (fileItem.loadingPercentage < 100) {
                    fileItem.loadingPercentage += 1;
                    return;
                }
                this.clearPollers(fileItem.file.name);
            }, 500);
        },
        uploadFile(fileItem: FileListItem): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    const {
                        data: {
                            data: { upload_url: uploadUrl, entity_id: uploadId, required_request_headers: headers },
                        },
                    } = await this.config.getUploadURL({
                        dataflow: this.dataflowId,
                        fileName: fileItem.file?.name as string,
                        metadata: this.metadata,
                    });
                    const newFile = new File([fileItem.file as File], uploadId);
                    const blob = new Blob([newFile], { type: 'binary' });

                    await uploadToSignedURL(blob, uploadUrl, 0, headers);
                    fileItem.entityName = uploadId;
                    fileItem.loadingPercentage = 100;
                    fileItem.status = EntityUploadStatus.REGISTERED;

                    this.trackFileUploadStatus(fileItem);
                },
                {
                    errorHandler: (e: any) => {
                        this.isUploading = false;
                        fileItem.loadingPercentage = 100;
                        fileItem.errorMessage = e.message || this.$t('operations.alerts.uploadError');
                    },
                },
            );
        },
        async uploadFileItem(fileItem: FileListItem): Promise<void> {
            fileItem.loadingPercentage = 0;
            fileItem.errorMessage = '';
            fileItem.status = EntityUploadStatus.REGISTERED;
            this.trackFileUploadPercentage(fileItem);
            await this.uploadFile(fileItem);
        },
        async onSubmit(): Promise<void> {
            this.isUploading = true;
            await Promise.all(this.fileList.map(this.uploadFileItem));
        },
        async onFileRetry(fileItem: FileListItem): Promise<void> {
            this.isUploading = true;
            await this.uploadFileItem(fileItem);
        },
        async getFileUploadStatus(fileItem: FileListItem): Promise<Partial<EntityDetailsByName['data']>> {
            try {
                const { data } = await this.config.getEntityDetailsByName(fileItem.entityName, this.dataflowId);
                return data?.data;
            } catch (e) {
                return this.getFileUploadStatus(fileItem);
            }
        },
        async mapFailedLines(fileItem: FileListItem, categories: string[]): Promise<void> {
            const responses = await Promise.all(
                categories.map(c => this.config.getEntityDetailsByCategory(fileItem.entityName, this.dataflowId, c)),
            );
            const failedRows = responses
                .map(res => res?.data?.data?.items)
                .flat()
                .map(item => item.id)
                .sort((a, b) => a - b);
            fileItem.errorMessage = this.$tc('operator.numberManagement.error.failedLinesMessage', failedRows.length, {
                n: failedRows.join(', '),
            });
            this.clearPollers(fileItem.file.name);
        },
        trackFileUploadStatus(fileItem: FileListItem): void {
            let count = 0;
            const INTERVAL = 5000;
            this.apiPollers[fileItem.file.name] = setInterval(async () => {
                const {
                    status,
                    event_category_counters: counters,
                    expected_count: expectedCount,
                } = await this.getFileUploadStatus(fileItem);
                fileItem.status = status;
                count += 1;

                // If pending more than 3mins(36 * 5sec)
                if (count > 36) {
                    this.clearPollers(fileItem.file.name);
                    fileItem.loadingPercentage = 100;
                    fileItem.errorMessage = this.$t('operations.alerts.uploadError');
                }

                if (
                    [EntityUploadStatus.DISCARDED, EntityUploadStatus.COMPLETED].includes(status as EntityUploadStatus)
                ) {
                    const categories = Object.keys(counters || {});
                    const successfulEvents = categories.find(c => c.includes('successful')) || '';
                    const failedEvents = categories.filter(c => !c.includes('successful'));

                    if (failedEvents.length) {
                        await this.mapFailedLines(fileItem, failedEvents);
                    }

                    const file = {
                        success: counters?.[successfulEvents] || 0,
                        fail: failedEvents.reduce(
                            (sum, failedEventCategory) => sum + (counters?.[failedEventCategory] || 0),
                            0,
                        ),
                    };

                    if (!file.fail && expectedCount) {
                        fileItem.status = EntityUploadStatus.COMPLETED;
                        this.$showSuccessAlert({
                            message: this.$t('operations.alerts.uploadSuccess', { fileName: fileItem.file?.name }),
                        });
                    } else {
                        fileItem.status = EntityUploadStatus.DISCARDED;
                        if (!fileItem.errorMessage) {
                            fileItem.errorMessage = this.$t('operations.alerts.uploadError');
                        }
                    }
                    this.$emit('upload', fileItem);
                    this.clearPollers(fileItem.file.name);
                }
            }, INTERVAL);
        },
    },
});
