



















import Vue from 'vue';

// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AppLabel from '@/components/partials/AppLabel.vue';

// Helpers
import * as Sentry from '@sentry/vue';
import { isEmpty } from 'lodash';
import tableColumnType from '@/common/filterTable';
import {
    CHANNEL_STATES_TO_STATUS_NAME_MAP,
    SENSITIVE_TRANSACTIOS_USE_CASE,
    SERVICE_TYPE,
    CHANNEL,
} from '@/__new__/features/customerCareSuite/common/SensitiveTransactionHistoryHelper';
import {
    TRANSACTION_STATUS_TO_COLOR,
    TRANSACTION_STATUS_TO_LABEL,
    USER_MANAGER_HIERARCHY,
} from '@/__new__/features/customerCare/common/customerCareHelper';

// HTTP
import customerCareHttp from '@/__new__/services/dno/user/http/customer-care';

export default Vue.extend({
    name: 'AuthenticationTransactionHistoryTile',
    components: {
        AbstractTableTile,
        AppLabel,
    },
    props: {
        userManagerHierarchy: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            authenticationTransactionHistory: [],
            authenticationTransactionHistoryApiResponse: {},
        };
    },
    computed: {
        columnsData() {
            return [
                {
                    name: this.$i18n.t('generic.time'),
                    key: 'timeFormatted',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.time),
                    sortBy: entity => entity.time,
                    field: 'time',
                    filterType: tableColumnType.DATE,
                },
                {
                    key: 'channel',
                    name: this.$i18n.t('generic.channel'),
                },
                {
                    key: 'method',
                    name: this.$i18n.t('generic.method'),
                },
                {
                    key: 'status',
                    name: this.$i18n.t('generic.status'),
                },
                {
                    key: 'id',
                    name: this.$i18n.t('generic.id'),
                },
                {
                    key: 'userId',
                    name: this.$i18n.t('customerCare.userId'),
                },
                {
                    key: 'portalUserId',
                    name: this.$i18n.t('generic.portalUserId'),
                },
                {
                    key: 'transactionType',
                    name: this.$i18n.t('billing.transactionType'),
                },
            ];
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        fetchTileData() {
            this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);

                    const response = await customerCareHttp.fetchServiceBlockerHistory(
                        this.$route.params.id,
                        USER_MANAGER_HIERARCHY.USER,
                        SERVICE_TYPE.END_USER_AUTHORIZATION,
                    );
                    this.authenticationTransactionHistoryApiResponse = response;
                    const otpAttempts = !isEmpty(response?.data?.otp_attempts) ? response.data.otp_attempts : [];
                    this.authenticationTransactionHistory = otpAttempts
                        .filter(el => el?.properties?.transaction_type === SENSITIVE_TRANSACTIOS_USE_CASE.FULL_INFO)
                        .map(el => {
                            return {
                                time: el.event_time,
                                channel: CHANNEL_STATES_TO_STATUS_NAME_MAP.get(
                                    el?.properties?.portal_id ? CHANNEL.PORTAL : CHANNEL.CLIENT,
                                ),
                                method: el?.properties?.method || '',
                                status: el.event_type,
                                id: el?.properties?.external_id || '',
                                userId: el?.identifier || '',
                                portalUserId: el?.properties?.portal_id || '',
                                transactionType: el?.properties?.transaction_type || '',
                            };
                        });

                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: error => {
                        this.authenticationTransactionHistoryApiResponse = error.response;
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                        Sentry.captureException(error);
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        getStatusMapping(entity) {
            return {
                title: TRANSACTION_STATUS_TO_LABEL.get(entity.status),
                color: TRANSACTION_STATUS_TO_COLOR.get(entity.status),
            };
        },
    },
});
