<template>
    <AbstractEditPageWrapper class="no-vertical-scroll">
        <template #header>
            <AppHeader :pageTitle="title" />
        </template>

        <template #content>
            <FileUploaderModal
                v-show="showWarningModal"
                :heading="$i18n.t('operator.numberManagement.uploadInProgress.heading')"
                :actionButtonLabel="$i18n.t('operator.numberManagement.uploadInProgress.actionLabel')"
                :paragraphList="$i18n.t('operator.numberManagement.uploadInProgress.message')"
                @close="showWarningModal = false"
                @action="
                    showWarningModal = false;
                    $router.go(-1);
                "
            />
            <FileUploaderModal
                v-show="showErrorModal"
                :heading="$i18n.t('operator.numberManagement.error.heading')"
                :paragraphList="failedLinesErrorMessage"
                @close="showErrorModal = false"
            />

            <div class="number-management mb-auto">
                <!-- COUNTS -->
                <section v-if="counts.length">
                    <div
                        v-for="count in counts"
                        :key="count.title"
                        class="mb-4"
                    >
                        <div class="lf-subtitle heading">
                            {{ count.title }}
                        </div>
                        <div
                            v-if="count.fields.length"
                            class="d-flex justify-content-between flex-wrap"
                        >
                            <AppCountCard
                                v-for="field in count.fields"
                                :key="field.label"
                                class="count-card mb-3"
                                :value="field.value"
                                :label="field.label"
                            />
                        </div>
                    </div>
                </section>

                <!-- UPLOADER -->
                <section v-if="uploaderEnabled">
                    <div class="lf-subtitle heading">
                        {{ titleUploadSection }}
                    </div>

                    <div
                        v-if="mvneSettingsEnabled"
                        class="mb-3"
                    >
                        <slot name="mvne-settings" />
                    </div>

                    <div class="content pt-0 inner-layout">
                        <DragDropFileUploader
                            class="file-upload-field"
                            data-test-id="drag-drop-file-uploader-nm"
                            :acceptType="acceptType"
                            :label="dragDropLable"
                            :description="dragDropHint"
                            :configFileUploader="configFileUploader"
                            :triggerUploadFiles="triggerUploadFiles"
                            :triggerClearPollers="triggerClearPollers"
                            @updateUploadStatus="updateUploadFilesStatus"
                            @updateAmountFilesUpload="updateAmountFilesUpload"
                            @error="displayFailedLines"
                        />
                    </div>
                    <slot name="search-utilities" />
                </section>

                <!-- UTILITIES -->
                <section v-if="searchEnabled">
                    <div class="lf-subtitle heading">
                        {{ titleUtilitiesSection }}
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                            <div class="all-caps-text">
                                {{ utilitiesSubtext }}
                            </div>

                            <AppMultiselectV3
                                v-model="utilities.type"
                                :options="utilityTypes"
                                :showLabels="false"
                                :small="true"
                                :preselectFirst="true"
                                :borderNone="true"
                                :blueArrow="true"
                                label="label"
                            />

                            <AppInputV3
                                v-model="utilities.value"
                                :placeholder="utilityValuePlaceholder"
                            />
                        </div>

                        <AppButton
                            data-test-id="numberSearchButton"
                            :buttonType="BUTTON_TYPES.PRIMARY"
                            :label="$i18n.t('generic.search')"
                            :iconType="ICON_TYPES.SEARCH"
                            @click="searchUtility"
                        />
                    </div>
                    <div
                        v-if="searchResult"
                        class="pt-5"
                    >
                        <AppTable
                            data-test-id="searchResultTable"
                            :entities="searchResult"
                            :canSelectColumns="true"
                            :canSort="true"
                            :columnsData="tableColumnsData"
                            :isDataLoading="false"
                            tableKey="numberManagement/queryResult"
                        >
                            <template #state="{ entity }">
                                <NumberStatusIndicator :status="entity.state" />
                            </template>
                        </AppTable>
                    </div>
                </section>
            </div>

            <div
                v-if="$slots['bulk-history']"
                class="mb-5"
            >
                <slot name="bulk-history" />
            </div>
        </template>

        <template #sideBar>
            <template v-if="$slots['sidebar']">
                <slot name="sidebar" />
            </template>
        </template>

        <template #controls>
            <AppButton
                :label="$i18n.t('generic.back')"
                class="cancel-btn mr-5"
                @click="onCancel"
            />
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                :label="$i18n.t('operator.numberManagement.uploadFile')"
                :iconType="ICON_TYPES.CHECK"
                :isLoading="uploadInProgress"
                :disabled="isUploadButtonDisabled"
                class="save-btn"
                data-test-id="upload-numbers-button"
                @click="onAdd"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
// COMPONENTS
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppCountCard from '@/components/partials/cards/AppCountCard.vue';
import FileUploaderModal from '@/components/partials/fileUploader/FileUploaderModal.vue';
import DragDropFileUploader from '@/components/partials/fileUploader/DragDropFileUploader.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTable from '@/components/partials/AppTable.vue';
import NumberStatusIndicator from './NumberStatusIndicator.vue';

// Http
import { queryNumber } from '@/__new__/services/dno/number/http/numberManagement';

// helpers
import * as Sentry from '@sentry/vue';
import { OWNERSHIP } from '@/__new__/services/dno/number/models/numberManagementDno';
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';

export default {
    name: 'EditorManagementLayout',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppButton,
        AppCountCard,
        DragDropFileUploader,
        FileUploaderModal,
        AppMultiselectV3,
        AppInputV3,
        AppTable,
        NumberStatusIndicator,
    },
    props: {
        entityLabelKey: {
            type: String,
            default: '',
        },
        dragDropEntityLabelKey: {
            type: String,
            default: '',
        },
        utilityTypes: {
            type: Array,
            required: true,
        },
        configFileUploader: {
            type: Object,
            required: true,
        },
        counts: {
            type: Array,
            default: () => [],
        },
        searchEnabled: {
            type: Boolean,
            default: false,
        },
        uploaderEnabled: {
            type: Boolean,
            default: false,
        },
        mvneSettingsEnabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            showWarningModal: false,
            showErrorModal: false,
            uploadInProgress: false,
            triggerUploadFiles: false,
            triggerClearPollers: false,
            isFilesUploadAmount: false,
            failedLinesErrorMessage: [],
            utilities: {
                type: null,
                value: '',
            },
            searchResult: null,
            tableColumnsData: [
                {
                    name: this.$i18n.t('customerCare.subscriberId'),
                    key: 'subscriberId',
                    field: 'subscriberId',
                },
                {
                    name: this.$i18n.t('operations.numberManagement.ownership'),
                    key: 'ownership',
                    field: 'ownership',
                    formatter: this.formatOwnership,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    field: 'state',
                },
                {
                    name: this.$i18n.t('customerCare.iccid'),
                    key: 'iccid',
                    field: 'iccid',
                },
            ],
        };
    },
    computed: {
        isUploadButtonDisabled() {
            return !this.isFilesUploadAmount;
        },
        title() {
            return `${this.$i18n.tc(this.entityLabelKey)} ${this.$i18n.t('operator.management')}`;
        },
        titleUploadSection() {
            return `${this.$i18n.tc(this.entityLabelKey)} ${this.$i18n.t('operator.upload')}`;
        },
        titleUtilitiesSection() {
            return `${this.$i18n.tc(this.entityLabelKey)} ${this.$i18n.t('operator.utilities')}`;
        },
        dragDropLable() {
            return this.$i18n.t('operator.addViaUpload', { entityType: this.$i18n.tc(this.dragDropEntityLabelKey, 2) });
        },
        dragDropHint() {
            return this.$i18n.t('operator.numberManagement.uploadFileHint', {
                entityType: this.$i18n.tc(this.dragDropEntityLabelKey, 2),
            });
        },
        utilitiesSubtext() {
            return this.$i18n.t('operator.getInfoForEntity', { entityType: this.$i18n.tc(this.entityLabelKey) });
        },
        utilityValuePlaceholder() {
            const entityType = this.utilities?.type?.i18n ? this.$i18n.t(this.utilities.type.i18n) : '';
            return this.$i18n.t('operator.addEntity', { entityType });
        },
        acceptType() {
            const operatorConfigValue = getOperatorConfigValue('uploadFileExtensions', []);
            return operatorConfigValue?.length > 0 ? operatorConfigValue.join(',') : '.csv';
        },
    },
    methods: {
        formatOwnership(ownership) {
            switch (ownership) {
                case OWNERSHIP.REGULAR:
                    return this.$i18n.t('operator.numberManagement.regular');
                case OWNERSHIP.PORT_IN:
                    return this.$i18n.t('operator.numberManagement.portIn');
                case OWNERSHIP.PORT_OUT:
                    return this.$i18n.t('operator.numberManagement.portOut');
                default:
                    return this.$i18n.t('generic.unknown');
            }
        },
        async searchUtility() {
            try {
                this.$Progress.start();
                const response = await queryNumber(this.utilities.value);
                const numberData = response.data?.result;
                if (numberData) {
                    this.searchResult = [
                        {
                            subscriberId: numberData.mapping.subscriber_id,
                            ownership: numberData.ownership,
                            state: numberData.state,
                            iccid: numberData.mapping.iccid,
                        },
                    ];
                }
                this.$Progress.finish();
            } catch (err) {
                this.$Progress.finish();
                Sentry.captureException(err);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: this.$i18n.t('generic.fetching'),
                        entityName: this.$i18n.t('generic.number'),
                    }),
                });
            }
        },
        onAdd() {
            if (this.isFilesUploadAmount) {
                // await this.addNumbersFromFiles();
                this.triggerUploadFiles = !this.triggerUploadFiles;
            }
        },
        displayFailedLines(lines) {
            this.failedLinesErrorMessage = [
                this.$i18n.tc('operator.numberManagement.error.failedLinesMessage', lines.length > 1 ? 2 : 1, {
                    n: lines,
                }),
            ];
            this.showErrorModal = true;
        },
        onCancel() {
            if (this.uploadInProgress) {
                this.showWarningModal = true;
            } else {
                this.triggerClearPollers = !this.triggerClearPollers;
                this.$router.go(-1);
            }
        },
        updateUploadFilesStatus(status) {
            this.uploadInProgress = status;
        },
        updateAmountFilesUpload(totalAmount) {
            this.isFilesUploadAmount = !!totalAmount;
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/colors';
@import 'src/assets/scss/palette';
@import 'src/assets/scss/typographyV2';
@import 'src/assets/scss/layout';

.no-vertical-scroll {
    overflow-y: hidden;
}

.number-management-wrapper {
    display: flex;
    flex-direction: column;
}

.number-management {
    padding-block: 2rem 4rem;

    .file-upload-field {
        flex-basis: 584px;
    }
}

section {
    .heading {
        margin: 0;
        margin-bottom: 1rem;
    }

    .sub-heading {
        margin: $spacing-xs 0;
    }

    .content {
        padding: 2rem 0;

        p {
            margin: 0;
            color: $gray90;
        }
    }
}

.inner-layout {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.5rem 0;
}

.count-card {
    width: calc(25% - 0.75rem);
}
</style>
