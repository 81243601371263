
// HTTP
import refundAPI from '@/http/refund';
import { getAppointments } from '@/http/appointments';
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
import {
    callbackExecution,
    getExecution,
    getMappedExecutions,
} from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';
import ossgpfiberHTTP from '@/__new__/services/dno/ossgpfiber/http/ossgpfiber';
import osstmofiberHTTP from '@/__new__/services/dno/osstmofiber/http/osstmofiber';
import consentHTTP from '@/__new__/services/dno/consent/http/consent';

// components
import Breadcrumbs from '@/components/partials/Breadcrumbs.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppDialog from '@/components/partials/AppDialog.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppCustomerHeader from '@/components/partials/AppCustomerHeader.vue';
import AccountEditor from '@/__new__/features/customerCare/account/AccountEditor.vue';
import AppTable from '@/components/partials/AppTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import AccountPaymentHistory from '@/__new__/features/customerCare/account/AccountPaymentHistory.vue';
import GORPaymentHistory from '@/__new__/features/customerCare/account/GORPaymentHistory.vue';
import DataTransfersTable from '@/__new__/features/customerCare/account/DataTransfersTable.vue';
import BillingTransactionHistoryTable from '@/__new__/features/customerCare/account/BillingTransactionHistoryTable.vue';
import SubscriberStateHistory from '@/__new__/features/customerCare/subscriber/SubscriberStateHistory.vue';
import OrderDetails from '@/__new__/features/customerCare/OrderDetails.vue';
import BillingStatements from '@/__new__/features/customerCare/account/BillingStatements.vue';
import AppointmentsSection from '@/__new__/features/customerCare/account/AppointmentsSection.vue';
import NotesSection from '@/__new__/features/customerCare/NotesSection.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import AppointmentNotes from '@/__new__/features/customerCare/account/AppointmentNotes.vue';
import UserAuthenticationBanner from '@/__new__/features/customerCare/UserAuthenticationBanner.vue';
import VoucherHistory from '@/__new__/features/customerCare/VoucherHistory.vue';
import RewardsHistory from '@/__new__/features/customerCare/RewardsHistory.vue';
import OrdersWrapperOMDE from '@/__new__/features/customerCare/account/OrdersWrapperOMDE.vue';
import AppToggleV2 from '@/components/partials/inputs/AppToggleV2.vue';
import Deliveries from '@/__new__/features/customerCare/account/Deliveries.vue';
import BalanceInfoCard from '@/__new__/features/customerCare/BalanceInfoCard.vue';
import SensitiveEditModal from '@/__new__/features/customerCare/SensitiveEditModal.vue';
import TopupForm from '@/__new__/features/customerCare/TopupForm.vue';
import DeductBalanceForm from '@/__new__/features/customerCare/DeductBalanceForm.vue';
import OePlans from '@/__new__/features/orchestrationengine/OePlans.vue';
import SubscriberCancelTerminateForm from '@/__new__/features/customerCare/SubscriberCancelTerminateForm.vue';
import AssociatedRoles from '@/__new__/features/customerCare/AssociatedRoles.vue';
import FiberStatus from '@/__new__/features/customerCare/account/FiberStatus.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import OntStatusChecker from '@/__new__/features/customerCare/account/OntStatusChecker.vue';
import ViasatMonthlyUsage from '@/__new__/features/customerCare/viasat/ViasatMonthlyUsage.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import AccountPromotions from '@/__new__/features/customerCare/account/AccountPromotions.vue';
import AccountSubscribers from '@/__new__/features/customerCare/account/AccountSubscribers.vue';
import OutageHistoryTable from '@/__new__/features/customerCare/account/OutageHistoryTable.vue';
import BalanceOverview from '@/__new__/features/customerCare/BalanceOverview.vue';

// Vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

// helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import AccessRole from '@/__new__/services/dno/user/models/AccessRole';
import Account from '@/__new__/services/dno/user/models/Account';
import ExternalResource from '@/__new__/services/dno/ossgpfiber/models/ExternalResource';
import User from '@/__new__/services/dno/user/models/User';
import Flag, { FLAG_TYPE, FLAG_MAP, FLAG_TC_GLOBAL_ACCEPTED_TEXT } from '@/__new__/services/dno/user/models/Flag';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { cloneDeep, isEmpty } from 'lodash';
import { APPT_TYPE } from '@/__new__/features/customerCare/common/appointmentsHelper';
import { groupOutagesByOutageId } from '@/__new__/features/customerCare/common/outageHelper';
import {
    USER_MANAGER_HIERARCHY,
    handleUserNotFoundError,
    SUBSCRIBER_STATE,
    subscriberStateToText,
    subscriberStateToColor,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import * as Sentry from '@sentry/vue';
import { getBeautifulBoolean, ordinalNumberFormatter } from '@/common/formatting';
import permissionsService, {
    getBillingVersion,
    BILLING_VERSIONS,
    getOperatorConfigValue,
    getAccountConsentFields,
    getAccountDetailFields,
    isUserAllowed,
    isUserInternal,
    isViewEnabled,
} from '@/services/permissions/permissions.service';
import RouteNames from '@/router/routeNames';
import tableColumnType from '@/common/filterTable';
import luaErrors from '@/common/luaErrors';
import { onlyFirstLetterUppercase, checkObjectPath } from '@/common/utils';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import OePlansMixin from '@/__new__/features/customerCare/mixins/OePlansMixin.vue';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import { WALLET_TYPES } from '@/__new__/features/customerCare/common/ordersHelper';
import { MODAL_CONTENT } from '@/__new__/features/customerCare/common/userInfoHelper';
import { minValue, decimal, required } from 'vuelidate/lib/validators';
import { NAME_OF_TRANSACTION_HISTORY_TABLE } from '@/__new__/features/customerCare/common/transactionHistoryHelper';
import { ICON_TYPES } from '@/common/iconHelper';
import { ORDER_ENTITY_STATES } from '@/__new__/features/customerCare/common/orderStateHelper';
import { ACCOUNT_DETAIL_FIELD } from '@/__new__/features/customerCare/common/detailFieldsHelper';
import LocalStorageHelper from '@/common/LocalStorageHelper';
import { LABEL_COLOR } from '@/common/labelsHelper';
import {
    EXECUTION_STATES,
    EXECUTION_STATE_TO_CANCELATION_MESSAGE,
} from '@/__new__/features/orchestrationengine/common/executionStatusIndicatorHelper';
import { mapBuckets, filterActive } from '@/__new__/features/customerCare/common/balanceHelper';
import { EntityStateMapping } from '@/common/entityStateMapper';
import { TARGET_TYPE } from '@/__new__/services/dno/user/models/targetTuple';
import {
    CONSENT_CONTEXT_TYPE,
    Consent,
    getTermsConsentTypeI18nKey,
} from '@/__new__/features/customerCare/common/consentsHelper';

export default {
    name: 'Account',
    components: {
        SearchBox,
        AppHeader,
        Breadcrumbs,
        AppButton,
        AppInputV3,
        AppCustomerHeader,
        AccountEditor,
        AppTable,
        AppDialog,
        AccountPaymentHistory,
        GORPaymentHistory,
        DataTransfersTable,
        BillingTransactionHistoryTable,
        SubscriberStateHistory,
        OrderDetails,
        BillingStatements,
        AppointmentsSection,
        NotesSection,
        TableFiltersRenderless,
        TableFiltersTags,
        FilterTable,
        IconButton,
        AppointmentNotes,
        RewardsHistory,
        OrdersWrapperOMDE,
        AppToggleV2,
        Deliveries,
        BalanceInfoCard,
        SensitiveEditModal,
        TopupForm,
        DeductBalanceForm,
        OePlans,
        SubscriberCancelTerminateForm,
        AssociatedRoles,
        ResponseModalButton,
        OntStatusChecker,
        UserAuthenticationBanner,
        VoucherHistory,
        ViasatMonthlyUsage,
        AppLabel,
        AccountPromotions,
        AccountSubscribers,
        OutageHistoryTable,
        BalanceOverview,
        FiberStatus,
    },

    mixins: [FilterTableMixin, supportButtonMixin, OePlansMixin],

    data() {
        return {
            apiPollers: {},
            PLURALIZATION,
            showBlackoutDialog: false,
            showBlackoutDialogCancel: false,
            ICON_TYPES,
            BUTTON_TYPES,
            permissionsService,
            getBillingVersion,
            BILLING_VERSIONS,
            // TODO: delete after hooking up real data
            // isDataLoading: true,
            account: new Account({}),
            transactionData: null,
            refundAmount: null,
            detailSectionValues: [],
            accountInfo: {},
            accountApiResponse: null,
            // accountData: [{ title: 'Details', value: this.detailSectionValues }],
            accountEntity: { id: 'test' },
            isEditorVisible: false,
            modalVisible: false,
            subscribers: [],
            subscriberInfos: [],
            rolesSearchQuery: '',
            roles: [],
            rolesApiResponse: null,
            rolesNew: [],
            offers: [],
            last4Digits: '',
            consents: [] as Consent[],
            amountForRefund: 0,
            refundAllIsChecked: false,
            nameOfTableForRefund: '',
            NAME_OF_TRANSACTION_HISTORY_TABLE,
            transactionHistoriesData: {},
            rolesTableColumnsData: [
                {
                    name: this.$i18n.t('customerCare.roleName'),
                    key: 'roleName',
                    field: 'roleName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userId'),
                    key: 'userIdLink',
                    field: 'userIdLink',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.email'),
                    key: 'emailAcc',
                    field: 'emailAcc',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ],
            isLFAdmin: permissionsService.isUserLotusFlareAdmin() && isUserInternal(),
            isEditEnabled:
                permissionsService.userManagementEditEnabled() &&
                isUserAllowed(
                    'UMAccountEditAccountUpdateFields',
                    'UMAccountEditAccountCancelOrder',
                    'UMAccountEditAccountTerminateUndoTermination',
                    'UMAccountEditAccountCancelUndoCancel',
                    'UMAccountEditAccountCancelSuspendResume',
                    'UMAccountEditAccountToggleFlag',
                ),
            isAgentNotesEnabled:
                permissionsService.userManagementCRMEnabled() && isUserAllowed('UMAccountAgentNotesRead'),
            isPaymentTransactionHistoryEnabled:
                permissionsService.userManagementPaymentTransactionHistoryEnabled() &&
                isUserAllowed('UMAccountPaymentTransactionHistoryRead'),
            isDataTransferEnabled:
                permissionsService.dataTransferEnabled() && isUserAllowed('UMAccountDataTransferRead'),
            isBillingTransactionHistoryEnabled:
                permissionsService.userManagementBillingTransactionHistoryEnabled() &&
                isUserAllowed('UMAccountBillingTransactionHistoryRead'),
            isRefundModalChargeBasedEnabled:
                permissionsService.userManagementRefundModalChargeBasedEnabled() &&
                isViewEnabled('UMAccountBillingTransactionHistoryRefundChargeBased'),
            isBillingStatementsSectionEnabled:
                permissionsService.userManagementBillingStatementsEnabled() &&
                isUserAllowed('UMAccountBillingStatementsRead'),
            isCancelationEnabled: isUserAllowed('UMAccountEditAccountCancelUndoCancel'),
            isCancelSubscriptionEnabled:
                permissionsService.userManagementCancelSubscriptionEnabled() &&
                isUserAllowed('UMAccountEditAccountCancelUndoCancel'),
            isDeleteOnCancelEnabled:
                permissionsService.userManagementCancelSubscriptionAndDeleteAccountEnabled() &&
                isViewEnabled('UMAccountDeleteOnCancel') &&
                isUserAllowed('UMAccountEditAccountCancelUndoCancel'),
            isTerminationEnabled:
                permissionsService.userManagementAccountTerminationEnabled() &&
                isUserAllowed('UMAccountEditAccountTerminateUndoTermination'),
            isOrderDetailsEnabled:
                permissionsService.accountOrdersEnabled() && isUserAllowed('UMAccountOrderDetailsRead'),
            isOutageHistoryEnabled:
                permissionsService.userManagementOutageHistoryEnabled() && isUserAllowed('UMAccountOutageHistoryRead'),
            isVoucherFeaturesAvailable:
                permissionsService.accountVoucherEnabled() && isUserAllowed('UMAccountVoucherHistoryRead'),
            userManagerDeliveryServiceEnabled:
                permissionsService.userManagerDeliveryServiceEnabled() && isUserAllowed('UMAccountDeliveriesRead'),
            isOrderDetailsDevEdge:
                permissionsService.userManagementOMDevEdgeServiceEnabled() &&
                isViewEnabled('UMAccountOrderDetailsDevEdge'),
            accountWalletTopUpEnabled:
                permissionsService.accountWalletTopUpEnabled() && isViewEnabled('UMAccountWalletTile'),
            rewardsHistoryAccountDetailsEnabled:
                permissionsService.rewardsHistoryAccountDetailsEnabled() &&
                isUserAllowed('UMAccountRewardsHistoryRead'),
            userAuthenticationEnabled:
                permissionsService.userManagementUserAuthenticationEnabled() &&
                isViewEnabled('UMUserUserAuthentication'),
            balanceOverviewEnabled:
                permissionsService.userManagementAccountBalanceOverviewEnabled() &&
                isUserAllowed('UMAccountBalanceOverviewRead'),
            isNewCustomerCareEnabled: getOperatorConfigValue('customerCareOptIn', false),
            isFiberOrderStatusEnable: isViewEnabled('FiberOrderStatus'),
            USER_MANAGER_HIERARCHY,
            appointmentsInfo: {},
            appointmentsInfoApiResponse: null,
            WALLET_TYPES,
            balances: null,
            selectedWalletType: WALLET_TYPES.PRIMARY,
            balancesModalVisible: false,
            editingContent: 0,
            MODAL_CONTENT,
            referralCode: '',
            sensitiveEditing: false, // proceed to updating only after alert confirmation
            suspendedServices: {},
            suspendModalVisible: false,
            externalResource: new ExternalResource(),
            viasatClientStorageData: {
                offer: {},
                addons: [],
            },
            LABEL_COLOR,
            user: new User({}),
            outageHistory: [],
            outageHistoryApiResponse: {},
            activateServiceLoading: false,
            balanceOverview: null,
            balanceOverviewApiResponse: null,
            fiberAndQueuedOders: [],
        };
    },
    computed: {
        ...mapGetters('userManagementUser', ['getUserVerificationStatusById']),
        ...mapGetters('userManagementAccount', [
            'getAccountTransactionsById',
            'getAccountTransactionsApiResponseById',
            'getTransactionsHistoriesById',
            'getTransactionsHistoriesApiResponseById',
            'getStateHistoryByIdAscending',
            'getPaymentMethodsById',
        ]),
        ...mapGetters('userManagementSubscriber', ['getSubscriberInfoById']),
        ...mapGetters('userManagementUser', {
            // viasat demo only
            getUserInfoById: 'getUserInfoById',
        }),
        ...mapGetters('productcatalog', [Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED]),
        ...mapGetters('orders', {
            getOrders: Getters.GET_ORDERS,
            getPrimaryOrders: Getters.GET_PRIMARY_ORDERS,
            ordersApiResponse: Getters.GET_ORDERS_API_RESPONSE,
            deliveries: Getters.GET_DELIVERIES,
            deliveriesApiResponse: Getters.GET_DELIVERIES_API_RESPONSE,
        }),
        ...mapGetters('charging', [Getters.GET_CHARGING_SPECIFICATIONS]),
        isAccountIdUrlParamPresent() {
            return !!this.$route.params.id;
        },
        orders() {
            return getBillingVersion() === BILLING_VERSIONS.v4 ? this.getOrders : this.getPrimaryOrders;
        },
        isChangePlanEnabled() {
            return permissionsService.changePlanEnabled() && isUserAllowed('UMAccountOrderDetailsChangeOrder');
        },
        accountTransactionHistory() {
            return this.$route.params.id && this.isPaymentTransactionHistoryEnabled
                ? this.getAccountTransactionsById(this.$route.params.id)
                : [];
        },
        accountTransactionHistoryApiResponse() {
            return this.$route.params.id && this.isPaymentTransactionHistoryEnabled
                ? this.getAccountTransactionsApiResponseById(this.$route.params.id)
                : {};
        },
        accountTransactionHistoryEnhanced() {
            return this.accountTransactionHistory.map(transaction => ({
                ...transaction,
                externalPaymentId: this.billingTransactionHistoryTableData.find(
                    billingTransaction =>
                        billingTransaction.paymentResponse?.internalPaymentId === transaction.transaction_id,
                )?.paymentResponse?.externalPaymentId,
            }));
        },
        paymentMethods() {
            return this.$route.params.id ? this.getPaymentMethodsById(this.$route.params.id) : [];
        },
        isTcConcentVisible() {
            return this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.TC_ACCEPTANCE_TIME);
        },
        billingTransactionHistoryTableData() {
            return this.$route.params.id && this.isBillingTransactionHistoryEnabled
                ? this.getTransactionsHistoriesById(this.$route.params.id)
                : [];
        },
        billingTransactionHistoryApiResponse() {
            return this.$route.params.id && this.isBillingTransactionHistoryEnabled
                ? this.getTransactionsHistoriesApiResponseById(this.$route.params.id)
                : {};
        },
        isUserVerified() {
            return this.account.userId ? this.getUserVerificationStatusById(this.account.userId) : false;
        },
        isEditAllowed() {
            return !this.userAuthenticationEnabled || this.isUserVerified;
        },
        refundAmountLabel() {
            return this.isRefundModalChargeBasedEnabled
                ? this.$i18n.t('customerCare.transactionHistory.refundPartialAmount')
                : this.$i18n.t('customerCare.transactionHistory.refundAmount');
        },
        errorMessageForRefundAmount() {
            return !this.refundAmount
                ? this.$i18n.t('alertMessage.userManagement.alertMessages.refundAmountIsRequiredField')
                : this.$i18n.t('alertMessage.userManagement.alertMessages.refundAmountValidation');
        },
        getStylesForRefundTable() {
            const currentWidth = 100 / (this.columnsDataRefundModal.length + 1);

            return `width: ${currentWidth}%`;
        },
        columnsDataRefundModal() {
            return [
                {
                    name: this.$i18n.tc('productCatalog.entities.service', PLURALIZATION.SINGULAR),
                    key: 'chargedItemName',
                    field: 'chargedItemName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.totalAmount'),
                    key: 'chargedItemPrice',
                    formatter: val => this.$localeLibrary.getFormattedAmount(val),
                    field: 'chargedItemPrice',
                    filterType: tableColumnType.NUMBER,
                },
            ];
        },
        transactionHistoriesFormatted() {
            const dataCharged = [];
            if (this.transactionData?.chargesData) {
                this.transactionData.chargesData.forEach((tr, index) => {
                    dataCharged[index] = {
                        ...dataCharged[index],
                        chargedItemName: tr.serviceDescription || tr.offerName,
                    };
                });
            }

            if (this.transactionData?.itemPrices) {
                this.transactionData.itemPrices.forEach((tr, index) => {
                    dataCharged[index] = { ...dataCharged[index], chargedItemPrice: tr.grossAmount };
                });
            }

            dataCharged.forEach((data, index) => {
                dataCharged[index] = {
                    ...dataCharged[index],
                    isChecked: false,
                };
            });
            return dataCharged;
        },
        stateHistory() {
            return this.$route.params.id ? this.getStateHistoryByIdAscending(this.$route.params.id) : [];
        },
        stateHistoryFormatted() {
            return this.stateHistory.map(stateData => ({
                state: stateData.state,
                date: this.$localeLibrary.getFormattedDate(stateData.change_time),
                time: this.$localeLibrary.getFormattedTime(stateData.change_time),
            }));
        },
        isStateHistoryNotEmpty() {
            return this.stateHistoryFormatted && this.stateHistoryFormatted.length;
        },
        breadcrumbList() {
            const breadcrumbList = [
                {
                    name: this.$i18n.t('customerCare.breadcrumbs.search'),
                    link: '/customer-care/user-management',
                },
            ];

            if (this.account.organizationId) {
                breadcrumbList.push({
                    name: this.$i18n.t('customerCare.breadcrumbs.organization'),
                    link: `/customer-care/organization/${this.account.organizationId}`,
                });
            }

            if (this.account.userId) {
                breadcrumbList.push({
                    name: this.$i18n.t('customerCare.breadcrumbs.userProfile'),
                    link: `/customer-care/user/${this.account.userId}`,
                });
            }

            breadcrumbList.push({ name: this.$i18n.t('customerCare.breadcrumbs.account') });

            return breadcrumbList;
        },
        filteredRoles() {
            return this.filteredEntitiesMixin(this.roles);
        },
        doesOrdersContainPurchases() {
            return getBillingVersion() === BILLING_VERSIONS.v4
                ? false
                : Object.values(this.orders).find(o => o.purchases != null && !!Object.values(o.purchases).length);
        },
        allowGetAppointments() {
            return (
                permissionsService.accountAppointmentsEnabled() &&
                (isUserAllowed('UMAccountAppointmentRead') || isUserAllowed('UMAccountAppointmentWrite'))
            );
        },
        allowInstallerNotes() {
            return isViewEnabled('UMAccountInstallerNotes');
        },
        allowBenefits() {
            return isViewEnabled('UMAccountBenefits');
        },
        accountDetailFields() {
            return getAccountDetailFields();
        },
        accountConsentFields() {
            return getAccountConsentFields();
        },
        hasAccountConsentFields() {
            return !!this.accountConsentFields?.length;
        },
        accountData() {
            const accountData = [
                {
                    title: this.$i18n.t('customerCare.basicInfo'),
                    value: this.basicInfo,
                    additionalLabels: this.basicInfoStatusIndicators,
                    initiallyExpanded: true,
                },
                {
                    title: this.$i18n.t('generic.address'),
                    value: this.addressInfo,
                },
            ];

            if (this.networkInfo.length) {
                accountData.push({
                    title: this.$i18n.t('generic.network'),
                    value: this.networkInfo,
                });
            }

            if (this.termsAndConditionsInfo.length) {
                accountData.push({
                    title: this.$i18n.t('generic.termsAndConditions'),
                    value: this.termsAndConditionsInfo,
                });
            }
            if (this.flagsInfo.length) {
                accountData.push({
                    title: this.$i18n.t('customerCare.account.flags'),
                    value: this.flagsInfo,
                    additionalLabels: this.flagsInfoStatusIndicators,
                });
            }

            if (this.activeOutage) {
                accountData.push({
                    title: this.$i18n.t('customerCare.account.outageInfo'),
                    value: this.outageInfo,
                    initiallyExpanded: true,
                });
            }

            return accountData;
        },
        basicInfo() {
            const data = [];

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.ACCOUNT_ID)) {
                data.push({
                    name: this.$i18n.t('customerCare.accountId'),
                    value: this.account.accountId,
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.ACCOUNT_NAME)) {
                data.push({
                    name: this.$i18n.t('customerCare.accountName'),
                    value: this.account.accountName,
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.BILLING_DAY)) {
                const billingDay = ordinalNumberFormatter(this.account.billingDay);
                data.push({
                    name: this.$i18n.t('customerCare.account.billingDay'),
                    value: billingDay ? `${billingDay} ${this.$i18n.t('customerCare.account.everyMonth')}` : '',
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.LAST_4_DIGITS)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.last4Digits'),
                    value: this.last4Digits ? this.last4Digits : '',
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.EXPIRY_TIME)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.expiryTime'),
                    value: this.externalResource?.expirationDate
                        ? this.$localeLibrary.getFormattedDate(this.externalResource.expirationDate)
                        : '',
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.REFERRAL_CODE)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.referralCode'),
                    value: this.referralCode ?? '',
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.CREATION_DATE)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.creationDate'),
                    value: this.account.creationDate,
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.CARDINALITY)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.cardinality'),
                    value: this.account.cardinality,
                });
            }

            return data;
        },
        basicInfoStatusIndicators() {
            const additionalLabels = [];

            if (Number.isInteger(this.account.state)) {
                additionalLabels.push({
                    title: subscriberStateToText(this.account.state),
                    color: subscriberStateToColor(this.account.state),
                });
            }

            return additionalLabels;
        },
        addressInfo() {
            const data = [];
            /**
             * @param {string} addressData
             * @param {string} addressLine
             */
            const addDataToAddressInfoValue = (addressData, addressLine) => {
                if (!addressData) {
                    return;
                }
                if (addressLine.value) {
                    addressLine.value += ', ';
                }
                addressLine.value += addressData;
            };

            if (!this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.ADDRESS)) {
                return data;
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.BUSSINES_ADDRESS_TYPE) && this.account.businessAccount) {
                data.push({
                    name: this.$i18n.t('customerCare.account.b/r'),
                    value: this.account.businessAccount,
                    explanationText: this.$i18n.t('customerCare.account.b/rInfo'),
                });
            }

            this.account.addresses.forEach(address => {
                const addressInfo = {
                    name: address.stringAddressType,
                    value: [],
                };
                const addressLines = [{ value: '' }, { value: '' }];

                // 1st line
                addDataToAddressInfoValue(address.address || address.street, addressLines[0]);
                addDataToAddressInfoValue(address.houseNumber, addressLines[0]);
                if (address.unitNumber || address.unitNo) {
                    addDataToAddressInfoValue(
                        `${this.$i18n.t('generic.unit')} ${address.unitNumber || address.unitNo}`,
                        addressLines[0],
                    );
                }
                if (address.floor) {
                    addDataToAddressInfoValue(`${this.$i18n.t('generic.floor')} ${address.floor}`, addressLines[0]);
                }
                addDataToAddressInfoValue(address.buildingName, addressLines[0]);
                addDataToAddressInfoValue(address.addressLine2, addressLines[0]);
                // 2nd line
                addDataToAddressInfoValue(address.city || address.village, addressLines[1]);
                addDataToAddressInfoValue(address.barangay, addressLines[1]);
                addDataToAddressInfoValue(address.state, addressLines[1]);
                addDataToAddressInfoValue(address.province, addressLines[1]);
                addDataToAddressInfoValue(address.zip || address.zipCode, addressLines[1]);
                addDataToAddressInfoValue(address.post, addressLines[1]);
                addDataToAddressInfoValue(address.postCode, addressLines[1]);

                addressLines.forEach(addressLine => {
                    if (addressLine.value) {
                        addressInfo.value.push(addressLine.value);
                    }
                });

                data.push(addressInfo);

                if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.TAX_AREA_ID) && address.taxAreaId) {
                    data.push({
                        name: this.$i18n.t('customerCare.account.taxAreaID'),
                        value: address.taxAreaId,
                    });
                }
            });

            return data;
        },
        networkInfo() {
            const data = [];

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.DOCUMENT)) {
                data.push({
                    name: this.$i18n.t('customerCare.document'),
                    value: [
                        {
                            text: this.account.documentType,
                            link: this.account.documentSrc,
                        },
                    ],
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.DOCUMENT_NUMBER)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.documentNumber'),
                    value: this.account.documentNumber,
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.SERVICE_ID)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.serviceId'),
                    value: this.account?.pilotServiceId || '',
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.ROUTER_IDS)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.routerIds'),
                    value: this.account?.routerIds
                        ? this.account.routerIds.reduce((acc, curr) => `${acc} ${curr}`, '')
                        : '',
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.PROVIDER)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.provider'),
                    value: this.account?.providerId ? onlyFirstLetterUppercase(this.account?.providerId) : '',
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.ONT_ID)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.ontId'),
                    value: this.account?.ontId || '',
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.ONT_STATUS)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.ontStatus'),
                    key: 'ontStatus',
                });
            }

            if (this.isExternalDataEnabled) {
                if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.EXTERNAL_SERVICE_ID)) {
                    data.push({
                        name: this.$i18n.t('customerCare.account.serviceId'),
                        value: this.externalResource.serviceId,
                    });
                }
                if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.ORDER_ID)) {
                    data.push({
                        name: this.$i18n.t('customerCare.orderHistory.orderId'),
                        value: this.externalResource.orderId,
                    });
                }

                if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.ORDER_ACTION_ID)) {
                    data.push({
                        name: this.$i18n.t('customerCare.account.orderActionId'),
                        value: this.externalResource.orderActionId,
                    });
                }

                if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.RESERVATION_ID)) {
                    data.push({
                        name: this.$i18n.t('customerCare.account.reservationId'),
                        value: this.externalResource.reservationId,
                    });
                }

                if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.RESERVED_DP_NAP_ID)) {
                    data.push({
                        name: this.$i18n.t('customerCare.account.reservedDpNapNodeId'),
                        value: this.externalResource.reservedDpNapNodeId,
                    });
                }
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.CVLAN)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.cvlan'),
                    value: this.account?.cvlan,
                });
            }

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.SVLAN)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.svlan'),
                    value: this.account?.svlan,
                });
            }

            return data;
        },
        outageInfo() {
            return [
                {
                    name: this.$i18n.t('generic.status'),
                    value: this.activeOutage.status,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.outageReason'),
                    value: this.activeOutage.reason || this.$i18n.t('generic.N/A'),
                },
                {
                    name: this.$i18n.t('generic.description'),
                    value: this.activeOutage.description,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.estimatedResolution'),
                    value: this.activeOutage.estimatedResolution ? `${this.activeOutage.estimatedResolution}h` : '',
                },
                {
                    name: this.$i18n.t('generic.startTime'),
                    value: this.$localeLibrary.getFormattedDateAndTime(this.activeOutage.startTime),
                },
                {
                    name: this.$i18n.t('generic.endTime'),
                    value: this.$localeLibrary.getFormattedDateAndTime(this.activeOutage.endTime),
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.outageId'),
                    value: this.activeOutage.id,
                },
            ];
        },
        termsAndConditionsInfo() {
            if (this.hasAccountConsentFields) {
                return this.accountConsentFields.map(consentType => {
                    const foundConsent = this.consents.find(consent => consent.context_type === consentType);
                    return {
                        name: this.$i18n.t(getTermsConsentTypeI18nKey(consentType)),
                        value: foundConsent?.is_set
                            ? this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(foundConsent.epoch)
                            : '',
                    };
                });
            }
            const data = [];

            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.AUTOPAY_TC)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.autopayTC'),
                    value: this.account.tcGlobalAccepted,
                });
            }

            // Checking if the Autopay T&C is accepted so in relation to that Autopay T&C timestamp is displayed
            if (
                this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.AUTOPAY_TC_TIMESTAMP) &&
                this.account.tcGlobalAccepted === FLAG_TC_GLOBAL_ACCEPTED_TEXT.ACCEPTED
            ) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.autopayTcTimestamp'),
                    value: this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(
                        this.account.tcAcceptanceTimestamp,
                    ),
                });
            }

            if (this.isTcConcentVisible) {
                const tcConsent = this.consents.find(
                    consent => consent.context_type === CONSENT_CONTEXT_TYPE.PLATFORM_TERMS_AND_CONDITIONS,
                );
                data.push({
                    name: this.$i18n.t('customerCare.account.ts&cs'),
                    value: tcConsent?.is_set
                        ? this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(tcConsent.epoch)
                        : '',
                });
            }
            return data;
        },
        flagsInfo() {
            const data = [];

            if (isEmpty(this.account.flags) || !this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.FLAGS)) {
                return data;
            }

            Object.values(this.account.flags)
                .filter(flag => {
                    // filter out the taxExemption flag if it's not enabled
                    if (
                        flag.field === FLAG_TYPE.TAX_EXEMPTION &&
                        (!this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.FLAG_TAX_EXEMPTION) ||
                            flag.originalValue === undefined)
                    ) {
                        return false;
                    }
                    if (flag.field === FLAG_TYPE.IS_TEST) {
                        return false;
                    }
                    return true;
                })
                .forEach(flag => {
                    data.push({
                        name: flag.name,
                        value: flag.detailedValue,
                    });
                });

            return data;
        },
        flagsInfoStatusIndicators() {
            const additionalLabels = [];
            const hasSuspendedServices = Object.values(this.account.flags).some(Flag.isFlagSuspendingSomeServices);

            if (hasSuspendedServices) {
                additionalLabels.push({
                    title: this.isSetForCancelationAtEndOfBillCycle
                        ? this.$i18n.t('customerCare.cancelTerminate.servicesSetForCancelation')
                        : this.$i18n.t('customerCare.suspendServices.someServicesSuspended'),
                    color: LABEL_COLOR.yellow,
                });
            }

            return additionalLabels;
        },
        walletInfo() {
            if (checkObjectPath(this.balances, 'wallets.primary')) {
                const { active_epoch: activeEpoch, grace_epoch: graceEpoch, amount } = this.balances.wallets.primary;

                return {
                    activeEpoch: activeEpoch ? this.$localeLibrary.getFormattedDate(activeEpoch) : '',
                    graceEpoch: graceEpoch ? this.$localeLibrary.getFormattedDate(graceEpoch) : '',
                    amountWithCurrency: this.$localeLibrary.getFormattedAmount(amount),
                    amount: Number(amount),
                };
            }
            return {
                activeEpoch: '',
                graceEpoch: '',
                amountWithCurrency: '',
                amount: 0,
            };
        },
        isOePlanTableVisible() {
            return (
                permissionsService.isUserLotusFlareAdmin() &&
                permissionsService.accountOEPlansEnabled() &&
                isUserAllowed('UMAccountOEPlansTableRead')
            );
        },
        isExternalDataEnabled() {
            return (
                permissionsService.userManagementAccountExternalDataEnabled() &&
                [
                    ACCOUNT_DETAIL_FIELD.EXTERNAL_SERVICE_ID,
                    ACCOUNT_DETAIL_FIELD.ORDER_ID,
                    ACCOUNT_DETAIL_FIELD.ORDER_ACTION_ID,
                    ACCOUNT_DETAIL_FIELD.RESERVATION_ID,
                    ACCOUNT_DETAIL_FIELD.RESERVED_DP_NAP_ID,
                ].some(this.isDetailFieldEnabled)
            );
        },
        pendingOrder() {
            return Object.values(this.orders).find(o => o.state === ORDER_ENTITY_STATES.PENDING);
        },
        pendingActivationOrder() {
            return Object.values(this.orders).find(o => o.state === ORDER_ENTITY_STATES.PENDING_ACTIVATION);
        },
        pendingOrderMvneExecutionId() {
            return this.oeMappingsList.find(e => e.planId === 'mvne_provisioning_plan')?.executionId;
        },
        activeOrder() {
            return Object.values(this.orders).find(o => o.state === ORDER_ENTITY_STATES.ACTIVE);
        },
        isViasatDemo() {
            return getOperatorConfigValue('isViasatDemo');
        },
        viasatOrderDetails() {
            if (Object.values(this.orders).length) {
                const orderId = Object.keys(this.orders).find(
                    key =>
                        this.orders[key].state === ORDER_ENTITY_STATES.PENDING ||
                        this.orders[key].state === ORDER_ENTITY_STATES.PENDING_ACTIVATION ||
                        this.orders[key].state === ORDER_ENTITY_STATES.ACTIVE,
                );
                return this.orders[orderId];
            }
            return null;
        },
        viasatCurrentPlan() {
            const orderEntities = Object.values(this.viasatOrderDetails?.entities?.offer ?? {});
            return orderEntities.find(entity => entity.contains_base_plan === true);
        },
        viasatCurrentAddons() {
            const orderEntities = Object.values(this.viasatOrderDetails?.entities?.offer ?? {});
            return orderEntities.filter(entity => entity.contains_base_plan !== true);
        },
        accountDataViasat() {
            // const billingDay = ordinalNumberFormatter(this.account.billingDay);
            const { first_name: firstName, last_name: lastName } = this.accountInfo?.user_owner_info ?? {};
            const backupName = [firstName, lastName]
                .filter(n => n)
                .join(' ')
                .trim();
            const accountData = [
                {
                    title: this.$i18n.t('customerCare.customerInfo'),
                    value: [
                        {
                            name: this.$i18n.t('account.fullName'),
                            value: this.account.fullName || backupName,
                        },
                        {
                            name: this.$i18n.t('customerCare.customerId'),
                            value: this.account.accountId,
                        },
                        {
                            name: this.$i18n.t('generic.email'),
                            value: this.roles[0]?.emailAcc || '',
                        },
                        {
                            name: this.$i18n.t('generic.phone'),
                            value: this.account.phone || this.user.msisdn,
                        },
                        {
                            name: this.$i18n.t('generic.address'),
                            value: this.addressInfo[0]?.value || '',
                        },
                        // {
                        //     name: this.$i18n.t('customerCare.account.billingDay'),
                        //     value: billingDay
                        //         ? `${billingDay} ${this.$i18n.t('customerCare.account.everyMonth')}`
                        //         : '',
                        // },
                        {
                            name: this.$i18n.t('customerCare.account.last4Digits'),
                            value: this.last4Digits ? this.last4Digits : '',
                        },
                        {
                            name: 'NPS score',
                            value: '9/10 very happy',
                        },
                        {
                            name: 'LTV',
                            value: 'high value',
                        },
                        {
                            name: 'Customer since',
                            value: this.accountInfo?.created
                                ? new Date(this.accountInfo.created * 1000).getFullYear().toString()
                                : '',
                        },
                    ],
                    initiallyExpanded: true,
                },
            ];

            accountData.push({
                title: this.$i18n.t('promotions.offers'),
                initiallyExpanded: true,
                value: [
                    {
                        name: 'Plan',
                        value: this.viasatCurrentPlan?.entity_name ?? '',
                    },
                    {
                        name: 'Price',
                        value: this.viasatOrderDetails?.totalAmountFormatted ?? '',
                    },
                    {
                        name: 'Purchase date',
                        value: this.viasatOrderDetails?.createTimestamp
                            ? this.$localeLibrary.getFormattedDateAndTime(this.viasatOrderDetails.createTimestamp)
                            : '',
                    },
                    {
                        name: 'Add-ons',
                        value: this.viasatCurrentAddons.map(addon => addon.entity_name).join('\n'),
                    },
                ],
            });

            if (this.isViasatOrderActive) {
                accountData.push({
                    title: 'Network Experience',
                    initiallyExpanded: true,
                    value: [
                        {
                            name: 'Network status',
                            key: 'goodLabel',
                        },
                        {
                            name: 'Equipment status',
                            key: 'goodLabel',
                        },
                        {
                            key: 'diagnosticTest',
                            isKeyHidden: true,
                        },
                        {
                            key: 'rebootModem',
                            isKeyHidden: true,
                        },
                    ],
                });
            }

            accountData.push({
                title: 'Shopping Cart',
                initiallyExpanded: true,
                value: [
                    {
                        name: 'Plan',
                        value: this.getOfferNameById(this.viasatClientStorageData.offer?.id),
                    },
                    {
                        name: 'Add-ons',
                        value: this.viasatClientStorageData.addons.map(this.getOfferNameById).join('\n').trim(),
                    },
                    {
                        key: 'resumePurchase',
                        isKeyHidden: true,
                    },
                ],
            });

            return accountData;
        },
        hasCanceledServices() {
            return this.account.flags?.[FLAG_TYPE.CANCEL_REQUESTED]?.originalValue === FLAG_MAP.TRUE;
        },
        isSetForCancelationAtEndOfBillCycle() {
            return this.hasCanceledServices && this.account.state === SUBSCRIBER_STATE.ACTIVE;
        },
        isActivateServiceEnabled() {
            return (
                isUserAllowed('UMAccountActivateService') &&
                permissionsService.userManagerActivateServiceEnabled() &&
                this.account.state !== SUBSCRIBER_STATE.ACTIVE &&
                ((this.pendingOrder && this.pendingOrderMvneExecutionId) || this.pendingActivationOrder)
            );
        },
        isViasatOrderActive() {
            return this.isViasatDemo && this.activeOrder;
        },
        isViasatDemoLoading() {
            return this.isViasatDemo && Object.values(this.orders).length === 0;
        },
        appointmentsInfoViasatFiltered() {
            const appointmentsInfoCopy = cloneDeep(this.appointmentsInfo);
            const appointments = appointmentsInfoCopy?.data?.appointments;
            // hide installation appointments for Viasat while orders are loading or when service is active
            if ((this.isViasatDemoLoading || this.isViasatOrderActive) && appointments?.length) {
                appointmentsInfoCopy.data.appointments = appointments.filter(
                    appointment => appointment.activity_type !== APPT_TYPE.INSTALLATION,
                );
            }
            return appointmentsInfoCopy;
        },
        userInfo() {
            return this.account?.userId ? this.getUserInfoById(this.account.userId) : {};
        },
        viasatResumePurchaseUrl() {
            if (!this.isViasatDemo || !this.viasatClientStorageData.offer?.id) return null;
            return this.isViasatOrderActive
                ? getOperatorConfigValue('viasatResumePurchaseUrlActive')
                : getOperatorConfigValue('viasatResumePurchaseUrlPending');
        },
        activeOutage() {
            return this.outageHistory.find(entry => entry.isActive);
        },
        getChargingSpecificationsApiPayload() {
            if (!this[Getters.GET_CHARGING_SPECIFICATIONS]) return [];
            return this[Getters.GET_CHARGING_SPECIFICATIONS].filter(cs => cs.state !== EntityStateMapping.DELETED);
        },
        isBalancesDataLoaded() {
            return Boolean(this.getChargingSpecifications.length && this.balanceOverview);
        },
        mappedBalances() {
            if (this.isBalancesDataLoaded) {
                return mapBuckets(this.balanceOverview?.buckets, this.getChargingSpecifications);
            }
            return {};
        },
        activeMappedBalances() {
            if (!isEmpty(this.mappedBalances)) {
                return filterActive(this.mappedBalances);
            }
            return {};
        },
        isGorPaymentPaymentHistoryEnabled() {
            return isViewEnabled('GORPaymentStatus') && this?.userInfo?.properties?.is_gor_user;
        },
    },
    watch: {
        transactionHistoriesFormatted: {
            handler() {
                // make a copy of transactionHistoriesFormatted since it is computed property
                // and we need to manipulate with value when user click refund button
                this.transactionHistoriesData = this.transactionHistoriesFormatted;
            },
            deep: true,
            imeediate: true,
        },
        // get executions for the viasat pending order
        pendingOrder: {
            async handler() {
                if (this.pendingOrder?.orderId) {
                    await this.fetchExecutions({ mapper_ids: [this.pendingOrder.orderId] });
                }
            },
            deep: true,
        },
    },
    async created() {
        if (!isEmpty(this.accountInfo)) {
            this.setAccount();
        }
        const accountId = this.$route.params.id;
        const promises = [];

        if (!accountId) {
            await this.$router.push({
                name: RouteNames.CUSTOMER_CARE_USER_MANAGEMENT,
                params: { companyId: this.$route.params.companyId },
            });
            return;
        }

        promises.push(
            this.fetchAppointments(),
            this.fetchExternalData(),
            this.fetchOrderData(),
            this.fetchOutageData(),
            this.fetchPaymentMethods(),
            this.fetchPurchasesRelatedData(),
            this.fetchReferralCode(),
            this.fetchRolesData(),
            this.fetchStateHistory(),
            this.fetchTransactionHistory(),
            this.fetchDeliveries(),
        );

        if (this.isPaymentTransactionHistoryEnabled) {
            promises.push(this.getAccountTransactionHistory(accountId));
        }

        if (this.isDeleteOnCancelEnabled) {
            promises.push(this.getAccountDeletionStatus());
            this.$eventBus.$on('accountDelete', this.getAccountDeletionStatus);
        }

        if (this.balanceOverviewEnabled) {
            promises.push(this.fetchChargingSpecs(), this.fetchBalances(accountId));
        }

        if (this.isTcConcentVisible || this.hasAccountConsentFields) {
            promises.push(this.fetchConsents());
        }

        // Account data must be fetched 1st
        await this.fetchAccountData();
        promises.push(this.setUserInfo(this.account.userId));
        await Promise.all(promises);
        this.mapRolesNew();
        this.offers = this[Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED](ENTITY_TYPES.OFFER);

        if (this.isViasatDemo) {
            this.viasatClientStorageData = await this.getViasatClientStorageData();
        }

        this.fiberAndQueuedOders = this.getFiberAndQueuedOrders(this?.userInfo?.properties?.fiber_order_ids || {});
        if (this.isCancelSubscriptionEnabled || this.isTerminationEnabled) {
            this.$eventBus.$on('updateEntity', this.fetchAccountEditorData);
        }
    },
    beforeDestroy() {
        this[Actions.RESET_DELIVERIES]();
        this[Actions.RESET_ORDERS]();
        this.clearApiPollers();
    },
    validations() {
        return {
            refundAmount: {
                decimal,
                required,
                minValue: minValue(0.01),
            },
        };
    },
    methods: {
        ...mapActions('userManagementAccount', [
            'getAccountTransactionHistory',
            'getTransactionHistoryTableData',
            'getStateHistory',
            'getPaymentMethods',
        ]),
        ...mapActions('userManagementSubscriber', ['getSubscriberInfo']),
        ...mapActions('userManagementUser', [
            // viasat only
            'getUserInfo',
        ]),
        ...mapActions('orders', [
            Actions.LOAD_ALL_OEORDERS_ACCOUNT,
            Actions.LOAD_ALL_OMDDEVEDGEORDERS_ACCOUNT,
            Actions.REPLACE_ORDER,
            Actions.CANCEL_REPLACE_ORDER,
            Actions.GET_DELIVERIES,
            Actions.RESET_DELIVERIES,
            Actions.RESET_ORDERS,
        ]),
        ...mapActions('productcatalog', [Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]),
        ...mapActions('orchestrationengine', {
            requestOEMappedExecutions: Actions.REQUEST_OE_MAPPED_EXECUTIONS,
        }),
        ...mapActions('charging', [Actions.REQUEST_CHARGING_SPECIFICATIONS]),
        isEmpty,
        isViewEnabled,
        isUserAllowed,
        async cancelPlanChange(accountId) {
            try {
                await this[Actions.CANCEL_REPLACE_ORDER]({ accountId });
            } catch (error) {
                Sentry.captureException(error);
                if (error.response?.data?.code === luaErrors.ORDEROE.TIME_CONSTRAINTS_NOT_SATISFIED.code) {
                    this.showBlackoutDialogCancel = true;
                } else {
                    this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
                }
            }
        },
        onRefundOneItemToggled(index) {
            if (this.transactionHistoriesData[index].isChecked) {
                this.amountForRefund += parseFloat(this.transactionHistoriesData[index].chargedItemPrice);

                // if every refund toggle is toggled set refund All toggle as toggled
                const numOfUncheckedRefundToggles = this.transactionHistoriesData.filter(tr => !tr.isChecked);
                if (numOfUncheckedRefundToggles.length === 0) {
                    this.refundAllIsChecked = true;
                }
            } else {
                this.amountForRefund -= parseFloat(this.transactionHistoriesData[index].chargedItemPrice);

                // if we uncheck one toggle button then refund all toggle should be unchecked too
                if (this.refundAllIsChecked) {
                    this.refundAllIsChecked = false;
                }
            }
        },
        onRefundAllToggled() {
            const { refundAllIsChecked, transactionHistoriesData } = this;
            this.amountForRefund = 0;

            if (refundAllIsChecked) {
                transactionHistoriesData.forEach((transaction, index) => {
                    if (!transaction.isChecked) {
                        transactionHistoriesData[index].isChecked = true;
                    }
                    this.amountForRefund += parseFloat(this.transactionHistoriesFormatted[index].chargedItemPrice);
                });
            } else {
                transactionHistoriesData.forEach((transaction, index) => {
                    if (transaction.isChecked) {
                        transactionHistoriesData[index].isChecked = false;
                    }
                });
            }
        },
        onCloseModal() {
            this.modalVisible = false;
            this.refundAllIsChecked = false;
            this.nameOfTableForRefund = '';
        },
        async planChange(accountId, offerId, changeType) {
            await this.$withProgressBar(
                async () => {
                    await this[Actions.REPLACE_ORDER]({
                        accountId,
                        offerId,
                        changeType,
                    });
                },
                {
                    errorHandler: error => {
                        if (error.response?.data?.code === luaErrors.ORDEROE.TIME_CONSTRAINTS_NOT_SATISFIED.code) {
                            this.showBlackoutDialog = true;
                        } else {
                            this.$showErrorAlert({
                                message: this.$i18n.t('alertMessage.somethingWentWrong'),
                            });
                        }
                    },
                },
            );
        },
        async viasatPlanChange(accountId, newPlanId, newAddOnIds) {
            await this.$withProgressBar(
                async () => {
                    await ordersHTTP.changeOrder({
                        accountId,
                        orderId: this.viasatOrderDetails?.orderId,
                        replaceIds: [
                            {
                                from: this.viasatCurrentPlan.entity_id,
                                to: newPlanId,
                            },
                        ],
                        removeIds: this.viasatCurrentAddons
                            .filter(a => !newAddOnIds.includes(a.entity_id))
                            .map(a => a.entity_id),
                        addIds: newAddOnIds.filter(a => !this.viasatCurrentAddons.map(c => c.entity_id).includes(a)),
                    });
                    this.$router.go();
                },
                {
                    errorHandler: error => {
                        if (error.response?.data?.code === luaErrors.ORDEROE.TIME_CONSTRAINTS_NOT_SATISFIED.code) {
                            this.showBlackoutDialog = true;
                        } else {
                            this.$showErrorAlert({
                                message: this.$i18n.t('alertMessage.somethingWentWrong'),
                            });
                        }
                    },
                },
            );
        },
        goToRoute(breadcrumb) {
            this.$router.push({ path: `/${this.$route.params.companyId}${breadcrumb.link}` });
        },
        showEditor() {
            this.isEditorVisible = true;
        },
        showRefundEditor(data, nameOfTable) {
            this.nameOfTableForRefund = nameOfTable;
            this.modalVisible = true;
            this.transactionData = data;
        },
        async refundPayment() {
            this.$v.$touch();
            if (!this.$v.$anyError) {
                this.$Progress.start();
                try {
                    let refundingAmount = this.amountForRefund;
                    if (this.nameOfTableForRefund === NAME_OF_TRANSACTION_HISTORY_TABLE.BILLING) {
                        // if user select partial amount then procceed with that value
                        if (this.refundAmount) {
                            refundingAmount = this.refundAmount;
                        }

                        await customerCareHTTP.refundTransaction(
                            this.account.accountId,
                            USER_MANAGER_HIERARCHY.ACCOUNT,
                            this.transactionData.id,
                            refundingAmount.toString(),
                        );
                    } else if (this.nameOfTableForRefund === NAME_OF_TRANSACTION_HISTORY_TABLE.PAYMENT) {
                        await refundAPI.refundOrCancel(
                            this.account.accountId,
                            USER_MANAGER_HIERARCHY.ACCOUNT,
                            this.transactionData.transaction_id,
                            this.transactionData.transaction_type,
                            this.refundAmount.toString(),
                        );

                        refundingAmount = this.refundAmount;
                    }
                    this.$Progress.finish();

                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.userManagement.refundSuccessefull', {
                            amount: refundingAmount,
                        }),
                        type: ALERT_TYPES.success,
                    });

                    this.onCloseModal();
                    await this.fetchTransactionHistory();
                } catch (error) {
                    Sentry.captureException(error);
                    this.$Progress.fail();
                    if (error.response?.data.code === luaErrors.BILLING_V4.INVALID_REFUND_AMOUNT.code) {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.userManagement.alertMessages.invalidRefundAmount'),
                            type: ALERT_TYPES.error,
                        });
                    } else if (error.response?.data.code === luaErrors.BILLING_V4.CHARGE_ALREADY_REFUNDED.code) {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.userManagement.alertMessages.chargeIsAlreadyRefunded'),
                            type: ALERT_TYPES.error,
                        });
                    } else {
                        this.showSupportAlert(
                            this.$i18n.t('alertMessage.userManagement.refundError'),
                            ALERT_TYPES.error,
                        );
                    }
                }
            }
        },
        async fetchAccountData() {
            try {
                this.$Progress.start();
                this.accountApiResponse = await customerCareHTTP.getAccount(
                    this.$route.params.id,
                    USER_MANAGER_HIERARCHY.ACCOUNT,
                );
                this.accountInfo = this.accountApiResponse.data?.account_info;
                this.setAccount();
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                if (handleUserNotFoundError(error)) {
                    await this.$router.push({
                        name: RouteNames.CUSTOMER_CARE_USER_MANAGEMENT,
                        params: { companyId: this.$route.params.companyId },
                    });
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.userManagement.accountNotFound'),
                    });
                } else {
                    this.showSupportAlert(
                        this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        ALERT_TYPES.error,
                    );
                }
                this.accountApiResponse = null;
                this.accountInfo = {};
            }

            // We fetch subscriber data after fetching account data
            // since the account data contains subscriber ids
            await this.fetchSubscriberData();
        },
        /**
         * Fetch subscriber data for each subscriber in `accountInfo`
         */
        async fetchSubscriberData() {
            const subscriberIds = this.accountInfo?.subscribers;
            if (subscriberIds == null) {
                return;
            }
            try {
                // Fetc subscribers data into store
                await Promise.all(subscriberIds.map(id => this.getSubscriberInfo(id)));

                this.subscriberInfos = subscriberIds.map(subscriberId => this.getSubscriberInfoById(subscriberId));

                // Map backend data
                this.subscribers = this.subscriberInfos.map(subscriber => ({
                    msisdn: subscriber.msisdn,
                    subscriberId: subscriber.subscriber_id,
                    blocked: getBeautifulBoolean(subscriber.is_blocked),
                    status: subscriber.state,
                }));
            } catch (error) {
                Sentry.captureException(error);
                this.subscriberInfos = [];
                this.subscribers = [];
            }
        },
        async fetchOrderData() {
            try {
                if (this.isOrderDetailsEnabled) {
                    this[Actions.LOAD_ALL_OEORDERS_ACCOUNT](this.$route.params.id);
                    const promises = [
                        this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.OFFER }),
                        this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.PRODUCT }),
                        this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.SERVICE }),
                    ];

                    await Promise.allSettled(promises);
                }
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
            }
        },
        async fetchChargingSpecs() {
            try {
                await this[Actions.REQUEST_CHARGING_SPECIFICATIONS]();
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                });
                Sentry.captureException(error);
            }
        },
        async fetchAccountEditorData() {
            await this.fetchAccountData();
            await this.fetchOrderData();
            await this.fetchStateHistory();
        },
        setAccount() {
            this.account = new Account(Account.remapAccountFromBe(this.accountInfo));
        },
        getBreadcrumbPath(type, id) {
            switch (type) {
                case USER_MANAGER_HIERARCHY.SUBSCRIBER:
                    return {
                        link: `/customer-care/subscriber/${id}`,
                    };
                case USER_MANAGER_HIERARCHY.ACCOUNT:
                    return {
                        link: `/customer-care/account/${id}`,
                    };
                case USER_MANAGER_HIERARCHY.USER:
                    return {
                        link: `/customer-care/user/${id}`,
                    };
                case USER_MANAGER_HIERARCHY.ORGANIZATION:
                    return {
                        link: `/customer-care/organization/${id}`,
                    };
                default:
                    return '';
            }
        },
        async fetchStateHistory() {
            try {
                this.$Progress.start();

                await this.getStateHistory(this.$route.params.id);

                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                Sentry.captureException(error);
            }
        },
        async fetchDeliveries() {
            if (this.userManagerDeliveryServiceEnabled) {
                try {
                    this.$Progress.start();

                    await this[Actions.GET_DELIVERIES](this.$route.params.id);

                    this.$Progress.finish();
                } catch (error) {
                    this.$Progress.fail();
                    this.showSupportAlert(
                        this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        ALERT_TYPES.error,
                    );
                    Sentry.captureException(error);
                }
            }
        },
        async fetchAppointments() {
            if (this.allowGetAppointments) {
                try {
                    const showViasatTroubleAppointment = LocalStorageHelper.get(
                        `viasatDemo-showTroubleAppointment-${this.$route.params.id}`,
                    );
                    if (this.isViasatDemo && showViasatTroubleAppointment === 'true') {
                        this.appointmentsInfo = await getAppointments(this.$route.params.id, [
                            {
                                key: 'filter',
                                value: 'trouble_call_appointment',
                            },
                        ]);
                    } else {
                        this.appointmentsInfo = await getAppointments(this.$route.params.id);
                    }
                    this.appointmentsInfoApiResponse = this.appointmentsInfo;
                } catch (error) {
                    Sentry.captureException(error);
                    this.showSupportAlert(
                        this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        ALERT_TYPES.error,
                    );
                    this.appointmentsInfoApiResponse = error.response;
                }
            }
        },
        async fetchOutageData() {
            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.OUTAGE_INFO) || this.isOutageHistoryEnabled) {
                await this.$withProgressBar(
                    async () => {
                        this.outageHistoryApiResponse = await osstmofiberHTTP.getOutageHistory(
                            this.$route?.params?.id,
                            USER_MANAGER_HIERARCHY.ACCOUNT,
                        );
                        const { outage_history: outageHistory } = this.outageHistoryApiResponse?.data || {};

                        if (Array.isArray(outageHistory)) {
                            this.outageHistory = groupOutagesByOutageId(outageHistory);
                        }
                    },
                    {
                        errorHandler: e => {
                            this.outageHistoryApiResponse = e.response;
                            this.$alert(
                                this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingOutageData'),
                            );
                        },
                    },
                );
            }
        },
        async fetchPurchasesRelatedData() {
            if (this.accountWalletTopUpEnabled) {
                const { id } = this.$route.params;
                const targetId = id || this.account.accountId;

                try {
                    this.$Progress.start();
                    this.balances = await ordersHTTP.getSharedWalletBalances(targetId, USER_MANAGER_HIERARCHY.ACCOUNT);

                    this.$Progress.finish();
                } catch (error) {
                    this.$Progress.fail();
                    this.showSupportAlert(
                        this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        ALERT_TYPES.error,
                    );
                    Sentry.captureException(error);
                }
            }
        },
        async fetchReferralCode() {
            if (this.isDetailFieldEnabled(ACCOUNT_DETAIL_FIELD.REFERRAL_CODE)) {
                try {
                    this.$Progress.start();

                    const {
                        data: { referral_code: refCode },
                    } = await customerCareHTTP.getReferralCode(this.$route.params.id, USER_MANAGER_HIERARCHY.ACCOUNT);

                    this.referralCode = refCode;

                    this.$Progress.finish();
                } catch (error) {
                    this.$Progress.fail();
                    this.$alert(this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingReferralCodeData'));
                    Sentry.captureException(error);
                }
            }
        },
        async fetchRolesData() {
            try {
                this.rolesApiResponse = await userManagementHTTP.getAccessList(
                    this.$route.params.id,
                    USER_MANAGER_HIERARCHY.ACCOUNT,
                );

                if (Array.isArray(this.rolesApiResponse?.data?.access_list)) {
                    this.roles = this.rolesApiResponse.data.access_list.map(role => ({
                        ...role,
                        roleName: role.role,
                        userId: role.caller_id,
                        emailAcc: role.email || this.$i18n.t('generic.empty'),
                    }));
                }
            } catch (error) {
                this.rolesApiResponse = error.response;
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                Sentry.captureException(error);
            }
        },
        async fetchTransactionHistory() {
            if (this.isBillingTransactionHistoryEnabled) {
                try {
                    const { id } = this.$route.params;
                    this.$Progress.start();

                    await this.getTransactionHistoryTableData({ accountId: id, sortType: 'DESC' });

                    this.$Progress.finish();
                } catch (error) {
                    this.$Progress.fail();
                    this.showSupportAlert(
                        this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        ALERT_TYPES.error,
                    );
                    Sentry.captureException(error);
                }
            }
        },
        async fetchPaymentMethods() {
            try {
                this.$Progress.start();

                await this.getPaymentMethods(this.$route.params.id);

                if (this.paymentMethods.length) {
                    this.mapLast4Digits();
                }

                this.$Progress.finish();
            } catch (e) {
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                Sentry.captureException(e);
            }
        },
        async fetchExternalData() {
            if (this.isExternalDataEnabled) {
                try {
                    this.$Progress.start();

                    const res = await ossgpfiberHTTP.getResource(this.$route.params.id, USER_MANAGER_HIERARCHY.ACCOUNT);
                    this.externalResource = new ExternalResource(
                        ExternalResource.mapResourceFromBE(res?.data?.resource),
                    );

                    this.$Progress.finish();
                } catch (e) {
                    this.$Progress.fail();
                    this.showSupportAlert(
                        this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        ALERT_TYPES.error,
                    );
                    Sentry.captureException(e);
                }
            }
        },
        async fetchConsents() {
            await this.$withProgressBar(
                async () => {
                    const consentResponse = await consentHTTP.getConsents(this.$route.params.id, TARGET_TYPE.ACCOUNT);
                    this.consents = consentResponse?.data?.consents || [];
                },
                {
                    errorHandler: () => {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        });
                    },
                },
            );
        },
        mapLast4Digits() {
            this.last4Digits = [...this.paymentMethods]
                .sort((a, b) => b.default - a.default)
                .map(({ default: isDefault, last_4: last4Digits }) => {
                    if (this.paymentMethods.length > 1 && isDefault) {
                        return `${last4Digits} (${this.$i18n.t('generic.default')})`;
                    }
                    return last4Digits;
                });
        },
        onDeductAmount(walletType) {
            this.selectedWalletType = walletType;
            this.balancesModalVisible = true;
            this.editingContent = this.MODAL_CONTENT.DEDUCT;
        },
        onTopUpAmount(walletType) {
            this.selectedWalletType = walletType;
            this.balancesModalVisible = true;
            this.editingContent = this.MODAL_CONTENT.TOP_UP;
        },
        onCancelTerminate() {
            this.suspendModalVisible = true;
            this.editingContent = this.MODAL_CONTENT.CANCEL_TERMINATE;
        },
        closeModal() {
            this.balancesModalVisible = false;
            this.suspendModalVisible = false;
            this.editingContent = 0;
            this.sensitiveEditing = false;
        },
        onSuspendServices(e) {
            this.suspendedServices = e;
            if (e.type === MODAL_CONTENT.CANCEL_TERMINATE) {
                this.onCancelTerminate();
            }
        },
        onRefreshButtonClicked() {
            Promise.all([this.fetchPurchasesRelatedData(), this.fetchReferralCode()]);
        },
        mapRolesNew() {
            const account = new AccessRole({
                targetId: this.$route.params.id,
                targetTypeId: USER_MANAGER_HIERARCHY.ACCOUNT,
            });
            const subscribers = this.subscribers.map(
                sub =>
                    new AccessRole({
                        targetId: sub.subscriberId,
                        targetTypeId: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    }),
            );

            this.rolesNew = [account, ...subscribers];
        },
        newCustomerCare() {
            this.$router.push({
                name: RouteNames.CCS_ACCOUNT_PAGE,
                params: { id: this.$route.params.id },
            });
        },
        async activateService() {
            this.$Progress.start();
            this.activateServiceLoading = true;
            try {
                const targetType = USER_MANAGER_HIERARCHY.ACCOUNT;
                const targetId = this.account.accountId;

                if (this.pendingOrder && this.pendingOrderMvneExecutionId) {
                    const { orderId } = this.pendingOrder;
                    await callbackExecution({ execution_id: this.pendingOrderMvneExecutionId });
                    await new Promise(resolve => setTimeout(resolve, 3000));
                    await ordersHTTP.oeActivateOrder({ targetId, targetType, orderId });
                } else if (this.pendingActivationOrder) {
                    const { orderId } = this.pendingActivationOrder;
                    await ordersHTTP.oeActivateOrder({ targetId, targetType, orderId });
                }
                this.$Progress.finish();
                await this.fetchAccountEditorData();
                this.$showSuccessAlert({
                    message: this.$t('customerCare.account.activateServiceSuccess'),
                });
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$alert(this.$i18n.t('alertMessage.userManagement.orderActivateFailed'));
            } finally {
                this.activateServiceLoading = false;
            }
        },
        isDetailFieldEnabled(fieldName) {
            return this.accountDetailFields.includes(fieldName);
        },
        async getViasatClientStorageData() {
            const data = {
                offer: {},
                addons: [],
            };
            if (!this.account.userId) return data;
            this.$Progress.start();
            try {
                const offerRes = await customerCareHTTP.getViasatClientStorage(this.account.userId, 'OFFER');
                const addonsRes = await customerCareHTTP.getViasatClientStorage(this.account.userId, 'ADD_ONS');
                this.$Progress.finish();
                data.offer = offerRes?.data?.storage?.OFFER ?? {};
                data.addons = Object.keys(addonsRes?.data?.storage?.ADD_ONS ?? {});
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
            }
            return data;
        },
        getOfferNameById(offerId) {
            if (!this.offers.length) return '';
            const offer = this.offers.find(o => o.id === offerId);
            return offer?.name ?? '';
        },
        // viasat only
        async setUserInfo(id) {
            try {
                this.$Progress.start();
                await this.getUserInfo(id);

                this.setUser();
                this.$Progress.finish();
            } catch (e) {
                this.$Progress.fail();
                throw e;
            }
        },
        setUser() {
            this.user = new User(User.remapUserFromBe(this.userInfo));
        },
        async getAccountDeletionStatus() {
            await this.getLatestDeletionExecutionId();
            if (this.accDeletionExecutionId) {
                await this.getExecutionStatus();
            }
        },
        async getLatestDeletionExecutionId() {
            await this.$withProgressBar(
                async () => {
                    const {
                        data: {
                            mappings: [executions = []],
                        },
                    } = await getMappedExecutions({
                        plan_ids: ['um_account_deletion'],
                        mapper_ids: [this.$route.params.id],
                    });
                    if (executions.length) {
                        const [latest] = executions.sort((a, b) => b.create_time - a.create_time);
                        this.accDeletionExecutionId = latest.execution_id;
                    }
                },
                {
                    errorHandler: () => {
                        this.$showErrorAlert({
                            message: this.$i18n.t(
                                'customerCare.cancelTerminate.alerts.somethingWentWrongFetchinCancelationStatus',
                            ),
                        });
                    },
                },
            );
        },
        async getExecutionStatus() {
            await this.$withProgressBar(
                async () => {
                    const {
                        data: { execution },
                    } = await getExecution(this.accDeletionExecutionId);

                    if (execution?.status) {
                        this.showExecutionStatusMessage(execution.status);
                    }
                },
                {
                    errorHandler: () => {
                        this.clearApiPollers('accountDelete');
                        this.$showErrorAlert({
                            message: this.$i18n.t(
                                'customerCare.cancelTerminate.alerts.somethingWentWrongFetchinCancelationStatus',
                            ),
                        });
                    },
                },
            );
        },
        async showExecutionStatusMessage(status) {
            const message =
                EXECUTION_STATE_TO_CANCELATION_MESSAGE.get(status) ||
                this.$i18n.t('customerCare.cancelTerminate.executionStatus.unknown');

            this.$eventBus.$emit('closeAllAlerts');

            switch (status) {
                case EXECUTION_STATES.PENDING:
                case EXECUTION_STATES.EXECUTING:
                    this.setCancelDeleteExecutionStatusPoller();
                    return this.$showInfoAlert({ message });

                case EXECUTION_STATES.WAITINGFORCALLBACK:
                    this.setCancelDeleteExecutionStatusPoller();
                    return this.showSupportAlert(message, ALERT_TYPES.warning);

                case EXECUTION_STATES.SUCCESS:
                    if (this.apiPollers.accountDelete) {
                        this.clearApiPollers('accountDelete');
                        // refetch data as canceled flag may not yet be set.
                        await this.fetchAccountEditorData();
                    }

                    if (this.isSetForCancelationAtEndOfBillCycle) {
                        return this.$showSuccessAlert({
                            message: this.$i18n.t(
                                'customerCare.cancelTerminate.alerts.isAccountSetForCancelAtTheEndOfBillCycle',
                            ),
                        });
                    }

                    if (this.hasCanceledServices && this.account.state !== SUBSCRIBER_STATE.TERMINATED) {
                        return this.$showSuccessAlert({ message });
                    }

                    // cancelation request was successfull but reverted after that.
                    return false;

                case EXECUTION_STATES.FAILED:
                    this.clearApiPollers('accountDelete');
                    return this.$showErrorAlert({ message });

                default:
                    this.clearApiPollers('accountDelete');
                    return this.showSupportAlert(message, ALERT_TYPES.error);
            }
        },
        setCancelDeleteExecutionStatusPoller() {
            if (!this.apiPollers.accountDelete) {
                const poller = setInterval(() => this.getExecutionStatus(), 5000);
                this.$set(this.apiPollers, 'accountDelete', poller);
            }
        },
        clearApiPollers(t) {
            if (t) {
                clearInterval(this.apiPollers[t]);
                delete this.apiPollers[t];
            } else {
                Object.values(this.apiPollers).forEach(timer => clearInterval(timer));
                this.apiPollers = {};
            }
        },
        async fetchBalances(targetId) {
            try {
                this.balanceOverview = await ordersHTTP.getBalances(targetId, USER_MANAGER_HIERARCHY.ACCOUNT);
                this.balanceOverviewApiResponse = this.balanceOverview;
            } catch (error) {
                this.balancesApiResponse = error.response;
                throw error;
            }
        },
        getFiberAndQueuedOrders(fiberOrderIdsMap) {
            const orders = Object.values(this.orders);
            const queuedOrders = orders.filter(order => order.state === ORDER_ENTITY_STATES.QUEUED);
            const fiberOrders = Object.keys(fiberOrderIdsMap).map(id => this.orders[id]);
            if (fiberOrders.length) {
                const activeOrderId = fiberOrders[0]?.currentlyActivateOrder?.order_id;
                const activeOrder = activeOrderId ? [this.orders[activeOrderId]] : [];
                return [...fiberOrders, ...activeOrder, ...queuedOrders];
            }
            return [...queuedOrders];
        },
    },
};
