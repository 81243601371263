
































































































































































































































































































import Vue from 'vue';

// Components
import AppHeader from '@/components/layout/AppHeader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import LayoutAnchor from '@/__new__/features/customerCareSuite/common/LayoutAnchor.vue';
import LayoutSection from '@/__new__/features/customerCareSuite/common/LayoutSection.vue';
import AuthenticationDialogs from '@/__new__/features/customerCareSuite/components/AuthenticationDialogs.vue';
import BillingAccountEditDialog from '@/__new__/features/customerCareSuite/components/BillingAccountEditDialog.vue';
import BillingAccountHotBillDialog from '@/__new__/features/customerCareSuite/components/BillingAccountHotBillDialog.vue';
import AccountEditor from '@/__new__/features/customerCare/account/AccountEditor.vue';
import UserProfileEditor from '@/__new__/features/customerCare/user/UserProfileEditor.vue';
import ActivateService from '@/__new__/features/customerCareSuite/components/ActivateService.vue';
import Breadcrumbs from '@/components/partials/Breadcrumbs.vue';

// HTTP
import {
    getLayoutPageByPageId,
    saveLayoutPageByPageId,
    revertLayoutPageByPageId,
    getLayoutDefaultByPageId,
    saveLayoutDefaultPageByPageId,
} from '@/__new__/services/portal/layoutPage/http/layoutPage';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { LAYOUT_POSITION_DIRECTION } from '@/__new__/features/customerCareSuite/common/ccsLayoutHelper';
import {
    LAYOUT_FEATURES,
    LAYOUT_BUTTONS,
    LAYOUT_PAGE_KEYS,
    LAYOUT_PAGE_KEYS_TO_UM_HIERARCHY_MAP,
} from '@/__new__/features/customerCareSuite/common/layoutSectionHelper';
import {
    SUBSCRIBER_SUSPEND_SERVICES_ACTIONS,
    SUBSCRIBER_CANCEL_REQUEST_ACTIONS,
    USER_MANAGER_HIERARCHY,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import RouteNames from '@/router/routeNames';
import permissionsService, {
    getBillingVersion,
    BILLING_VERSIONS,
    getOperatorConfigValue,
    isUserAllowed,
    isViewEnabled,
} from '@/services/permissions/permissions.service';
import User from '@/__new__/services/dno/user/models/User';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { getBreadcrumbsList } from './breadcrumbsHelper';
import * as Sentry from '@sentry/vue';

// Vuex
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';

const MODAL_TYPES = {
    EDIT_ACCOUNT: 'Edit Account',
    EDIT_USER: 'Edit User',
    GENERATE: 'Generate',
    VERIFY: 'Verify',
    EDIT_BILLING_ACCOUNT: 'Edit Billing Account',
    HOT_BILL: 'Generate Hot Bill',
    SUSPEND: 'Suspend',
    CANCEL_REQUEST: 'Cancel Request',
};

export default Vue.extend({
    name: 'LayoutPage',

    components: {
        AppButton,
        AppInputV3,
        AppHeader,
        LayoutAnchor,
        LayoutSection,
        AuthenticationDialogs,
        AccountEditor,
        UserProfileEditor,
        ActivateService,
        Breadcrumbs,
        BillingAccountEditDialog,
        BillingAccountHotBillDialog,
    },
    props: {
        page: {
            type: String,
            default: LAYOUT_PAGE_KEYS.USER,
        },
        pageTitle: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            layouts: [],
            layoutPage: {},
            colNum: 12,
            editModeEnabled: false,
            defaultLayout: false,
            addSectionName: '',
            isNewCustomerCareEnabled: getOperatorConfigValue('customerCareOptIn', false),
            layoutFeaturesConfig: getOperatorConfigValue('layoutFeatures', []),
            layoutButtonsConfig: getOperatorConfigValue('layoutButtons', []),
            layoutSavingInProgress: false,

            // modals related
            isModalVisible: false,
            modalType: null as typeof MODAL_TYPES,

            userAuthenticationEnabled:
                permissionsService.userManagementUserAuthenticationEnabled() &&
                isViewEnabled('UMUserUserAuthentication'),

            // proxy
            BUTTON_TYPES,
            ICON_TYPES,
            MODAL_TYPES,
            LAYOUT_PAGE_KEYS,
            LAYOUT_BUTTONS,
            SUBSCRIBER_SUSPEND_SERVICES_ACTIONS,
            SUBSCRIBER_CANCEL_REQUEST_ACTIONS,
            isUserAllowed,
        };
    },
    computed: {
        ...mapGetters('userManagementUser', ['getUserVerificationStatusById']),
        ...mapGetters(Modules.customerCareSuite, [
            Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID,
            Getters.GET_USER_AUTHENTICATION_STATE,
        ]),
        ...mapGetters('orders', {
            getOrders: Getters.GET_ORDERS,
            getPrimaryOrders: Getters.GET_PRIMARY_ORDERS,
        }),
        // Generic/global stuff
        viewedEntityId() {
            return this.$route.params.id || '';
        },
        viewedEntityTypeEnum() {
            return LAYOUT_PAGE_KEYS_TO_UM_HIERARCHY_MAP.get(this.page);
        },
        innerPageTitle() {
            if (this.editModeEnabled) {
                return this.pageTitle.concat(' - ', this.$i18n.t('customerCareSuite.editMode'));
            }
            if (this[Getters.GET_USER_AUTHENTICATION_STATE]) {
                return this.pageTitle.concat(' - ', this[Getters.GET_USER_AUTHENTICATION_STATE].status);
            }
            return this.pageTitle;
        },
        defaultLayoutAvailable() {
            return this.defaultLayout && this.layoutPage.userId;
        },
        breadcrumbList() {
            if (this.viewedEntityId && this.viewedEntityTypeEnum) {
                const currentUMEntityData = this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                    targetType: this.viewedEntityTypeEnum,
                    targetId: this.viewedEntityId,
                });
                if (currentUMEntityData) {
                    return getBreadcrumbsList(currentUMEntityData);
                }
            }
            return [];
        },
        // modal related
        isGenerateModalVisible() {
            return this.isModalVisible && this.modalType === MODAL_TYPES.GENERATE;
        },
        isVerifyModalVisible() {
            return this.isModalVisible && this.modalType === MODAL_TYPES.VERIFY;
        },
        // This part will be remove once we make Acc edit a separate page
        isEditAccountModalVisible() {
            return this.isModalVisible && this.modalType === MODAL_TYPES.EDIT_ACCOUNT;
        },
        isEditUserModalVisible() {
            return this.isModalVisible && this.modalType === MODAL_TYPES.EDIT_USER;
        },
        isBillingAccountEditModalVisible() {
            return this.isModalVisible && this.modalType === MODAL_TYPES.EDIT_BILLING_ACCOUNT;
        },
        isBillingAccountHotBillModalVisible() {
            return this.isModalVisible && this.modalType === MODAL_TYPES.HOT_BILL;
        },
        isEditSubscriberAllowed() {
            // If UMEndUserFullDataAccess is disabled then user wouldn`t have access to page until he authorize
            return (
                (this.userAuthenticationEnabled && this.isUserVerified) ||
                !isUserAllowed('UMEndUserFullDataAccess') ||
                isUserAllowed('UMEditOverride')
            );
        },
        isUserVerified() {
            return this.umEntityData?.userId ? this.getUserVerificationStatusById(this.umEntityData.userId) : false;
        },
        umEntityData() {
            const defaultValue = this.isLayoutPage(LAYOUT_PAGE_KEYS.ACCOUNT) ? {} : new User({});
            if (
                this.isLayoutPage(LAYOUT_PAGE_KEYS.ACCOUNT) ||
                this.isLayoutPage(LAYOUT_PAGE_KEYS.USER) ||
                this.isLayoutPage(LAYOUT_PAGE_KEYS.SUBSCRIBER)
            ) {
                let targetType;
                if (this.isLayoutPage(LAYOUT_PAGE_KEYS.ACCOUNT)) {
                    targetType = USER_MANAGER_HIERARCHY.ACCOUNT;
                } else if (this.isLayoutPage(LAYOUT_PAGE_KEYS.USER)) {
                    targetType = USER_MANAGER_HIERARCHY.USER;
                } else {
                    targetType = USER_MANAGER_HIERARCHY.SUBSCRIBER;
                }
                const umEntityFromStore = this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                    targetType,
                    targetId: this.viewedEntityId,
                });
                return umEntityFromStore || defaultValue;
            }
            return null;
        },
        accountOrders() {
            if (this.isLayoutPage(LAYOUT_PAGE_KEYS.ACCOUNT)) {
                return getBillingVersion() === BILLING_VERSIONS.v4 ? this.getOrders : this.getPrimaryOrders;
            }
            return [];
        },
    },
    async created() {
        // Retrieve user specific or operator default layout.
        await this.initData();
    },
    methods: {
        ...mapActions('orders', [Actions.LOAD_ALL_OEORDERS_ACCOUNT]),
        async initData() {
            try {
                this.$Progress.start();

                const defaultLayout = await getLayoutDefaultByPageId(this.page);

                if (defaultLayout.data) {
                    this.defaultLayout = defaultLayout.data;
                }

                const fetchedPageLayout = await getLayoutPageByPageId(this.page);
                if (fetchedPageLayout.data && fetchedPageLayout.data.layoutJSON) {
                    this.layoutPage = fetchedPageLayout.data;
                    this.layouts = fetchedPageLayout.data.layoutJSON;
                }

                if (this.isLayoutPage(LAYOUT_PAGE_KEYS.ACCOUNT)) {
                    this[Actions.LOAD_ALL_OEORDERS_ACCOUNT](this.viewedEntityId);
                }

                this.$Progress.finish();
            } catch (e) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCareSuite.layoutPageLoadError'),
                });
            }
        },
        isLayoutPage(layoutPageType) {
            return this.page === layoutPageType;
        },
        openModal(modalType: typeof MODAL_TYPES) {
            this.isModalVisible = false;
            this.modalType = modalType;
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
            this.modalType = null;
        },
        reloadForBillingAccount() {
            window.location.reload();
        },
        toggleEditMode() {
            this.editModeEnabled = !this.editModeEnabled;
        },
        updateLayout(event) {
            if (event.layoutIndex != null) {
                this.layouts[event.layoutIndex].sectionName = event.sectionName;
                this.layouts[event.layoutIndex].isCollapsed = event.isCollapsed;
                this.layouts[event.layoutIndex].layout = event.layout;
            }
        },
        async onSave(returnToView) {
            if (isUserAllowed('CCSUserLayoutEdit')) {
                await this.$withProgressBar(
                    async () => {
                        this.layoutSavingInProgress = true;

                        const saveResponse = await saveLayoutPageByPageId(this.page, this.layouts);
                        if (saveResponse.data) {
                            this.layoutPage = saveResponse.data;
                        }

                        if (returnToView) {
                            this.toggleEditMode();
                        }

                        this.$showSuccessAlert({
                            message: this.$i18n.t('customerCareSuite.layoutSaved'),
                        });

                        this.layoutSavingInProgress = false;
                    },
                    {
                        errorHandler: () => {
                            this.$alert(this.$i18n.t('customerCareSuite.layoutSaveError'));
                            this.layoutSavingInProgress = false;
                        },
                    },
                );
            } else {
                this.$alert(this.$i18n.t('generic.youDontHavePermissionsToAct'), { type: ALERT_TYPES.error });
            }
        },
        async onSaveAsDefault() {
            if (isUserAllowed('CCSOperatorLayoutEdit')) {
                await this.$withProgressBar(
                    async () => {
                        this.layoutSavingInProgress = true;
                        const saveResponse = await saveLayoutDefaultPageByPageId(this.page, this.layouts);
                        if (saveResponse.data) {
                            this.layoutPage = saveResponse.data;
                        }
                        this.$showSuccessAlert({
                            message: this.$i18n.t('customerCareSuite.layoutSaved'),
                        });
                        this.layoutSavingInProgress = false;
                    },
                    {
                        errorHandler: () => {
                            this.$alert(this.$i18n.t('customerCareSuite.layoutSaveError'));
                            this.layoutSavingInProgress = false;
                        },
                    },
                );
            } else {
                this.$alert(this.$i18n.t('generic.youDontHavePermissionsToAct'), { type: ALERT_TYPES.error });
            }
        },
        async onRevertToDefault() {
            await this.$withProgressBar(
                async () => {
                    this.layoutSavingInProgress = true;
                    await revertLayoutPageByPageId(this.page);
                    this.$showSuccessAlert({
                        message: this.$i18n.t('customerCareSuite.layoutReverted'),
                    });
                    await this.initData();
                    this.layoutSavingInProgress = false;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$18n.t('customerCareSuite.noOperatorDefault'));
                        this.layoutSavingInProgress = false;
                    },
                },
            );
        },
        addSection() {
            this.layouts.push({
                sectionName: this.addSectionName,
                layout: [],
            });
            this.addSectionName = '';
        },
        removeSection(event) {
            this.layouts.splice(event.layoutIndex, 1);
        },
        moveSection(event) {
            const currentElement = this.layouts[event.layoutIndex];

            if (event.direction === LAYOUT_POSITION_DIRECTION.UP) {
                // Manipulating in place does not work as create event for LayoutSection must be triggered
                // to appropriately process

                this.layouts.splice(event.layoutIndex, 1);
                this.layouts.splice(event.layoutIndex - 1, 0, currentElement);
            }

            if (event.direction === LAYOUT_POSITION_DIRECTION.DOWN) {
                this.layouts.splice(event.layoutIndex, 1);
                this.layouts.splice(event.layoutIndex + 1, 0, currentElement);
            }
        },
        isSectionMoveUp(index) {
            return index > 0;
        },
        isSectionMoveDown(index) {
            return index < this.layouts.length - 1;
        },
        legacyCustomerCare(layoutPageType) {
            let legacyRoute;

            switch (layoutPageType) {
                case LAYOUT_PAGE_KEYS.USER:
                    legacyRoute = RouteNames.USER_MANAGEMENT_USER_V2;
                    break;
                case LAYOUT_PAGE_KEYS.ACCOUNT:
                    legacyRoute = RouteNames.USER_MANAGEMENT_ACCOUNT_V2;
                    break;
                case LAYOUT_PAGE_KEYS.SUBCRIBER:
                    legacyRoute = RouteNames.USER_MANAGEMENT_SUBSCRIBER_V2;
                    break;
                case LAYOUT_PAGE_KEYS.ORGANIZATION:
                    legacyRoute = RouteNames.USER_MANAGEMENT_ORGANIZATION;
                    break;
                default:
                    legacyRoute = RouteNames.CUSTOMER_CARE_USER_MANAGEMENT;
                    break;
            }
            this.$router.push({
                name: legacyRoute,
                params: { id: this.viewedEntityId },
            });
        },
        editSubscriber() {
            const { id } = this.$route.params;
            this.$router.push({
                name: RouteNames.CCS_SUBSCRIBER_EDIT_PAGE,
                params: { id, companyId: this.$route.params.companyId },
            });
        },
        isLayoutButtonEnabled(buttonName: LAYOUT_BUTTONS) {
            if (this.layoutFeaturesConfig.includes(LAYOUT_FEATURES.CONFIGURE_BUTTONS)) {
                return this.layoutButtonsConfig.includes(buttonName);
            }
            // all buttons are enabled by default for backward compatibility
            return true;
        },
        onUpdatedData() {
            this.initData();
            this.refreshTilesData();
        },
        refreshTilesData(componentKeysToRefresh) {
            try {
                for (const prop in this.$refs) {
                    if (this.$refs?.[prop]?.[0]) {
                        this.$refs?.[prop]?.[0]?.refreshTilesData(componentKeysToRefresh);
                    }
                }
            } catch (error) {
                Sentry.captureException(error);
            }
        },
    },
});
