














































import Vue, { type PropType } from 'vue';

// Components
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import CollapsibleItem from '@/components/partials/CollapsibleItem.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import IconButton from '@/components/partials/IconButton.vue';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import { type UploadedFileDetails } from '@/common/fileUploadHelper';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';

export default Vue.extend({
    name: 'BulkUploadHistorySidebar',

    components: {
        AppOverviewBlock,
        CollapsibleItem,
        EntityOverview,
        IconButton,
        OverviewHeaderV2,
    },

    props: {
        entity: {
            type: Object as PropType<UploadedFileDetails | undefined>,
            default: () => undefined,
        },
        data: {
            type: Array,
            default: () => [],
        },
        isDownloadable: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            ENTITY_TYPES,
            ICON_TYPES,
        };
    },

    methods: {
        downloadFile(): void {
            this.$emit('download', this.entity);
        },
    },
});
