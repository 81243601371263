






















































































import Vue from 'vue';

// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import BulkUploadHistory from '@/__new__/features/resources/BulkUploadHistory.vue';
import BulkUploadHistorySidebar from '@/__new__/features/resources/BulkUploadHistorySidebar.vue';
import EditorManagementLayout from '@/__new__/features/resources/EditorManagementLayout.vue';

// Helpers
import download from 'downloadjs';
import { SEGMENT_ID_TYPES, StaticFilterLabels } from '@/common/StaticFilter';
import { isUserAllowed, isViewEnabled } from '@/services/permissions/permissions.service';
import { SIM_TYPE, RESOURCE_TYPE, REMOTE_CONFIG_KEYS } from '@/__new__/features/resources/common/sim';
import {
    formatEventCategory,
    getAllFileUploadStatusStrings,
    type UploadedFileDetails,
} from '@/common/fileUploadHelper';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import { type CollapsibleListItem } from '@/common/AppCollapsibleListHelper';
import { DATAFLOW_API_TYPE } from '@/__new__/services/dno/ossmvne/ossmvneHelper';

// Models
import Counts from '@/__new__/services/dno/sim/models/counts';
import SimProfileUtilities from '@/__new__/features/resources/sim/SimProfileUtilities.vue';

// HTTP
import {
    getSignedURL,
    getRegisteredEntities,
    getEntityDetailsByName,
    getEntityDetailsByCategory,
    getCounts,
} from '@/__new__/services/dno/sim/http/inventory';
import { getUploadHistory } from '@/__new__/services/dno/sim/http/uploadHistory';
import { getRemoteConfig } from '@/__new__/services/dno/remoteConfig/http/remoteConfig';
import { getSignedURLForDownload } from '@/__new__/services/dno/ossmvne/http/ossmvne';

type SimResourceUpload = {
    brand?: string;
    channel?: string;
    resourceType?: {
        id: (typeof RESOURCE_TYPE)[keyof typeof RESOURCE_TYPE];
        name: string;
    };
};

export default Vue.extend({
    name: 'SimProfileManagement',

    components: {
        AppMultiselectV3,
        BulkUploadHistory,
        BulkUploadHistorySidebar,
        EditorManagementLayout,
        SimProfileUtilities,
    },

    data() {
        return {
            utilityTypes: [
                {
                    id: SEGMENT_ID_TYPES.ICCID,
                    label: StaticFilterLabels[SEGMENT_ID_TYPES.ICCID],
                    i18n: 'customerCare.iccid',
                },
            ],
            counts: [] as any[],
            brands: [] as string[],
            channels: { [SIM_TYPE.ESIM]: [], [SIM_TYPE.PSIM]: [] } as Record<SIM_TYPE, string[]>,
            upload: {
                resourceType: undefined,
                brand: undefined,
                channel: undefined,
            } as SimResourceUpload,
            uploadHistory: [] as UploadedFileDetails[],
            uploadHistoryColumnsData: [
                {
                    name: this.$t('generic.uploadTime'),
                    key: 'createdAtStr',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.createdAt),
                    sortBy: (entity: UploadedFileDetails) => entity.createdAt,
                    field: 'createdAt',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$t('generic.filename'),
                    key: 'fileName',
                    field: 'fileName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$tc('operator.simProfile'),
                    mapper: entity =>
                        entity.entityName.split('_')[0] === String(RESOURCE_TYPE[SIM_TYPE.ESIM]) ? 'e-SIM' : 'p-SIM',
                    key: 'simType',
                    field: 'simType',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: ['e-SIM', 'p-SIM'],
                },
                {
                    name: this.$t('qodNumberManagement.recordCount'),
                    key: 'recordCount',
                    field: 'recordCount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$t('generic.status'),
                    key: 'fileUploadStatusStr',
                    field: 'fileUploadStatusStr',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: getAllFileUploadStatusStrings(),
                },
            ] as TableColumn[],
            uploadHistoryDefaultSort: {
                key: 'createdAtStr',
                sortBy: (entity: UploadedFileDetails) => entity.createdAt,
                type: 'desc',
            },
            uploadHistorySelected: undefined as UploadedFileDetails | undefined,
            isLoadingConfig: false as boolean,
            isLoadingHistory: false as boolean,
            isMvne: isViewEnabled('EsimBrandsUpload'),
            isUploadEnabled: isUserAllowed('UploadSIMProfiles'),
        };
    },

    computed: {
        configFileUploader(): Record<string, any> {
            return {
                getSignedURL,
                getEntityDetailsByCategory,
                getEntityDetailsByName,
                getRegisteredEntities,
                // Fallback values for brand/channel needs to be a string in uppercase('NULL').
                getSignedURLParams: (params: { uuid: string; fileName: string; blobName: string }) => ({
                    resourceType: String(this.upload.resourceType?.id),
                    esimBrandName: (this.isEsim && this.upload.brand) || 'NULL',
                    uuid: params.uuid,
                    fileName: params.fileName,
                    name: params.blobName,
                    channelTag: this.upload.channel || 'NULL',
                }),
                customData: {},
            };
        },
        resourceTypes(): Array<SimResourceUpload['resourceType']> {
            return [
                { name: 'e-SIM', id: RESOURCE_TYPE[SIM_TYPE.ESIM] },
                { name: 'p-SIM', id: RESOURCE_TYPE[SIM_TYPE.PSIM] },
            ];
        },
        channelOptions(): string[] {
            return this.isEsim ? this.channels[SIM_TYPE.ESIM] : this.channels[SIM_TYPE.PSIM];
        },
        isEsim(): boolean {
            return this.upload.resourceType?.id === RESOURCE_TYPE[SIM_TYPE.ESIM];
        },
        historySidebarData(): CollapsibleListItem[] {
            return [
                {
                    name: this.$t('generic.general'),
                    rows: [
                        {
                            name: this.$t('generic.filename'),
                            value: this.uploadHistorySelected?.fileName || '',
                        },
                        {
                            name: this.$t('qodNumberManagement.bulkUploadId'),
                            value: this.uploadHistorySelected?.bulkUploadId || this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('qodNumberManagement.recordCount'),
                            value: this.uploadHistorySelected?.recordCount ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('generic.uploadedBy'),
                            value: this.entity?.createdBy || this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('generic.uploadTime'),
                            value: this.$localeLibrary.getFormattedDateAndTime(this.uploadHistorySelected?.createdAt),
                        },
                    ],
                    isCollapsed: false,
                },
                {
                    name: this.$t('qodNumberManagement.provisionStatus'),
                    rows: Object.entries(this.uploadHistorySelected?.eventCategoryCounters || {}).map(
                        ([status, count]) => ({
                            name: formatEventCategory(status),
                            value: this.uploadHistorySelected?.recordCount
                                ? `${count}/${this.uploadHistorySelected?.recordCount}`
                                : this.$t('generic.unknown'),
                        }),
                    ),
                    isCollapsed: false,
                },
            ];
        },
    },

    created() {
        if (isUserAllowed('ViewSIMProfileCounts')) {
            this.loadCounts();
        }
        if (isViewEnabled('EsimBrandsUpload')) {
            this.loadBrands();
        }
    },

    methods: {
        loadCounts() {
            this.$withProgressBar(
                async () => {
                    const { data } = await getCounts();
                    const counts = {
                        [SIM_TYPE.PSIM]: new Counts(data?.counts?.psim_profile),
                        [SIM_TYPE.ESIM]: new Counts(data?.counts?.esim_profile),
                    };

                    const columns: Array<keyof Counts> = ['total', 'available', 'reserved', 'used'];
                    this.counts = Object.values(SIM_TYPE).map(key =>
                        counts[key]
                            ? {
                                  title: `${this.$tc(`operator.${key}`)} ${this.$t('operator.counts')}`,
                                  fields: columns.map(column => ({
                                      label: `${this.$t(`operator.${column}`)} ${this.$tc(
                                          `operator.${key}`,
                                          counts[key][column],
                                      )}`,
                                      value: counts[key][column],
                                  })),
                              }
                            : null,
                    );
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('alertMessage.failedToLoadNecessaryData'));
                    },
                },
            );
        },
        loadBrands() {
            this.$withProgressBar(
                async () => {
                    this.isLoadingConfig = true;
                    const {
                        data: { data },
                    } = await getRemoteConfig();

                    if (data[REMOTE_CONFIG_KEYS.ESIM_BRANDS]) {
                        this.brands = JSON.parse(data[REMOTE_CONFIG_KEYS.ESIM_BRANDS]?.value);
                        [this.upload.brand] = this.brands;
                    }
                    if (data[REMOTE_CONFIG_KEYS.INVENTORY_TAGS]) {
                        const inventoryTags = JSON.parse(data[REMOTE_CONFIG_KEYS.INVENTORY_TAGS].value);
                        this.channels[SIM_TYPE.ESIM] = Object.keys(inventoryTags.esim_profile || {});
                        this.channels[SIM_TYPE.PSIM] = Object.keys(inventoryTags.psim_profile || {});
                    }
                    [this.upload.channel] = this.channelOptions;
                    this.isLoadingConfig = false;
                },
                {
                    errorHandler: () => {
                        this.isLoadingConfig = false;
                        this.$alert(this.$t('alertMessage.failedToLoadNecessaryData'));
                    },
                },
            );
        },
        async loadHistory(): Promise<void> {
            await this.$withProgressBar(
                async () => {
                    this.isLoadingHistory = true;
                    this.uploadHistory = await getUploadHistory();
                    this.isLoadingHistory = false;
                },
                {
                    errorHandler: () => {
                        this.isLoadingHistory = false;
                        this.$alert(this.$t('qodNumberManagement.loadingBulkUploadHistoryFailed'));
                    },
                },
            );
        },
        onHistoryRowSelect(entity: UploadedFileDetails): void {
            this.uploadHistorySelected = entity;
        },
        onResourceTypeSelect(): void {
            [this.upload.brand] = this.brands;
            [this.upload.channel] = this.channelOptions;
        },
        async onDownloadUploadedFile(): Promise<void> {
            await this.$withProgressBar(
                async () => {
                    const uploadedFilename = this.uploadHistorySelected?.entityName as string;
                    const {
                        data: {
                            signed_entity: { url },
                        },
                    } = await getSignedURLForDownload(DATAFLOW_API_TYPE.SIM_BULK, uploadedFilename);
                    // Download file from signed URL
                    const fileRes = await fetch(url);
                    const file = await fileRes.text();

                    if (!fileRes.ok) {
                        throw new Error(`Fetch to endpoint ${url} to download ${uploadedFilename} failed.`);
                    }

                    download(file, this.uploadHistorySelected?.fileName, 'text/plain');
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('qodNumberManagement.failedToDownloadFile'));
                    },
                },
            );
        },
    },
});
