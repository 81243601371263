export default {
    HOME_VIEW: 'Home',
    LOGIN: 'Login',

    // PRICING AND FEES

    FEE_RULES: 'Fee Rules',
    FEE_RULES_EDITOR: 'Fee Rules Editor',
    PRICING_RULES: 'Pricing Rules',
    PRICING_RULES_EDITOR: 'Pricing Rules Editor',

    // BILLING

    DUNNING_PROFILES: 'Dunning Profiles',
    DUNNING_PROFILES_EDITOR: 'Dunning Profiles Editor',
    CYCLE_SPECIFICATION: 'Cycle Specification',
    CYCLE_SPECIFICATION_EDITOR: 'Cycle Specification Editor',

    // CHARGING

    CHARGING_WRAPPER: 'Charging Wrapper',
    CHARGING_POLICY_COUNTERS: 'Policy Counters',
    CHARGING_POLICY_COUNTER_EDITOR: 'Policy Counter Editor',
    CHARGING_POLICY_RULES: 'Policy Rules',
    CHARGING_POLICY_RULE_EDITOR: 'Policy Rule Editor',
    CHARGING_RATING_GROUPS: 'Rating Groups',
    CHARGING_RATING_GROUP_EDITOR: 'Rating Group Editor',
    CHARGING_CHARGING_SPECIFICATIONS: 'Charging Specifications',
    CHARGING_CHARGING_SPECIFICATION_EDITOR: 'Charging Specification Editor',
    CHARGING_CHARGING_SPECIFICATIONS_GROUPS: 'Charging Specifications Groups',
    CHARGING_SPECIFICATIONS_GROUP_EDITOR: 'Charging Specifications Group Editor',
    CHARGING_USAGE_COUNTERS: 'Usage Counters',
    CHARGING_USAGE_COUNTERS_EDITOR: 'Usage Counters Editor',
    CHARGING_CONDITION_PARAMETERS: 'Condition Parameters',
    CHARGING_CONDITION_PARAMETERS_EDITOR: 'Condition Parameters Editor',
    CHARGING_WALLET_COUNTERS: 'Wallet Counters',
    CHARGING_WALLET_COUNTER_EDITOR: 'Wallet Counter Editor',
    CHARGING_CONTENT_TYPES: 'Content Types',
    CHARGING_CONTENT_TYPE_EDITOR: 'Content Type Editor',
    CHARGING_TARIFF_SPECIFICATIONS: 'Tariff Specifications',
    ZERO_RATE_CHARGING_TARIFF_SPECIFICATIONS: 'Zero Rate Tariff Specifications',
    CHARGING_TARIFF_SPECIFICATION_EDITOR: 'Tariff Specification Editor',
    CHARGING_TARIFF_SPECIFICATION_GROUPS: 'Tariff Specification Groups',
    CHARGING_TARIFF_SPECIFICATION_GROUP_EDITOR: 'Tariff Specification Group Editor',
    CHARGING_BUCKET_WIZARD_USER_SEARCH: 'Bucket Wizard User Search',
    CHARGING_BUCKET_WIZARD: 'Bucket Wizard',

    // ORCHESTRATION ENGINE

    ORCHESTRATION_ENGINE_LIST: 'Orchestration Engine List',
    ORCHESTRATION_ENGINE_EDITOR: 'Orchestration Engine Editor',
    PLAN_EDITOR: 'Plan Editor',
    ORCHESTRATION_ENGINE_EXECUTION_DETAILS: 'Execution Details',
    ORCHESTRATION_ENGINE_TEMPLATES_LIST_PAGE: 'Orchestration Engine Templates List Page',
    ORCHESTRATION_ENGINE_TEMPLATES_EDITOR: 'Orchestration Engine Templates Editor',
    ORCHESTRATION_ENGINE_EXECUTIONS_SEARCH: 'Orchestration Engine Executions Search',
    ORCHESTRATION_ENGINE_MAPPED_EXECUTIONS_SEARCH_RESULT: 'Orchestration Engine Mapped Executions Search Result',
    ORCHESTRATION_ENGINE_PAUSED_EXECUTIONS_LIST_PAGE: 'Orchestration Engine Paused Executions List Page',
    ORCHESTRATION_ENGINE_BATCH_ACTIONS_LIST: 'Orchestration Engine Batch Actions List',
    ORCHESTRATION_ENGINE_BATCH_ACTIONS_EDITOR: 'Orchestration Engine Batch Actions Editor',

    // OPERATE APIs
    OPERATE_APIS_APPLICATION_OWNER_LIST_PAGE: 'Operate APIs Application Owner List Page',
    OPERATE_APIS_APPLICATION_OWNER_EDITOR: 'Operate APIs Application Owner Editor',
    OPERATE_APIS_APPLICATIONS_LIST_PAGE: 'Operate APIs Applications List Page',
    OPERATE_APIS_APPLICATIONS_EDITOR: 'Operate APIs Applications Editor',
    OPERATE_APIS_PRODUCTS_LIST_PAGE: 'Operate APIs Products List Page',
    OPERATE_APIS_PRODUCT_ORDERS_LIST_PAGE: 'Operate APIs Product Orders List Page',
    OPERATE_APIS_PRODUCT_ORDERS_EDITOR: 'Operate APIs Product Orders Editor',
    OPERATE_APIS_CHANNEL_PARTNERS_LIST_PAGE: 'Operate APIs Channel Partners List Page',

    // MANUAL ORDER RESUME
    MANUAL_ORDER_RESUME: 'Manual Order Resume',

    // PRODUCT CATALOG

    // TEMPLATES
    TEMPLATES_LIST: 'Templates',
    TEMPLATE_EDITOR: 'Template Editor',
    PRESET_TEMPLATES_LIST: 'Preset Templates',
    PRESET_TEMPLATES_EDITOR: 'Preset Templates Editor',
    // ENTITIES
    OFFERS_LIST_NEW: 'Offers new',
    PRODUCT_LIST_NEW: 'Product new',
    SERVICES_LIST_NEW: 'Services new',
    RESOURCE_LIST_NEW: 'Resources new',
    ENTITY_EDITOR: 'Entity Editor',
    BULK_EDIT_ENTITIES: 'Bulk Edit',
    BULK_EDIT_FEEDBACK: 'Bulk Edit Status',
    ATIOT_OFFER_PRICE_LIST: 'Offer price list',
    // TAGS
    TAGS_LIST: 'Tags',
    TAG_EDITOR: 'Tag Editor',
    // CATEGORIES
    CATEGORY_LIST: 'Category List',
    CATEGORY_EDITOR: 'Category Editor',
    // FACETS
    FACET_LIST: 'Facets',
    FACET_EDITOR: 'Facet Editor',
    // VALIDATION RULES
    VALIDATION_RULES_LIST: 'Validation Rules',
    VALIDATIONS_RULES_EDITOR: 'Validation Rules Editor',

    // PROMOTIONS

    PROMOTIONS: 'Promotions',
    PROMOTIONS_EDITOR: 'Promotions Editor',

    // REWARDS

    REWARDS_VIEW: 'Rewards',
    REWARDS_RULES: 'Rules',
    REWARDS_TIERS: 'Tiers',
    REWARD_EDITOR: 'Reward Editor',
    PRIZE_VIEW: 'Prizes',
    PRIZE_EDITOR: 'Prize Editor',
    PRIZE_ENGINE_VIEW: 'Prize Engine',
    PRIZE_ENGINE_EDITOR: 'Prize Engine Editor',
    VOUCHER_SET_VIEW: 'Voucher',
    VOUCHER_SET_EDITOR: 'Voucher Editor',
    PAYOUTS: 'Payouts',
    REWARD_PAYOUT_EDITOR: 'Payout Editor',
    LOTTERIES: 'Lotteries',
    LOTTERY_EDITOR: 'Lottery Editor',

    // PRICING

    PRICING_RULES_KUIPER_DEMO: 'Pricing Rules Kuiper Demo',
    PRICING_RULES_EDITOR_KUIPER_DEMO: 'Pricing Rules Editor Kuiper Demo',

    // NUMBER MANAGEMENT

    OPERATOR_NUMBER_MANAGEMENT_VIEW: 'Number Management',
    OPERATOR_NUMBER_MANAGEMENT_ADD: 'Add a number',

    // OPERATIONS

    OPS_VIEW: 'Operations',
    OPS_GRAPHS: 'Graphs',
    OPS_SERVICE_DESK: 'Service Desk',

    // SIM PROFILE MANAGEMENT

    SIM_PROFILE_MANAGEMENT: 'SIM Profile Management',
    SIM_UPLOAD: 'SIM Upload',

    // DEVELOPER LINE AUTHORIZATION

    DEVELOPER_LINE_AUTHORIZATION: 'Developer Line Authorization',

    // UPDATE WPS
    UPDATE_WPS: 'Update WPS',

    // DEVEDGE APPLICATION

    DEVEDGE_APPLICATION: 'Devedge Application',

    // SEGMENTS

    DATA_HUB_VIEW: 'Data Hub',
    SEGMENTS_VIEW: 'Segments',
    SEGMENTS_LIST: 'Manage Segments',
    SEGMENTS_EDIT: 'Edit Segment',
    SEGMENTS_ADD: 'Create Segment',
    SEGMENTS_STATIC_FILTERS_ADD: 'Create Static Filter',
    SEGMENTS_STATIC_FILTERS_EDIT: 'Edit Static Filter',

    // EVENTS

    EVENTS_DASHBOARD: 'Events Dashboard',
    EVENTS_EDITOR: 'Events editor',
    EVENTS_EDITOR_V2: 'Events editor v2',
    ORD_CONFIG_DASHBOARD: 'ORD configuration dashboard',
    ORD_CONFIG_EDITOR: 'ORD configuration editor',
    REDSHIFT_CONFIG_DASHBOARD: 'Redshift configuration dashboard',
    REDSHIFT_CONFIG_EDITOR: 'Redshift configuration editor',
    KAFKA_SINK_CONFIG_DASHBOARD: 'Kafka sink configuration dashboard',
    KAFKA_SINK_CONFIG_EDITOR: 'Kafka sink configuration editor',
    API_INVOKER_CONFIG_DASHBOARD: 'API Destinations dashboard',
    API_INVOKER_CONFIG_EDITOR: 'API Destinations editor',
    JSONPATH_TESTER: 'JSONPath Tester',
    DLQ_DASHBOARD: 'Redshift Ingestion Recovery',

    // PIPELINES

    SHARED_FILE_LOCATIONS_DASHBOARD: 'Shared File Locations',

    // REPORTS

    REPORTS: 'Reports',

    // Dataflow

    DATAFLOWS_EDITOR: 'Dataflows editor',

    // CAMPAIGNS

    MESSAGES_VIEW: 'Messages',
    CAMPAIGNS_VIEW: 'Campaigns',
    CAMPAIGNS_ADD: 'Create Campaign',
    CAMPAIGNS_EDIT: 'Edit Campaign',
    MESSAGE_TEMPLATE_TESTER: 'Message Template Tester',

    // CUSTOMER CARE

    CUSTOMER_CARE_VIEW: 'Customer Care',
    CUSTOMER_CARE_USER_MANAGEMENT: 'User Management',
    USER_MANAGEMENT_USER_V2: 'User Manager - User',
    USER_MANAGEMENT_ACCOUNT_V2: 'User Manager - Account',
    USER_MANAGEMENT_SUBSCRIBER_V2: 'User Manager - Subscriber',
    USER_MANAGEMENT_ORGANIZATION: 'User Manager - Organization',
    USER_MANAGEMENT_PHONES_TABLE: 'User Manager - Phones table',
    USER_MANAGEMENT_USER_TABLE: 'User Manager - User table',
    MESSAGE_HISTORY: 'Message History',
    ESIM: 'ESIM',
    ESIM_DETAILS: 'ESIM details',
    AGENT_NOTES_NEW_NOTE: 'Agent Notes - New Note',
    VIASAT_LEADS: 'Leads',
    QR_CODE_RESEND_USER_SEARCH: 'Guest purchase',
    QR_CODE_RESEND_PAGE: 'QR Code resend',
    VIASAT_PARTNER_MANAGEMENT: 'Partner Management',
    GOR_PAYMENT_USER_SEARCH: 'Gor Payment User Search',
    GOR_PAYMENT_INFO: 'Gor Payment Info',
    END_USER_AUTHORIZATION: 'EndUserAuthorization',

    // SETTINGS

    SETTINGS: 'Settings',
    USER_SETTINGS: 'User Settings',
    OPERATOR_LOCALIZATION: 'Operator Localization',
    REMOTE_CONFIG: 'Remote configuration',
    TENANTS: 'Tenants',
    TENANT_EDITOR: 'Tenants Editor',
    QUIET_HOURS: 'Quiet Hours',
    CAMPAIGN_CATEGORIES: 'Campaign categories',
    CAMPAIGN_CATEGORIES_EDITOR: 'Campaign categories editor',
    QUIET_HOURS_EDITOR: 'Quiet Hours Editor',
    USER: 'User',
    USER_EDITOR: 'User Editor',
    ROLES: 'Roles',
    ROLE_EDITOR: 'Role Editor',
    APPLICATION_MANAGER: 'Application Management',
    MANUAL_PROXY_REQUEST: 'Manual Proxy Request',
    HOMEPAGE_CONFIG: 'Hopepage view config',
    THIRD_PARTY_APPLICATIONS: 'Third-party Applications',

    // OPERATOR

    OPERATOR_VIEW: 'Operator',
    OPERATOR_CONFIG_VIEW: 'Operator Config',
    OPERATOR_CONFIG_LIST: 'List of Configurations',
    OPERATOR_CONFIG_ADD: 'Add Configuration Entry',
    OPERATOR_CONFIG_EDIT: 'Edit Configuration Entry',

    // ACCOUNT

    ACCOUNT_VIEW: 'Manage Your Account',

    // USER GUIDE

    USER_GUIDE: 'User Guide',
    USER_GUIDE_HOME: 'User Guide Home',
    USER_GUIDE_TAGS: 'User Guide Tags',
    USER_GUIDE_TAG: 'User Guide Tag',
    USER_GUIDE_ARTICLES: 'User Guide Articles',
    USER_GUIDE_ARTICLE: 'User Guide Article',

    // DOCUMENTS

    DOCUMENT_TEMPLATES: 'Document Templates',
    DOCUMENT_TEMPLATES_EDITOR: 'Document Templates Editor',
    DOCUMENT_ASSETS: 'Document Assets',
    DOCUMENT_ASSETS_EDITOR: 'Document Assets Editor',

    // AUTHENTICATION

    AUTHENTICATION: 'Authentication',
    AUTHENTICATION_MAIN: 'Auth Configurator Main Page',
    AUTHENTICATION_CONFIGURATOR: 'Auth Configurator',
    AUTHENTICATION_ROLE_MAPPER: 'Role to Group Mappings',
    AUTHENTICATION_USER_MAPPER: 'User to Role Mappings',
    AUTHENTICATION_ROLE_MAPPING: 'Role Mapping',

    // CUSTOMER CARE SUITE

    CCS_VIEW: 'Customer Care Suite',
    CCS_SEARCH: 'Customer Care Suite Search',
    CCS_USER_RESULTS_TABLE: 'Customer Care Suite Search - User Results',
    CCS_USER_PHONES_TABLE: 'Customer Care Suite Search - Phone Results',
    CCS_USER_PAGE: 'Customer Care - User',
    CCS_ACCOUNT_PAGE: 'Customer Care - Account Page',
    CCS_SUBSCRIBER_PAGE: 'Customer Care - Subscriber Page',
    CCS_SUBSCRIBER_EDIT_PAGE: 'Customer Care - Edit Subscriber',
    CCS_ORGANIZATION_PAGE: 'Customer Care - Organization Page',
    CCS_BILLING_ACCOUNT_PAGE: 'Customer Care - Billing Account Page',
};
