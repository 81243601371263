



























import Vue, { type PropType } from 'vue';

// components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppJSON from '@/components/partials/AppJSON.vue';

// helpers
import { castArray, compact, filter, isObject } from 'lodash';
import filters from '@/common/filters';
import { type ImportEntityType, entityCreators, shouldEntityImportResetVersion } from '@/common/importEntitiesHelper';
import { getErrorMessage } from '@/common/cepHelper';
import { entityTypeToEntityLabel } from '@/common/entities/entityHelper';

type ImportEntities = Record<string, any>;

export default Vue.extend({
    name: 'ImportEntitiesModal',
    filters,
    components: { AppButton, AppJSON, AppDialogV2 },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        entityType: {
            type: String as PropType<ImportEntityType>,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false as boolean,
            entities: null as null | ImportEntities,
            objectIsValid: false as boolean,
            BUTTON_TYPES,
        };
    },
    computed: {
        entitiesArray(): ImportEntities[] {
            return filter(castArray(this.entities), isObject);
        },
        buttonLabel(): string {
            return this.entitiesArray.length
                ? [
                      this.$t('generic.import'),
                      this.$options?.filters?.pluralFull(
                          entityTypeToEntityLabel[this.entityType] || this.entityType,
                          this.entitiesArray.length,
                      ),
                  ].join(' ')
                : this.$t('generic.import');
        },
        buttonDisabled(): boolean {
            return !this.entitiesArray.length || !this.objectIsValid;
        },
        entityCreateFn(): (typeof entityCreators)[keyof typeof entityCreators] {
            if (!entityCreators[this.entityType]) {
                this.$showErrorAlert({
                    message: this.$t('alerts.entityNotSupported', {
                        entity: this.entityType,
                    }),
                });
            }

            return entityCreators[this.entityType];
        },
    },
    methods: {
        async startImport(): Promise<void> {
            let res = [];
            this.$eventBus.$emit('closeAllAlerts');

            if (this.entityCreateFn) {
                this.isLoading = true;

                res = await Promise.all(
                    this.entitiesArray.map(async entity => {
                        try {
                            return await this.entityCreateFn(entity);
                        } catch (e) {
                            return this.$showErrorAlert({
                                message: this.$t('alerts.entitiesImportError', {
                                    entity: this.entityType,
                                    name: entity.name,
                                    message: getErrorMessage(e),
                                }),
                            });
                        }
                    }),
                );

                this.isLoading = false;
            }

            this.handleImportResult(res);
        },

        handleImportResult(res: any): void {
            const successCount = compact(res).length;

            if (successCount < 1) {
                return;
            }

            this.$emit('finish');

            if (successCount === this.entitiesArray.length) {
                this.$showSuccessAlert({
                    message: this.$t('alerts.entitiesImportSuccessAll', {
                        entities: this.$options?.filters?.plural(this.entityType, 2),
                    }),
                });
                this.entities = null;
                this.$emit('close');
            } else {
                this.$showSuccessAlert({
                    message: this.$t('alerts.entitiesImportSuccessPart', {
                        imported: successCount,
                        total: this.entitiesArray.length,
                        entities: this.$options?.filters?.plural(this.entityType, successCount),
                    }),
                });
            }
        },
        onJsonInput(config: any): void {
            this.entities = config;

            if (this.entities && shouldEntityImportResetVersion(this.entityType)) {
                this.entities.version = 0;
            }
        },
    },
});
