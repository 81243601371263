import i18n from '@/i18n';
import { GENERATION_TYPES_BY_KEYS, SEGMENT_CREATION_SOURCES, SEGMENT_CREATION_SOURCES_CODES } from '@/common/segments';
import { StaticFilterLabels } from '@/common/StaticFilter';
import { generationTypeLabelMap } from '@/__new__/features/segments/common/segmentHelper';
import {
    StaticFilterEntriesDifferenceData,
    StaticFilterRange,
} from '@/__new__/services/dno/segments/models/StaticFilter';

import { EntityActions } from '@/common/commonEntityStateMapper';
import localeLibrary from '@/common/locale/localeLibrary';
import { STATUS_CODES_OPERATIONS, USER_MODES } from '@/common/commonHelper';
import { TranslateResult } from 'vue-i18n';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { replaceIdByEventTypeInTriggerCondition } from '@/__new__/services/dno/triggers/TriggerCondition';

export default class Segment {
    id: string;
    name: string;
    creationDate;
    filtersJson;
    description: string | null;
    segmentIdType: any;
    generationType: any;
    addCondition: any;
    removeCondition: any;
    createdBy;
    entryValues: string[] | null;
    inputMethods: Record<string, boolean> | null;
    ranges?: StaticFilterRange[] | null;
    version: number;
    entriesDifferenceData: StaticFilterEntriesDifferenceData | null;
    segmentIdTypeLabel: string | null;
    segmentType: null | number;
    operation: null | number;
    latestSnapshotUpdateTimestamp: null | number;
    latestSnapshotUpdateTimestampFormatted: TranslateResult | null;
    state: null | number;
    produceEventsEnterLeave: boolean;

    constructor(
        id: string,
        name: string,
        creationDate: number,
        filtersJson = [],
        description = null,
        segmentIdType: any,
        generationType: any,
        addCondition = null,
        removeCondition = null,
        createdBy = SEGMENT_CREATION_SOURCES.USER_MADE,
        entryValues = null,
        inputMethods = null,
        ranges = null,
        version = 1,
        entriesDifferenceData = null,
        segmentIdTypeLabel = '',
        segmentType = null,
        operation = STATUS_CODES_OPERATIONS.RUNNING,
        latestSnapshotUpdateTimestamp = Date.now(),
        state = null,
        produceEventsEnterLeave = false,
    ) {
        this.id = id;
        this.name = name;
        this.creationDate = creationDate;
        this.filtersJson = filtersJson;
        this.description = description;
        this.segmentIdType = segmentIdType;
        this.generationType = generationType;
        this.addCondition = addCondition;
        this.removeCondition = removeCondition;
        this.createdBy = createdBy;
        this.inputMethods = inputMethods;
        this.entryValues = entryValues;
        this.ranges = ranges;
        this.version = version;
        this.entriesDifferenceData = entriesDifferenceData;
        this.segmentIdTypeLabel = segmentIdTypeLabel;
        this.segmentType = segmentType;
        this.operation = operation;
        this.latestSnapshotUpdateTimestamp = latestSnapshotUpdateTimestamp;
        this.latestSnapshotUpdateTimestampFormatted =
            localeLibrary.getFormattedDateAndTime(latestSnapshotUpdateTimestamp);
        this.state = state;
        this.produceEventsEnterLeave = produceEventsEnterLeave;
    }

    validate() {
        if (this.generationType === GENERATION_TYPES_BY_KEYS.DYNAMIC) {
            if (!this.filtersJson.length) {
                throw new Error(i18n.t('segments.alerts.chooseAtLeastOneFilter').toString());
            }
        }

        if (this.generationType === GENERATION_TYPES_BY_KEYS.TRIGGER_BASED) {
            if (!this.addCondition.length) {
                throw new Error(i18n.t('segments.alerts.chooseAtLeastOneFilter').toString());
            }
        }
    }
    buildDynamicSegmentJson() {
        const payload: any = {
            id: this.id ?? undefined,
            version: this.version ?? undefined,
            data: {
                name: this.name,
                description: this.description,
                segment_type: this.generationType,
                creation_type: this.createdBy,
                entry_type: this.segmentIdType,
                produce_events_enter_leave: this.produceEventsEnterLeave,
            },
        };

        if (this.generationType === GENERATION_TYPES_BY_KEYS.DYNAMIC) {
            payload.data.filters = this.filtersJson;
        } else if (this.generationType === GENERATION_TYPES_BY_KEYS.TRIGGER_BASED) {
            payload.data.add_condition = this.addCondition?.length
                ? {
                      filters: this.addCondition,
                      id_type: this.segmentIdType,
                  }
                : null;
            payload.data.remove_condition = this.removeCondition?.length
                ? {
                      filters: this.removeCondition,
                      id_type: this.segmentIdType,
                  }
                : null;
        }

        return payload;
    }
    buildJson() {
        return this.generationType === GENERATION_TYPES_BY_KEYS.STATIC_FILTER
            ? this.buildStaticSegmentJson()
            : this.buildDynamicSegmentJson();
    }
    buildStaticSegmentJson() {
        return {
            id: this.id ?? undefined,
            version: this.version ?? undefined,
            data: {
                segment_type: this.generationType,
                produce_events_enter_leave: this.produceEventsEnterLeave,
                entry_type: this.segmentIdType,
                description: this.description,
                name: this.name,
                entry_values: this.entryValues,
                input_methods: this.inputMethods,
                ...(this.ranges && { ranges: this.ranges }),
                ...(this.entriesDifferenceData && { ...this.entriesDifferenceData }),
            },
        };
    }

    static empty() {
        return {
            name: null,
        };
    }
    static fromJson(json: any) {
        return new Segment(
            json.id,
            json.data.name,
            json.update_time,
            json.data.filters
                ? replaceIdByEventTypeInTriggerCondition({
                      filters: json.data.filters, // pass object here to avoid creating separate function
                  }).filters
                : null,
            json.data.description,
            json.data.entry_type,
            mapSegmentType(json.data.segment_type),
            json.data.add_condition?.filters
                ? replaceIdByEventTypeInTriggerCondition(json.data.add_condition).filters
                : null,
            json.data.remove_condition?.filters
                ? replaceIdByEventTypeInTriggerCondition(json.data.remove_condition).filters
                : null,
            SEGMENT_CREATION_SOURCES_CODES[json.data.creation_type],
            null,
            json.data.input_methods,
            null,
            json.version,
            null,
            StaticFilterLabels[json.data.entry_type],
            json.data.segment_type,
            json.data.operation,
            json.data.latest_snapshot_update_timestamp,
            json.state,
            json.data.produce_events_enter_leave,
        );
    }
}

export function mapSegmentOperationToEntityActions(segment: Segment, userMode: string) {
    const userHasWriteAccess = isUserAllowed('SegmentsWrite');
    const userHasReadAccess = isUserAllowed('SegmentsRead');

    const minimalAvailableActions = [EntityActions.DETAILS];

    // Pre-created segments are read-only for all users
    if (segment.createdBy === SEGMENT_CREATION_SOURCES.PRE_CREATED) {
        return minimalAvailableActions;
    }

    if (userHasReadAccess && !userHasWriteAccess) {
        return minimalAvailableActions;
    }

    const defaultEditActions = [
        ...minimalAvailableActions,
        EntityActions.EDIT,
        EntityActions.DELETE,
        EntityActions.DOWNLOAD,
    ];
    if (userHasWriteAccess && userMode === USER_MODES.REGULAR) {
        return defaultEditActions;
    }

    if (
        userHasWriteAccess &&
        userMode === USER_MODES.EXPERIMENTAL &&
        segment.segmentType === GENERATION_TYPES_BY_KEYS.DYNAMIC
    ) {
        switch (segment.operation) {
            case STATUS_CODES_OPERATIONS.STOPPED:
                return [...defaultEditActions, EntityActions.START];
            case STATUS_CODES_OPERATIONS.RUNNING:
                return [...defaultEditActions, EntityActions.STOP];
            default:
                return defaultEditActions;
        }
    }

    return minimalAvailableActions;
}

function mapSegmentType(value: number) {
    return generationTypeLabelMap(value) || value;
}
