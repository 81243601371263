<template>
    <AbstractEditPageWrapper>
        <template #header>
            <AppHeader :pageTitle="pageTitle" />
        </template>

        <template #content>
            <!-- General section -->
            <div class="editor-section">
                <div class="d-flex align-items-center mb-3">
                    <div
                        v-show="isEditing"
                        class="lf-subtitle mb-0"
                    >
                        {{ $i18n.t('generic.general') }}
                        <p class="lf-secondary-text mb-0">{{ $i18n.t('generic.id') }}: {{ entityId }}</p>
                        <p class="lf-secondary-text mb-0">
                            {{ $i18n.t('generic.updateTime') }}:
                            {{ $localeLibrary.getFormattedDateAndTime(updateTime) }}
                        </p>
                        <p class="lf-secondary-text mb-0">{{ $i18n.t('generic.updateUser') }}: {{ updateName }}</p>
                    </div>
                    <div class="flex-grow-1 d-flex align-items-center justify-content-end">
                        <EntityStatusIndicator
                            v-if="showStatusIndicator"
                            :status="state"
                            class="ml-3"
                        />
                        <AppLabel
                            v-if="isDraft"
                            :title="$i18n.t('generic.stateMap.draft')"
                            :color="LABEL_COLOR.gray"
                            class="ml-3"
                        />
                        <AppLabel
                            v-if="isPublished"
                            :title="$i18n.t('generic.stateMap.published')"
                            :color="LABEL_COLOR.green"
                            class="ml-3"
                        />
                        <AppLabel
                            v-if="isUnpublished"
                            :title="$i18n.t('generic.stateMap.unpublishedChanges')"
                            :color="LABEL_COLOR.gray"
                            class="ml-3"
                        />
                        <AppButton
                            v-if="allowEditDraftBtn"
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$i18n.t('generic.editDraft')"
                            :class="{ 'ml-auto': !isDraft && !isPublished }"
                            class="ml-3"
                            @click="reloadEditor(EDITOR_MODE.EDIT)"
                        />
                        <AppButton
                            v-if="allowViewPublishedBtn"
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$i18n.t('generic.viewPublished')"
                            :class="{ 'ml-auto': !isDraft && !isPublished }"
                            class="ml-3"
                            @click="reloadEditor(EDITOR_MODE.VIEW)"
                        />
                        <AppButton
                            v-if="isRevertAvailable"
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$i18n.t('productCatalog.editors.revertToPublished')"
                            class="ml-3"
                            @click="resetToPublished"
                        />
                    </div>
                </div>
            </div>
            <AppInputV3
                v-model="name[selectedLanguage]"
                :placeholder="$i18n.t('generic.addName')"
                :label="$i18n.t('generic.name')"
                :invalid="requiredErrors.showNameFieldRequiredError"
                :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                :disabled="readonly"
                class="editor-input-largest mb-3"
                data-test-id="name-input"
                @input="requiredErrors.showNameFieldRequiredError = false"
            />
            <AppTextareaV3
                v-model="description[selectedLanguage]"
                :placeholder="$i18n.t('generic.addDescription')"
                :label="$i18n.t('generic.description')"
                :disabled="readonly"
                data-test-id="description-textarea"
                class="editor-input-largest mb-5"
            />

            <!-- ChargingSpecification type CS selected -->
            <div
                v-t="'charging.chargingSpecifications.editor.chargingSpecificationDetails'"
                class="section-title"
            />
            <div
                v-t="'charging.chargingSpecifications.editor.chooseCSType'"
                class="section-content"
            />

            <div class="cs-form-wrapper">
                <!-- CS type selection: Voice, Data, SMS, MMS, API -->
                <CardListWrapper
                    v-model="selectedCSType"
                    :cardsValues="chargingSpecificationOptions"
                    :small="true"
                    :requiredError="requiredErrors.showCSTypeSelectRequiredError"
                    :disabled="isCsApproved || readonly"
                    @input="chargingSpecificationOptionsChanged"
                >
                    <template slot="cardBodyvoice">
                        <div class="d-flex justify-content-center align-items-center">
                            <AppIcon
                                :type="ICON_TYPES.VOICE"
                                :color="ICON_COLORS.BLUE"
                            />
                            <div
                                v-t="'generic.chargingSpecificationTypes.voice'"
                                class="chargingSpecification-type-cards"
                            />
                        </div>
                    </template>
                    <template slot="cardBodydata">
                        <div class="d-flex justify-content-center align-items-center">
                            <AppIcon
                                :type="ICON_TYPES.DATA"
                                :color="ICON_COLORS.BLUE"
                            />
                            <div
                                v-t="'generic.chargingSpecificationTypes.data'"
                                class="chargingSpecification-type-cards"
                            />
                        </div>
                    </template>
                    <template slot="cardBodysms">
                        <div class="d-flex justify-content-center align-items-center">
                            <AppIcon
                                :type="ICON_TYPES.SMS"
                                :color="ICON_COLORS.BLUE"
                            />
                            <div
                                v-t="'generic.chargingSpecificationTypes.sms'"
                                class="chargingSpecification-type-cards"
                            />
                        </div>
                    </template>
                    <template slot="cardBodymms">
                        <div class="d-flex justify-content-center align-items-center">
                            <AppIcon
                                :type="ICON_TYPES.MMS"
                                :color="ICON_COLORS.BLUE"
                            />
                            <div
                                v-t="'generic.chargingSpecificationTypes.mms'"
                                class="chargingSpecification-type-cards"
                            />
                        </div>
                    </template>
                    <template
                        v-if="isChargingSpecificationsApiTypeEnabled"
                        slot="cardBodyapi"
                    >
                        <div class="d-flex justify-content-center align-items-center">
                            <AppIcon
                                :type="ICON_TYPES.SIDEBAR_DATA_HUB"
                                :color="ICON_COLORS.BLUE"
                            />
                            <div
                                v-t="'generic.chargingSpecificationTypes.api'"
                                class="chargingSpecification-type-cards"
                            />
                        </div>
                    </template>
                </CardListWrapper>

                <div v-if="isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.api.id)">
                    <div
                        v-t="'charging.chargingSpecifications.editor.chargeCSApiType'"
                        class="section-content"
                    />
                    <CardListWrapper
                        v-model="apiSubType"
                        :cardsValues="API_SUB_TYPE_TYPES_OPTIONS[CHARGING_SPEC_TYPE.api.id]"
                        :disabled="isCsApproved || readonly"
                        :small="true"
                        :requiredError="$v.apiSubType.$error"
                    >
                        <template slot="cardBodyrequestBased">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.requestBased'"
                                    class="chargingSpecification-type-cards"
                                />
                            </div>
                        </template>
                        <template slot="cardBodydurationBased">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.durationBased'"
                                    class="chargingSpecification-type-cards"
                                />
                            </div>
                        </template>
                        <template slot="cardBodysubscriptionBased">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.subscriptionBased'"
                                    class="chargingSpecification-type-cards"
                                />
                            </div>
                        </template>
                    </CardListWrapper>
                </div>

                <div
                    v-if="Boolean(selectedCSType.id)"
                    v-t="'charging.chargingSpecifications.editor.chargeCSType'"
                    class="section-content"
                />

                <!-- CS type Voice selected -->
                <div v-show="isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.voice.id)">
                    <!-- Charge types: Per period, Per unit, Quota, Unlimited -->
                    <CardListWrapper
                        v-model="chargeSpecState.voice.chargeForChargingSpecification"
                        :cardsValues="CHARGE_TYPES_OPTIONS[CHARGING_SPEC_TYPE.voice.id]"
                        :disabled="isCsApproved || readonly"
                        :small="true"
                        :requiredError="requiredErrors.showAnyChargeSelectRequiredError"
                        @input="onSelectedCSType"
                    >
                        <template slot="groupCardBodyperUnit">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.chargePerUnit'"
                                    class="chargingSpecification-type-cards-small-font"
                                />
                            </div>
                        </template>
                        <template slot="groupCardBodyperPeriod">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.chargePerPeriod'"
                                    class="chargingSpecification-type-cards-small-font"
                                />
                            </div>
                        </template>
                        <template slot="groupCardBodytieredCharge">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.tieredCharge'"
                                    class="chargingSpecification-type-cards-small-font"
                                />
                            </div>
                        </template>
                        <template slot="cardBodyquota">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.quota'"
                                    class="chargingSpecification-type-cards-small"
                                />
                            </div>
                        </template>
                        <template slot="cardBodyunlimited">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.unlimited'"
                                    class="chargingSpecification-type-cards-small"
                                />
                            </div>
                        </template>
                    </CardListWrapper>
                    <ChargeConfigurationVoiceV3
                        v-show="chargeSpecState.voice.chargeForChargingSpecification.id"
                        v-model="chargeTypeModel.voice"
                        :chargeType="chargeSpecState.voice.chargeForChargingSpecification.id"
                        :limitOptions="limitOptions"
                        :totatlLimitOptions="totatlLimitOptions"
                        :disabled="readonly"
                        :requiredError="requiredErrors.chargeValuesSelectRequiredError"
                        @clearRequiredError="requiredErrors.chargeValuesSelectRequiredError = false"
                    />
                </div>

                <!-- CS type Data selected -->
                <div v-show="isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.data.id)">
                    <!-- Charge types: Per period, Per unit, Quota, Unlimited -->
                    <CardListWrapper
                        v-model="chargeSpecState.data.chargeForChargingSpecification"
                        :cardsValues="CHARGE_TYPES_OPTIONS[CHARGING_SPEC_TYPE.data.id]"
                        :disabled="isCsApproved || readonly"
                        :small="true"
                        :requiredError="requiredErrors.showAnyChargeSelectRequiredError"
                        @input="onSelectedCSType"
                    >
                        <template slot="groupCardBodyperUnit">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.chargePerUnit'"
                                    class="chargingSpecification-type-cards-small-font"
                                />
                            </div>
                        </template>
                        <template slot="groupCardBodyperPeriod">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.chargePerPeriod'"
                                    class="chargingSpecification-type-cards-small-font"
                                />
                            </div>
                        </template>
                        <template slot="groupCardBodytieredCharge">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.tieredCharge'"
                                    class="chargingSpecification-type-cards-small-font"
                                />
                            </div>
                        </template>
                        <template slot="cardBodyquota">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.quota'"
                                    class="chargingSpecification-type-cards-small"
                                />
                            </div>
                        </template>
                        <template slot="cardBodyunlimited">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.unlimited'"
                                    class="chargingSpecification-type-cards-small"
                                />
                            </div>
                        </template>
                    </CardListWrapper>

                    <ChargeConfigurationDataV3
                        v-show="chargeSpecState.data.chargeForChargingSpecification.id"
                        v-model="chargeTypeModel.data"
                        :chargeType="chargeSpecState.data.chargeForChargingSpecification.id"
                        :limitOptions="limitOptions"
                        :totatlLimitOptions="totatlLimitOptions"
                        :disabled="readonly"
                        :requiredError="requiredErrors.chargeValuesSelectRequiredError"
                        @clearRequiredError="requiredErrors.chargeValuesSelectRequiredError = false"
                    />
                    <div
                        v-show="showPolicyCounterSelection"
                        class="mt-3"
                    >
                        <div class="d-flex justify-content-between">
                            <div class="d-flex align-items-center">
                                <div class="section-content">
                                    {{ policyCountersLabel }}
                                </div>
                            </div>
                            <div
                                v-show="selectedPolicyCounters.length"
                                :class="['remove-condition', { 'disabled-area': readonly }]"
                                @click="removePolicyCounters"
                            >
                                {{ $i18n.t('generic.remove') }}
                            </div>
                        </div>
                        <TagsMultiselect
                            v-if="!readonly"
                            v-model="selectedPolicyCounters"
                            :options="policyCountersOptions"
                            :placeholder="$i18n.t('charging.policyCounters.addPolicyCounters')"
                            :labelFormatter="entity => entity.label"
                            label="label"
                            trackBy="id"
                            class="col-lg-4 col-12 section-content"
                            data-test-id="addPolicyCounters"
                        />
                        <div
                            v-if="readonly"
                            class="tags-container mb-5"
                        >
                            <ChargingTagLabel
                                v-for="entry in getLowerEntitiesVersions(
                                    CHARGING_ENTITY_TYPES.POLICY_COUNTER,
                                    lowerEntitiesVersions,
                                )"
                                :key="entry && entry.id"
                                :label="entry && entry.name"
                                :overviewMode="true"
                                class="ml-1"
                                @click="openLowerEntitiData(entry, RouteNames.CHARGING_POLICY_COUNTER_EDITOR)"
                            />
                        </div>
                    </div>
                </div>

                <!-- CS type SMS selected -->
                <div v-show="isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.sms.id)">
                    <!-- Charge types: Per unit, Quota, Unlimited -->
                    <CardListWrapper
                        v-model="chargeSpecState.sms.chargeForChargingSpecification"
                        :cardsValues="CHARGE_TYPES_OPTIONS[CHARGING_SPEC_TYPE.sms.id]"
                        :disabled="isCsApproved || readonly"
                        :small="true"
                        :requiredError="requiredErrors.showAnyChargeSelectRequiredError"
                        @input="onSelectedCSType"
                    >
                        <template slot="groupCardBodyperUnit">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.chargePerUnit'"
                                    class="chargingSpecification-type-cards"
                                />
                            </div>
                        </template>
                        <template slot="groupCardBodytieredCharge">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.tieredCharge'"
                                    class="chargingSpecification-type-cards"
                                />
                            </div>
                        </template>
                        <template slot="cardBodyquota">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.quota'"
                                    class="chargingSpecification-type-cards-small"
                                />
                            </div>
                        </template>
                        <template slot="cardBodyunlimited">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.unlimited'"
                                    class="chargingSpecification-type-cards-small"
                                />
                            </div>
                        </template>
                    </CardListWrapper>
                    <ChargeConfigurationSMSandMMS
                        v-show="chargeSpecState.sms.chargeForChargingSpecification.id"
                        v-model="chargeTypeModel.sms"
                        :chargeType="chargeSpecState.sms.chargeForChargingSpecification.id"
                        :requiredError="requiredErrors.chargeValuesSelectRequiredError"
                        :disabled="readonly"
                        @clearRequiredError="requiredErrors.chargeValuesSelectRequiredError = false"
                    />
                </div>

                <!-- CS type MMS selected -->
                <div v-show="isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.mms.id)">
                    <!-- Charge types: Per Unit, Quota, Unlimited -->
                    <CardListWrapper
                        v-model="chargeSpecState.mms.chargeForChargingSpecification"
                        :cardsValues="CHARGE_TYPES_OPTIONS[CHARGING_SPEC_TYPE.mms.id]"
                        :disabled="isCsApproved || readonly"
                        :small="true"
                        :requiredError="requiredErrors.showAnyChargeSelectRequiredError"
                        @input="onSelectedCSType"
                    >
                        <template slot="groupCardBodyperUnit">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.chargePerUnit'"
                                    class="chargingSpecification-type-cards"
                                />
                            </div>
                        </template>
                        <template slot="groupCardBodytieredCharge">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.tieredCharge'"
                                    class="chargingSpecification-type-cards"
                                />
                            </div>
                        </template>
                        <template slot="cardBodyquota">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.quota'"
                                    class="chargingSpecification-type-cards-small"
                                />
                            </div>
                        </template>
                        <template slot="cardBodyunlimited">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.unlimited'"
                                    class="chargingSpecification-type-cards-small"
                                />
                            </div>
                        </template>
                    </CardListWrapper>
                    <ChargeConfigurationSMSandMMS
                        v-show="chargeSpecState.mms.chargeForChargingSpecification.id"
                        v-model="chargeTypeModel.mms"
                        :chargeType="chargeSpecState.mms.chargeForChargingSpecification.id"
                        :requiredError="requiredErrors.chargeValuesSelectRequiredError"
                        :useMMS="true"
                        :disabled="isCsApproved || readonly"
                        @clearRequiredError="requiredErrors.chargeValuesSelectRequiredError = false"
                    />
                </div>

                <!-- CS type API selected -->
                <div v-show="isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.api.id)">
                    <!-- Charge types: Per Unit, Per Period, Unlimited -->
                    <CardListWrapper
                        v-model="chargeSpecState.api.chargeForChargingSpecification"
                        :cardsValues="CHARGE_TYPES_OPTIONS[CHARGING_SPEC_TYPE.api.id]"
                        :disabled="isCsApproved || readonly"
                        :small="true"
                        :requiredError="requiredErrors.showAnyChargeSelectRequiredError"
                        @input="onSelectedCSType"
                    >
                        <template slot="groupCardBodyperUnit">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.chargePerUnit'"
                                    class="chargingSpecification-type-cards"
                                />
                            </div>
                        </template>
                        <template slot="groupCardBodyperPeriod">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.chargePerPeriod'"
                                    class="chargingSpecification-type-cards"
                                />
                            </div>
                        </template>
                        <template slot="groupCardBodytieredCharge">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.tieredCharge'"
                                    class="chargingSpecification-type-cards"
                                />
                            </div>
                        </template>
                        <template slot="cardBodyquota">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.quota'"
                                    class="chargingSpecification-type-cards-small"
                                />
                            </div>
                        </template>
                        <template slot="cardBodyunlimited">
                            <div class="d-flex justify-content-center">
                                <div
                                    v-t="'charging.chargingSpecifications.editor.unlimited'"
                                    class="chargingSpecification-type-cards-small"
                                />
                            </div>
                        </template>
                    </CardListWrapper>
                    <ChargeConfigurationApi
                        v-show="chargeSpecState.api.chargeForChargingSpecification.id"
                        v-model="chargeTypeModel.api"
                        :chargeType="chargeSpecState.api.chargeForChargingSpecification.id"
                        :apiSubType="apiSubType"
                        :requiredError="requiredErrors.chargeValuesSelectRequiredError"
                        :disabled="isCsApproved || readonly"
                        @clearRequiredError="requiredErrors.chargeValuesSelectRequiredError = false"
                    />

                    <AppMultiselectV3
                        v-model="additionalAggregations"
                        :options="ADDITIONAL_AGGREGATIONS_OPTIONS"
                        :additionalLabel="$i18n.t('charging.chargingSpecifications.editor.additionalAggregations')"
                        :placeholder="$i18n.t('charging.chargingSpecifications.editor.selectAdditionalAggregation')"
                        :disabled="readonly"
                        label="name"
                        class="editor-input-large mt-3 mb-5"
                        data-test-id="additionalAggregations"
                    />
                </div>

                <div
                    v-if="showUsageCounterSelection"
                    class="mt-3"
                >
                    <div class="d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <div class="section-content">
                                {{ usageCountersLabel }}
                            </div>
                        </div>
                        <div
                            v-if="!readonly"
                            v-show="selectedUsageCounters.length"
                            class="remove-condition"
                            @click="removeUsageCounters"
                        >
                            {{ $i18n.t('generic.remove') }}
                        </div>
                    </div>
                    <TagsMultiselect
                        v-if="!readonly"
                        v-model="selectedUsageCounters"
                        :options="usageCountersOptions"
                        :placeholder="$i18n.t('charging.chargingSpecifications.editor.addUsageCounters')"
                        :labelFormatter="entity => entity.label"
                        label="label"
                        trackBy="id"
                        class="col-lg-4 col-12 section-content"
                        data-test-id="addUsageCounters"
                    />
                    <div
                        v-if="readonly"
                        class="tags-container mb-5"
                    >
                        <ChargingTagLabel
                            v-for="entry in getLowerEntitiesVersions(
                                CHARGING_ENTITY_TYPES.USAGE_COUNTER,
                                lowerEntitiesVersions,
                            )"
                            :key="entry && entry.id"
                            :label="entry && entry.name"
                            :overviewMode="true"
                            class="ml-1"
                            @click="openLowerEntitiData(entry, RouteNames.CHARGING_USAGE_COUNTERS_EDITOR)"
                        />
                    </div>
                </div>

                <AppMultiselectV3
                    v-if="areContentTypesEnabled"
                    v-model="selectedContentType"
                    :options="getContentTypes"
                    :additionalLabel="contentTypeLabel"
                    :placeholder="$i18n.t('charging.contentTypes.addnewCy')"
                    :disabled="readonly"
                    label="name"
                    trackBy="id"
                    class="editor-input-large mt-3 mb-5"
                    data-test-id="addContentTypes"
                />

                <AppToggle
                    v-if="isQuotaSelected"
                    v-model="useServiceAsCurrency"
                    :label="$i18n.t('charging.chargingSpecifications.editor.useSaac')"
                    :explanationText="$i18n.t('charging.chargingSpecifications.editor.useSaacInfo')"
                    :tooltipOffset="-50"
                    :small="true"
                    :disabled="readonly"
                    class="mb-3"
                    data-test-id="service-as-currency-flag"
                />

                <!-- Conditions Expression-->
                <div>
                    <div
                        v-t="'charging.chargingSpecifications.conditionsExpression'"
                        class="section-title mt-4"
                    />
                    <div
                        v-t="'charging.chargingSpecifications.editor.expressionDescriptionText'"
                        class="section-message mb-4"
                    />
                    <div
                        v-if="readonly"
                        class="tags-container mb-5"
                    >
                        <ChargingTagLabel
                            v-for="entry in getLowerEntitiesVersions(
                                CHARGING_ENTITY_TYPES.CONDITION_PARAMETER,
                                lowerEntitiesVersions,
                            )"
                            :key="entry && entry.id"
                            :label="entry && entry.name"
                            :overviewMode="true"
                            class="ml-1"
                            @click="openLowerEntitiData(entry, RouteNames.CHARGING_CONDITION_PARAMETERS_EDITOR)"
                        />
                    </div>
                    <ConditionsExpressionEditor
                        v-model="expression"
                        :conditionsList="conditionList"
                        :disabled="isEmpty(selectedCSType) || readonly"
                    />
                </div>

                <!-- Surcharge -->
                <div v-if="showSurcharge">
                    <div>
                        <div
                            v-t="'charging.chargingSpecifications.surcharge'"
                            class="section-title mt-5"
                        />
                        <AppToggle
                            v-model="enableSurcharge"
                            :label="$i18n.t('charging.chargingSpecifications.enableSurcharge')"
                            :small="true"
                            :fitContent="true"
                            :disabled="readonly"
                            class="mt-2"
                            data-test-id="enable-surcharge"
                            @input="onEnableSurcharge"
                        />
                    </div>
                    <div v-if="enableSurcharge">
                        <div
                            v-t="'generic.defineRate'"
                            class="section-content mt-4 mb-0"
                        />
                        <div class="d-flex align-items-center">
                            <div class="inline-text">
                                {{ $i18n.t('charging.chargingSpecifications.editor.userWillBeCharged') }}
                            </div>
                            <AppInputV3
                                v-model.number="surcharge.rate"
                                :min="0"
                                :step="0.0001"
                                :roundNumber="true"
                                :disablePadding="true"
                                :invalid="$v.surcharge.rate.$error"
                                :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                                :class="{ 'mt-4': $v.surcharge.rate.$error }"
                                :disabled="readonly"
                                placeholder="123"
                                type="number"
                                data-test-id="surcharge-rate"
                            />
                            <div
                                v-if="displaySmsMmsLabel"
                                class="inline-text ml-2"
                            >
                                {{ displaySmsMmsLabel }}
                            </div>
                            <div
                                v-if="showCsSurchargeRatePeriod"
                                class="d-flex align-items-center ml-2"
                            >
                                <div class="inline-text">
                                    {{ $i18n.t('charging.chargingSpecifications.editor.every') }}
                                </div>
                                <UnitPicker
                                    v-model.number="surcharge.ratePeriod"
                                    :error="$v.surcharge.ratePeriod.$error"
                                    :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                                    :min="0"
                                    :definitionsWidth="5"
                                    :disabled="disableSurchargeRatePeriod || readonly"
                                    :type="getFormulaCalculatedThreshold"
                                    :isEditorDesign="true"
                                    :class="{ 'mt-4': $v.surcharge.ratePeriod.$error }"
                                    data-test-id="surcharge-rate-period"
                                    placeholder="123"
                                />
                            </div>
                        </div>
                        <!-- Surcharge Conditions Expression-->
                        <div>
                            <div
                                v-t="'charging.chargingSpecifications.conditionsExpression'"
                                class="section-title mt-4"
                            />
                            <div
                                v-t="'charging.chargingSpecifications.editor.expressionDescriptionText'"
                                class="section-message mb-4"
                            />
                            <div
                                v-if="readonly"
                                class="tags-container mb-5"
                            >
                                <ChargingTagLabel
                                    v-for="entry in getLowerEntitiesVersions(
                                        CHARGING_ENTITY_TYPES.CONDITION_PARAMETER_SURCHARGE,
                                        lowerEntitiesVersions,
                                    )"
                                    :key="entry && entry.id"
                                    :label="entry && entry.name"
                                    :overviewMode="true"
                                    class="ml-1"
                                    @click="openLowerEntitiData(entry, RouteNames.CHARGING_CONDITION_PARAMETERS_EDITOR)"
                                />
                            </div>
                            <ConditionsExpressionEditor
                                v-model="surcharge.expression"
                                :conditionsList="conditionList"
                                :disabled="isEmpty(selectedCSType) || readonly"
                            />
                        </div>
                    </div>
                </div>

                <!-- Actions -->
                <div>
                    <div
                        v-t="showUsageCounterSelection"
                        class="section-title mt-5"
                    />
                    <AppToggle
                        v-model="enableActions"
                        :label="$i18n.t('charging.chargingSpecifications.editor.enableActions')"
                        :small="true"
                        :fitContent="true"
                        :disabled="readonly"
                        class="mt-2"
                        data-test-id="on-enable-actions"
                    />
                </div>
                <div
                    v-if="enableActions"
                    class="mt-5"
                    data-test-id="actions-container"
                >
                    <ChargingActions
                        :actionsModel="actionsModel"
                        :validationsModel="$v.actionsModel"
                        :selectedUsageCounters="selectedUsageCounters"
                        :selectedCSType="selectedCSType"
                        :actionsOptions="chargingActionsOptions"
                        :triggersOptions="chargingActionsTriggersOptions"
                        :disabled="readonly"
                    />
                </div>
                <AppTextareaV3
                    v-if="!readonly"
                    v-model="remark"
                    :label="$i18n.t('generic.remark')"
                    :disabled="readonly"
                    data-test-id="remark"
                    class="editor-input-largest mt-5"
                />
            </div>
        </template>
        <template
            v-if="readonly"
            #sideBar
        >
            <div class="lf-subtitle m-3">
                {{ $i18n.t('productCatalog.editors.versionHistory') }}
            </div>
            <div
                v-for="(entry, index) in versionsHistory"
                :key="entry.chargingVersion"
            >
                <div
                    class="d-flex lf-primary-text version-item"
                    :class="{ active: entry.chargingVersion === currentVersion }"
                    :data-test-id="`version-item-${index}`"
                    @click="selectVersion(entry)"
                >
                    <AppIcon
                        v-if="entry.chargingVersion === currentVersion"
                        type="check-new"
                        size="1rem"
                        class="icon mr-2"
                    />
                    {{ entry.chargingVersion }} - {{ entry.updateTime }}
                    <div class="version-details card-content-spacing white-card-bg">
                        <div class="info">
                            <div>
                                <span class="property-name">
                                    {{ `${$i18n.t('generic.status')}:` }}
                                </span>
                                {{ entry.pushStatusLabel }}
                            </div>
                            <div>
                                <span class="property-name">
                                    {{ `${$i18n.t('generic.version')}:` }}
                                </span>
                                {{ entry.chargingVersion }}
                            </div>
                            <div>
                                <span class="property-name">
                                    {{ `${$i18n.t('generic.lastUpdatedTime')}:` }}
                                </span>
                                {{ entry.updateTime }}
                            </div>
                            <div>
                                <span class="property-name">
                                    {{ `${$i18n.t('generic.pushedTime')}:` }}
                                </span>
                                {{ entry.pushedTimestamp }}
                            </div>
                            <div>
                                <span class="property-name">
                                    {{ `${$i18n.t('generic.updateUser')}:` }}
                                </span>
                                {{ entry.userName }}
                            </div>
                            <div>
                                <span class="property-name">
                                    {{ `${$i18n.t('generic.remark')}:` }}
                                </span>
                                {{ entry.remark }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #modal>
            <MutationDialog
                v-model="showModal"
                :saveButtonEnabled="true"
                :entities="getAffectedEntities()"
                @updateEntity="saveAfterConfirm"
            />
        </template>
        <template #controls>
            <EditorButtons
                :showSaveDraft="!readonly"
                :showPublish="!readonly"
                :disableSave="readonly || disableSaveButton"
                data-test-id="submit-button"
                @cancel="onCancel"
                @saveDraft="onSave(false)"
                @publish="onSave(true)"
                @save="onSave"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
// Vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters, State } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Http
import { getChargingSpecificationEntityDraft } from '@/__new__/services/dno/charging/http/chargingSpecifications';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';

// Validations
import { validationMixin } from 'vuelidate';
import { required, minValue, requiredIf, between, minLength } from 'vuelidate/lib/validators';

// Generic Vue Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import CardListWrapper from '@/components/partials/cards/CardListWrapper.vue';
import MutationDialog from '@/components/partials/MutationDialog.vue';
import TagsMultiselect from '@/components/partials/inputs/TagsMultiselect.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import UnitPicker from '@/components/partials/inputs/UnitPicker.vue';
import ConditionsExpressionEditor from '@/__new__/features/charging/ConditionsExpressionEditor.vue';
import ChargingActions from '@/__new__/features/charging/ChargingActions.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import Button from '@/common/button/Button';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import ChargingTagLabel from '@/__new__/features/charging/ChargingTagLabel.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

// Conditions parsers
import { parseExpressionToTree, parseExpressionsFromBe } from '@/__new__/services/dno/charging/common/expression';

// Mixins
import mutationDialogMixin from '@/components/partials/mutations/mutationDialogMixin.vue';
import entityEditorMixin from '@/common/entityEditorMixin';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

// Configuration
import ChargeConfigurationDataV3 from '@/__new__/features/charging/chargingSpecification/configurations/ChargeConfigurationDataV3.vue';
import ChargeConfigurationSMSandMMS from '@/__new__/features/charging/chargingSpecification/configurations/ChargeConfigurationSMSandMMS.vue';
import ChargeConfigurationVoiceV3 from '@/__new__/features/charging/chargingSpecification/configurations/ChargeConfigurationVoiceV3.vue';
import ChargeConfigurationApi from '@/__new__/features/charging/chargingSpecification/configurations/ChargeConfigurationApi.vue';

// Helpers
import {
    CHARGING_TYPE,
    CHARGING_TYPE_MAP_VALUES,
    CHARGING_TYPE_OPTIONS,
    CHARGING_SPEC_TYPE,
    LIMIT_OPTIONS,
    TOTAL_LIMIT_OPTIONS,
    PERIOD_TYPES,
    getRatePeriodBaseOnUnits,
    CONDITION_TYPES,
    PC_CONDITION_TYPES,
    getTextLabelForConditionType,
    allConditionOptions,
    getCSType,
    CHARGE_TYPES_OPTIONS,
    CHAGE_TYPES,
    API_SUB_TYPE_TYPES_OPTIONS,
    API_CHARGING_TYPE,
    API_SUB_TYPE,
    API_CHAGE_TYPES,
    ADDITIONAL_AGGREGATIONS_OPTIONS,
} from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import {
    triggerOptions,
    TRIGGER_TYPES,
    actionTypesOptionsMapper,
    ACTION_TYPES_OPTIONS,
    THRESHOLD_CALCULATION_TYPES,
} from '@/__new__/services/dno/charging/common/chargingActionsHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { EntityStateMapping } from '@/common/entityStateMapper';

import ENTITY_TYPES from '@/common/entities/entityTypes';
import {
    getProperlyFormattedMultilangFieldValue,
    getAffectedEntities,
    getMultiLangFieldValueByLocale,
    formatMutationDialogEntities,
    EDITOR_MODE,
} from '@/common/entities/entityHelper';
import * as Sentry from '@sentry/vue';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { languageMap } from '@/common/locale/language';
import RouteNames from '@/router/routeNames';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { unitTypes } from '@/common/formatting';
import ActionModel from '@/__new__/services/dno/charging/models/ActionModel';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import { LABEL_COLOR } from '@/common/labelsHelper';
import { STATUS_CODES } from '@/common/commonHelper';
import {
    loadVersionHistory,
    CHARGING_ENTITY_TYPES,
    getLowerEntitiesVersions,
} from '@/__new__/services/dno/charging/common/versioningHelper';

export default {
    name: 'ChargingSpecificationEditor',
    components: {
        AbstractEditPageWrapper,
        AppTextareaV3,
        AppInputV3,
        AppHeader,
        AppToggle,
        MutationDialog,
        TagsMultiselect,
        AppIcon,
        UnitPicker,
        CardListWrapper,
        ChargeConfigurationVoiceV3,
        ChargeConfigurationDataV3,
        ChargeConfigurationSMSandMMS,
        ChargeConfigurationApi,
        ConditionsExpressionEditor,
        ChargingActions,
        EditorButtons,
        AppLabel,
        AppButton,
        EntityStatusIndicator,
        ChargingTagLabel,
        AppMultiselectV3,
    },
    mixins: [validationMixin, entityEditorMixin, mutationDialogMixin, ChargingCommonActionsMixin],
    data() {
        return {
            LIMIT_OPTIONS,
            CHARGING_TYPE,
            CHARGING_SPEC_TYPE,
            PC_CONDITION_TYPES,
            CHARGE_TYPES_OPTIONS,
            CHAGE_TYPES,
            CONDITION_TYPES,
            ICON_COLORS,
            ICON_TYPES,
            BUTTON_TYPES,
            LABEL_COLOR,
            EDITOR_MODE,
            API_SUB_TYPE_TYPES_OPTIONS,
            API_CHARGING_TYPE,

            schedulerData: {
                activationTime: null,
            },
            entityId: null,
            name: {},
            description: {},
            remark: '',
            data: {},
            version: null,
            updateTime: null,
            selectedLanguage: '',
            unlimited: false,
            entityType: ENTITY_TYPES.CHARGING_SPECIFICATION,

            // preselect the chargingSpecification type if its only one enabled
            selectedChargingSpecificationType: {},
            selectedCSType: {},
            chargeSpecState: {
                voice: {
                    chargeForChargingSpecification: {},
                },
                data: {
                    chargeForChargingSpecification: {},
                },
                sms: {
                    chargeForChargingSpecification: {},
                },
                mms: {
                    chargeForChargingSpecification: {},
                },
                api: {
                    chargeForChargingSpecification: {},
                },
            },
            apiSubType: null,
            additionalAggregations: null,
            ADDITIONAL_AGGREGATIONS_OPTIONS,
            selectedPolicyCounters: [],
            selectedUsageCounters: [],
            selectedContentType: null,
            appearanceIcon: {},
            approveOnCreate: false,
            enableCustomFeatureId: false,
            showAppearanceIconErrors: false,
            conditionIsChoosing: false,
            initAppearanceIcon: null,
            standardFeatureId: null,
            bucketSizeLimit: null,
            bucketSizeLimitValue: null,
            customFeatureId: null,
            // featureIds will be provided by operator
            featureIds: ['1', '2', '3', '4', '5'],
            selectedCondition: null,
            allConditionOptions,
            getTextLabelForConditionType,
            expression: [],
            chargeTypeModel: {
                voice: {
                    [CHARGING_TYPE.perPeriod]: {},
                    [CHARGING_TYPE.perUnit]: {
                        editingEnabled: true,
                    },
                    [CHARGING_TYPE.tieredCharge]: {
                        editingEnabled: true,
                        statuses: ['', ''],
                        thresholds: [0],
                        chargePeriodAmount: 1,
                    },
                    [CHARGING_TYPE.quota]: {},
                    [CHARGING_TYPE.unlimited]: {},
                },
                data: {
                    [CHARGING_TYPE.perPeriod]: {},
                    [CHARGING_TYPE.perUnit]: {
                        editingEnabled: true,
                    },
                    [CHARGING_TYPE.tieredCharge]: {
                        editingEnabled: true,
                        statuses: ['', ''],
                        thresholds: [0],
                        chargePeriodAmount: 1,
                    },
                    [CHARGING_TYPE.quota]: {},
                    [CHARGING_TYPE.unlimited]: {
                        fupEnabled: false,
                        fupAmount: 1,
                    },
                },
                sms: {
                    [CHARGING_TYPE.perPeriod]: {},
                    [CHARGING_TYPE.perUnit]: {
                        editingEnabled: true,
                    },
                    [CHARGING_TYPE.tieredCharge]: {
                        editingEnabled: true,
                        statuses: ['', ''],
                        thresholds: [0],
                        chargePeriodAmount: 1,
                    },
                    [CHARGING_TYPE.quota]: {},
                    [CHARGING_TYPE.unlimited]: {},
                },
                mms: {
                    [CHARGING_TYPE.perPeriod]: {},
                    [CHARGING_TYPE.perUnit]: {
                        editingEnabled: true,
                    },
                    [CHARGING_TYPE.tieredCharge]: {
                        editingEnabled: true,
                        statuses: ['', ''],
                        thresholds: [0],
                        chargePeriodAmount: 1,
                    },
                    [CHARGING_TYPE.quota]: {},
                    [CHARGING_TYPE.unlimited]: {},
                },
                api: {
                    [CHARGING_TYPE.perPeriod]: {},
                    [CHARGING_TYPE.perUnit]: {
                        editingEnabled: true,
                    },
                    [CHARGING_TYPE.tieredCharge]: {
                        editingEnabled: true,
                        statuses: ['', ''],
                        thresholds: [0],
                        chargePeriodAmount: 1,
                    },
                    [CHARGING_TYPE.quota]: {},
                    [CHARGING_TYPE.unlimited]: {
                        fupEnabled: false,
                        fupAmount: 1,
                    },
                },
            },
            requiredErrors: {
                showNameFieldRequiredError: false,
                showChargingSpecificationSelectRequiredError: false,
                showCSTypeSelectRequiredError: false,
                showAnyChargeSelectRequiredError: false,
                chargeValuesSelectRequiredError: false,
                selectedAppRequiredError: false,
            },
            showSurcharge: true,
            enableSurcharge: false,
            surcharge: {
                expression: [],
                rate: null,
                ratePeriod: null,
            },
            enableActions: false,
            actionsModel: [new ActionModel()],
            useServiceAsCurrency: false,
            readonly: false,
            updateName: this.$i18n.t('generic.N/A'),
            entityDraft: null,
            isUnpublished: false,
            isOnlyDraft: false,
            revertConfirmationButton: new Button({
                label: this.$i18n.t('productCatalog.editors.revert'),
                alertType: ALERT_TYPES.warning,
                handler: () => this.initData(true),
            }),
            isChargingSpecificationsApiTypeEnabled: permissionsService.chargingChargingSpecificationsApiTypeEnabled(),
            areContentTypesEnabled:
                permissionsService.chargingContentTypesEnabled() && isUserAllowed('ContentTypesReadOnly'),
            versionsHistory: [],
            currentVersion: null,
            state: EntityStateMapping.UNAPPROVED,
            lowerEntitiesVersions: null,
            CHARGING_ENTITY_TYPES,
            getLowerEntitiesVersions,
            RouteNames,
            maxValueOfPcThreshold: 100,
        };
    },
    validations: {
        name: {
            en: {
                required,
            },
        },
        apiSubType: {
            // eslint-disable-next-line func-names
            required: requiredIf(function () {
                return this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.api.id);
            }),
        },
        // per unit per period quota
        chargeTypeModel: {
            voice: {
                perUnit: {
                    moneyAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return (
                                this.isPerUnitSelected &&
                                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.voice.id) &&
                                this.chargeTypeModel.voice[CHARGING_TYPE.perUnit].editingEnabled
                            );
                        }),
                        minValue: minValue(0),
                    },
                    chargePeriodAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return (
                                this.isPerUnitSelected &&
                                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.voice.id) &&
                                this.chargeTypeModel.voice[CHARGING_TYPE.perUnit].editingEnabled
                            );
                        }),
                        minValue: minValue(1),
                    },
                },
                tieredCharge: {
                    statuses: {
                        minLength: minLength(2),
                        $each: {
                            minValue: minValue(0),
                            // eslint-disable-next-line func-names
                            required: requiredIf(function () {
                                return (
                                    this.isTieredChargeSelected &&
                                    this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.voice.id) &&
                                    this.chargeTypeModel.voice[CHARGING_TYPE.tieredCharge].editingEnabled
                                );
                            }),
                        },
                    },
                    thresholds: {
                        minLength: minLength(1),
                        $each: {
                            // eslint-disable-next-line func-names
                            required: requiredIf(function () {
                                return (
                                    this.isTieredChargeSelected &&
                                    this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.voice.id) &&
                                    this.chargeTypeModel.voice[CHARGING_TYPE.tieredCharge].editingEnabled
                                );
                            }),
                        },
                    },
                    chargePeriodAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return (
                                this.isPerUnitSelected &&
                                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.voice.id) &&
                                this.chargeTypeModel.voice[CHARGING_TYPE.perUnit].editingEnabled
                            );
                        }),
                        minValue: minValue(1),
                    },
                },
                perPeriod: {
                    moneyAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return (
                                this.isPerPeriodSelected && this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.voice.id)
                            );
                        }),
                        minValue: minValue(0),
                    },
                    limitValue: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.isPeriodimitValueVoiceRequired;
                        }),
                        minValue: minValue(1),
                    },
                    totalLimitValue: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.isTotalLimitValueVoiceRequired;
                        }),
                        maxPeriodLimit() {
                            return this.maxPeriodLimitVoice;
                        },
                    },
                },
                quota: {
                    countAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.isQuotaSelected && this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.voice.id);
                        }),
                        minValue: minValue(1),
                    },
                    chargePeriodAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.isQuotaSelected && this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.voice.id);
                        }),
                        minValue: minValue(1),
                    },
                },
            },
            data: {
                perUnit: {
                    moneyAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return (
                                this.isPerUnitSelected &&
                                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.data.id) &&
                                this.chargeTypeModel.data[CHARGING_TYPE.perUnit].editingEnabled
                            );
                        }),
                        minValue: minValue(0),
                    },
                    chargePeriodAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return (
                                this.isPerUnitSelected &&
                                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.data.id) &&
                                this.chargeTypeModel.data[CHARGING_TYPE.perUnit].editingEnabled
                            );
                        }),
                        minValue: minValue(1),
                    },
                },
                tieredCharge: {
                    statuses: {
                        minLength: minLength(2),
                        $each: {
                            minValue: minValue(0),
                            // eslint-disable-next-line func-names
                            required: requiredIf(function () {
                                return (
                                    this.isTieredChargeSelected &&
                                    this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.data.id) &&
                                    this.chargeTypeModel.data[CHARGING_TYPE.tieredCharge].editingEnabled
                                );
                            }),
                        },
                    },
                    thresholds: {
                        minLength: minLength(1),
                        $each: {
                            // eslint-disable-next-line func-names
                            required: requiredIf(function () {
                                return (
                                    this.isTieredChargeSelected &&
                                    this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.data.id) &&
                                    this.chargeTypeModel.data[CHARGING_TYPE.tieredCharge].editingEnabled
                                );
                            }),
                        },
                    },
                    chargePeriodAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return (
                                this.isPerUnitSelected &&
                                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.data.id) &&
                                this.chargeTypeModel.data[CHARGING_TYPE.tieredCharge].editingEnabled
                            );
                        }),
                        minValue: minValue(1),
                    },
                },
                perPeriod: {
                    moneyAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.isPerPeriodSelected && this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.data.id);
                        }),
                        minValue: minValue(0),
                    },
                    limitValue: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.isPeriodimitValueDataRequired;
                        }),
                        minValue: minValue(1),
                    },
                    totalLimitValue: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.isTotalLimitValueDataRequired;
                        }),
                        maxPeriodLimit() {
                            return this.maxPeriodLimitData;
                        },
                    },
                },
                quota: {
                    countAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.isQuotaSelected && this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.data.id);
                        }),
                        minValue: minValue(1),
                    },
                    chargePeriodAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.isQuotaSelected && this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.data.id);
                        }),
                        minValue: minValue(1),
                    },
                },
                unlimited: {
                    fupAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return (
                                this.isUnlimitedSelected &&
                                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.data.id) &&
                                this.chargeTypeModel.data[CHARGING_TYPE.unlimited].fupEnabled
                            );
                        }),
                        minValue: minValue(1),
                    },
                },
            },
            sms: {
                perUnit: {
                    moneyAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return (
                                this.isPerUnitSelected &&
                                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.sms.id) &&
                                this.chargeTypeModel.sms[CHARGING_TYPE.perUnit].editingEnabled
                            );
                        }),
                        minValue: minValue(0),
                    },
                },
                tieredCharge: {
                    statuses: {
                        minLength: minLength(2),
                        $each: {
                            minValue: minValue(0),
                            // eslint-disable-next-line func-names
                            required: requiredIf(function () {
                                return (
                                    this.isTieredChargeSelected &&
                                    this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.sms.id) &&
                                    this.chargeTypeModel.sms[CHARGING_TYPE.tieredCharge].editingEnabled
                                );
                            }),
                        },
                    },
                    thresholds: {
                        minLength: minLength(1),
                        $each: {
                            // eslint-disable-next-line func-names
                            required: requiredIf(function () {
                                return (
                                    this.isTieredChargeSelected &&
                                    this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.sms.id) &&
                                    this.chargeTypeModel.sms[CHARGING_TYPE.tieredCharge].editingEnabled
                                );
                            }),
                        },
                    },
                    chargePeriodAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return (
                                this.isTieredChargeSelected &&
                                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.sms.id) &&
                                this.chargeTypeModel.sms[CHARGING_TYPE.tieredCharge].editingEnabled
                            );
                        }),
                        minValue: minValue(1),
                    },
                },
                quota: {
                    countAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.isQuotaSelected && this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.sms.id);
                        }),
                        minValue: minValue(1),
                    },
                },
            },
            mms: {
                perUnit: {
                    moneyAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return (
                                this.isPerUnitSelected &&
                                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.mms.id) &&
                                this.chargeTypeModel.mms[CHARGING_TYPE.perUnit].editingEnabled
                            );
                        }),
                        minValue: minValue(0),
                    },
                },
                tieredCharge: {
                    statuses: {
                        minLength: minLength(2),
                        $each: {
                            minValue: minValue(0),
                            // eslint-disable-next-line func-names
                            required: requiredIf(function () {
                                return (
                                    this.isTieredChargeSelected &&
                                    this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.mms.id) &&
                                    this.chargeTypeModel.mms[CHARGING_TYPE.tieredCharge].editingEnabled
                                );
                            }),
                        },
                    },
                    thresholds: {
                        minLength: minLength(1),
                        $each: {
                            // eslint-disable-next-line func-names
                            required: requiredIf(function () {
                                return (
                                    this.isTieredChargeSelected &&
                                    this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.mms.id) &&
                                    this.chargeTypeModel.mms[CHARGING_TYPE.tieredCharge].editingEnabled
                                );
                            }),
                        },
                    },
                    chargePeriodAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return (
                                this.isTieredChargeSelected &&
                                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.mms.id) &&
                                this.chargeTypeModel.mms[CHARGING_TYPE.tieredCharge].editingEnabled
                            );
                        }),
                        minValue: minValue(1),
                    },
                },
                quota: {
                    countAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.isQuotaSelected && this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.mms.id);
                        }),
                        minValue: minValue(1),
                    },
                },
            },
            api: {
                perUnit: {
                    moneyAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return (
                                this.isPerUnitSelected &&
                                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.api.id) &&
                                this.chargeTypeModel.api[CHARGING_TYPE.perUnit].editingEnabled
                            );
                        }),
                        minValue: minValue(0),
                    },
                    chargePeriodAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return (
                                this.isPerUnitSelected &&
                                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.api.id) &&
                                this.chargeTypeModel.api[CHARGING_TYPE.perUnit].editingEnabled
                            );
                        }),
                        minValue: minValue(1),
                    },
                },
                tieredCharge: {
                    statuses: {
                        minLength: minLength(2),
                        $each: {
                            minValue: minValue(1),
                            // eslint-disable-next-line func-names
                            required: requiredIf(function () {
                                return (
                                    this.isTieredChargeSelected &&
                                    this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.api.id) &&
                                    this.chargeTypeModel.api[CHARGING_TYPE.tieredCharge].editingEnabled
                                );
                            }),
                        },
                    },
                    thresholds: {
                        minLength: minLength(1),
                        $each: {
                            // eslint-disable-next-line func-names
                            required: requiredIf(function () {
                                return (
                                    this.isTieredChargeSelected &&
                                    this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.api.id) &&
                                    this.chargeTypeModel.api[CHARGING_TYPE.tieredCharge].editingEnabled
                                );
                            }),
                        },
                    },
                    chargePeriodAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return (
                                this.isPerUnitSelected &&
                                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.api.id) &&
                                this.chargeTypeModel.api[CHARGING_TYPE.perUnit].editingEnabled
                            );
                        }),
                        minValue: minValue(1),
                    },
                },
                perPeriod: {
                    moneyAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.isPerPeriodSelected && this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.api.id);
                        }),
                        minValue: minValue(0),
                    },
                    limitValue: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.isPeriodimitValueDataRequired;
                        }),
                        minValue: minValue(1),
                    },
                },
                quota: {
                    countAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.isQuotaSelected && this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.api.id);
                        }),
                        minValue: minValue(1),
                    },
                },
                unlimited: {
                    fupAmount: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return (
                                this.isUnlimitedSelected &&
                                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.api.id) &&
                                this.chargeTypeModel.api[CHARGING_TYPE.unlimited].fupEnabled
                            );
                        }),
                        minValue: minValue(1),
                    },
                },
            },
        },
        actionsModel: {
            $each: {
                actionTrigger: {
                    type: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.enableActions;
                        }),
                    },
                    usageCounter: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.enableActions;
                        }),
                    },
                    thresholdCalcType: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.enableActions;
                        }),
                    },
                    ucThreshold: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.enableActions;
                        }),
                        minValue: minValue(0),
                    },
                    notificationPercentage: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function (el) {
                            return this.enableActions && el.enablePerceptions;
                        }),
                        between: between(1, 100),
                    },
                },
                actionFlag: {
                    type: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.enableActions;
                        }),
                    },
                    name: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function (obj) {
                            return this.enableActions && obj?.type?.id === ACTION_TYPES_OPTIONS.BUCKET_FLAG;
                        }),
                    },
                },
            },
        },
        surcharge: {
            rate: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return this.enableSurcharge;
                }),
                minValue: minValue(0),
            },
            ratePeriod: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return this.enableSurcharge && this.showCsSurchargeRatePeriod;
                }),
                minValue: minValue(1),
            },
        },
    },
    computed: {
        ...mapGetters('operators', [Getters.languageDefault]),
        ...mapGetters(Modules.charging, [
            Getters.GET_CHARGING_SPECIFICATION_BY_ID,
            Getters.GET_CHARGING_SPECIFICATION_BY_VERSION,
            Getters.GET_APPROVED_CONDITION_PARAMETERS,
        ]),
        ...mapGetters(Modules.chargingV2, [
            Getters.GET_ONLY_PUBLISHED_CONTENT_TYPES,
            Getters.GET_CONTENT_TYPE_BY_ID,
            Getters.GET_APPROVED_POLICY_COUNTERS,
            Getters.GET_POLICY_COUNTER_BY_IDS,
            Getters.GET_APPROVED_USAGE_COUNTERS,
            Getters.GET_USAGE_COUNTER_BY_ID,
            Getters.GET_USAGE_COUNTERS_BY_IDS,
        ]),
        // validation property
        isAnyChargeForCSTypeSelected() {
            return !isEmpty(this.chargeSpecState[this.selectedCSType.id].chargeForChargingSpecification);
        },
        chargingSpecificationByVersion() {
            return this[Getters.GET_CHARGING_SPECIFICATION_BY_VERSION];
        },
        conditionParameters() {
            if (!this[Getters.GET_APPROVED_CONDITION_PARAMETERS]) return [];
            const cpData = cloneDeep(this[Getters.GET_APPROVED_CONDITION_PARAMETERS]);
            return cpData
                .sort((entity1, entity2) => entity1.name.localeCompare(entity2.name))
                .map(condition => ({
                    conditionUUID: condition.id,
                    label: condition.name,
                    type: condition.conditionParameter.type,
                }));
        },
        conditionList() {
            const conditionOptionsKeys = this.conditionOptions().map(el => el.type);
            return this.conditionParameters.filter(condition => conditionOptionsKeys.includes(condition.type));
        },
        policyCountersOptions() {
            let data = [];
            const dataPolicyCounters = this[Getters.GET_APPROVED_POLICY_COUNTERS].filter(el => el?.thresholds?.length);
            const percentagePolicyCounters = this[Getters.GET_APPROVED_POLICY_COUNTERS].filter(
                el => el?.thresholdsPercentage?.length,
            );

            if (
                this.chargeSpecState?.[this.selectedCSType?.id]?.chargeForChargingSpecification?.id ===
                CHARGING_TYPE.unlimited
            ) {
                const getPercentagePcWithOneThreshold = [...percentagePolicyCounters].filter(
                    ({ thresholdsPercentage }) =>
                        thresholdsPercentage.length === 1 && thresholdsPercentage[0] === this.maxValueOfPcThreshold,
                );
                data = [...dataPolicyCounters, ...getPercentagePcWithOneThreshold];
            } else {
                data = [...dataPolicyCounters, ...percentagePolicyCounters];
            }

            return data.map(policyCounter => ({
                ...policyCounter,
                label: getMultiLangFieldValueByLocale(policyCounter.name),
            }));
        },
        getContentTypes() {
            return this[Getters.GET_ONLY_PUBLISHED_CONTENT_TYPES] || [];
        },
        usageCountersOptions() {
            const usageCounters = this[Getters.GET_APPROVED_USAGE_COUNTERS] || [];
            const availableUsageCounters = [];

            usageCounters.forEach(uc => {
                if (uc.charging_spec_id === undefined) {
                    availableUsageCounters.push({
                        ...uc,
                        label: getMultiLangFieldValueByLocale(uc.name),
                    });
                }
            });
            return availableUsageCounters;
        },
        showPolicyCounterSelection() {
            return (
                (this.isQuotaSelected || this.isUnlimitedSelected) &&
                permissionsService.chargingPolicyCountersEnabled() &&
                isUserAllowed('PolicyCountersReadOnly')
            );
        },
        showUsageCounterSelection() {
            const usageCountersRead =
                permissionsService.chargingUsageCountersEnabled() && isUserAllowed('UsageCountersReadOnly');
            if (this.selectedCSType?.id === CHARGING_SPEC_TYPE.api.id && usageCountersRead) {
                return true;
            }
            return (this.isQuotaSelected || this.isUnlimitedSelected) && usageCountersRead;
        },
        // validation property
        isPerUnitSelected() {
            if (!isEmpty(this.selectedCSType)) {
                return (
                    this.chargeSpecState[this.selectedCSType.id].chargeForChargingSpecification.id ===
                    CHARGING_TYPE.perUnit
                );
            }
            return false;
        },
        isTieredChargeSelected() {
            if (!isEmpty(this.selectedCSType)) {
                return (
                    this.chargeSpecState[this.selectedCSType.id].chargeForChargingSpecification.id ===
                    CHARGING_TYPE.tieredCharge
                );
            }
            return false;
        },
        // validation property
        isPerPeriodSelected() {
            if (!isEmpty(this.selectedCSType)) {
                return (
                    this.chargeSpecState[this.selectedCSType.id].chargeForChargingSpecification.id ===
                    CHARGING_TYPE.perPeriod
                );
            }
            return false;
        },
        // validation property
        isQuotaSelected() {
            if (!isEmpty(this.selectedCSType)) {
                return (
                    this.chargeSpecState[this.selectedCSType.id].chargeForChargingSpecification.id ===
                    CHARGING_TYPE.quota
                );
            }
            return false;
        },
        // validation property
        isUnlimitedSelected() {
            if (!isEmpty(this.selectedCSType)) {
                return (
                    this.chargeSpecState[this.selectedCSType.id].chargeForChargingSpecification.id ===
                    CHARGING_TYPE.unlimited
                );
            }
            return false;
        },
        maxPeriodLimitVoice() {
            const model = this.chargeTypeModel;
            return (
                !this.isPeriodimitValueVoiceRequired ||
                model.voice.perPeriod.selectedTotalLimit.id === TOTAL_LIMIT_OPTIONS.noTotalLimit.id ||
                model.voice.perPeriod.totalLimitValue > model.voice.perPeriod.limitValue
            );
        },
        maxPeriodLimitData() {
            const model = this.chargeTypeModel;
            return (
                !this.isPeriodimitValueDataRequired ||
                model.data.perPeriod.selectedTotalLimit.id === TOTAL_LIMIT_OPTIONS.noTotalLimit.id ||
                model.data.perPeriod.totalLimitValue > model.data.perPeriod.limitValue
            );
        },
        isPeriodimitValueVoiceRequired() {
            return (
                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.voice.id) &&
                this.isPerPeriodSelected &&
                this.chargeTypeModel.voice.perPeriod.selectedLimit.id === LIMIT_OPTIONS.withLimit.id
            );
        },
        isTotalLimitValueVoiceRequired() {
            return (
                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.voice.id) &&
                this.isPerPeriodSelected &&
                this.chargeTypeModel.voice.perPeriod.selectedTotalLimit.id === TOTAL_LIMIT_OPTIONS.withLimit.id
            );
        },
        isPeriodimitValueDataRequired() {
            return (
                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.data.id) &&
                this.isPerPeriodSelected &&
                this.chargeTypeModel.data.perPeriod.selectedLimit.id === LIMIT_OPTIONS.withLimit.id
            );
        },
        isTotalLimitValueDataRequired() {
            return (
                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.data.id) &&
                this.isPerPeriodSelected &&
                this.chargeTypeModel.data.perPeriod.selectedTotalLimit.id === TOTAL_LIMIT_OPTIONS.withLimit.id
            );
        },
        languages() {
            return this.$store.state.operators[State.Languages];
        },
        chargingSpecificationData() {
            return this[Getters.GET_CHARGING_SPECIFICATION_BY_ID](this.entityId) || {};
        },
        affectedEntities() {
            return getAffectedEntities(this.$attrs.id, ENTITY_TYPES.CHARGING_SPECIFICATION);
        },
        isChargingSpecificationUsedByProduct() {
            return this.affectedEntities.charging_specifications_group.length > 0;
        },
        chargingSpecificationOptions() {
            const options = [];
            options.push(
                CHARGING_SPEC_TYPE.data,
                CHARGING_SPEC_TYPE.voice,
                CHARGING_SPEC_TYPE.sms,
                CHARGING_SPEC_TYPE.mms,
            );

            if (this.isChargingSpecificationsApiTypeEnabled) {
                options.push(CHARGING_SPEC_TYPE.api);
            }

            return options;
        },
        featureId() {
            return this.enableCustomFeatureId ? this.customFeatureId : this.standardFeatureId;
        },
        limitOptions() {
            return Object.values(this.LIMIT_OPTIONS).map(opt => ({
                id: opt.id,
                label: this.$i18n.t(opt.i18nLabel),
            }));
        },
        totatlLimitOptions() {
            return Object.values(TOTAL_LIMIT_OPTIONS).map(opt => ({
                id: opt.id,
                label: this.$i18n.t(opt.i18nLabel),
            }));
        },
        shouldDisableDestination() {
            return this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.data.id);
        },
        disableSurchargeRatePeriod() {
            return (
                this.chargeSpecState[this.selectedCSType?.id]?.chargeForChargingSpecification?.id !==
                CHARGING_TYPE.unlimited
            );
        },
        getFormulaCalculatedThreshold() {
            return this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.voice.id) ? unitTypes.DURATION : unitTypes.DATA;
        },
        showCsSurchargeRatePeriod() {
            return (
                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.data.id) ||
                this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.voice.id)
            );
        },
        displaySmsMmsLabel() {
            if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.sms.id)) {
                return this.$i18n.t('charging.chargingSpecifications.editor.perSMS');
            }
            if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.mms.id)) {
                return this.$i18n.t('charging.chargingSpecifications.editor.perMMS');
            }

            return '';
        },
        displaySurchargeOption() {
            if (this.selectedCSType?.id === CHARGING_SPEC_TYPE.api.id) {
                return false;
            }

            const selectedCSType = this.chargeSpecState[this.selectedCSType?.id]?.chargeForChargingSpecification?.id;
            return selectedCSType !== CHARGING_TYPE.perPeriod && selectedCSType !== CHARGING_TYPE.tieredCharge;
        },
        chargingActionsOptions() {
            return [
                actionTypesOptionsMapper[ACTION_TYPES_OPTIONS.BUCKET_FLAG],
                actionTypesOptionsMapper[ACTION_TYPES_OPTIONS.NOTIFICATION],
            ];
        },
        chargingActionsTriggersOptions() {
            return [triggerOptions[TRIGGER_TYPES.US_THRESHOLD]];
        },
        isCsApproved() {
            return this.chargingSpecificationData?.state === EntityStateMapping.APPROVED && !this.$route.params.clone;
        },
        isDraft() {
            return this.entityId && !this.readonly && this.entityDraft;
        },
        isPublished() {
            return this.entityId && this.readonly;
        },
        allowEditDraftBtn() {
            return this.isPublished && !this.isOnlyDraft;
        },
        isRevertAvailable() {
            return this.isEditing && !this.readonly && !this.isOnlyDraft;
        },
        allowViewPublishedBtn() {
            return this.entityId && !this.readonly && !this.isOnlyDraft;
        },
        pageTitle() {
            if (this.readonly) {
                return this.$i18n.t('charging.chargingSpecifications.chargingSpecification');
            }
            if (this.entityId) {
                return this.$i18n.t('charging.chargingSpecifications.editor.editChargingSpecification');
            }
            return this.$i18n.t('charging.chargingSpecifications.editor.addNewChargingSpecification');
        },
        showStatusIndicator() {
            return Boolean(this.entityId && this.state !== STATUS_CODES.NA);
        },
        usageCountersLabel() {
            return this.readonly
                ? this.$i18n.t('charging.chargingSpecifications.editor.usageCountersAdded')
                : this.$i18n.t('charging.chargingSpecifications.editor.addUsageCounters');
        },
        policyCountersLabel() {
            return this.readonly
                ? this.$i18n.t('charging.policyCounters.policyCountersAdded')
                : this.$i18n.t('charging.policyCounters.addPolicyCounters');
        },
        contentTypeLabel() {
            return this.readonly
                ? this.$i18n.t('charging.contentTypes.ctAdded')
                : this.$i18n.t('charging.contentTypes.addCy');
        },
    },
    watch: {
        chargeTypeModel: {
            immediate: true,
            deep: true,
            handler() {
                this.setSurchargeData();
            },
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                const { id, readonly, mode, clone } = this.$route.params;
                this.entityId = id;

                if (this.entityId && readonly) {
                    this.readonly = readonly;
                }

                if (this.entityId && mode) {
                    this.readonly = mode === EDITOR_MODE.VIEW;
                }

                const promises = [
                    this[Actions.REQUEST_CHARGING_SPECIFICATIONS_GROUPS](),
                    this[Actions.REQUEST_CHARGING_SPECIFICATIONS](),
                    this[Actions.REQUEST_CONDITION_PARAMETERS](),
                ];

                if (permissionsService.chargingPolicyCountersEnabled() && isUserAllowed('PolicyCountersReadOnly')) {
                    promises.push(this[Actions.REQUEST_POLICY_COUNTERS]());
                }

                if (permissionsService.chargingUsageCountersEnabled() && isUserAllowed('UsageCountersReadOnly')) {
                    promises.push(this[Actions.REQUEST_USAGE_COUNTERS]());
                }

                if (this.areContentTypesEnabled) {
                    promises.push(this[Actions.REQUEST_CONTENT_TYPES]());
                }

                if (!clone && !this.readonly) {
                    promises.push(this.loadEntityDraft());
                }

                await Promise.all(promises);
                this.selectedLanguage = (this[Getters.languageDefault] || languageMap.en.key).toString();

                if (id) {
                    this.initData(false);
                    if (this.readonly) {
                        this.loadVersionHistory(CHARGING_ENTITY_TYPES.CHARGING_SPECIFICATION, this.entityId);
                    }
                }
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    });
                },
            },
        );
    },
    methods: {
        ...mapActions(Modules.charging, [
            Actions.REQUEST_CHARGING_SPECIFICATIONS_GROUPS,
            Actions.REQUEST_CHARGING_SPECIFICATIONS,
            Actions.REQUEST_CHARGING_SPECIFICATION_BY_VERSION,
            Actions.REQUEST_CONDITION_PARAMETERS,
        ]),
        ...mapActions(Modules.chargingV2, [
            Actions.REQUEST_CONTENT_TYPES,
            Actions.REQUEST_POLICY_COUNTERS,
            Actions.REQUEST_USAGE_COUNTERS,
        ]),
        isEmpty,
        removePolicyCounters() {
            this.selectedPolicyCounters = [];
            this.data.policy_counter_ids = undefined;
        },
        removeUsageCounters() {
            this.selectedUsageCounters = [];
            this.data.usage_counter_ids = undefined;
        },
        async initData(forceInitPublished = false, initVersion = true) {
            const cs = this.chargingSpecificationData;

            if (initVersion) {
                this.currentVersion = cs?.chargingVersion || null;
            }

            let entityData;
            let csData;

            if (this.entityDraft && !forceInitPublished) {
                entityData = cloneDeep(this.entityDraft);
                entityData.version = cs.version;
                csData = entityData.data;

                const publishedEntity = cs;
                this.isOnlyDraft = publishedEntity?.state === STATUS_CODES.NA;

                if (publishedEntity?.update_time <= entityData?.update_time) {
                    this.isUnpublished = true;
                }
            } else if (this.currentVersion) {
                await this.loadHistoryEntity(this.entityId, this.currentVersion);
                entityData = cloneDeep(this.chargingSpecificationByVersion);
                csData = entityData.data;
            } else {
                entityData = cloneDeep(cs);
                csData = entityData.data;
            }

            const properlyFormattedName = getProperlyFormattedMultilangFieldValue(csData.name);
            if (this.$route.params.clone) {
                Object.entries(properlyFormattedName).forEach(([localeName, localizedName]) => {
                    properlyFormattedName[localeName] = `${localizedName} (cloned)`;
                });
            }

            this.name = properlyFormattedName;
            this.remark = csData.remark;
            this.description = getProperlyFormattedMultilangFieldValue(csData.description);
            this.version = entityData.version;
            this.updateTime = entityData.update_time;
            this.getUpdateUserName(entityData.update_portal_id);
            this.state = entityData.state;
            this.version = entityData.version;
            this.lowerEntitiesVersions = entityData.lowerEntitiesVersions || null;

            const getApiSubTypeId = Object.values(API_SUB_TYPE).find(el => el.key === csData?.api_sub_type);
            this.apiSubType = API_CHAGE_TYPES?.[getApiSubTypeId?.id] ?? null;

            if (csData?.additional_aggregations?.length) {
                const [additionalAggregation] = csData?.additional_aggregations;
                this.additionalAggregations = ADDITIONAL_AGGREGATIONS_OPTIONS?.find(
                    el => el.value === additionalAggregation,
                );
            }

            if (csData.policy_counter_ids) {
                this.selectedPolicyCounters = [];
                const pcData = this[Getters.GET_POLICY_COUNTER_BY_IDS](csData.policy_counter_ids);
                pcData.forEach(pc => {
                    this.selectedPolicyCounters.push({
                        ...pc,
                        label: getMultiLangFieldValueByLocale(pc.name),
                    });
                });
            }

            if (csData.usage_counters) {
                this.selectedUsageCounters = [];
                const ucData = this[Getters.GET_USAGE_COUNTERS_BY_IDS](csData.usage_counters);
                ucData.forEach(uc => {
                    this.selectedUsageCounters.push({
                        ...uc,
                        label: getMultiLangFieldValueByLocale(uc.name),
                    });
                });
            }

            if (csData?.content_type_id && this.areContentTypesEnabled) {
                this.selectedContentType = this[Getters.GET_CONTENT_TYPE_BY_ID](csData.content_type_id) || null;
            }

            const {
                service_types: serviceTypes,
                charge_type: chargeType,
                period_type: periodType,
                is_charge_per_period: isChargePerPeriod = false,
                rate_tiers: rateTiers,
            } = csData;

            this.selectedCSType = getCSType(serviceTypes?.[0]);
            let chargeTypeId =
                chargeType === CHARGING_TYPE_OPTIONS[CHARGING_TYPE.perUnit] && periodType
                    ? CHARGING_TYPE.perPeriod
                    : CHARGING_TYPE_MAP_VALUES.get(chargeType);

            if (isChargePerPeriod) {
                chargeTypeId = CHARGING_TYPE.perPeriod;
            }

            if (this.selectedCSType?.id && chargeTypeId) {
                this.chargeSpecState[this.selectedCSType.id].chargeForChargingSpecification =
                    this.CHAGE_TYPES[chargeTypeId];

                if (rateTiers) {
                    this.chargeSpecState[this.selectedCSType.id].chargeForChargingSpecification =
                        this.CHAGE_TYPES[CHARGING_TYPE.tieredCharge];
                }

                const {
                    limit,
                    service_as_a_currency_enabled: useServiceAsCurrency,
                    period_limit: periodLimit,
                    rate,
                    unit_amount: unitAmount,
                    rate_period: ratePeriod,
                    should_group_balance: shouldGroupBalance,
                    base_quota: baseQuota,
                    fup,
                } = csData;

                this.useServiceAsCurrency = useServiceAsCurrency ?? false;
                this.chargeTypeModel[this.selectedCSType.id][
                    this.chargeSpecState[this.selectedCSType.id].chargeForChargingSpecification.id
                ] = {
                    moneyAmount: Number(rate) || 0,
                    countAmount: Number(unitAmount) || 0,
                    chargePeriodAmount: Number(ratePeriod) || 1,
                    periodType: PERIOD_TYPES.find(t => t.id === periodType) || null,
                    limitValue: periodLimit || 1,
                    totalLimitValue: limit || 1,
                    selectedLimit: periodLimit ? this.limitOptions[0] : this.limitOptions[1],
                    selectedTotalLimit: limit ? this.totatlLimitOptions[0] : this.totatlLimitOptions[1],
                    groupBalance: shouldGroupBalance || false,
                    basePeriod: baseQuota || 0,
                    editingEnabled: rate === undefined ? true : !!rate,
                    fupAmount: fup?.threshold || 1,
                    statuses: rateTiers?.rates || ['', ''],
                    thresholds: rateTiers?.thresholds || [0],
                };
            }

            this.loadConditionsData(entityData);
            this.loadSurchargeData(entityData);
            this.loadActionsData(entityData);
        },
        populateCSFields() {
            const { id: selectedCSTypeId = null, key: selectedCSTypeKey = null } = this.selectedCSType;

            const csType = this.chargeSpecState?.[selectedCSTypeId]?.chargeForChargingSpecification?.id || null;
            const csValues = this.chargeTypeModel?.[selectedCSTypeId]?.[csType] || {};
            const { countAmount = 0, chargePeriodAmount = 0, chargePeriodUnit = 0, statuses, thresholds } = csValues;

            this.data.charge_type = CHARGING_TYPE_OPTIONS[csType] ?? null;
            this.data.service_types = [selectedCSTypeKey];

            if (csType === CHARGING_TYPE.perUnit) {
                this.data.rate = csValues.moneyAmount ? csValues.moneyAmount.toString() : null;

                if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.sms.id)) {
                    if (this.chargeTypeModel.sms[CHARGING_TYPE.perUnit].editingEnabled) {
                        this.data.rate_period = 1;
                    } else {
                        this.data.rate_period = null;
                        this.data.rate = null;
                    }
                } else if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.data.id)) {
                    if (this.chargeTypeModel.data[CHARGING_TYPE.perUnit].editingEnabled) {
                        this.data.rate_period = chargePeriodAmount;
                    } else {
                        this.data.rate_period = null;
                        this.data.rate = null;
                    }
                } else if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.voice.id)) {
                    if (this.chargeTypeModel.voice[CHARGING_TYPE.perUnit].editingEnabled) {
                        this.data.rate_period = chargePeriodAmount;
                    } else {
                        this.data.rate_period = null;
                        this.data.rate = null;
                    }
                } else if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.mms.id)) {
                    if (this.chargeTypeModel.mms[CHARGING_TYPE.perUnit].editingEnabled) {
                        this.data.rate_period = 1;
                    } else {
                        this.data.rate_period = null;
                        this.data.rate = null;
                    }
                } else if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.api.id)) {
                    if (this.chargeTypeModel.api[CHARGING_TYPE.perUnit].editingEnabled) {
                        this.data.rate_period = chargePeriodAmount;
                    } else {
                        this.data.rate_period = null;
                    }
                }
            } else if (csType === CHARGING_TYPE.quota) {
                this.data.should_group_balance = csValues.groupBalance;

                if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.sms.id)) {
                    // add rate_period to request csValues
                    this.data.rate_period = getRatePeriodBaseOnUnits(
                        chargePeriodAmount,
                        chargePeriodUnit,
                        this.selectedCSType.id,
                    );
                    this.data.unit_amount = countAmount;
                } else if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.data.id)) {
                    // add rate_period to request csValues
                    this.data.rate_period = chargePeriodAmount;
                    this.data.unit_amount = countAmount;
                } else if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.voice.id)) {
                    // add rate_period to request csValues
                    this.data.rate_period = chargePeriodAmount;
                    this.data.unit_amount = countAmount;
                } else if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.mms.id)) {
                    // add rate_period to request csValues
                    this.data.rate_period = getRatePeriodBaseOnUnits(
                        chargePeriodAmount,
                        chargePeriodUnit,
                        this.selectedCSType.id,
                    );
                    this.data.unit_amount = countAmount;
                } else if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.api.id)) {
                    this.data.unit_amount = countAmount;
                }
            } else if (csType === CHARGING_TYPE.perPeriod) {
                this.data.rate = csValues.moneyAmount?.toString();

                if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.api.id)) {
                    this.data.rate_period = chargePeriodAmount;
                    this.data.is_charge_per_period = true;
                } else {
                    this.data.period_type = csValues?.periodType?.id;
                    // if not unlimited. Number 1 is the key that represents unlimited value
                    if (csValues.selectedLimit.id === 1) {
                        this.data.period_limit =
                            this.chargeTypeModel[this.selectedCSType.id][
                                this.chargeSpecState[this.selectedCSType.id].chargeForChargingSpecification.id
                            ].limitValue;
                    }

                    if (csValues.selectedTotalLimit.id === 1) {
                        this.data.limit =
                            this.chargeTypeModel[this.selectedCSType.id][
                                this.chargeSpecState[this.selectedCSType.id].chargeForChargingSpecification.id
                            ].totalLimitValue;
                    }
                }
            } else if (csType === CHARGING_TYPE.tieredCharge) {
                this.data = {
                    ...this.data,
                    rate_tiers: {
                        thresholds,
                        rates: statuses.map(el => el.toString()),
                    },
                    rate_period: chargePeriodAmount,
                };
            } else {
                this.data.rate = '0';
                this.data.rate_period = 1;
                if (csValues.fupEnabled) {
                    this.data.fup = { threshold: csValues.fupAmount };
                }
            }

            if (this.selectedPolicyCounters?.length) {
                this.data.policy_counter_ids = this.selectedPolicyCounters.map(pc => pc.id);
            }

            if (this.selectedUsageCounters?.length) {
                this.data.usage_counters = this.selectedUsageCounters.map(pc => pc.id);
            }

            if (this.selectedContentType && this.areContentTypesEnabled) {
                this.data.content_type_id = this.selectedContentType?.id || null;
            }

            this.data = {
                ...this.data,
                ...this.parseConditionsData(this.expression),
            };

            if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.api.id)) {
                this.data = {
                    ...this.data,
                    api_sub_type: this.apiSubType?.key,
                    additional_aggregations: this.additionalAggregations?.value
                        ? [this.additionalAggregations?.value]
                        : [],
                };
            }

            // parse actions
            if (this.enableActions) {
                this.data.actions = [];
                this.actionsModel.forEach(action => {
                    const { thresholdCalcType, ucThreshold, usageCounter, notificationPercentage } =
                        action.actionTrigger;
                    const { type, name } = action.actionFlag;

                    const thresholdPercentage =
                        type?.id === ACTION_TYPES_OPTIONS.NOTIFICATION ? notificationPercentage : 100;

                    const actionData = {
                        action_trigger: {
                            trigger_type: thresholdCalcType?.id,
                            usage_counter_id: usageCounter?.id,
                            usage_counter_threshold_percentage: thresholdPercentage,
                        },
                        action: {
                            action_type: type?.id,
                        },
                    };

                    if (thresholdCalcType?.id === THRESHOLD_CALCULATION_TYPES.SET_THRESHOLD_MANUALLY) {
                        actionData.action_trigger.total_threshold = ucThreshold;
                    }

                    if (type?.id === ACTION_TYPES_OPTIONS.BUCKET_FLAG) {
                        actionData.action.flag_name = name;
                        actionData.action.flag_value = true;
                    }

                    this.data.actions.push(actionData);
                });
            }

            if (this.enableSurcharge) {
                const { rate, ratePeriod, expression } = this.surcharge;

                this.data.surcharges = {};
                this.data.surcharges.rate = rate?.toString() || '';

                if (
                    this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.sms.id) ||
                    this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.mms.id)
                ) {
                    this.data.surcharges.rate_period = this.data.rate_period;
                } else {
                    this.data.surcharges.rate_period = ratePeriod;
                }

                this.data.surcharges = {
                    ...this.data.surcharges,
                    ...this.parseConditionsData(expression),
                };
            }

            this.data.service_as_a_currency_enabled = this.isQuotaSelected ? this.useServiceAsCurrency : false;
        },
        parseConditionsData(expression) {
            const data = {
                condition_expr: {},
            };

            if (expression && expression.length > 0 && expression[0].value) {
                const expressionParsed = parseExpressionToTree(expression);

                if (expressionParsed) {
                    data.condition_expr = expressionParsed;
                } else {
                    data.condition_expr = {};
                }
            }

            return data;
        },
        async onSave(isPublish = true) {
            if (this.isSaveButtonClicked) {
                return;
            }

            if (isPublish) {
                if (!this.checkDataBeforeSave()) {
                    return;
                }
            }

            this.populateCSFields();
            this.data.name = this.name[this.selectedLanguage];
            this.data.description = this.description;
            this.data.remark = this.remark;

            await this.saveEntityData(
                this.isOnlyDraft,
                this.affectedEntities.charging_specifications_group || [],
                isPublish,
            );
        },
        loadConditionsData(chargingSpecification) {
            const { condition_expr: conditionExpr = {} } = cloneDeep(chargingSpecification.data);

            this.expression = parseExpressionsFromBe(conditionExpr) || [];
        },
        loadActionsData(chargingSpecification) {
            const { actions = [] } = chargingSpecification.data;
            if (actions.length) {
                this.actionsModel = [];
                this.enableActions = true;
            }

            this.actionsModel = actions.map(actionData => {
                const { action: actionFlag, action_trigger: actionTrigger } = cloneDeep(actionData);

                const action = new ActionModel({ actionTrigger, actionFlag });

                const uc = this[Getters.GET_USAGE_COUNTER_BY_ID](action.actionTrigger?.ucId) ?? {};
                action.actionTrigger.usageCounter = {
                    ...uc,
                    label: getMultiLangFieldValueByLocale(uc.name),
                };

                action.actionTrigger.type = triggerOptions[TRIGGER_TYPES.US_THRESHOLD];

                return action;
            });
        },
        loadSurchargeData(chargingSpecification) {
            this.showSurcharge = this.displaySurchargeOption;

            if (!chargingSpecification.data?.surcharges) {
                return;
            }

            const {
                rate,
                rate_period: ratePeriod,
                condition_expr: conditionExpr,
            } = chargingSpecification.data?.surcharges;

            if (rate || ratePeriod) {
                this.enableSurcharge = true;
            }

            this.surcharge = {
                expression: parseExpressionsFromBe(conditionExpr) || [],
                rate,
                ratePeriod,
            };
        },
        checkDataBeforeSave() {
            this.$v.$touch();
            if (this.$v.name.$invalid) {
                this.requiredErrors.showNameFieldRequiredError = true;
                return false;
            }
            if (!this.isAnyChargeForCSTypeSelected) {
                this.requiredErrors.showAnyChargeSelectRequiredError = true;
                return false;
            }
            if (this.$v.chargeTypeModel.$invalid) {
                this.requiredErrors.chargeValuesSelectRequiredError = true;
                return false;
            }

            if (this.$v.actionsModel.$invalid) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('charging.chargingSpecifications.editor.enabledActionsError'),
                    type: ALERT_TYPES.error,
                });
                return false;
            }

            // Surcharge validation
            if (this.$v.surcharge.rate.$invalid || this.$v.surcharge.ratePeriod.$invalid) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('charging.chargingSpecifications.editor.surchargeError'),
                    type: ALERT_TYPES.error,
                });
                return false;
            }

            return true;
        },
        getAffectedEntities() {
            return formatMutationDialogEntities(this.affectedEntities);
        },
        isSelectedCSTypeEqualTo(type) {
            return this.selectedCSType?.id === type;
        },
        isSelectedApiSubTypeEqualTo(type) {
            return this.apiSubType?.id === type;
        },
        conditionOptions() {
            const options = [];

            options.push(
                this.allConditionOptions[CONDITION_TYPES.SUBSCRIBER_FLAG],
                this.allConditionOptions[CONDITION_TYPES.BUCKET_FLAG],
                this.allConditionOptions[CONDITION_TYPES.VLR_ID],
                this.allConditionOptions[CONDITION_TYPES.SERVICE_ID],
            );

            if (
                !this.showPolicyCounterSelection ||
                (this.showPolicyCounterSelection &&
                    this.selectedPolicyCounters &&
                    this.selectedPolicyCounters.length === 0)
            ) {
                options.push(this.allConditionOptions[CONDITION_TYPES.SCHEDULE]);
            }
            if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.data.id)) {
                options.push(
                    this.allConditionOptions[CONDITION_TYPES.BEARER],
                    this.allConditionOptions[CONDITION_TYPES.NETWORK_SLICE_IDENTIFIER],
                    this.allConditionOptions[CONDITION_TYPES.ZONE],
                    this.allConditionOptions[CONDITION_TYPES.APN],
                    this.allConditionOptions[CONDITION_TYPES.RATING_GROUP],
                    this.allConditionOptions[CONDITION_TYPES.SUBSCRIBER_LOCATION],
                );
            } else if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.voice.id)) {
                options.push(
                    this.allConditionOptions[CONDITION_TYPES.BEARER],
                    this.allConditionOptions[CONDITION_TYPES.ZONE],
                    this.allConditionOptions[CONDITION_TYPES.TRAFFIC_TYPE],
                    this.allConditionOptions[CONDITION_TYPES.SPECIAL_NUMBER],
                    this.allConditionOptions[CONDITION_TYPES.OTHER_PARTY_RN],
                    this.allConditionOptions[CONDITION_TYPES.OTHER_PARTY_NUMBER_LENGTH],
                    this.allConditionOptions[CONDITION_TYPES.SUBSCRIBER_LOCATION],
                    this.allConditionOptions[CONDITION_TYPES.OTHER_PARTY_NUMBER],
                );
                if (
                    this.chargeSpecState[this.selectedCSType.id].chargeForChargingSpecification.id ===
                    CHARGING_TYPE.perUnit
                ) {
                    options.push(this.allConditionOptions[CONDITION_TYPES.TIME_IN_CALL]);
                }
            } else if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.sms.id)) {
                options.push(
                    this.allConditionOptions[CONDITION_TYPES.BEARER],
                    this.allConditionOptions[CONDITION_TYPES.ZONE],
                    this.allConditionOptions[CONDITION_TYPES.SPECIAL_NUMBER],
                    this.allConditionOptions[CONDITION_TYPES.OTHER_PARTY_RN],
                    this.allConditionOptions[CONDITION_TYPES.OTHER_PARTY_NUMBER_LENGTH],
                    this.allConditionOptions[CONDITION_TYPES.SUBSCRIBER_LOCATION],
                    this.allConditionOptions[CONDITION_TYPES.OTHER_PARTY_NUMBER],
                );
            } else if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.mms.id)) {
                options.push(
                    this.allConditionOptions[CONDITION_TYPES.ZONE],
                    this.allConditionOptions[CONDITION_TYPES.SPECIAL_NUMBER],
                    this.allConditionOptions[CONDITION_TYPES.OTHER_PARTY_RN],
                    this.allConditionOptions[CONDITION_TYPES.OTHER_PARTY_NUMBER_LENGTH],
                    this.allConditionOptions[CONDITION_TYPES.SUBSCRIBER_LOCATION],
                    this.allConditionOptions[CONDITION_TYPES.OTHER_PARTY_NUMBER],
                );
            } else if (this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.api.id)) {
                options.push(
                    this.allConditionOptions[CONDITION_TYPES.BEARER],
                    this.allConditionOptions[CONDITION_TYPES.NETWORK_SLICE_IDENTIFIER],
                    this.allConditionOptions[CONDITION_TYPES.ZONE],
                    this.allConditionOptions[CONDITION_TYPES.APN],
                    this.allConditionOptions[CONDITION_TYPES.RATING_GROUP],
                    this.allConditionOptions[CONDITION_TYPES.SUBSCRIBER_LOCATION],
                );
            }

            return options.sort((option1, option2) => option1.type.localeCompare(option2.type));
        },
        chargingSpecificationOptionsChanged() {
            this.requiredErrors.showCSTypeSelectRequiredError = false;
            this.requiredErrors.showAnyChargeSelectRequiredError = false;
            this.surcharge.rate = null;
            this.surcharge.ratePeriod = null;
            this.expression = [];
            this.surcharge.expression = [];
        },
        onSelectedCSType() {
            this.requiredErrors.showAnyChargeSelectRequiredError = false;

            this.showSurcharge = this.displaySurchargeOption;
            this.surcharge.rate = null;
            this.surcharge.ratePeriod = null;
            this.expression = [];
            this.surcharge.expression = [];
            this.selectedPolicyCounters = [];
        },
        onEnableSurcharge(data) {
            if (!data) {
                this.surcharge = {
                    expression: [],
                    rate: null,
                    ratePeriod: null,
                };
            } else {
                this.setSurchargeData();
            }
        },
        setSurchargeData() {
            const csSpec = this.chargeSpecState[this.selectedCSType?.id]?.chargeForChargingSpecification?.id;
            const data =
                this.chargeTypeModel[this.selectedCSType?.id] && this.chargeTypeModel[this.selectedCSType?.id][csSpec];

            if (data && csSpec !== CHARGING_TYPE.unlimited) {
                const { chargePeriodAmount } = data;
                this.surcharge.ratePeriod = chargePeriodAmount ?? null;
            }
        },
        async loadEntityDraft() {
            if (this.entityId) {
                try {
                    if (!this.readonly) {
                        const result = await getChargingSpecificationEntityDraft(this.entityId);
                        const data = result?.data?.data?.[this.entityId] || null;

                        if (data) {
                            this.entityDraft = {
                                ...data,
                                isDraft: true,
                                state: STATUS_CODES.NA,
                                entityVersion: STATUS_CODES.DRAFT,
                                updateTime: data.update_time,
                            };
                        }
                    }
                } catch (e) {
                    this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), { type: ALERT_TYPES.error });
                }
            }
        },
        reloadEditor(mode) {
            const { id } = this.$route.params;
            // Use push to list page because router don`t want ot reload same page
            this.$router
                .push({
                    name: RouteNames.CHARGING_CHARGING_SPECIFICATIONS,
                    params: { companyId: this.$route.params.companyId },
                })
                .then(() => {
                    this.$router.push({
                        name: RouteNames.CHARGING_CHARGING_SPECIFICATION_EDITOR,
                        params: {
                            id,
                            mode,
                            companyId: this.$route.params.companyId,
                        },
                    });
                });
        },
        async getUpdateUserName(id) {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        resetToPublished() {
            this.$alert(this.$i18n.t('productCatalog.editors.revertWarning'), {
                type: ALERT_TYPES.warning,
                buttons: [this.revertConfirmationButton],
            });
        },
        async loadVersionHistory(entityType, id) {
            this.versionsHistory = await loadVersionHistory(entityType, id);
            const [latestVersion] = this.versionsHistory;
            this.currentVersion = latestVersion?.chargingVersion || null;
        },
        selectVersion(entry) {
            if (this.currentVersion !== entry?.chargingVersion) {
                this.currentVersion = entry?.chargingVersion;
                this.initData(false, false);
            }
        },
        async loadHistoryEntity(id, version) {
            if (id) {
                await this.$withProgressBar(
                    async () => {
                        await this[Actions.REQUEST_CHARGING_SPECIFICATION_BY_VERSION]({
                            id,
                            version,
                        });
                    },
                    {
                        errorHandler: () => {
                            this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), {
                                type: ALERT_TYPES.error,
                            });
                        },
                    },
                );
            }
        },
        openLowerEntitiData(entry, routeName) {
            this.$router.push({
                name: routeName,
                params: {
                    companyId: this.$route.params.companyId,
                    id: entry.id,
                    readonly: true,
                    chargingVersion: entry.chargingVersion,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/animations';

$icon-path: '~@/assets/icons/';

.info-icon-white {
    width: 1rem;
    height: 1rem;
    content: url($icon-path + $info);
}

.section-content {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-right: 1rem;
    color: $gray90;
}

.cs-form-wrapper {
    width: 80%;
}

.section-message {
    font-size: 0.75rem;
    margin-bottom: 0.75rem;
    color: $gray60;
}

.chargingSpecification-type-cards {
    padding: 1rem;
}

.chargingSpecification-type-cards-small {
    padding: 0.625rem;
}

.chargingSpecification-type-cards-small-font {
    padding: 1.25rem;
    font-size: 0.8rem;
}

.remove-condition {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.86;
    color: $blue;
    cursor: pointer;
}

.version-item {
    font-size: 0.875rem;
    padding: 1rem 1.5rem;
    cursor: pointer;
    position: relative;

    &:hover {
        background-color: $blue5;

        .version-details {
            display: block;
        }
    }

    &.active {
        color: $blue;
        background-color: $blue15;
    }

    .icon {
        position: relative;
        top: 3px;
    }
}

.version-details {
    position: absolute;
    top: 2rem;
    transform: translateY(-10%);
    display: none;
    width: 21.25rem;
    height: auto;
    color: $gray60;
    transition: $fast-animation-time;
    z-index: $overlap-smth-z-index;

    .info {
        background-color: $gray5;
        padding: 0.75rem 1.25rem;
        font-size: $text-sm;

        .property-name {
            font-weight: $medium-font-weight;
        }
    }
}

.tags-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
</style>
