
























































import Vue from 'vue';

// Components
import LabelDanger from '@/components/partials/LabelDanger.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import CardListRadioInput from '@/components/partials/cards/CardListRadioInput.vue';
import CustomTextarea from '@/__new__/features/customerCare/subscriber/CustomTextarea.vue';
import { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// http
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';
import ossmvneHTTP from '@/__new__/services/dno/ossmvne/http/ossmvne';
import notesHTTP from '@/__new__/services/dno/crm/http/crm';

// helpers
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { dateToEpoch } from '@/common/formatting';
import Button from '@/common/button/Button';
import Actions from '@/store/mutation-types';
import { mapActions } from 'vuex';
import { Modules } from '@/store/store';
import { isViewEnabled } from '@/services/permissions/permissions.service';
import { RESOURCE_TYPE, SIM_TYPE, SIM_SWAP_FLOW } from '@/__new__/features/resources/common/sim';

export default Vue.extend({
    name: 'SimSwapModalMVNE',
    components: {
        AppInputV3,
        LabelDanger,
        CustomTextarea,
        CardListRadioInput,
    },
    mixins: [validationMixin],
    props: {
        targetId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isDataReady: false,
            notesTxt: '',
            iccid: '',
            iccidInvalid: false,
            profileData: {} as Record<string, any>,
            BUTTON_TYPES,
            selectedFlow: null as SIM_SWAP_FLOW | null,
            flowConfig: {
                [RESOURCE_TYPE[SIM_TYPE.ESIM]]: [
                    {
                        id: SIM_SWAP_FLOW.ESIM_TO_ESIM,
                        label: this.$t('generic.eSIM'),
                        isEnabled: isViewEnabled('UMSubscriberSimSwapEsimToEsim'),
                    },
                    {
                        id: SIM_SWAP_FLOW.ESIM_TO_PSIM,
                        label: this.$t('generic.pSIM'),
                        isEnabled: isViewEnabled('UMSubscriberSimSwapEsimToPsim'),
                    },
                ],
                [RESOURCE_TYPE[SIM_TYPE.PSIM]]: [
                    {
                        id: SIM_SWAP_FLOW.PSIM_TO_ESIM,
                        label: this.$t('generic.eSIM'),
                        isEnabled: isViewEnabled('UMSubscriberSimSwapPsimToEsim'),
                    },
                    {
                        id: SIM_SWAP_FLOW.PSIM_TO_PSIM,
                        label: this.$t('generic.pSIM'),
                        isEnabled: isViewEnabled('UMSubscriberSimSwapPsimToPsim'),
                    },
                ],
            },
        };
    },
    validations() {
        return {
            notesTxt: {
                required,
            },
        };
    },
    computed: {
        isESim(): boolean {
            return this.profileData?.sim_type === RESOURCE_TYPE[SIM_TYPE.ESIM];
        },
        flowOptions(): { id: SIM_SWAP_FLOW; label: string; isEnabled: boolean }[] {
            const flowConfig = this.flowConfig[this.profileData?.sim_type] || [];
            return flowConfig.filter(({ isEnabled }) => isEnabled);
        },
        requestNewIccid(): boolean {
            return this.selectedFlow
                ? [SIM_SWAP_FLOW.ESIM_TO_PSIM, SIM_SWAP_FLOW.PSIM_TO_PSIM].includes(this.selectedFlow)
                : false;
        },
        simTypeNew(): number {
            const simType = [SIM_SWAP_FLOW.ESIM_TO_ESIM, SIM_SWAP_FLOW.PSIM_TO_ESIM, null].includes(this.selectedFlow)
                ? SIM_TYPE.ESIM
                : SIM_TYPE.PSIM;
            return RESOURCE_TYPE[simType];
        },
    },
    created() {
        this.$withProgressBar(async () => {
            const profileInfo = await customerCareHTTP.getProfileInfo({
                targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                targetId: this.targetId,
            });
            this.profileData = profileInfo.data?.profile_data;
            this.selectedFlow = this.flowOptions[0]?.id || null;
            this.isDataReady = true;
        }, {});
    },
    methods: {
        ...mapActions(Modules.customerCareSuite, [Actions.LOAD_ASSOCIATED_UM_ENTITIES]),
        setSimFlow(flow: SIM_SWAP_FLOW) {
            this.selectedFlow = flow;
            this.clearInputsValues();
        },
        clearInputsValues() {
            this.iccid = '';
            this.iccidInvalid = false;
            this.notesTxt = '';
            this.$v.notesTxt.$reset();
        },
        onSave() {
            this.$v.$touch();
            if (!this.$v.$anyError) {
                this.$alert(this.$i18n.t('customerCare.subscriberTab.simSwap.areYouSure'), {
                    type: ALERT_TYPES.warning,
                    buttons: [
                        new Button({
                            label: this.$i18n.t('generic.confirm'),
                            alertType: ALERT_TYPES.warning,
                            handler: () => this.swapSim(),
                        }),
                    ],
                });
            }
        },
        swapSim() {
            return this.$withProgressBar(
                async () => {
                    if (this.requestNewIccid) {
                        const response = await ossmvneHTTP
                            .validateICCID(this.targetId, USER_MANAGER_HIERARCHY.SUBSCRIBER, this.iccid)
                            .catch(() => ({ status: 400 }));
                        this.iccidInvalid = response.status !== 200;
                        if (this.iccidInvalid) throw new Error('Invalid ICCID');
                    }
                    await customerCareHTTP.swapSIM({
                        target_id: this.targetId,
                        target_type: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        sim_type_old: this.profileData?.sim_type,
                        iccid_old: this.profileData?.iccid,
                        iccid_new: this.requestNewIccid ? this.iccid : undefined,
                        sim_type_new: this.simTypeNew,
                    });
                    this.$alert(this.$i18n.t('customerCare.subscriberTab.simSwapRequestSuccessful'), {
                        type: ALERT_TYPES.success,
                    });
                    this.$emit('isSensitiveOperationCompleted', true);
                },
                {
                    successHandler: () => {
                        this.setAgentNote();
                    },
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('customerCare.subscriberTab.simSwapRequestFail'));
                    },
                },
            );
        },
        setAgentNote() {
            return this.$withProgressBar(
                () =>
                    notesHTTP.addNote({
                        id: this.targetId,
                        idType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        epoch: dateToEpoch(Date.now()),
                        noteText: this.notesTxt,
                        tags: [this.$i18n.t('generic.stateMap.simSwap')],
                    }),
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongSavingAgentNotes'));
                    },
                },
            );
        },
    },
});
