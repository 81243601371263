import {
    getRegisteredEntities,
    getEntityDetailsByName,
} from '@/__new__/services/dno/progressTracker/http/progressTracker';
import {
    DATAFLOW_API_TYPE,
    type EntityDetailsByName,
    type RegisteredEntityItem,
} from '@/__new__/services/dno/progressTracker/progressTrackerHelper';
import {
    FILE_UPLOAD_STATUS,
    fileUploadStatusToStr,
    getFileUploadStatus,
    type UploadedFileDetails,
} from '@/common/fileUploadHelper';

export const getUploadHistory = async (dataflowId: DATAFLOW_API_TYPE): Promise<UploadedFileDetails[]> => {
    // eslint-disable-next-line prettier/prettier
    const { data: { data: { items }}} = await getRegisteredEntities(dataflowId);
    return Promise.all(
        (items as RegisteredEntityItem[]).map(entity => uploadFileDetailsFromEntity(entity, dataflowId)),
    );
};

/**
 * Transforms backend `RegisteredEntityItem` into Portal friendly `UploadedFileDetails`
 */
const uploadFileDetailsFromEntity = async (
    entity: RegisteredEntityItem,
    dataflowId: DATAFLOW_API_TYPE,
): Promise<UploadedFileDetails> => {
    /**
     * Build details object
     * Explicitly leaving fields undefined since:
     *  - they will be updated asynchronously
     *  - they need to be reactive
     */
    const fileDetails: UploadedFileDetails = {
        entityName: entity.entity_name,
        createdAt: entity.created_at,
        lastUpdated: entity.last_updated,
        fileName: entity.entity_name,
        bulkUploadId: undefined,
        createdBy: undefined,
        recordCount: undefined,
        metadata: undefined,
    };
    /**
     * Determine file upload status
     * Attempt to load this async as we need an additional request
     */
    const getFileDetails = (fileDetails: UploadedFileDetails) =>
        getEntityDetailsByName(fileDetails.entityName, dataflowId)
            .then((details: { data: EntityDetailsByName }) => {
                const recordCount = details.data.data.expected_count;
                const eventCategoryCounters = details.data.data.event_category_counters;
                fileDetails.recordCount = recordCount;
                fileDetails.eventCategoryCounters = eventCategoryCounters;
                fileDetails.fileUploadStatus = getFileUploadStatus(recordCount, eventCategoryCounters);
                fileDetails.fileUploadStatusStr = fileUploadStatusToStr(fileDetails.fileUploadStatus);
                fileDetails.metadata = details.data.data.metadata;
                fileDetails.createdBy = details.data.data.metadata?.uploaded_by || '';
                fileDetails.createdByPortalId = Number(details.data.data.metadata?.principal_id) || undefined;

                if (fileDetails.metadata?.context_metadata) {
                    fileDetails.fileName = fileDetails.metadata?.context_metadata.fileName;
                    fileDetails.bulkUploadId = fileDetails.metadata?.context_metadata.uuid;
                }

                return fileDetails;
            })
            .catch(() => {
                fileDetails.recordCount = 0;
                fileDetails.fileUploadStatus = FILE_UPLOAD_STATUS.UNKNOWN;
                fileDetails.fileUploadStatusStr = fileUploadStatusToStr(fileDetails.fileUploadStatus);
                return fileDetails;
            });

    // All data is needed in order to group values before rendering
    if (dataflowId === DATAFLOW_API_TYPE.SIM_BULK) {
        return await getFileDetails(fileDetails);
    }

    getFileDetails(fileDetails);

    return fileDetails;
};
