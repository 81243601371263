import {
    getFormattedAmount,
    getArrayFromObject,
    getStringFromArray,
    getUnitDefinitions,
    getBestUnit,
    unitTypes,
} from '@/common/formatting';
import i18n from '@/i18n';
import { sortBy } from 'lodash';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import { CHARGING_SPECIFICATION_OPTIONS } from '@/common/chargingSpecification';
import cloneDeep from 'lodash/cloneDeep';
import _upperFirst from 'lodash/upperFirst';
import { STATUS_CODES } from '@/common/commonHelper';

export const scheduleInitValue = {
    days: {
        mon: {
            key: 'mon',
            active: false,
            i18nKey: 'charging.chargingSpecifications.editor.conditions.dateTime.mon',
            apiKey: 2,
        },
        tue: {
            key: 'tue',
            active: false,
            i18nKey: 'charging.chargingSpecifications.editor.conditions.dateTime.tue',
            apiKey: 3,
        },
        wed: {
            key: 'wed',
            active: false,
            i18nKey: 'charging.chargingSpecifications.editor.conditions.dateTime.wed',
            apiKey: 4,
        },
        thu: {
            key: 'thu',
            active: false,
            i18nKey: 'charging.chargingSpecifications.editor.conditions.dateTime.thu',
            apiKey: 5,
        },
        fri: {
            key: 'fri',
            active: false,
            i18nKey: 'charging.chargingSpecifications.editor.conditions.dateTime.fri',
            apiKey: 6,
        },
        sat: {
            key: 'sat',
            active: false,
            i18nKey: 'charging.chargingSpecifications.editor.conditions.dateTime.sat',
            apiKey: 7,
        },
        sun: {
            key: 'sun',
            active: false,
            i18nKey: 'charging.chargingSpecifications.editor.conditions.dateTime.sun',
            apiKey: 1,
        },
    },
    timeSlots: [
        {
            s: '',
            e: '',
        },
    ],
};

export const getScheduleInitValue = () => cloneDeep(scheduleInitValue);

export const PERIOD_TYPES = [
    // { id: 1, label: 'regular' },    // For documentation only (we don't want to show on UI)
    {
        id: 2,
        label: i18n.t('charging.chargingSpecifications.periodTypes.24hours'),
    },
    {
        id: 3,
        label: i18n.t('charging.chargingSpecifications.periodTypes.day'),
    },
    {
        id: 4,
        label: i18n.t('charging.chargingSpecifications.periodTypes.hour'),
    },
    {
        id: 5,
        label: i18n.t('charging.chargingSpecifications.periodTypes.event'),
    },
];

export const CHARGING_TYPE = {
    perUnit: 'perUnit',
    perPeriod: 'perPeriod',
    quota: 'quota',
    unlimited: 'unlimited',
    tieredCharge: 'tieredCharge',
};

export const CHARGING_TYPE_MAP_VALUES = new Map([
    [3, CHARGING_TYPE.perUnit],
    [2, CHARGING_TYPE.quota],
    [1, CHARGING_TYPE.unlimited],
]);

export const CHARGING_TYPE_OPTIONS = {
    [CHARGING_TYPE.perUnit]: 3,
    [CHARGING_TYPE.perPeriod]: 3,
    [CHARGING_TYPE.tieredCharge]: 3,
    [CHARGING_TYPE.quota]: 2,
    [CHARGING_TYPE.unlimited]: 1,
};

export const LIMIT_OPTIONS = {
    withLimit: {
        id: 1,
        i18nLabel: 'charging.chargingSpecifications.editor.withLimit',
    },
    unlimited: {
        id: 2,
        i18nLabel: 'charging.chargingSpecifications.editor.noPeriodLimit',
    },
};

export const TOTAL_LIMIT_OPTIONS = {
    withLimit: {
        id: 1,
        i18nLabel: 'charging.chargingSpecifications.editor.withTotalLimit',
    },
    noTotalLimit: {
        id: 2,
        i18nLabel: 'charging.chargingSpecifications.editor.noTotalLimit',
    },
};

// Enum: https://github.com/lotusflare/lua/blob/4b72c2e0deddfcf6ec65216be6db572a9d2568a7/V3/Charging/Util/Enums.lua#L32
export const CHARGING_SPEC_TYPE = {
    data: {
        key: 1,
        id: 'data',
    },
    voice: {
        key: 2,
        id: 'voice',
    },
    sms: {
        key: 3,
        id: 'sms',
    },
    mms: {
        key: 5,
        id: 'mms',
    },
    api: {
        key: 6,
        id: 'api',
    },
};

export const MAP_CHARGING_SPEC_TYPES = {
    [CHARGING_SPEC_TYPE.data.key]: [CHARGING_SPEC_TYPE.data.id],
    [CHARGING_SPEC_TYPE.voice.key]: [CHARGING_SPEC_TYPE.voice.id],
    [CHARGING_SPEC_TYPE.sms.key]: [CHARGING_SPEC_TYPE.sms.id],
    [CHARGING_SPEC_TYPE.mms.key]: [CHARGING_SPEC_TYPE.mms.id],
    [CHARGING_SPEC_TYPE.api.key]: [CHARGING_SPEC_TYPE.api.id],
};

// new function remove below one once we merge this
export function getChargingSpecificationValue(id) {
    const correspondingChargingSpecificationType = Object.values(CHARGING_SPEC_TYPE).find(
        chargingSpecificationType => chargingSpecificationType.key === id,
    );

    return correspondingChargingSpecificationType ? correspondingChargingSpecificationType.id : i18n.t('generic.N/A');
}

export function getChargingSpecificationKey(chargingSpecificationTypeKey) {
    const correspondingChargingSpecificationType = Object.values(CHARGING_SPEC_TYPE).find(
        chargingSpecificationType => chargingSpecificationType.id === chargingSpecificationTypeKey,
    );

    return correspondingChargingSpecificationType ? correspondingChargingSpecificationType.key : i18n.t('generic.N/A');
}

export function getChargingSpecificationTypeAsString(chargingSpecificationTypeKey) {
    const correspondingChargingSpecificationType = Object.values(CHARGING_SPECIFICATION_OPTIONS).find(
        chargingSpecificationType => chargingSpecificationType.key === chargingSpecificationTypeKey,
    );
    return correspondingChargingSpecificationType
        ? i18n.t(correspondingChargingSpecificationType.i18nLabel)
        : i18n.t('generic.N/A');
}

/**
 * Accepts Charging Specification charge type and returns text to be displayed.
 * @param type
 * @returns {*}
 */
export function chargeTypeToText(type) {
    const typeMap = {
        [CHARGING_TYPE.perUnit]: i18n.t('generic.yes'),
        [CHARGING_TYPE.quota]: i18n.t('generic.no'),
        [CHARGING_TYPE.perPeriod]: i18n.t('charging.chargingSpecifications.editor.chargePerPeriod'),
        [CHARGING_TYPE.unlimited]: i18n.t('charging.chargingSpecifications.editor.unlimited'),
    };
    return typeMap[type];
}

export function chargeTypeToTextOverview(type) {
    const typeMap = {
        [CHARGING_TYPE.perUnit]: i18n.t('charging.chargingSpecifications.editor.chargePerUnit'),
        [CHARGING_TYPE.quota]: i18n.t('charging.chargingSpecifications.editor.quota'),
        [CHARGING_TYPE.perPeriod]: i18n.t('charging.chargingSpecifications.editor.chargePerPeriod'),
        [CHARGING_TYPE.unlimited]: i18n.t('charging.chargingSpecifications.editor.unlimited'),
    };
    return typeMap[type] || i18n.t('generic.N/A');
}

export function getChargeText(entityData) {
    let serviceType = entityData?.service_types?.[0];
    if (entityData.serviceTypeFirst) {
        serviceType = entityData.serviceTypeFirst;
    } else if (entityData.service_types) {
        [serviceType] = entityData.service_types;
    }

    if (Object.prototype.hasOwnProperty.call(entityData, 'charge_type')) {
        if (entityData.charge_type === CHARGING_TYPE_OPTIONS[CHARGING_TYPE.unlimited]) {
            return chargeTypeToText(CHARGING_TYPE.unlimited);
        }
        if (entityData.period_type) {
            const resBase = `$${entityData.rate} ${i18n.t('charging.chargingSpecifications.editor.per')} ${
                PERIOD_TYPES.find(p => p.id === entityData.period_type).label
            }`;
            if (entityData.limit) {
                const formatData = getFormattedAmount(serviceType, entityData.limit);
                return `${resBase} ${i18n.t('charging.chargingSpecifications.editor.withLimitOf')} ${formatData}`;
            }
            return `${resBase} ${i18n.t('charging.chargingSpecifications.editor.noLimit')}`;
        }
        if (entityData.unit_amount != null) {
            const amount = entityData.unit_amount;
            const formattedAmount = getFormattedAmount(serviceType, amount);
            return `${i18n.t('charging.chargingSpecifications.flatRate')} ${formattedAmount}`;
        }
    }
    if (entityData.rate === '0') {
        return i18n.t('generic.free');
    }
    if (entityData.rate != null) {
        const period = entityData.rate_period == null ? 1 : entityData.rate_period;

        if (
            entityData?.service_types?.[0] === CHARGING_SPEC_TYPE.api.key &&
            entityData?.api_sub_type === API_SUB_TYPE.durationBased.key
        ) {
            const unitDefinitions = getUnitDefinitions(unitTypes.DURATION);
            const selectedUnitDefinition = getBestUnit(unitDefinitions, period);
            const value = period / selectedUnitDefinition.multiplier;

            return `$${entityData.rate} ${i18n.t('charging.chargingSpecifications.editor.per')} ${value} ${
                selectedUnitDefinition.field
            }`;
        }

        if (entityData?.service_types?.[0] === CHARGING_SPEC_TYPE.api.key && entityData.is_charge_per_period) {
            const unitDefinitions = getUnitDefinitions(unitTypes.DURATION);
            const selectedUnitDefinition = getBestUnit(unitDefinitions, period);
            const value = period / selectedUnitDefinition.multiplier;
            return `$${entityData.rate} ${i18n.t('charging.chargingSpecifications.editor.per')} ${value} ${_upperFirst(
                i18n.t('generic.days'),
            )}`;
        }
        const formattedAmount = getFormattedAmount(serviceType, period);
        return `$${entityData.rate} ${i18n.t('charging.chargingSpecifications.editor.per')} ${formattedAmount}`;
    }

    if (entityData.rate_tiers) {
        return i18n.t('charging.chargingSpecifications.editor.tieredCharge');
    }

    return i18n.t('generic.N/A');
}

export function getStateAsString(isActive) {
    return isActive ? i18n.t('generic.active') : i18n.t('generic.inactive');
}

export const LOGICAL_OPERATORS = {
    and: 'AND',
    or: 'OR',
};

export const CONDITION_TYPES = {
    BEARER: 'bearer',
    ZONE: 'zone',
    SPECIAL_NUMBER: 'special_number',
    SCHEDULE: 'schedule',
    APN: 'apn',
    RATING_GROUP: 'rating_group',
    TRAFFIC_TYPE: 'traffic_type',
    TIME_IN_CALL: 'time_in_call',
    NETWORK_SLICE_IDENTIFIER: 'nssid',
    OTHER_PARTY_RN: 'routing_number',
    SERVICE_TYPE: 'service_type',
    SUBSCRIBER_FLAG: 'subscriber_flag',
    BUCKET_FLAG: 'bucket_flag',
    OTHER_PARTY_NUMBER_LENGTH: 'length_of_number',
    SUBSCRIBER_LOCATION: 'subscriber_location',
    OTHER_PARTY_NUMBER: 'other_party_number',
    VLR_ID: 'vlr_id',
    SERVICE_ID: 'service_id',
    DATA_BALANCE: 'data_balance',
    BUCKET_TYPE: 'bucket_type',
    CUSTOM_CONDITION: 'custom',
};

export const getDefaultValueForConditionType = type => {
    const mapping = {
        [CONDITION_TYPES.BEARER]: [],
        [CONDITION_TYPES.ZONE]: {},
        [CONDITION_TYPES.SPECIAL_NUMBER]: [''],
        [CONDITION_TYPES.SCHEDULE]: getScheduleInitValue(),
        [CONDITION_TYPES.APN]: [],
        [CONDITION_TYPES.RATING_GROUP]: [],
        [CONDITION_TYPES.TRAFFIC_TYPE]: [],
        [CONDITION_TYPES.TIME_IN_CALL]: [],
        [CONDITION_TYPES.NETWORK_SLICE_IDENTIFIER]: {},
        [CONDITION_TYPES.SERVICE_TYPE]: 0,
        [CONDITION_TYPES.SUBSCRIBER_FLAG]: {},
        [CONDITION_TYPES.BUCKET_FLAG]: {},
        [CONDITION_TYPES.VLR_ID]: {},
        [CONDITION_TYPES.SERVICE_ID]: {},
        [CONDITION_TYPES.DATA_BALANCE]: {},
        [CONDITION_TYPES.BUCKET_TYPE]: null,
        [CONDITION_TYPES.CUSTOM_CONDITION]: null,
    };
    return mapping[type];
};

export const getTextLabelForConditionType = type => {
    const mapping = {
        [CONDITION_TYPES.RATING_GROUP]: i18n.t('charging.chargingSpecifications.editor.conditionText.ratingGroup'),
        [CONDITION_TYPES.APN]: i18n.t('charging.chargingSpecifications.editor.conditionText.apn'),
        [CONDITION_TYPES.BEARER]: i18n.t('charging.chargingSpecifications.editor.conditionText.bearer'),
        [CONDITION_TYPES.ZONE]: i18n.t('charging.chargingSpecifications.editor.conditionText.zone'),
        [CONDITION_TYPES.SPECIAL_NUMBER]: i18n.t('charging.chargingSpecifications.editor.conditionText.specialNumber'),
        [CONDITION_TYPES.SCHEDULE]: i18n.t('charging.chargingSpecifications.editor.conditionText.dateAndTime'),
        [CONDITION_TYPES.TRAFFIC_TYPE]: i18n.t('charging.chargingSpecifications.editor.conditionText.trafficType'),
        [CONDITION_TYPES.TIME_IN_CALL]: i18n.t('charging.chargingSpecifications.editor.conditionText.timeInCall'),
        [CONDITION_TYPES.NETWORK_SLICE_IDENTIFIER]: i18n.t('productCatalog.services.editor.networkSliceIdentifier'),
        [CONDITION_TYPES.OTHER_PARTY_RN]: i18n.t(
            'charging.chargingSpecifications.editor.conditionText.otherPartyRNFull',
        ),
        [CONDITION_TYPES.SERVICE_TYPE]: i18n.t('charging.chargingSpecifications.editor.serviceType'),
        [CONDITION_TYPES.SUBSCRIBER_FLAG]: i18n.t('charging.chargingSpecifications.editor.subscriberFlag'),
        [CONDITION_TYPES.BUCKET_FLAG]: i18n.t('charging.chargingSpecifications.editor.bucketFlag'),
        [CONDITION_TYPES.OTHER_PARTY_NUMBER_LENGTH]: i18n.t(
            'charging.chargingSpecifications.editor.conditionText.otherPartyNumberLength',
        ),
        [CONDITION_TYPES.SUBSCRIBER_LOCATION]: i18n.t(
            'charging.chargingSpecifications.editor.conditionText.subscriberLocation',
        ),
        [CONDITION_TYPES.OTHER_PARTY_NUMBER]: i18n.t(
            'charging.chargingSpecifications.editor.conditionText.otherPartyNumber',
        ),
        [CONDITION_TYPES.VLR_ID]: i18n.t('charging.chargingSpecifications.editor.conditionText.vlrid'),
        [CONDITION_TYPES.SERVICE_ID]: i18n.t('charging.chargingSpecifications.editor.conditionText.serviceId'),
        [CONDITION_TYPES.DATA_BALANCE]: i18n.t('charging.chargingSpecifications.editor.conditionText.dataBalance'),
        [CONDITION_TYPES.BUCKET_TYPE]: i18n.t('charging.chargingSpecifications.editor.conditionText.bucketType'),
        [CONDITION_TYPES.CUSTOM_CONDITION]: i18n.t(
            'charging.chargingSpecifications.editor.conditionText.customCondition',
        ),
    };
    return mapping[type];
};

export const allConditionOptions = {
    [CONDITION_TYPES.RATING_GROUP]: {
        id: 1,
        label: i18n.t('productCatalog.entities.ratingGroup'),
        text: i18n.t('charging.chargingSpecifications.editor.conditionText.ratingGroup'),
        type: CONDITION_TYPES.RATING_GROUP,
    },
    [CONDITION_TYPES.APN]: {
        id: 2,
        label: i18n.t('charging.chargingSpecifications.editor.apn'),
        text: i18n.t('charging.chargingSpecifications.editor.conditionText.apn'),
        type: CONDITION_TYPES.APN,
    },
    [CONDITION_TYPES.BEARER]: {
        id: 3,
        label: i18n.t('charging.chargingSpecifications.editor.bearer'),
        text: i18n.t('charging.chargingSpecifications.editor.conditionText.bearer'),
        type: CONDITION_TYPES.BEARER,
    },
    [CONDITION_TYPES.ZONE]: {
        id: 4,
        label: i18n.t('charging.chargingSpecifications.editor.zone'),
        text: i18n.t('charging.chargingSpecifications.editor.conditionText.zone'),
        type: CONDITION_TYPES.ZONE,
    },
    [CONDITION_TYPES.SPECIAL_NUMBER]: {
        id: 5,
        label: i18n.t('charging.chargingSpecifications.editor.specialNumber'),
        text: i18n.t('charging.chargingSpecifications.editor.conditionText.specialNumber'),
        type: CONDITION_TYPES.SPECIAL_NUMBER,
    },
    [CONDITION_TYPES.SCHEDULE]: {
        id: 6,
        label: i18n.t('charging.chargingSpecifications.editor.dateAndTime'),
        text: i18n.t('charging.chargingSpecifications.editor.conditionText.dateAndTime'),
        type: CONDITION_TYPES.SCHEDULE,
    },
    [CONDITION_TYPES.TRAFFIC_TYPE]: {
        id: 7,
        label: i18n.t('charging.chargingSpecifications.editor.trafficType'),
        text: i18n.t('charging.chargingSpecifications.editor.conditionText.trafficType'),
        type: CONDITION_TYPES.TRAFFIC_TYPE,
    },
    [CONDITION_TYPES.TIME_IN_CALL]: {
        id: 8,
        label: i18n.t('charging.chargingSpecifications.editor.conditionText.timeInCall'),
        text: i18n.t('charging.chargingSpecifications.editor.conditionText.timeInCall'),
        type: CONDITION_TYPES.TIME_IN_CALL,
    },
    [CONDITION_TYPES.NETWORK_SLICE_IDENTIFIER]: {
        id: 9,
        label: i18n.t('productCatalog.services.editor.networkSliceIdentifier'),
        text: i18n.t('productCatalog.services.editor.networkSliceIdentifier'),
        type: CONDITION_TYPES.NETWORK_SLICE_IDENTIFIER,
    },
    [CONDITION_TYPES.SERVICE_TYPE]: {
        id: 10,
        label: i18n.t('charging.chargingSpecifications.editor.serviceType'),
        text: i18n.t('charging.chargingSpecifications.editor.serviceType'),
        type: CONDITION_TYPES.SERVICE_TYPE,
    },
    [CONDITION_TYPES.OTHER_PARTY_RN]: {
        id: 11,
        label: i18n.t('charging.chargingSpecifications.editor.conditionText.otherPartyRN'),
        text: i18n.t('charging.chargingSpecifications.editor.conditionText.otherPartyRN'),
        type: CONDITION_TYPES.OTHER_PARTY_RN,
    },
    [CONDITION_TYPES.SUBSCRIBER_FLAG]: {
        id: 12,
        label: i18n.t('charging.chargingSpecifications.editor.subscriberFlag'),
        text: i18n.t('charging.chargingSpecifications.editor.subscriberFlag'),
        type: CONDITION_TYPES.SUBSCRIBER_FLAG,
    },
    [CONDITION_TYPES.BUCKET_FLAG]: {
        id: 13,
        label: i18n.t('charging.chargingSpecifications.editor.bucketFlag'),
        text: i18n.t('charging.chargingSpecifications.editor.bucketFlag'),
        type: CONDITION_TYPES.BUCKET_FLAG,
    },
    [CONDITION_TYPES.OTHER_PARTY_NUMBER_LENGTH]: {
        id: 14,
        label: i18n.t('charging.chargingSpecifications.editor.conditionText.otherPartyNumberLength'),
        text: i18n.t('charging.chargingSpecifications.editor.conditionText.otherPartyNumberLength'),
        type: CONDITION_TYPES.OTHER_PARTY_NUMBER_LENGTH,
    },
    [CONDITION_TYPES.SUBSCRIBER_LOCATION]: {
        id: 15,
        label: i18n.t('charging.chargingSpecifications.editor.conditionText.subscriberLocation'),
        text: i18n.t('charging.chargingSpecifications.editor.conditionText.subscriberLocation'),
        type: CONDITION_TYPES.SUBSCRIBER_LOCATION,
    },
    [CONDITION_TYPES.OTHER_PARTY_NUMBER]: {
        id: 15,
        label: i18n.t('charging.chargingSpecifications.editor.conditionText.otherPartyNumber'),
        text: i18n.t('charging.chargingSpecifications.editor.conditionText.otherPartyNumber'),
        type: CONDITION_TYPES.OTHER_PARTY_NUMBER,
    },
    [CONDITION_TYPES.VLR_ID]: {
        id: 16,
        label: i18n.t('charging.chargingSpecifications.editor.conditionText.vlrid'),
        text: i18n.t('charging.chargingSpecifications.editor.conditionText.vlrid'),
        type: CONDITION_TYPES.VLR_ID,
    },
    [CONDITION_TYPES.SERVICE_ID]: {
        id: 17,
        label: i18n.t('charging.chargingSpecifications.editor.conditionText.serviceId'),
        text: i18n.t('charging.chargingSpecifications.editor.conditionText.serviceId'),
        type: CONDITION_TYPES.SERVICE_ID,
    },
    [CONDITION_TYPES.DATA_BALANCE]: {
        id: 19,
        label: i18n.t('charging.chargingSpecifications.editor.conditionText.dataBalance'),
        text: i18n.t('charging.chargingSpecifications.editor.conditionText.dataBalance'),
        type: CONDITION_TYPES.DATA_BALANCE,
    },
    [CONDITION_TYPES.BUCKET_TYPE]: {
        id: 18,
        label: i18n.t('charging.chargingSpecifications.editor.conditionText.bucketType'),
        text: i18n.t('charging.chargingSpecifications.editor.conditionText.bucketType'),
        type: CONDITION_TYPES.BUCKET_TYPE,
    },
    [CONDITION_TYPES.CUSTOM_CONDITION]: {
        id: 19,
        label: i18n.t('charging.chargingSpecifications.editor.conditionText.customCondition'),
        text: i18n.t('charging.chargingSpecifications.editor.conditionText.customCondition'),
        type: CONDITION_TYPES.CUSTOM_CONDITION,
    },
};

export function getRatePeriodBaseOnUnits(ratePeriod, ratePeriodUnit, chargingSpecificationType) {
    const inBaseUnits = Number(ratePeriod);

    if (chargingSpecificationType === CHARGING_SPEC_TYPE.data.id) {
        const mapping = {
            Bytes: () => inBaseUnits,
            KB: () => inBaseUnits * 1024,
            MB: () => inBaseUnits * 1024 * 1024,
            GB: () => inBaseUnits * 1024 * 1024 * 1024,
        };
        return mapping[ratePeriodUnit]();
    }
    if (chargingSpecificationType === CHARGING_SPEC_TYPE.voice.id) {
        const mapping = {
            seconds: () => inBaseUnits,
            minutes: () => inBaseUnits * 60,
            hours: () => inBaseUnits * 60 * 60,
            days: () => inBaseUnits * 60 * 60 * 24,
        };
        return mapping[ratePeriodUnit]();
    }
    return 1;
}

export const getAmendedConditionsList = (amendment, chargingSpecification) => {
    const amendedFields = Object.keys(amendment.data);
    const conditionFields = amendedFields.filter(key => key.includes('conditions.parameters'));
    const conditionsList = conditionFields.map(key => {
        const uuid = key.split('.')[2];
        const conditionIndex = Object.keys(chargingSpecification.data.conditions.parameters).indexOf(uuid);
        return {
            conditionAmendment: amendment.data[key],
            conditionIndex: conditionIndex + 1,
        };
    });
    return sortBy(conditionsList, 'conditionIndex');
};

export const PC_CONDITION_TYPES = {
    BEARER: 'bearer',
    ZONE: 'zone',
    SPECIAL_NUMBER: 'special_number',
    SCHEDULE: 'schedule',
    APN: 'apn',
    RATING_GROUP: 'rating_group',
    TRAFFIC_TYPE: 'traffic_type',
    TIME_IN_CALL: 'time_in_call',
    NETWORK_SLICE_IDENTIFIER: 'nssid',
    SERVICE_TYPE: 'service_type',
    OTHER_PARTY_RN: 'routing_number',
    SUBSCRIBER_FLAG: 'subscriber_flag',
    BUCKET_FLAG: 'bucket_flag',
    OTHER_PARTY_NUMBER_LENGTH: 'length_of_number',
    SUBSCRIBER_LOCATION: 'subscriber_location',
    OTHER_PARTY_NUMBER: 'other_party_number',
    VLR_ID: 'vlr_id',
    SERVICE_ID: 'service_id',
    DATA_BALANCE: 'data_balance',
    BUCKET_TYPE: 'bucket_type',
    CUSTOM_CONDITION: 'custom',
};

export const getTextLabelForPcConditionType = type => {
    const mapping = {
        [PC_CONDITION_TYPES.RATING_GROUP]: i18n.t('charging.chargingSpecifications.editor.conditionText.ratingGroup'),
        [PC_CONDITION_TYPES.APN]: i18n.t('charging.chargingSpecifications.editor.conditionText.apn'),
        [PC_CONDITION_TYPES.BEARER]: i18n.t('charging.chargingSpecifications.editor.conditionText.bearer'),
        [PC_CONDITION_TYPES.ZONE]: i18n.t('charging.chargingSpecifications.editor.conditionText.zone'),
        [PC_CONDITION_TYPES.SPECIAL_NUMBER]: i18n.t(
            'charging.chargingSpecifications.editor.conditionText.specialNumber',
        ),
        [PC_CONDITION_TYPES.SCHEDULE]: i18n.t('charging.chargingSpecifications.editor.conditionText.dateAndTime'),
        [PC_CONDITION_TYPES.TRAFFIC_TYPE]: i18n.t('charging.chargingSpecifications.editor.conditionText.trafficType'),
        [PC_CONDITION_TYPES.TIME_IN_CALL]: i18n.t('charging.chargingSpecifications.editor.conditionText.timeInCall'),
        [PC_CONDITION_TYPES.OTHER_PARTY_NUMBER_LENGTH]: i18n.t(
            'charging.chargingSpecifications.editor.conditionText.otherPartyNumberLength',
        ),
        [PC_CONDITION_TYPES.SUBSCRIBER_LOCATION]: i18n.t(
            'charging.chargingSpecifications.editor.conditionText.subscriberLocation',
        ),
    };
    return mapping[type];
};

export const chargingSpecificationTypeMap = new Map([
    ['charging_spec', i18n.t('charging.chargingSpecifications.types.chargingSpec')],
    ['bucket', i18n.t('charging.chargingSpecifications.types.bucket')],
    ['feature', i18n.t('charging.chargingSpecifications.types.feature')],
]);

export function formatChargingSpecificationDataForOverview(chargingSpecification) {
    const chargingSpecificationData = chargingSpecification.data;
    const defaultProperties = [
        {
            label: 'ID',
            value: chargingSpecification.id,
        },
        {
            label: 'Type',
            value: chargingSpecificationTypeMap.get(chargingSpecificationData.type),
        },
        {
            label: 'Feature ID',
            value: chargingSpecificationData.feature_id,
        },
    ];

    if (chargingSpecificationData.static_assets) {
        const icons = getArrayFromObject(chargingSpecificationData.static_assets);

        defaultProperties.push({
            label: 'Icons',
            value: getStringFromArray(icons),
        });
    }

    return {
        name: getMultiLangFieldValueByLocale(chargingSpecificationData.name),
        properties: [
            ...defaultProperties,
            ...(chargingSpecificationData.type === 'bucket'
                ? [
                      {
                          label: 'Bucket size',
                          value: chargingSpecificationData.is_unlimited ? 'UNL' : chargingSpecification.bucket_size,
                      },
                  ]
                : []),
        ],
    };
}

export const getCSType = type => {
    switch (type) {
        case 1: {
            return {
                id: 'data',
                key: 1,
            };
        }
        case 2: {
            return {
                id: 'voice',
                key: 2,
            };
        }
        case 3: {
            return {
                id: 'sms',
                key: 3,
            };
        }
        case 5: {
            return {
                id: 'mms',
                key: 5,
            };
        }
        case 6: {
            return {
                id: 'api',
                key: 6,
            };
        }
        default: {
            return {};
        }
    }
};

export const serviceTypeOptions = [
    CHARGING_SPECIFICATION_OPTIONS.Data,
    CHARGING_SPECIFICATION_OPTIONS.Voice,
    CHARGING_SPECIFICATION_OPTIONS.SMS,
    CHARGING_SPECIFICATION_OPTIONS.MMS,
    CHARGING_SPECIFICATION_OPTIONS.API,
];

export const trafficTypeConditionOptions = [
    {
        label: i18n.t('charging.chargingSpecifications.editor.originating'),
        value: 1,
    },
    {
        label: i18n.t('charging.chargingSpecifications.editor.forwarding'),
        value: 2,
    },
    {
        label: i18n.t('charging.chargingSpecifications.editor.terminating'),
        value: 3,
    },
];

export const CHAGE_TYPES = {
    [CHARGING_TYPE.perUnit]: {
        id: CHARGING_TYPE.perUnit,
        title: i18n.t('charging.chargingSpecifications.editor.monetary'),
        header: true,
    },
    [CHARGING_TYPE.perPeriod]: {
        id: CHARGING_TYPE.perPeriod,
        title: i18n.t('charging.chargingSpecifications.editor.monetary'),
        header: true,
    },
    [CHARGING_TYPE.tieredCharge]: {
        id: CHARGING_TYPE.tieredCharge,
        title: i18n.t('charging.chargingSpecifications.editor.monetary'),
        header: true,
    },
    [CHARGING_TYPE.quota]: {
        id: CHARGING_TYPE.quota,
        title: i18n.t('charging.chargingSpecifications.editor.nonMonetary'),
        header: true,
    },
    [CHARGING_TYPE.unlimited]: {
        id: CHARGING_TYPE.unlimited,
        title: i18n.t('charging.chargingSpecifications.editor.nonMonetary'),
        header: true,
    },
};

export const CHARGE_TYPES_OPTIONS = {
    [CHARGING_SPEC_TYPE.data.id]: [
        {
            id: 'monetary-data',
            title: i18n.t('charging.chargingSpecifications.editor.monetary'),
            header: true,
            child: [
                CHAGE_TYPES[CHARGING_TYPE.perUnit],
                CHAGE_TYPES[CHARGING_TYPE.perPeriod],
                CHAGE_TYPES[CHARGING_TYPE.tieredCharge],
            ],
        },
        CHAGE_TYPES[CHARGING_TYPE.quota],
        CHAGE_TYPES[CHARGING_TYPE.unlimited],
    ],
    [CHARGING_SPEC_TYPE.voice.id]: [
        {
            id: 'monetary-voice',
            title: i18n.t('charging.chargingSpecifications.editor.monetary'),
            header: true,
            child: [
                CHAGE_TYPES[CHARGING_TYPE.perUnit],
                CHAGE_TYPES[CHARGING_TYPE.perPeriod],
                CHAGE_TYPES[CHARGING_TYPE.tieredCharge],
            ],
        },
        CHAGE_TYPES[CHARGING_TYPE.quota],
        CHAGE_TYPES[CHARGING_TYPE.unlimited],
    ],
    [CHARGING_SPEC_TYPE.sms.id]: [
        {
            id: 'monetary-voice',
            title: i18n.t('charging.chargingSpecifications.editor.monetary'),
            header: true,
            child: [CHAGE_TYPES[CHARGING_TYPE.perUnit], CHAGE_TYPES[CHARGING_TYPE.tieredCharge]],
        },
        CHAGE_TYPES[CHARGING_TYPE.quota],
        CHAGE_TYPES[CHARGING_TYPE.unlimited],
    ],
    [CHARGING_SPEC_TYPE.mms.id]: [
        {
            id: 'monetary-voice',
            title: i18n.t('charging.chargingSpecifications.editor.monetary'),
            header: true,
            child: [CHAGE_TYPES[CHARGING_TYPE.perUnit], CHAGE_TYPES[CHARGING_TYPE.tieredCharge]],
        },
        CHAGE_TYPES[CHARGING_TYPE.quota],
        CHAGE_TYPES[CHARGING_TYPE.unlimited],
    ],
    [CHARGING_SPEC_TYPE.api.id]: [
        {
            id: 'monetary-voice',
            title: i18n.t('charging.chargingSpecifications.editor.monetary'),
            header: true,
            child: [CHAGE_TYPES[CHARGING_TYPE.perUnit], CHAGE_TYPES[CHARGING_TYPE.tieredCharge]],
        },
        CHAGE_TYPES[CHARGING_TYPE.quota],
        CHAGE_TYPES[CHARGING_TYPE.unlimited],
    ],
};

export const CSG_ROLLOVERS_FIRST_USE_TYPES = {
    ROLLED_OVER_BALANCE: 1,
    NEW_BALANCE: 2,
};

export const CSG_ROLLOVERS_FIRST_USE_OPTIONS = {
    [CSG_ROLLOVERS_FIRST_USE_TYPES.ROLLED_OVER_BALANCE]: {
        id: CSG_ROLLOVERS_FIRST_USE_TYPES.ROLLED_OVER_BALANCE,
        label: i18n.t('charging.CSG.editor.rolledOverBalance'),
    },
    [CSG_ROLLOVERS_FIRST_USE_TYPES.NEW_BALANCE]: {
        id: CSG_ROLLOVERS_FIRST_USE_TYPES.NEW_BALANCE,
        label: i18n.t('charging.CSG.editor.newBalance'),
    },
};

export const ROW_STATE_ACTIONS_CS_TYPES = {
    PUSH_CS_VERSION: 'pushCsVersion',
};

export function isPushedVersionAtCurrentVersion({ chargingVersion, pushedVersion, state }) {
    if (state === STATUS_CODES.NA) {
        return false;
    }

    const res = chargingVersion?.localeCompare(pushedVersion, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
    return res === 1;
}

/**
 * Sorts an object by its keys, where the keys are version strings in the format 'version_X.Y.Z.W'.
 * The versions are sorted in descending order based on their numeric values.
 *
 * @param {Object} obj - The object with version strings as keys and associated values.
 * @returns {Object} - A new object with the same key-value pairs, but with keys sorted by version in descending order.
 */
export function sortChargingSpecVersionsCount(obj) {
    if (!obj) {
        return {};
    }

    const sortedKeys = Object.keys(obj).sort((a, b) => {
        // Helper function to convert a version string into an array of integers
        const getVersionArray = version => version.split('_')[1].split('.').map(Number);

        const versionA = getVersionArray(a);
        const versionB = getVersionArray(b);

        // Compare each part of the version numbers
        for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
            // Default to 0 if the part is missing (e.g., comparing '1.0' with '1.0.0')
            const diff = (versionB[i] || 0) - (versionA[i] || 0);
            if (diff !== 0) {
                return diff; // Return the difference if the parts are not equal
            }
        }
        return 0; // Return 0 if the versions are exactly equal
    });

    // Create a new object with the sorted keys
    const sortedObj = {};
    for (const key of sortedKeys) {
        sortedObj[key] = obj[key];
    }

    return sortedObj;
}

export const API_SUB_TYPE = {
    requestBased: {
        key: 1,
        id: 'requestBased',
    },
    durationBased: {
        key: 2,
        id: 'durationBased',
    },
    subscriptionBased: {
        key: 2,
        id: 'subscriptionBased',
    },
};

export const API_CHARGING_TYPE = {
    requestBased: 'requestBased',
    durationBased: 'durationBased',
    subscriptionBased: 'subscriptionBased',
};

export const API_CHAGE_TYPES = {
    [API_CHARGING_TYPE.requestBased]: {
        id: API_CHARGING_TYPE.requestBased,
        header: true,
        key: API_SUB_TYPE.requestBased.key,
    },
    [API_CHARGING_TYPE.durationBased]: {
        id: API_CHARGING_TYPE.durationBased,
        header: true,
        key: API_SUB_TYPE.durationBased.key,
    },
    [API_CHARGING_TYPE.subscriptionBased]: {
        id: API_CHARGING_TYPE.subscriptionBased,
        header: true,
        key: API_SUB_TYPE.subscriptionBased.key,
    },
};

export const API_SUB_TYPE_TYPES_OPTIONS = {
    [CHARGING_SPEC_TYPE.api.id]: [
        API_CHAGE_TYPES[API_CHARGING_TYPE.requestBased],
        API_CHAGE_TYPES[API_CHARGING_TYPE.durationBased],
        API_CHAGE_TYPES[API_CHARGING_TYPE.subscriptionBased],
    ],
};

export const ADDITIONAL_AGGREGATIONS_OPTIONS = [
    {
        name: i18n.t('productCatalog.entities.application'),
        value: 1,
    },
];

export default {
    formatChargingSpecificationDataForOverview,
};
