
import Vue from 'vue';

// COMPONENTS

import BulkUploadCounts, { type BulkUploadCounter } from '@/__new__/features/resources/BulkUploadCounts.vue';
import BulkUploadPageLayout from '@/__new__/features/resources/BulkUploadPageLayout.vue';
import NumberSearch from '@/__new__/features/resources/number/NumberSearch.vue';

// Models
import MSISDNStatistics from '@/__new__/services/dno/number/models/msisdnStatistics';

// HELPERS
import type { Tab } from '@/__new__/features/resources/BulkUploadTabs.vue';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import { type CollapsibleListItem } from '@/common/AppCollapsibleListHelper';
import { DATAFLOW_API_TYPE } from '@/__new__/services/dno/progressTracker/progressTrackerHelper';
import {
    formatEventCategory,
    getAllFileUploadStatusStrings,
    type UploadedFileDetails,
} from '@/common/fileUploadHelper';
import { SEGMENT_ID_TYPES, StaticFilterLabels } from '@/common/StaticFilter';
import { isUserAllowed } from '@/services/permissions/permissions.service';

// HTTP
import { getMsisdnStatistics } from '@/__new__/services/dno/number/http/numberManagement';
import { getUploadHistory } from '@/__new__/services/dno/progressTracker/http/progressTrackerService';

export default Vue.extend({
    name: 'NumberManagement',
    components: {
        BulkUploadCounts,
        BulkUploadPageLayout,
        NumberSearch,
    },
    data() {
        return {
            utilityTypes: [
                {
                    id: SEGMENT_ID_TYPES.MSISDN,
                    label: StaticFilterLabels[SEGMENT_ID_TYPES.MSISDN],
                    i18n: 'customerCare.msisdn',
                },
            ],
            counts: [] as BulkUploadCounter[],
            tab: 'upload' as Tab['id'],
            dataflow: DATAFLOW_API_TYPE.NUMBER_BULK,
            uploadHistoryColumnsData: [
                {
                    name: this.$t('generic.uploadTime'),
                    key: 'createdAtStr',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.createdAt),
                    sortBy: entity => entity.createdAt,
                    field: 'createdAt',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$t('generic.filename'),
                    key: 'fileName',
                    field: 'fileName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.uploadedBy'),
                    key: 'createdBy',
                    field: 'createdBy',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('qodNumberManagement.recordCount'),
                    key: 'recordCount',
                    field: 'recordCount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$t('generic.status'),
                    key: 'fileUploadStatusStr',
                    field: 'fileUploadStatusStr',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: getAllFileUploadStatusStrings(),
                },
            ] as TableColumn[],
            entities: [] as UploadedFileDetails[],
            isHistoryDataLoading: false,
            // Search
            isSearchEnabled: isUserAllowed('SearchNumbers'),
        };
    },
    computed: {
        tabs(): Tab[] {
            const tabs = [
                {
                    id: 'upload',
                    label: this.$t('operations.bulkUpload.uploadHistory'),
                },
            ];

            if (this.isSearchEnabled) {
                tabs.push({
                    id: 'search',
                    label: this.$t('generic.search'),
                });
            }

            return tabs;
        },
        title(): string {
            return `${this.$tc('operator.number')} ${this.$t('operator.management')}`;
        },
    },
    created() {
        const apis = [this.loadHistory()];

        if (isUserAllowed('ViewNumberCounts')) {
            apis.push(this.loadCounts());
        }

        Promise.all(apis);
    },
    methods: {
        mapSidebarData(entity: any): CollapsibleListItem[] {
            return [
                {
                    name: this.$t('generic.general'),
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$t('generic.filename'),
                            value: entity?.fileName ?? '',
                        },
                        {
                            name: this.$t('qodNumberManagement.bulkUploadId'),
                            value: entity?.bulkUploadId ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('qodNumberManagement.recordCount'),
                            value: entity?.recordCount ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('generic.uploadedBy'),
                            value: entity?.createdBy ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('generic.uploadTime'),
                            value: this.$localeLibrary.getFormattedDateAndTime(entity?.createdAt),
                        },
                    ],
                },
                {
                    name: this.$t('qodNumberManagement.provisionStatus'),
                    isCollapsed: false,
                    rows: Object.entries(entity?.eventCategoryCounters || {}).length
                        ? Object.entries(entity?.eventCategoryCounters || {}).map(([status, count]) => ({
                              name: formatEventCategory(status),
                              value: `${count}/${entity?.recordCount}`,
                          }))
                        : [{ name: this.$t('generic.unknown') }],
                },
            ];
        },
        loadCounts(): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    const result = await getMsisdnStatistics();
                    const statistics = result?.data?.result;
                    if (statistics) {
                        this.setData(statistics);
                    }
                },
                {
                    errorHandler: () => this.$alert(this.$t('operations.alerts.unableToGetCountersData')),
                },
            );
        },
        loadHistory(): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    this.isHistoryDataLoading = true;
                    this.entities = await getUploadHistory(this.dataflow);
                    this.isHistoryDataLoading = false;
                },
                {
                    errorHandler: () => {
                        this.isHistoryDataLoading = false;
                        this.$alert(this.$t('qodNumberManagement.loadingBulkUploadHistoryFailed'));
                    },
                },
            );
        },
        setData(statistics: any): void {
            const msisdnStatistics = new MSISDNStatistics(statistics);
            this.counts = [
                {
                    label: `${this.$t('operations.numberManagement.totalNumbers')}`,
                    value: msisdnStatistics.totalNumber,
                },
                {
                    label: `${this.$t('operations.numberManagement.totalOwnedNumbers')}`,
                    value: msisdnStatistics.totalOwnedNumber,
                },
                {
                    label: `${this.$t('operations.numberManagement.totalAvailableNumbers')}`,
                    value: msisdnStatistics.availableNumber,
                },
                {
                    label: `${this.$t('operations.numberManagement.totalReservedNumbers')}`,
                    value: msisdnStatistics.reserveNumber,
                },
                {
                    label: `${this.$t('operations.numberManagement.totalAssignedNumbersOwned')}`,
                    value: msisdnStatistics.assignedOwnedNumber,
                },
                {
                    label: `${this.$t('operations.numberManagement.totalAssignedNumbersPortIn')}`,
                    value: msisdnStatistics.assignedNumberPortIn,
                },
                {
                    label: `${this.$t('operations.numberManagement.totalAssignedNumbersPortOut')}`,
                    value: msisdnStatistics.assignedNumberPortOut,
                },
                {
                    label: `${this.$t('operations.numberManagement.cooldownNumbers')}`,
                    value: msisdnStatistics.coolDownNumber,
                },
                {
                    label: `${this.$t('operations.numberManagement.unavailableNumbers')}`,
                    value: msisdnStatistics.unavailableNumber,
                },
            ];
        },
    },
});
