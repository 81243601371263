








































import { isEqual, keys, uniq, without } from 'lodash';
import {
    CohortType,
    TargetingOptions,
    TargetingStrategiesByMessageType,
} from '@/__new__/services/dno/campaigns/models/Campaign';
import { CohortList } from '@/__new__/services/dno/sinkConfigs/models/CohortExpression';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';
import AppRadioButton from '@/components/partials/inputs/AppRadioButton.vue';

export default {
    name: 'TargetingStrategySelect',
    components: { AppCheckbox, AppRadioButton },
    props: {
        value: { type: String, default: '' },
        cohorts: { type: Object, default: () => ({}) },
        messageType: { type: String, default: '' },
        isScheduledDelivery: { type: Boolean, default: false },
        isUnregisteredAllowed: { type: Boolean, default: false },
    },
    data() {
        return {
            showUnregisteredCheckbox: false,
            optionsByMessageType: {},
        };
    },
    computed: {
        cohortsGroups() {
            return without(uniq(keys(this.cohorts[CohortList.WHITELIST])), 'logical_operator');
        },
        targetingStrategy() {
            const strategy = { ...TargetingStrategiesByMessageType[this.messageType] };

            if (this.isScheduledDelivery && !isEqual(this.cohortsGroups, [CohortType.SUBSCRIBER])) {
                strategy.options = without(strategy.options, TargetingOptions.Exact);
            }

            return strategy;
        },
        optionsWithLabels() {
            return this.targetingStrategy.options.map((option: string) => ({
                value: option,
                ...this.$t(`campaigns.targetingOptions.${option}`),
            }));
        },
        validCohortsPresent() {
            return [CohortType.MSISDN, CohortType.EMAIL].some(group => this.cohortsGroups.includes(group));
        },
    },
    watch: {
        targetingStrategy(strategy) {
            // Make sure to select relevant targeting option after message type changed
            if (!strategy.options.includes(this.value)) {
                this.$emit('input', strategy.options[0] || null);
            }
            if (!strategy.unregistered) {
                this.$emit('allowUnregistered', false);
            }
            this.showUnregisteredCheckbox = strategy.unregistered;
        },
    },
};
