



















// Generic
import Vue from 'vue';

// Vuex
import { mapGetters, mapActions } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';

// Components
import LayoutPage from '@/__new__/features/customerCareSuite/common/LayoutPage.vue';
import NonSensitiveInfoPage from '@/__new__/features/customerCareSuite/common/NonSensitiveInfoPage.vue';
import AppSpinner from '@/components/partials/AppSpinner.vue';

// Helpers
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { LAYOUT_PAGE_KEYS } from '@/__new__/features/customerCareSuite/common/layoutSectionHelper';
import { isUserAllowed } from '@/services/permissions/permissions.service';

// Mixins
import EndUserAuthorizationMixin from '@/__new__/features/customerCareSuite/components/EndUserAuthorizationMixin.vue';

export default Vue.extend({
    name: 'UserPageView',

    components: {
        LayoutPage,
        NonSensitiveInfoPage,
        AppSpinner,
    },

    mixins: [EndUserAuthorizationMixin],

    props: {
        id: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            USER_MANAGER_HIERARCHY,
            layoutPageKey: LAYOUT_PAGE_KEYS.USER,
            isDataLoading: false,
        };
    },

    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        accountData() {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.USER,
                targetId: this.id,
            });
        },
    },

    created() {
        this.$withProgressBar(
            async () => {
                this.isDataLoading = true;

                if (!isUserAllowed('UMEndUserFullDataAccess')) {
                    await this.getUserIdFromNonSensitiveInfo();
                }

                if (this.isEndUserAuthorizedForUm) {
                    await this[Actions.LOAD_ASSOCIATED_UM_ENTITIES]({
                        targetType: USER_MANAGER_HIERARCHY.USER,
                        targetId: this.id,
                    });
                }
                this.isDataLoading = false;
            },
            {
                errorHandler: () => {
                    this.isDataLoading = false;
                    this.$showErrorAlert({
                        message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    });
                },
            },
        );
    },

    methods: {
        ...mapActions(Modules.customerCareSuite, [Actions.LOAD_ASSOCIATED_UM_ENTITIES]),
    },
});
