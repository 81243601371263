import i18n from '@/i18n';

export const PRIZE_ENGINE_TYPE_KEYS = {
    GENERIC: 'generic',
    SPIN_WHEEL: 'spin_wheel',
};

export const PRIZE_ENGINE_TYPE_OPTIONS = {
    [PRIZE_ENGINE_TYPE_KEYS.GENERIC]: {
        id: 'generic',
        i18nLabel: 'generic.generic',
    },
    [PRIZE_ENGINE_TYPE_KEYS.SPIN_WHEEL]: {
        id: 'spin_wheel',
        i18nLabel: 'rewards.spinWheel',
    },
};

export const PRIZE_ENGINE_CURRENCY_TYPES = {
    primary: {
        id: 'primary',
        i18nLabel: 'productCatalog.offers.editor.monetary',
    },
    rewardPoints: {
        id: 'coins',
        i18nLabel: 'productCatalog.offers.editor.rewardPoints',
    },
    spins: {
        id: 'spins',
        i18nLabel: 'productCatalog.offers.editor.spins',
    },
};

export const SEGMENT_COMBINE_OPERATION_TYPES = {
    intersection: {
        id: 'AND',
        i18nLabel: 'prizeEngine.editor.intersectionOfUsersFrom',
    },
    union: {
        id: 'OR',
        i18nLabel: 'prizeEngine.editor.allUsersFrom',
    },
};

export const PRIZE_ENGINE_PRIZE_TYPE_OPTIONS = {
    common: {
        id: 1,
        i18nLabel: 'prizeEngine.editor.commonPrize',
    },
    noWin: {
        id: 2,
        i18nLabel: 'prizeEngine.editor.noWin',
    },
};

export const prizeEngineTypes = new Map([['spin_wheel', 'rewards.spinWheel']]);

export const rewardTimeUnit = new Map([
    [1, 'generic.days'],
    [2, 'generic.weeks'],
    [3, 'generic.months'],
    [4, 'generic.minutes'],
]);

export const rewardTimeLimits = new Map([
    [true, 'generic.unlimited'],
    [false, 'generic.exactly'],
]);

const purchaseEventTypesMap = new Map([
    [1, 'rewards.walletHelper.createOrder'],
    [2, 'rewards.walletHelper.topUp'],
    [3, 'rewards.walletHelper.resetWallet'],
    [4, 'rewards.walletHelper.partialTopUp'],
    [5, 'rewards.walletHelper.cancelOrder'],
    [6, 'rewards.walletHelper.cancelTopUp'],
    [7, 'rewards.walletHelper.stopRecurrence'],
    [8, 'rewards.walletHelper.activateOrder'],
    [9, 'rewards.walletHelper.recurrence'],
    [10, 'rewards.walletHelper.replaceOrder'],
    [11, 'rewards.walletHelper.deductBalance'],
    [12, 'rewards.walletHelper.deactivateOrder'],
    [13, 'rewards.walletHelper.orderHoldFunds'],
    [14, 'rewards.walletHelper.orderChargeFunds'],
    [15, 'rewards.walletHelper.walletCommit'],
    [19, 'rewards.walletHelper.serviceAsCurrencyCommit'],
    [20, 'rewards.walletHelper.serviceAsCurrencyDebit'],
    [24, 'rewards.walletHelper.walletDebit'],
]);

export const REQUIRED_PRIZES_TOTAL_PROBABILITY = 100;

export function getPurchaseEventType(eventId) {
    return i18n.t(purchaseEventTypesMap.get(eventId));
}
