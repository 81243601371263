import http, { type DnoResponseBase } from '@/http/index';
import { type AxiosPromise } from 'axios';
import { TARGET_TYPE } from '@/__new__/services/dno/user/models/targetTuple';
import { RECORD_CATEGORY } from '@/__new__/features/customerCareSuite/common/serviceTransactionsHelper';
import { type DailyUsageApiResponse } from '@/__new__/features/customerCareSuite/common/serviceTransactionsHelper';
import { type SignedUrlForDownloadEntity } from '@/common/fileUploadHelper';
import { type DATAFLOW_API_TYPE } from '@/__new__/services/dno/ossmvne/ossmvneHelper';
import { RESOURCE_TYPE, SIM_TYPE } from '@/__new__/features/resources/common/sim';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';

interface GetAvailableWPSResponse extends DnoResponseBase {
    wps: string[];
}

interface GetEsimProfileResponse extends DnoResponseBase {
    profiles: {
        profileState: string;
    }[];
}

export function getAvailableWPS(target_id: string): AxiosPromise<GetAvailableWPSResponse> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/getavailablewps',
        data: {
            target_id,
            target_type: TARGET_TYPE.SUBSCRIBER,
        },
    });
}

export function updateWPS(
    target_id: string,
    msisdn: string,
    iccid: string,
    wps: string,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/updatewps',
        data: {
            target_type: TARGET_TYPE.SUBSCRIBER,
            target_id,
            msisdn,
            iccid,
            wps,
        },
    });
}

export function suspendLine(target_id: string, target_type: number, reason: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/suspendline',
        data: {
            target_id,
            target_type,
            reason,
        },
    });
}

export function resumeSuspendLine(
    target_id: string,
    target_type: number,
    should_unblock_imei: boolean,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/resumesuspendline',
        data: {
            target_id,
            target_type,
            should_unblock_imei,
        },
    });
}

export function requestCancelLine(target_id: string, target_type: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/requestcancelline',
        data: {
            target_id,
            target_type,
        },
    });
}

export function cancelLineCancellationRequest(target_id: string, target_type: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/cancellinecancellationrequest',
        data: {
            target_id,
            target_type,
        },
    });
}

export function referrenceNGPInquiry(market_zip: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/referrencengpinquiry',
        data: {
            market_zip,
        },
    });
}

export function changeMSISDN(
    target_id: string,
    target_type: number,
    market_zip: string,
    msisdn: string,
    iccid: string,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/changemsisdn',
        data: {
            target_id,
            target_type,
            market_zip,
            msisdn,
            iccid,
        },
    });
}

export function getUsage(target_id: string, target_type: USER_MANAGER_HIERARCHY): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/getusage',
        data: {
            target_id,
            target_type,
        },
    });
}

export function getDailyUsage(
    msisdn: string,
    recordCategory = RECORD_CATEGORY.VOICE_AND_SMS,
    nextPageToken?: string,
): AxiosPromise<DailyUsageApiResponse> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/getdailyusage',
        data: {
            msisdn,
            next_page_token: nextPageToken,
            filter: {
                record_category: recordCategory,
            },
        },
    });
}

export function getSignedURLForDownload(
    dataflow: DATAFLOW_API_TYPE,
    filename: string,
): AxiosPromise<SignedUrlForDownloadEntity> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/signedurl/download',
        headers: {
            'Content-type': 'application/json',
        },
        data: {
            dataflow,
            file_name: filename,
        },
    });
}

export function getEsimProfile(iccid: string): AxiosPromise<GetEsimProfileResponse> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/getesimprofile',
        data: { iccid },
    });
}

export function resetVoiceMailPwd(
    target_id: string,
    target_type: number,
    msisdn: string,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/resetvoicemailpassword',
        data: {
            target_id,
            target_type,
            msisdn,
        },
    });
}

export function validateICCID(
    target_id: string,
    target_type: number,
    iccid: string,
    sim_type: number = RESOURCE_TYPE[SIM_TYPE.PSIM],
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ossmvne/validateiccid',
        data: {
            target_id,
            target_type,
            iccid,
            sim_type,
        },
    });
}

export const OSSMVNEService = {
    getOptions: getAvailableWPS,
    update: updateWPS,
};

export default {
    getAvailableWPS,
    updateWPS,
    suspendLine,
    resumeSuspendLine,
    requestCancelLine,
    cancelLineCancellationRequest,
    referrenceNGPInquiry,
    changeMSISDN,
    getUsage,
    getDailyUsage,
    getEsimProfile,
    resetVoiceMailPwd,
    validateICCID,
};
