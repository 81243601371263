//
/* eslint-disable indent */
import VueI18n from 'vue-i18n';
import range from 'lodash/range';
import { HumanizeDurationLanguage, HumanizeDuration } from 'humanize-duration-ts';
import isArray from 'lodash/isArray';
import moment from 'moment';
import numeral from 'numeral';
import toUpper from 'lodash/toUpper';
import i18n from '@/i18n';
import Big from 'big.js';
import localeLibrary, { getCurrency } from '@/common/locale/localeLibrary';
import isEmpty from 'lodash/isEmpty';
import currencyTypes from '@/__new__/features/pc/common/currencyTypes';
import { AccountAddress } from '@/__new__/services/dno/user/models/Account';

const langService: HumanizeDurationLanguage = new HumanizeDurationLanguage();
const humanizer: HumanizeDuration = new HumanizeDuration(langService);

// clearly mutation numeral object to get proper formatting
// please keep it here until we implement proper localization
numeral.localeData().delimiters.thousands = ' ';
numeral.nullFormat('');

export class UnitDefinition {
    private _label: string;
    id: string;
    multiplier: number;
    field?: string;

    constructor(id: string, multiplier: number, label: string, field?: string) {
        this.id = id;
        this.multiplier = multiplier;
        this._label = label;
        this.field = field;
    }

    // Defined as getter so child classes can override it and make it reactive
    // if needed(e.g. MonetaryUnitDefinition)
    public get label(): string {
        return this._label;
    }

    public set label(value: string) {
        this._label = value;
    }
}

export class MonetaryUnitDefinition extends UnitDefinition {
    type = currencyTypes.MONETARY;

    constructor(id: string) {
        super(id, 1, getCurrency());
    }

    // Override of label gettter is needed as getCurrency() is fetching value
    // from config store which at the moment of compiling is empty and will
    // fallback to default value;
    get label() {
        return getCurrency();
    }
}

export const DATA_DEFINITION_IDS = {
    BYTES: 'b',
    KILOBYTES: 'k',
    MEGABYTES: 'm',
    GIGABYTES: 'g',
};
export const DATA_DEFINITIONS = [
    new UnitDefinition(DATA_DEFINITION_IDS.GIGABYTES, 1024 * 1024 * 1024, 'GB'),
    new UnitDefinition(DATA_DEFINITION_IDS.MEGABYTES, 1024 * 1024, 'MB'),
    new UnitDefinition(DATA_DEFINITION_IDS.KILOBYTES, 1024, 'KB'),
    new UnitDefinition(DATA_DEFINITION_IDS.BYTES, 1, 'bytes'),
];

export const APP_DATA_DEFINITIONS = [
    new UnitDefinition(DATA_DEFINITION_IDS.MEGABYTES, 1024 * 1024, 'Megabits/Second'),
    new UnitDefinition(DATA_DEFINITION_IDS.KILOBYTES, 1024, 'Kilobits/Second'),
    new UnitDefinition(DATA_DEFINITION_IDS.BYTES, 1, 'Bits/Second'),
];

export const DURATION_DEFINITION_IDS = {
    SECONDS: 's',
    MINUTES: 'm',
    HOURS: 'h',
    DAYS: 'd',
};

export const DURATION_DEFINITIONS_HOUR_DAY = [
    new UnitDefinition(DURATION_DEFINITION_IDS.DAYS, 60 * 60 * 24, 'days', 'day'),
    new UnitDefinition(DURATION_DEFINITION_IDS.HOURS, 60 * 60, 'hours', 'hour'),
];

export const DURATION_DEFINITIONS_SECONDS_MINUTES = [
    new UnitDefinition(DURATION_DEFINITION_IDS.MINUTES, 60, 'minutes', 'minute'),
    new UnitDefinition(DURATION_DEFINITION_IDS.SECONDS, 1, 'seconds', 'second'),
];

export const DURATION_DEFINITIONS = [...DURATION_DEFINITIONS_HOUR_DAY, ...DURATION_DEFINITIONS_SECONDS_MINUTES];

export const SMS_DEFINITIONS = [new UnitDefinition('s', 1, 'sms')];

export const MESSAGE_DEFINITIONS = [new UnitDefinition('msg', 1, 'messages', 'message')];

export const MONETARY_DEFINITIONS = [new MonetaryUnitDefinition('currency')];

export const MMS_DEFINITIONS = [new UnitDefinition('s', 1, 'mms')];

export const unitTypes = {
    DATA: 'data',
    DATA_AND_MONETARY: 'dataAndMonetary',
    DURATION: 'duration',
    DURATIONHourDay: 'durationHourDay',
    DURATIONSecondsMinutes: 'duratioSecondsMinutes',
    APPDATA: 'appData',
    SMS: 'sms',
    MMS: 'mms',
} as const;

/**
 * Function calculates scaled amount based on
 * received amount of bytes and unitDefinition multiplier.
 * It cuts all decimals after second or third decimal, depends on unitDefinition.
 * For GB rounding on 2 decimals we can lose too much information.
 * @param unitDefinition
 * @param amountBytes
 * @returns {boolean}
 */
export function getScaledAmount(unitDefinition: UnitDefinition, amountBytes: number): number {
    if (!unitDefinition) {
        throw new Error('Unit Definitions should be provided, but not found.');
    }
    if (unitDefinition.id === DATA_DEFINITION_IDS.GIGABYTES) {
        return Math.floor((amountBytes / unitDefinition.multiplier) * 1000) / 1000;
    }
    return Math.floor((amountBytes / unitDefinition.multiplier) * 100) / 100;
}

/**
 * Function checks if values after second decimal point are different from 0.
 * Essentially is it save to use said value without losing precision with two decimal numbers.
 * If it is function returns true, otherwise false.
 * @param value
 * @returns {boolean}
 */
export function hasUsablePrecision(value: number): boolean {
    return (value * 100) % 1 === 0;
}

/**
 * Function checks if values is whole number.
 * If it is function returns true, otherwise false.
 * @param value
 * @returns {boolean}
 */
export function isWholeNumber(n: number): boolean {
    return n % 1 === 0;
}

/**
 *
 * @param unitType
 * @returns {*[]}
 */
export function getUnitDefinitions(unitType: string): UnitDefinition[] {
    switch (unitType) {
        case unitTypes.DATA:
            return DATA_DEFINITIONS;
        case unitTypes.DATA_AND_MONETARY:
            return [...MONETARY_DEFINITIONS, ...DATA_DEFINITIONS];
        case unitTypes.DURATION:
            return DURATION_DEFINITIONS;
        case unitTypes.DURATIONHourDay:
            return DURATION_DEFINITIONS_HOUR_DAY;
        case unitTypes.DURATIONSecondsMinutes:
            return DURATION_DEFINITIONS_SECONDS_MINUTES;
        case unitTypes.APPDATA:
            return APP_DATA_DEFINITIONS;
        case unitTypes.SMS:
            return SMS_DEFINITIONS;
        case unitTypes.MMS:
            return MMS_DEFINITIONS;
        default:
            throw new Error(`Unknown unit type: ${unitType}`);
    }
}

/**
 * Function return best value for duration definition
 * @param amount
 * @param unitDefinitions
 * @returns {UnitDefinition}
 */
function getBestDurationDefinitions(amount: number, unitDefinitions: UnitDefinition[]): UnitDefinition {
    const findBestResults = unitDefinitions.filter(d => hasUsablePrecision(amount / d.multiplier));
    const bestResults = !isEmpty(findBestResults) ? findBestResults : unitDefinitions;

    if (bestResults.length > 1 && !isWholeNumber(amount / bestResults[0].multiplier)) {
        return bestResults[1];
    }

    if (amount > bestResults[0].multiplier) {
        const result = amount === 0 ? unitDefinitions[0] : unitDefinitions.find(d => amount / d.multiplier >= 1);
        if (result) {
            return result;
        }
    }

    return bestResults[0];
}

/**
 * Function separately calculates best unit definition for data or duration,
 * depending on received unit definition and amount.
 * @param unitDefinitions
 * @param amount
 * @returns {*}
 */
export function getBestUnit(unitDefinitions: UnitDefinition[] | null, amount: number): UnitDefinition | undefined {
    if (!isArray(unitDefinitions)) {
        throw new TypeError('Unit Definition must be of type array.');
    } else if (!unitDefinitions.length) {
        throw new Error('Unit Definitions must contain at least one definition');
    }
    switch (unitDefinitions) {
        case DATA_DEFINITIONS:
        case APP_DATA_DEFINITIONS:
            return amount === 0 ? unitDefinitions[0] : unitDefinitions.find(ud => amount / ud.multiplier >= 1);
        case DURATION_DEFINITIONS_SECONDS_MINUTES:
        case DURATION_DEFINITIONS_HOUR_DAY:
        case DURATION_DEFINITIONS:
            return getBestDurationDefinitions(amount, unitDefinitions);
        case SMS_DEFINITIONS:
            return unitDefinitions[0];
        case MESSAGE_DEFINITIONS:
            return unitDefinitions[0];
        case MMS_DEFINITIONS:
            return unitDefinitions[0];
        default:
            return undefined;
    }
}

/**
 * Function uses Numeral.js to present amount of Bytes in the best fit unit.
 * For GB it rounds on 3 decimals, and for other units it rounds on 2 decimals.
 * Formatting: removes 'i' from units (GiB -> GB), removes decimals if all are zero,
 * adding space between number and units, and formats values for bytes.
 * @param amountBytes
 * @returns {string}
 */
export function formatDataAmount(amountBytes: number | string): string {
    if (!amountBytes) {
        return '0';
    }

    const biggerThanByte = new Set(['K', 'M', 'G']);
    let formattedAmount;
    let indexOfPoint;

    if (Number(amountBytes) >= 1024 ** 3) {
        // 1 GB
        formattedAmount = numeral(amountBytes).format('0.00ib').replace('i', '');
        indexOfPoint = formattedAmount.indexOf('.');
        if (formattedAmount.substring(indexOfPoint + 1, indexOfPoint + 3) === '00') {
            formattedAmount = formattedAmount.replace('.00', '');
        }
    } else {
        formattedAmount = numeral(amountBytes).format('0.00ib').replace('i', '');
        indexOfPoint = formattedAmount.indexOf('.');
        if (formattedAmount.substring(indexOfPoint + 1, indexOfPoint + 3) === '00') {
            formattedAmount = formattedAmount.replace('.00', '');
        }
    }
    if (biggerThanByte.has(formattedAmount[formattedAmount.length - 2])) {
        formattedAmount = [
            `${formattedAmount.slice(0, formattedAmount.length - 2)} ${formattedAmount.slice(
                formattedAmount.length - 2,
            )}`,
        ].join();
    } else {
        formattedAmount = [
            `${formattedAmount.slice(0, formattedAmount.length - 1)} ${formattedAmount.slice(
                formattedAmount.length - 1,
            )}`,
        ].join();
        if (formattedAmount === '0 B') {
            formattedAmount = '0';
        } else {
            formattedAmount =
                formattedAmount === '1 B'
                    ? formattedAmount.replace('B', 'byte')
                    : formattedAmount.replace('B', 'bytes');
        }
    }
    return formattedAmount;
}

/**
 * This method accepts SECONDS and is converting them to ms in its method calls.
 * @param durationSeconds
 */
export function formatDuration(durationSeconds: number): string {
    return humanizer.humanize(durationSeconds * 1000);
}

/**
 * Delegation and SMS logic.
 * @param serviceType
 * @param amount
 * @returns {*}
 */
export function getFormattedAmount(serviceType: number, amount: number | string): VueI18n.TranslateResult | number {
    if (!amount || !serviceType) {
        return i18n.t('generic.N/A');
    }

    switch (serviceType) {
        case 1:
            return formatDataAmount(amount);
        case 2:
            return typeof amount === 'number' ? formatDuration(amount) : amount;
        case 3:
            return `${amount} SMS`;
        case 4:
            return formatDataAmount(amount);
        case 5:
            return `${amount} MMS`;
        case 6:
            return `${amount} API REQUEST`;
        default:
            return amount;
    }
}

/**
 * Function returns 'Yes' if passed value is Truthy, otherwise returns 'No'
 * @param value
 * @returns {string}
 */
export function getBeautifulBoolean(value: boolean): VueI18n.TranslateResult {
    return value ? i18n.t('generic.yes') : i18n.t('generic.no');
}

/**
 * @param unixTimestamp
 * @returns {string}
 */
export function unixTimestampToFullDateWithTimezone(unixTimestamp: number): string {
    if (unixTimestamp && moment(unixTimestamp).isValid()) {
        return moment.unix(unixTimestamp).format('YYYY-MM-DD, hh:mm:ssa (Z)');
    }
    return '';
}

export function removeSpacesFromString(string: string): string | null {
    if (typeof string === 'string') {
        return string.replace(/ /g, '');
    }
    return null;
}

/**
 * Format value before displaying on the UI. If value is 'Invalid date' or
 * it's falsy, except of the case when it's equal to 0 - 'Empty' is returned,
 * otherwise value is returned
 * @param val
 * @returns {*}
 */
export function displayNaIfNotProvided(val: any): string | VueI18n.TranslateResult {
    if (typeof val === 'boolean') {
        return getBeautifulBoolean(val);
    }
    if (typeof val === 'string') {
        return !!val && val !== 'Invalid date' && removeSpacesFromString(val) ? val : 'Empty';
    }
    if (val === 0 || !!val) {
        return val;
    }
    return 'Empty';
}

/**
 * Format date values.
 * @returns {string}
 * @param unixTimestamp
 */
export function unixTimestampToFullDate(unixTimestamp: number) {
    if (moment(unixTimestamp).isValid()) {
        return moment.unix(Math.round(unixTimestamp / 1000)).format(localeLibrary.getDateFormat());
    }
    return 'Empty';
}

export function epochToDate(val: string | number) {
    return moment.unix(Number(val) / 1000).format(localeLibrary.getDateFormat());
}

export function secondsToDate(val: number) {
    return moment.unix(val).format(localeLibrary.getDateFormat());
}

export function stringTimeToDefaultFormat(val: moment.MomentInput) {
    return moment(val).format(localeLibrary.getDateFormat());
}

/**
 * Format input value for displaying.
 * @param val
 * @param formatter
 * @returns {*}
 */
export function formatValueForDisplaying(val: any, formatter: string) {
    switch (formatter) {
        case 'datetime':
            return localeLibrary.getFormattedDateAndTime(val);
        case 'date':
            return localeLibrary.getFormattedDate(val);
        case 'data':
            return formatDataAmount(val);
        case 'duration':
            return formatDuration(val);
        case 'boolean':
            return getBeautifulBoolean(val);
        default:
            return displayNaIfNotProvided(val);
    }
}

/**
 * Function receives amount of seconds and returns amount of minutes equal to provided seconds
 * @param seconds
 * @returns {Number}
 */
export function convertSecondsToMinutes(seconds: number): number {
    return Math.floor(Number(seconds) / 60);
}

/**
 * Function receives service type and returns default unit name for passed service type
 * @param serviceType
 * @returns {String}
 */
export function getDefaultUnitNameByServiceType(serviceType: number): string {
    if (serviceType === 1) {
        return 'B';
    }
    if (serviceType === 2) {
        return 's';
    }
    return '';
}

export const TimeStringFormat = 'h:mma';
/**
 * Format is '4:20am'. Moment: 'h:mma'.
 * @returns true if valid
 */
export function validateTimeString(timeString: moment.MomentInput): boolean {
    return moment(timeString, TimeStringFormat, true).isValid();
}

export function parseTimeString(timeString: moment.MomentInput): moment.Moment {
    return moment(timeString, TimeStringFormat);
}

export function formatTimeString(time: moment.MomentInput): string {
    return moment(time).format(TimeStringFormat);
}

export function constructTimeString(hours: number, minutes: number): string {
    return formatTimeString(moment().hours(hours).minutes(minutes).seconds(0));
}

export function getMomentFromDateAndTimeString(date: moment.MomentInput, timeString: string): moment.Moment {
    return moment(date)
        .hours(parseTimeString(timeString).hours())
        .minutes(parseTimeString(timeString).minutes())
        .seconds(0);
}

export function getTimePickerOptions(): string[] {
    const startOfDay = moment().startOf('day').subtract(30, 'minutes'); // because add 30
    return range(24 * 2) // day as 30 minutes intervals
        .map(() => formatTimeString(startOfDay.add(30, 'minutes')));
}

/**
 * Function receives number and returns ~ prefix for this number if necessary
 * @param number
 * @returns {String}
 */
export function approximatePrefix(number: number | null | undefined): string {
    return typeof number === 'number' && Number.isFinite(number) && number >= 1000 ? '~' : '';
}

/**
 * Function receives number and returns another number with prefix that represents passed one
 * @param number
 * @returns {String}
 */
export function suffix(number: number | null | undefined): string {
    return numeral(number).format('0a');
}

export function suffixUpper(number: number | null | undefined): string {
    return toUpper(suffix(number));
}

/**
 * Function receives number and returns abbreviated number (see tests for expected format)
 * @param number
 * @returns {String}
 */
export function abbreviate(number: number | null | undefined): string {
    return number === undefined ? '...' : approximatePrefix(number) + suffixUpper(number);
}

/**
 * Function receives value that should be 100-based percent number and
 * returns percent number in default format: 42%
 * @param value
 * @returns {String}
 */
export function formatPercents(value: number | null | undefined): string | undefined {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return '0%';
    }
    if (value < 1 && value !== 0) {
        // numeral treats 1 as 100%
        return '<1%';
    }
    return numeral(value / 100).format('0%');
}

/**
 * Function accepts number and total and returns percent value of number in total with proper formatting
 * @param number
 * @param total
 * @returns {string}
 */
export function getPercent(number: number | null | undefined, total: number | null | undefined): string | undefined {
    if (number === undefined || total === undefined) {
        return undefined;
    }
    if (
        typeof number === 'number' &&
        typeof total === 'number' &&
        total !== 0 &&
        Number.isFinite(number) &&
        Number.isFinite(total)
    ) {
        return formatPercents((number * 100) / total);
    }
    return '';
}

/**
 * Function accepts number and total and returns percent value
 * of number in total with proper formatting and brackets around it
 * @param number
 * @param total
 * @returns {string}
 */
export function getPercentsWithBrackets(number: number, total: number): string {
    const percentsStr = getPercent(number, total);
    return percentsStr ? `(${percentsStr})` : '';
}

/**
 * Function accepts number and returns it formatted as a currency
 * @param value
 * @returns {string}
 */
export function currency(value: number | null | undefined): string | undefined {
    if (value === undefined) {
        return undefined;
    }
    return numeral(value).format('$0,0[.]00').substring(1); // remove $
}

export function validateUniqValues(
    arrayWithObjs: { key: any; value: any }[],
    previousInvalidIndexes: number[],
    allowEmptyValues: boolean,
) {
    function validateObj(obj: { key: any; value: any }, emptyValueAllowed: boolean) {
        if (emptyValueAllowed) {
            return Boolean(obj.key);
        }
        return obj.key && obj.value;
    }

    let invalidIndexes = previousInvalidIndexes;

    if (arrayWithObjs.length === 0) {
        return [];
    }
    if (arrayWithObjs.length > 1) {
        const objNames: string[] = [];

        arrayWithObjs.forEach((obj, index) => {
            if (objNames.includes(obj.key)) {
                if (invalidIndexes.indexOf(index) === -1) {
                    invalidIndexes.push(index);
                }
                if (invalidIndexes.indexOf(objNames.indexOf(obj.key)) === -1) {
                    invalidIndexes.push(objNames.indexOf(obj.key));
                }
            }

            if (!validateObj(obj, allowEmptyValues) && invalidIndexes.indexOf(index) === -1) {
                invalidIndexes.push(index);
            }

            objNames.push(obj.key);
        });
    } else if (invalidIndexes.indexOf(0) === -1 && (arrayWithObjs[0].key || arrayWithObjs[0].value)) {
        invalidIndexes = validateObj(arrayWithObjs[0], allowEmptyValues) ? [] : [0];
    }

    return invalidIndexes;
}

export function getObjectFromArray(arrayWithObjs: { key: string; value: string }[]) {
    return Object.assign({}, ...arrayWithObjs.map(x => ({ [x.key]: x.value })));
}

export function getArrayFromObject(obj: object | null | undefined) {
    return obj && Object.prototype.toString.call(obj) === '[object Object]'
        ? Object.entries(obj).map(([key, value]) => ({ key, value }))
        : [];
}

export function getStringFromArray(arrayWithObjs: { key: string; value: string }[]) {
    return arrayWithObjs ? arrayWithObjs.map(item => `${item.key}: ${item.value}`).join(', ') : 'Empty';
}

export function toDashedDateWithoutTime(unixTimestamp: number | null | undefined) {
    if (!unixTimestamp) {
        return 'Empty';
    }
    return moment(moment.unix(unixTimestamp)).format(localeLibrary.getDateFormat());
}

/**
 * Function for converting timestamp in milliseconds into human readable full date
 * @param millisTimestamp
 * @returns {String}
 */
export function millisTimestampToDateWithSeconds(millisTimestamp: number | null): string {
    if (millisTimestamp && moment(millisTimestamp).isValid()) {
        return moment.unix(millisTimestamp).format('YYYY-MM-DD, hh:mm:ssa (Z)');
    }
    return 'Empty';
}

/**
 * Function for converting number into ordinal number string e.g. 1 => 1st
 * @param number
 * @returns {String}
 */
export function ordinalNumberFormatter(number: number): string | VueI18n.TranslateResult | null {
    if (typeof number !== 'number') {
        return null;
    }
    if (number === 1) {
        return i18n.t('generic.firstShorthand');
    }
    if (number === 2) {
        return i18n.t('generic.secondShorthand');
    }
    if (number === 3) {
        return i18n.t('generic.thirdShorthand');
    }
    return `${number}th`;
}

/**
 * Function for converting amount of prices based on given currency
 * @param amount
 * @param amountCurrency
 * @returns {String}
 */
export function formatAmountBasedOnCurrency(amount: number | string, amountCurrency?: string) {
    if (amountCurrency) {
        if (amountCurrency.toLowerCase() === currencyTypes.DATA.toLowerCase()) {
            return formatDataAmount(amount);
        }

        return localeLibrary.getFormattedAmount(amount);
    }

    // if currency is not provided from api
    return localeLibrary.getFormattedAmount(amount);
}

export function capitalizeWord(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export function dateToEpoch(date: moment.MomentInput): number {
    return moment(date).unix();
}

/**
 * converts string to snake_case,
 * change uppercase letters to lowercase
 * example: 'My Awesome String' --> 'my_awesome_string'
 */
export function stringToSnakeCase(str: string): string {
    return str
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '_')
        .replace(/^[_0-9]+/g, '');
}

export function snakeCaseToCommonString(str: string): string {
    const arr = str.split('_').map(el => el.charAt(0).toUpperCase() + el.slice(1));
    return arr.join(' ');
}

export function validateKebabCase(val: string): boolean {
    return Boolean(val.match(/^[a-z_\-0-9.]+$/));
}

export function validateAlphaNumericWithDashAndUnderscore(val: string): boolean {
    return Boolean(val.match(/^[a-zA-Z0-9-_]+$/));
}

export const textToLowerCase = (val: string) => val.toLowerCase();

/** returns random small letter a - z **/
export const getRandomLetter = (): string => {
    return String.fromCharCode(97 + Math.floor(Math.random() * 26));
};

export const formatAddressLines = (address?: AccountAddress) => {
    if (!address) {
        return {
            line1: undefined,
            line2: undefined,
            fullAddress: undefined,
        };
    }

    const unit = address.unitNumber || address.unitNo;

    const line1Parts = [
        address.address || address.street,
        address.houseNumber,
        unit && `${i18n.t('generic.unit')} ${unit}`,
        address.floor && `${i18n.t('generic.floor')} ${address.floor}`,
        address.buildingName,
        address.addressLine2,
    ];

    const line2Parts = [
        address.city || address.village,
        address.barangay,
        address.state,
        address.province,
        address.zip || address.zipCode,
        address.post,
        address.postCode,
    ];

    const line1 = line1Parts.filter(Boolean).join(', ');
    const line2 = line2Parts.filter(Boolean).join(', ');
    const lines = [line1, line2].filter(Boolean);

    return {
        line1,
        line2,
        lines,
    };
};

export const sumNumbers = function (num1: number | string, num2: number | string, decimalsAmount = 2) {
    const summ = Big(num1).plus(num2);
    return summ.toFixed(decimalsAmount);
};
