
import CustomOfferOption from '@/__new__/features/pc/validationRules/CustomOfferOption.vue';
import ProductCatalogOfferModel from '@/__new__/services/dno/pc/models/ProductCatalogOfferModel';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import { PropType } from 'vue';
import AppToggle from './AppToggle.vue';

export default {
    name: 'CategorizedOffers',
    components: {
        AppMultiselectV3,
        CustomOfferOption,
        AppToggle,
    },
    props: {
        categories: {
            type: Array as PropType<{ id: number; label: string }[]>,
            required: true,
        },
        offers: {
            type: Array as PropType<ProductCatalogOfferModel[]>,
            required: true,
        },
        value: {
            type: Object as PropType<Record<string, string[]>>,
            required: true,
        },
        label: {
            default: undefined,
            type: String,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        categorizedOffers(): { id: number; label: string; enabled: boolean; offers: string[] }[] {
            return this.categories.map(category => ({
                ...category,
                enabled: this.value[category.id] !== undefined,
                offers: this.value[category.id] || [],
            }));
        },
    },
    methods: {
        toggleEnabled(categoryId: number, enabled: boolean) {
            this.$emit('input', { ...this.value, [categoryId]: enabled ? [] : undefined });
        },
    },
};
