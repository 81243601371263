






























































































// Components
import AppTable from '@/components/partials/AppTable.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import IconButton from '@/components/partials/IconButton.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import MultipleTablesTabs from '@/components/partials/MultipleTablesTabs.vue';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { PropType } from 'vue';

export type TableDefaultSort = { key?: string; sortBy?: () => any; type: 'asc' | 'desc' };

export default {
    name: 'AbstractTableTile',
    components: {
        AppTable,
        IconButton,
        SearchBox,
        ResponseModalButton,
        FilterTable,
        TableFiltersRenderless,
        TableFiltersTags,
        MultipleTablesTabs,
    },
    props: {
        entities: {
            type: Array,
            default: () => [],
        },
        columnsData: {
            type: Array,
            default: () => [],
        },
        defaultSort: {
            type: Object as PropType<TableDefaultSort | null>,
            default: null,
        },
        tableKey: {
            type: String,
            default: '',
        },
        entityKey: {
            type: String,
            default: '',
        },
        apiResponse: {
            type: [Object, Array],
            default: () => ({}),
        },
        tabs: {
            // [{id, name}]
            type: Array,
            default: () => [],
        },
        activeTabId: {
            type: String,
            default: null,
        },
        entityType: {
            type: String,
            default: '',
        },
        nestedRows: {
            type: Boolean,
            default: false,
        },
        isDefaultHoverEnabled: {
            type: Boolean,
            default: false,
        },
        newDesign: {
            type: Boolean,
            default: false,
        },
        estimatedMinColumnWidth: {
            type: Number,
            default: 150,
        },
    },
    data() {
        return {
            selectedEntity: {},
            searchQuery: '',

            ICON_TYPES,
        };
    },
    computed: {
        areTabsEnabled() {
            return Boolean(this.tabs.length);
        },
        selectedEntityId() {
            return this.selectedEntity?.[this.entityKey] ?? '';
        },
    },
    methods: {
        selectRow(key) {
            this.selectedEntity = this.entities.find(el => el[this.entityKey] === key);
            this.$emit('rowSelected', key);
        },
        onSelectedEntityModel(entity) {
            this.$emit('selectedEntityModel', entity);
        },
    },
};
