















import Vue from 'vue';

// Vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Components
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import OrchestrationListPageLayout from '@/__new__/features/orchestrationengine/OrchestrationListPageLayout.vue';

// Helpers
import { BatchRescheduledexEcutionsDno, BatchActions } from '@/__new__/services/dno/orchestrationengine/batchActions';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import RouteNames from '@/router/routeNames';
import * as Sentry from '@sentry/vue';
import { validationMixin } from 'vuelidate';

export default Vue.extend({
    name: 'BatchActionsListPage',
    components: {
        OrchestrationListPageLayout,
    },
    mixins: [FilterTableMixin, validationMixin],
    data() {
        return {
            pageTitle: this.$i18n.tc('orchestrationEngine.batchActions.name', PLURALIZATION.PLURAL) as string,
            entityType: ENTITY_TYPES.ORCHESTRATION_ENGINE_BATCH_ACTIONS,
            isDataLoading: false as boolean,
            addNewRouteName: RouteNames.ORCHESTRATION_ENGINE_BATCH_ACTIONS_EDITOR,
        };
    },
    computed: {
        ...mapGetters(Modules.orchestrationengine, [
            Getters.GET_OE_BATCH_ACTIONS,
            Getters.GET_OE_BATCH_ACTION_BY_ID,
            Getters.GET_OE_BATCH_ACTIONS_API_RESPONSE,
        ]),
        tableColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.batchActions.batchId'),
                    key: 'batchId',
                    field: 'batchId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'operationType',
                    field: 'operationType',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        writeEnabled(): boolean {
            return isUserAllowed('BatchActionsWrite');
        },
        payoutsApiResponse(): BatchRescheduledexEcutionsDno {
            return this[Getters.GET_OE_BATCH_ACTIONS_API_RESPONSE];
        },
        batchActions(): BatchActions[] {
            return Object.values(this[Getters.GET_OE_BATCH_ACTIONS]);
        },
    },
    created() {
        this.initData();
    },
    methods: {
        ...mapActions(Modules.orchestrationengine, [Actions.REQUEST_OE_BATCH_ACTIONS]),
        initData() {
            this.$withLoadingSpinner(
                async () => {
                    this.$Progress.start();
                    this.isDataLoading = true;

                    await this[Actions.REQUEST_OE_BATCH_ACTIONS]();

                    this.$Progress.finish();
                    this.isDataLoading = false;
                },
                {
                    errorHandler: (e: any) => {
                        this.isDataLoading = false;
                        this.$Progress.fail();

                        Sentry.captureException(e);
                        this.$eventBus.$emit('showAlert', {
                            message: e.message,
                        });
                    },
                },
            );
        },
    },
});
