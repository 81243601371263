
// components
import AppButton from '@/components/partials/inputs/AppButton.vue';
import EventCompositionKey from '@/__new__/features/sinkConfigs/eventComposition/EventCompositionKey.vue';
// helpers
import { EventCompositionKey as EventCompositionKeyClass } from '@/__new__/services/dno/sinkConfigs/EventCompositionConfig';
import { cloneDeep } from 'lodash';
import { ICON_TYPES } from '@/common/iconHelper';
import { PropType } from 'vue';

export default {
    name: 'EventCompositionStep',
    components: { AppButton, EventCompositionKey },
    props: {
        value: {
            type: Array as PropType<EventCompositionKeyClass[][]>,
            required: true,
        },
    },
    data() {
        return {
            ICON_TYPES,
        };
    },
    methods: {
        onKeyUpdate(keyValue: EventCompositionKeyClass, index: number) {
            const clonedKeys = cloneDeep(this.value);
            clonedKeys[index] = keyValue;
            this.$emit('input', clonedKeys);
        },
        onKeyRemove(index: number) {
            const clonedKeys = cloneDeep(this.value).filter((_: EventCompositionKeyClass, i: number) => index !== i);
            this.$emit('input', clonedKeys);
        },
        onAddKeyBtnClick() {
            const clonedKeys = cloneDeep(this.value);
            const newKey = clonedKeys[0].map((key: EventCompositionKeyClass) => {
                return new EventCompositionKeyClass(
                    key.eventName,
                    key.eventType,
                    key.allProperties[0],
                    key.allProperties,
                );
            });
            clonedKeys.push(newKey);
            this.$emit('input', clonedKeys);
        },
    },
};
