
// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';

// Helpers
import tableColumnType from '@/common/filterTable';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import DevedgeUsageDetail from '@/__new__/services/dno/ossdevedge/models/DevedgeUsageDetail';

// HTTP
import ossdevedgeHTTP, { SERVICE_TYPES } from '@/__new__/services/dno/ossdevedge/http/ossdevedge';

const MAX_ALLOWED_DAYS = 30;
const MAX_DATE_RANGE_IN_EPOCH_MILLISECONDS = MAX_ALLOWED_DAYS * 24 * 60 * 60 * 1000 - 1000;

function convertSizeToReadable(size: number): string {
    if (!size) return 'Empty';
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return `${Number((size / 1024 ** i).toFixed(2))} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}

export default {
    name: 'DevedgeUsageTile',
    components: {
        AbstractTableTile,
        DateTimePicker,
    },
    data() {
        return {
            entities: [],
            apiResponse: {},
            selectedEntity: {},
            showAdditionalSidebar: false,
            datePickerOptions: {
                fromDateValue: new Date(new Date().getTime() - MAX_DATE_RANGE_IN_EPOCH_MILLISECONDS),
                endDateValue: new Date(),
                type: 'date',
            },
        };
    },
    computed: {
        setDateTimePickerValue() {
            return [this.datePickerOptions.fromDateValue, this.datePickerOptions.endDateValue];
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.appId'),
                    key: 'dnoAppId',
                    field: 'dnoAppId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.channel'),
                    key: 'channels',
                    field: 'channels',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.customerName'),
                    key: 'customerName',
                    field: 'customerName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.msisdn'),
                    key: 'msisdn',
                    field: 'msisdn',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                },
                {
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.sessionStartTime'),
                    key: 'startTime',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.startTime),
                    sortBy: entity => entity.startTime,
                    field: 'startTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.sessionEndTime'),
                    key: 'endTime',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.endTime),
                    sortBy: entity => entity.endTime,
                    field: 'endTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.dataSessionLength'),
                    key: 'dataSessionLength',
                    field: 'dataSessionLength',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                },
                {
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.dataSessionLengthTotal'),
                    key: 'dataSessionLengthTotal',
                    field: 'dataSessionLengthTotal',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                },
                {
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.consumedDataSize'),
                    key: 'consumedDataSize',
                    field: 'consumedDataSize',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    mapper: entity => convertSizeToReadable(entity.consumedDataSize),
                },
                {
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.consumedDataSizeTotal'),
                    key: 'consumedDataSizeTotal',
                    field: 'consumedDataSizeTotal',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    mapper: entity => convertSizeToReadable(entity.consumedDataSizeTotal),
                },
            ];
        },
    },
    async created() {
        await this.fetchTileData();
    },
    methods: {
        async fetchTileData(from, to) {
            const defaultFrom = new Date().getTime() - MAX_DATE_RANGE_IN_EPOCH_MILLISECONDS;
            const fromTime = from ? from.getTime() : defaultFrom;
            const toTime = to ? to.getTime() : new Date().getTime();

            await this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);

                    const response = await ossdevedgeHTTP.getUsageDetails(
                        this.$route.params.id,
                        USER_MANAGER_HIERARCHY.ACCOUNT,
                        SERVICE_TYPES.QOD,
                        fromTime,
                        toTime,
                    );

                    this.apiResponse = response;

                    if (response?.data?.usage_details) {
                        this.entities = response.data.usage_details.map(el => {
                            return DevedgeUsageDetail.mapDevedgeUsageDetailFromBE(el);
                        });
                    }

                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: (e: any) => {
                        this.apiResponse = e.response;
                        this.$alert(this.$i18n.t('customerCare.dataTransferFetchError'));
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        selectEntity(id) {
            this.selectedEntity = this.entities.find(el => el.id === id);
            this.showAdditionalSidebar = true;
        },
        rangeDateSelected(startDate, endDate) {
            if (endDate.getTime() - startDate.getTime() > MAX_DATE_RANGE_IN_EPOCH_MILLISECONDS) {
                this.$alert(
                    this.$i18n.t('customerCareSuite.devedgeUsageTile.dateRangeError', {
                        maxAllowedDays: MAX_ALLOWED_DAYS,
                    }),
                );
                return;
            }

            this.datePickerOptions.fromDateValue = startDate;
            this.datePickerOptions.endDateValue = endDate;

            this.fetchTileData(startDate, endDate);
        },
        checkIsDateDisabled(date) {
            return date > new Date();
        },
    },
};
