
















// components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import TransactionHistoryStatusIndicator from '@/__new__/features/customerCare/account/TransactionHistoryStatusIndicator.vue';

// helpers
import * as Sentry from '@sentry/vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import tableColumnType from '@/common/filterTable';
import { getBeautifulBoolean, formatAmountBasedOnCurrency } from '@/common/formatting';
import {
    CHARGE_STATES_INDICATOR_MAP,
    CHARGE_STATES_TO_STATUS_NAME_MAP,
} from '@/common/userManager/transactionHistoryV4StatusHelper';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { TransactionV4 } from '@/__new__/services/dno/orders/models/TransactionV4';
import { ICON_TYPES } from '@/common/iconHelper';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import get from 'lodash/get';
import { uuidV4 } from '@/common/utils';
import keyBy from 'lodash/keyBy';
import {
    PAYMENT_TRANSACTION_TYPE_CODES,
    getPaymentTransactionTypeInString,
} from '@/__new__/features/customerCareSuite/common/transactionHelper';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';

// HTTP
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
import paymentHTTP from '@/http/payment';

export default {
    name: 'PaymentTransactionTile',
    components: {
        AbstractTableTile,
        TransactionHistoryStatusIndicator,
    },
    mixins: [supportButtonMixin],
    data() {
        return {
            transactionHistories: [],
            billingTransactionHistory: [],
            rawResponse: {},
            selectedEntity: {},
            showAdditionalSidebar: false,
            refundAmount: null,
            amountForRefund: 0,
            refundAllIsChecked: false,

            ICON_TYPES,
            CHARGE_STATES_INDICATOR_MAP,
            CHARGE_STATES_TO_STATUS_NAME_MAP,
            TOOLTIP_POSITION,
        };
    },
    computed: {
        isRefundEnabled() {
            return (
                permissionsService.paymentTransactionHistoryRefundEnabled() &&
                permissionsService.isUserLotusFlareAdmin() &&
                isUserAllowed('UMAccountPaymentTransactionHistoryIssueRefund')
            );
        },
        formattedEntities() {
            let transactionsArray = [];

            if (this.transactionHistories && this.transactionHistories.length) {
                const internalPayments = keyBy(this.billingTransactionHistory, 'paymentResponse.internalPaymentId');

                const extendedTransactionHistories = this.transactionHistories.map(transaction => ({
                    ...transaction,
                    externalPaymentId:
                        internalPayments?.[transaction.transaction_id]?.paymentResponse?.externalPaymentId,
                }));

                transactionsArray = extendedTransactionHistories.map(transaction => ({
                    ...transaction,
                    transactionTypeMapped: getPaymentTransactionTypeInString(transaction.transaction_type),
                    amountFormatted: formatAmountBasedOnCurrency(transaction.amount, transaction.currency),
                    refundAction: this.showRefundButton(transaction.transaction_type),
                    id: uuidV4(),
                }));
            }

            return transactionsArray;
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.transactionHistory.transactionId'),
                    key: 'transaction_id',
                    mapper: entity => get(entity, 'transaction_id', this.$i18n.t('generic.N/A')),
                    field: 'transaction_id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.timestamp'),
                    key: 'create_timestamp',
                    mapper: entity => {
                        if (entity.create_timestamp) {
                            return this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(
                                entity.create_timestamp,
                            );
                        }

                        return '';
                    },
                    sortBy: entity => entity.create_timestamp,
                    field: 'create_timestamp',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.chargeFor'),
                    key: 'entity_name',
                    mapper: entity => get(entity, 'payment_details.entity_name', this.$i18n.t('generic.N/A')),
                    field: 'entity_name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'transactionTypeMapped',
                    field: 'transactionTypeMapped',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(
                        new Set(this.formattedEntities.map(entity => entity.transactionTypeMapped)),
                    ),
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.hold'),
                    key: 'is_hold',
                    mapper: entity =>
                        Object.hasOwnProperty.call(entity, 'is_hold')
                            ? this.getBeautifulBoolean(entity.is_hold)
                            : this.$i18n.t('generic.N/A'),
                    field: 'is_hold',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(
                        new Set(
                            this.formattedEntities.map(entity =>
                                Object.hasOwnProperty.call(entity, 'is_hold')
                                    ? this.getBeautifulBoolean(entity.is_hold)
                                    : this.$i18n.t('generic.N/A'),
                            ),
                        ),
                    ),
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.amount'),
                    key: 'amountFormatted',
                    sortBy: entity => entity.amount,
                    field: 'amount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.method'),
                    key: 'payment_method',
                    field: 'payment_method',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'state',
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.formattedEntities.map(entity => entity.state))),
                },
            ];
        },
    },
    async created() {
        await this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            try {
                this.$Progress.start();
                this.$emit('isDataLoadingUpd', true);
                this.rawResponse = await paymentHTTP.getTransactionHistory({
                    target_id: this.$route.params.id,
                    target_type: USER_MANAGER_HIERARCHY.ACCOUNT,
                    include_tax: true,
                });
                this.transactionHistories = this.rawResponse?.data?.transactions;
                const billingResponse = await ordersHTTP.getTransactions(
                    this.$route.params.id,
                    USER_MANAGER_HIERARCHY.ACCOUNT,
                    'DESC',
                );
                this.billingTransactionHistory = billingResponse?.data?.transactions.map(
                    transactionJSON => new TransactionV4(transactionJSON),
                );
                this.$Progress.finish();
                this.$emit('isDataLoadingUpd', true);
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
            } finally {
                this.$emit('isDataLoadingUpd', false);
            }
        },
        selectItem(id) {
            this.selectedEntity = this.formattedEntities.find(el => el.id === id);
            this.showAdditionalSidebar = true;
        },
        showRefundButton(transactionType) {
            const allowedTransactionTypes = [
                PAYMENT_TRANSACTION_TYPE_CODES.ORDER_PAYMENT,
                PAYMENT_TRANSACTION_TYPE_CODES.MICROCHARGING_PAYMENT,
                PAYMENT_TRANSACTION_TYPE_CODES.MANUAL_PAYMENT,
            ];

            return this.isRefundEnabled && allowedTransactionTypes.includes(transactionType);
        },
        getBeautifulBoolean,
    },
};
