import { render, staticRenderFns } from "./EditorManagementLayout.vue?vue&type=template&id=40952e7c&scoped=true"
import script from "./EditorManagementLayout.vue?vue&type=script&lang=js"
export * from "./EditorManagementLayout.vue?vue&type=script&lang=js"
import style0 from "./EditorManagementLayout.vue?vue&type=style&index=0&id=40952e7c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40952e7c",
  null
  
)

export default component.exports