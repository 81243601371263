export default class SinkConfigSettings {
    allowApprovedFieldsEdit: string[];
    title: string;
    specificConfig: any[];
    dontUseSnakeCaseInColumnNames: boolean;
    fieldNameFormattingFunction: string | null;
    fieldsLabels: Record<string, any>;
    fieldsEditRules: Record<string, any>;
    cloneRules: any[];
    allowDraftState: boolean;
    forbidFieldsEdit: string[];
    showSumLatest: boolean;
    isEventCompositionEnabled: boolean;

    constructor(data: Partial<SinkConfigSettings> = {}) {
        this.allowApprovedFieldsEdit = data.allowApprovedFieldsEdit || [];
        this.title = data.title || '';
        this.specificConfig = data.specificConfig || [];
        this.dontUseSnakeCaseInColumnNames = data.dontUseSnakeCaseInColumnNames || false;
        this.fieldNameFormattingFunction = data.fieldNameFormattingFunction || null;
        this.fieldsLabels = data.fieldsLabels || {};
        this.fieldsEditRules = data.fieldsEditRules || {};
        this.cloneRules = data.cloneRules || [];
        this.allowDraftState = data.allowDraftState || false;
        this.forbidFieldsEdit = data.forbidFieldsEdit || [];
        this.showSumLatest = data.showSumLatest || false;
        this.isEventCompositionEnabled = data.isEventCompositionEnabled || false;
    }

    static mapFromResponse({ data: { sink_config_params: config } }: any): SinkConfigSettings {
        const specificConfig = (config?.specific_configuration || []).map((c: any) => ({
            ...c,
            isValid: true,
            errorMsg: '',
        }));

        return new SinkConfigSettings({
            allowApprovedFieldsEdit: config?.allowApprovedFieldsEdit,
            title: config?.title,
            specificConfig,
            dontUseSnakeCaseInColumnNames: config?.dontUseSnakeCaseInColumnNames,
            fieldNameFormattingFunction: config?.fields_configuration?.fieldNameFormattingFunction,
            fieldsLabels: config?.fields_configuration?.labels,
            fieldsEditRules: config?.fields_configuration?.edit_rules || {},
            cloneRules: config?.cloneRules || [],
            allowDraftState: config?.allowDraftState || false,
            forbidFieldsEdit: config?.forbidFieldsEdit,
            showSumLatest: config?.fields_configuration?.showSumLatest,
            isEventCompositionEnabled: config?.isEventCompositionEnabled,
        });
    }
}
