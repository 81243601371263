
// components
import AppHeader from '@/components/layout/AppHeader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import EventProperty from '@/__new__/features/events/EventProperty.vue';
import AppStepper from '@/components/partials/AppStepper.vue';
import AppEntitiesManagementBlock from '@/components/partials/AppEntitiesManagementBlock.vue';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import AppCheckboxCardViewList from '@/components/partials/AppCheckboxCardViewList.vue';
import EventStatusIndicator from '@/components/partials/EventStatusIndicator.vue';
import AppSpinner from '@/components/partials/AppSpinner.vue';
import AbstractEditPageWrapper, { EDITOR_PAGE_SIDEBAR_POSITION } from '@/components/layout/AbstractEditPageWrapper.vue';
import AppCheckboxCardEditList from '@/components/partials/AppCheckboxCardEditList.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import TagsMultiselect from '@/components/partials/inputs/TagsMultiselect.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppJSON from '@/components/partials/AppJSON.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import EventPropertiesOverview from '@/__new__/features/events/EventPropertiesOverview.vue';

// helpers
import { maxLength, required } from 'vuelidate/lib/validators';
import {
    validateEventName,
    CDP_SUPPORTED_API_CONTROL_AUTH_TYPES,
    getAPIControlItemsList,
    eventStatuses,
    validateEventType,
    validatePropertyName,
    CDP_SUPPORTED_API_CONTROL_EVENT_SOURCE_BY_KEYS,
    isJsonSchemaValid,
    StepperItem,
} from '@/common/events/eventsHelper';
import { cloneDeep, isEmpty, union, uniq, without } from 'lodash';
import * as Sentry from '@sentry/vue';
import RouteNames from '@/router/routeNames';
import entityEditorMixin from '@/common/entityEditorMixin';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { createNamespacedHelpers } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import EventProp, {
    eventPropFromJson,
    modifyPropertiesBeforeSend,
} from '@/__new__/services/dno/events/models/EventProp';
import { Event } from '@/__new__/services/dno/events/models/Event';
import { createEvent, updateEvent } from '@/__new__/services/dno/events/http/events';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import { checkNamesDuplicates } from '@/__new__/services/dno/sinkConfigs/models/SinkConfig';
import { stringToSnakeCase } from '@/common/formatting';
import Button from '@/common/button/Button';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import { CollapsibleListItem } from '@/common/AppCollapsibleListHelper';
import { TranslateResult } from 'vue-i18n';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import { PRIORITY_LABELS } from '@/common/cepHelper';

const { mapGetters, mapActions } = createNamespacedHelpers('events');

export default {
    name: 'EventsEditor',
    components: {
        AppMultiselectV3,
        AppIcon,
        AppTooltip,
        AppJSON,
        AppToggle,
        EventStatusIndicator,
        AppHeader,
        AppButton,
        AppInputV3,
        AppTextareaV3,
        EventProperty,
        AppSpinner,
        AbstractEditPageWrapper,
        AppCheckboxCardEditList,
        AppStepper,
        AppEntitiesManagementBlock,
        OverviewHeaderV2,
        AppCheckboxCardViewList,
        AppOverviewBlock,
        TagsMultiselect,
        EventPropertiesOverview,
    },
    mixins: [entityEditorMixin, supportButtonMixin],
    data() {
        return {
            entityType: ENTITY_TYPES.EVENT,
            ICON_TYPES,
            ICON_COLORS,
            TOOLTIP_POSITION,
            BUTTON_TYPES,
            eventName: '',
            eventType: '',
            eventPriority: PRIORITY_LABELS[3], // medium by default
            eventStatuses,
            eventDescription: '',
            eventLabels: [],
            apiControlValues: {
                [CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.CLIENT]: false,
                [CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.SERVER]: false,
                [CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.UNREGISTERED]: false,
            },
            eventProperties: [],
            saveInProcess: false,
            invalidPropertiesIds: [],
            activeStepIndex: 0,
            activePropertyId: '',
            isAdvancedConfigVisible: false,
            eventSchemaInput: '',
            eventSchemaInputValid: true,
            insertSchemaManually: false,
            schemaDefs: {},
        };
    },
    validations: {
        eventName: {
            required,
            validateEventName,
        },
        eventType: {
            required,
            validateEventType,
        },
        eventProperties: {
            $each: {
                name: {
                    required,
                    validatePropertyName,
                },
                doc: {
                    maxLength: maxLength(300),
                },
            },
        },
        selectedEventUsage: {
            required,
        },
        apiControlValues: {
            requiredChecked: (values: any) =>
                values[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.CLIENT] ||
                values[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.SERVER] ||
                values[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.UNREGISTERED],
        },
    },
    computed: {
        ...mapGetters({
            requiredFields: Getters.GET_EVENT_REQUIRED_PROPERTIES,
            eventPropertyTypes: Getters.GET_EVENT_PROPERTY_TYPES,
            dataSources: Getters.GET_EVENT_DATA_SOURCES,
            getEventById: Getters.GET_EVENT_BY_ID,
            events: Getters.GET_MAPPED_EVENTS,
        }),
        eventPriorityOptions() {
            return Object.values(PRIORITY_LABELS);
        },
        currentEvent(): Event {
            return new Event(
                this.eventName,
                this.eventType,
                this.isEditing ? this.editableEvent.id : null,
                this.eventDescription,
                this.isEditing ? this.editableEvent.state : '',
                this.eventProperties,
                this.apiControlValues[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.CLIENT],
                this.apiControlValues[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.SERVER],
                this.apiControlValues[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.UNREGISTERED],
                this.eventType,
                this.isEditing ? this.editableEvent.producerProducts : [],
                this.isEditing ? this.editableEvent.producerServices : [],
                this.eventLabels,
                this.isEditing ? this.editableEvent.updateTime : null,
                this.isEditing ? this.editableEvent.apiControl : null,
                [],
                this.isEditing ? this.editableEvent.version : undefined,
                this.eventSchemaInput.$defs,
                this.eventPriority,
            );
        },
        isInternalEventEdit(): boolean {
            return this.isEditing && this.isInternalEvent;
        },
        eventLabelsOptions(): string[] {
            const labels = this.events.map((event: Event) => (isEmpty(event.labels) ? [] : event.labels));
            return uniq(labels.flat());
        },

        generalOverviewItems(): CollapsibleListItem[] {
            const rows = [
                {
                    name: this.$t('events.eventType'),
                    value: this.eventType,
                },
                {
                    name: this.$t('events.eventPriority'),
                    value: this.eventPriority,
                },
            ];

            if (this.eventDescription || this.eventSchemaInput?.description) {
                rows.push({
                    name: this.$t('generic.description'),
                    value: this.eventDescription,
                });
            }

            return [
                {
                    name: this.$t('generic.general'),
                    isCollapsed: false,
                    rows,
                },
            ];
        },
        advancedConfigBtnLabel(): TranslateResult {
            return this.isAdvancedConfigVisible ? this.$t('events.hideAdvConfig') : this.$t('events.showAdvConfig');
        },
        steps(): StepperItem[] {
            return [
                {
                    title: this.$i18n.t('generic.general'),
                    isCompleted: this.isEditing,
                },
                {
                    title: this.$i18n.t('events.eventProperties'),
                    isCompleted: this.isEditing,
                },
                {
                    title: this.$i18n.t('events.eventApiConfiguration'),
                    isCompleted: this.isEditing,
                },
                {
                    title: this.$i18n.t('events.eventReview'),
                    isCompleted: this.isEditing,
                },
            ];
        },
        proceedNextBtnLabel(): TranslateResult | string {
            return (
                this.steps[this.activeStepIndex + 1]?.title ||
                (this.isEditing ? this.$i18n.t('generic.update') : this.$i18n.t('generic.save'))
            );
        },
        proceedNextBtnIcon(): ICON_TYPES.CHECK | ICON_TYPES.ARROW_RIGHT {
            return this.activeStepIndex === 3 ? ICON_TYPES.CHECK : ICON_TYPES.ARROW_RIGHT;
        },
        apiControlItemsListOverview(): any {
            return getAPIControlItemsList(
                this.apiControlValues[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.SERVICE],
                this.apiControlValues[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.CLIENT],
                this.apiControlValues[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.SERVER],
                this.apiControlValues[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.UNREGISTERED],
            ).filter(item => item.value);
        },
        sidebarPosition(): string {
            return this.activeStepIndex === 1 ? EDITOR_PAGE_SIDEBAR_POSITION.LEFT : EDITOR_PAGE_SIDEBAR_POSITION.RIGHT;
        },
        shouldDisplaySidebar(): boolean {
            return this.activeStepIndex === 1 || this.activeStepIndex === 3;
        },
        editableEvent(): Event {
            return this.events.find((ev: Event) => ev.id === this.$route.params.id);
        },
        isInternalEvent(): boolean {
            return (
                this.editableEvent?.apiControl.event_source === CDP_SUPPORTED_API_CONTROL_EVENT_SOURCE_BY_KEYS.INTERNAL
            );
        },
        nameErrorMessage(): string | TranslateResult {
            let errorMsg = '';
            if (!this.$v.eventName.validateEventName) {
                errorMsg = this.$i18n.t('events.alerts.failedEventNameValidation');
            }
            if (!this.$v.eventName.required) {
                errorMsg = this.$i18n.t('generic.validations.fieldIsRequired');
            }
            return errorMsg;
        },
        eventTypeErrorMessage(): string | TranslateResult {
            let errorMsg = '';
            if (!this.$v.eventType.validateEventType) {
                errorMsg = this.$i18n.t('events.alerts.failedEventTypeValidation');
            }
            if (!this.$v.eventType.required) {
                errorMsg = this.$i18n.t('generic.validations.fieldIsRequired');
            }
            return errorMsg;
        },
        apiControlItemsList(): any {
            return [
                {
                    label: this.$i18n.t('events.eventApiControlsList.internalApi'),
                    description: this.$i18n.t('events.eventApiControlsList.internalApiDescription'),
                    disabled: true,
                    value: this.apiControlValues[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.SERVICE],
                    key: CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.SERVICE,
                },
                {
                    label: this.$i18n.t('events.eventApiControlsList.clientApi'),
                    description: this.$i18n.t('events.eventApiControlsList.clientApiDescription'),
                    disabled: this.isInternalEventEdit,
                    value: this.apiControlValues[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.CLIENT],
                    key: CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.CLIENT,
                },
                {
                    label: this.$i18n.t('events.eventApiControlsList.serverToServerApi'),
                    description: this.$i18n.t('events.eventApiControlsList.serverToServerApiDescription'),
                    disabled: this.isInternalEventEdit,
                    value: this.apiControlValues[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.SERVER],
                    key: CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.SERVER,
                },
                {
                    label: this.$i18n.t('events.eventApiControlsList.unauthenticatedApi'),
                    description: this.$i18n.t('events.eventApiControlsList.unauthenticatedApiDescription'),
                    disabled: this.isInternalEventEdit,
                    value: this.apiControlValues[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.UNREGISTERED],
                    key: CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.UNREGISTERED,
                },
            ];
        },
        activeEventProperty(): EventProp {
            return this.eventProperties.find((prop: EventProp) => prop.id === this.activePropertyId) || {};
        },

        requiredFieldsNames(): string[] {
            return Object.keys(this.requiredFields);
        },
    },
    created() {
        const promises = [this.loadEventRequiredProperties(), this.loadPropertyTypes(), this.loadDataSources()];
        this.$withLoadingSpinner(async () => {
            await Promise.all(promises);

            if (!this.events.length) {
                await this[Actions.LOAD_ALL_EVENTS]();
            }
            if (this.isEditing) {
                this.initializeEditEventPage();
            }
            this.setInitialEventProperties(this.isEditing);
        });
    },

    methods: {
        onEventJsonChange(val: any) {
            this.eventSchemaInput = val;
            this.eventName = val.humanReadableName;
            this.eventDescription = val.description;
            this.eventType = val.title;
            this.schemaDefs = val.$defs;
        },
        stringToSnakeCase,
        ...mapActions([
            Actions.LOAD_EVENT_REQUIRED_PROPERTIES,
            Actions.LOAD_EVENT_PROPERTY_TYPES,
            Actions.LOAD_EVENT_DATA_SOURCES,
            Actions.LOAD_ALL_EVENTS,
        ]),
        onSchemaInsertTypeChange(insertManually: boolean) {
            if (insertManually) {
                this.eventSchemaInput = {
                    humanReadableName: this.eventName,
                    title: this.eventType,
                    description: this.eventDescription,
                    properties: modifyPropertiesBeforeSend(this.eventProperties, this.dataSources),
                    required: this.eventProperties.filter((p: EventProp) => p.mandatory).map((p: EventProp) => p.name),
                    type: 'object',
                    $defs: this.schemaDefs,
                };
            } else {
                this.eventProperties = eventPropFromJson(
                    this.eventSchemaInput,
                    this.dataSources,
                    Object.keys(this.requiredFields),
                );

                this.changeActiveEventProperty(this.eventProperties[0].id);
            }
            this.insertSchemaManually = insertManually;
        },

        changeActiveEventProperty(id: string) {
            if (this.activePropertyId === id) return;
            this.activePropertyId = id;
        },

        onStepClick(stepIndex: number) {
            const previousStepIsCompleted = this.steps[stepIndex - 1]?.isCompleted;
            if (previousStepIsCompleted || stepIndex === 0) {
                const currentStepIsValid = this.validateStep(this.activeStepIndex);
                if (currentStepIsValid) {
                    this.activeStepIndex = stepIndex;
                }
            }
        },
        initializeEditEventPage() {
            try {
                this.eventName = this.editableEvent.name;
                this.eventDescription = this.editableEvent.description;
                this.eventType = this.editableEvent.eventType;
                this.eventPriority = this.editableEvent.priority;
                this.schemaDefs = this.editableEvent.schemaDefs;
                this.eventLabels = isEmpty(this.editableEvent.labels) ? [] : this.editableEvent.labels;
                this.setInitialApiControlValues(this.editableEvent.apiControl);
            } catch (error) {
                Sentry.captureException(error);
                this.$alert(this.$i18n.t('events.alerts.failedToLoadEvent'));
                this.navigateToEventsDashboardPageWithTimeout();
            }
        },
        async loadPropertyTypes() {
            try {
                await this[Actions.LOAD_EVENT_PROPERTY_TYPES]();
            } catch (error) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('events.alerts.failedToLoadPropertyTypes'),
                    type: ALERT_TYPES.error,
                });
            }
        },
        setInitialEventProperties(isEditing: boolean) {
            if (isEditing) {
                // render all properties, that came from previously created event
                this.eventProperties = this.isInternalEventEdit
                    ? this.editableEvent.properties.map((prop: EventProp) => ({
                          ...prop,
                          forbiddenToEdit: prop.forbiddenToEdit || this.isInternalEventEdit,
                      }))
                    : this.editableEvent.properties;
            } else {
                // render properties, that came from getRequiredFields API + 1 initial user property
                const requiredProps = eventPropFromJson(
                    {
                        properties: this.requiredFields,
                        required: Object.keys(this.requiredFields),
                    },
                    [],
                    Object.keys(this.requiredFields),
                );
                const newCustomPropName = `${this.$i18n.t('generic.property')}_${requiredProps.length + 1}`;
                const initialUserProperty = new EventProp({
                    name: newCustomPropName,
                    doc: '',
                    type: this.eventPropertyTypes[0],
                    mandatory: false,
                    forbiddenToEdit: false,
                    dataSource: this.$t('generic.none'),
                    isNullable: true,
                });
                this.eventProperties = [...requiredProps, initialUserProperty];
            }
            this.changeActiveEventProperty(this.eventProperties[0]?.id);
        },
        setInitialApiControlValues(apiControl: any) {
            if (Object.keys(apiControl).length) {
                const auth = apiControl.auth || {};

                this.apiControlValues = {
                    [CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.SERVICE]:
                        !!auth[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.SERVICE],
                    [CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.CLIENT]: !!auth[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.CLIENT],
                    [CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.SERVER]: !!auth[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.SERVER],
                    [CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.UNREGISTERED]:
                        !!auth[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.UNREGISTERED],
                };
            }
        },
        async loadEventRequiredProperties() {
            try {
                await this[Actions.LOAD_EVENT_REQUIRED_PROPERTIES]();
            } catch (err) {
                this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
            }
        },
        async loadDataSources() {
            try {
                await this[Actions.LOAD_EVENT_DATA_SOURCES]();
            } catch (err) {
                this.$alert(this.$t('events.alerts.failedToLoadDataSources'));
            }
        },

        navigateToEventsDashboardPageWithTimeout() {
            setTimeout(() => {
                this.saveInProcess = false;
                this.$router.push({
                    name: RouteNames.EVENTS_DASHBOARD,
                    params: { companyId: this.$route.params.companyId },
                });
            }, 2000);
        },

        setInvalidPropertiesIds({ id, hasError }: { id: string; hasError: boolean }) {
            this.invalidPropertiesIds = hasError
                ? union(this.invalidPropertiesIds, [id])
                : without(this.invalidPropertiesIds, id);
        },
        onEventNameChanged(name: string) {
            this.eventName = name.trim();
            this.$v.eventName.$touch();
            if (!this.isEditing) {
                this.eventType = stringToSnakeCase(this.eventName);
                this.$v.eventType.$touch();
            }
            if (this.isEditing && this.insertSchemaManually) {
                const clonedSchema = cloneDeep(this.eventSchemaInput);
                clonedSchema.humanReadableName = name;
                this.eventSchemaInput = clonedSchema;
            }
        },
        onEventDescriptionChanged(descr: string) {
            this.eventDescription = descr;
            if (this.isEditing && this.insertSchemaManually) {
                const clonedSchema = cloneDeep(this.eventSchemaInput);
                clonedSchema.description = descr;
                this.eventSchemaInput = clonedSchema;
            }
        },
        onEventTypeChange(type: string) {
            this.eventType = type;
            this.$v.eventType.$touch();
        },
        onUpdateApiControlValues(item: any, value: any) {
            this.$v.apiControlValues.$touch();

            this.apiControlValues[item.key] = value;
        },

        validateStep(stepIndex: number) {
            if (stepIndex === 0) {
                this.$v.eventName.$touch();
                if (this.$v.eventName.$error) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('events.alerts.eventNameIsMissing'),
                        type: ALERT_TYPES.alert,
                    });
                    return false;
                }
                if (this.$v.eventType.$error) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('events.alerts.failedEventTypeValidation'),
                        type: ALERT_TYPES.alert,
                    });
                    this.isAdvancedConfigVisible = true;
                    return false;
                }
            }
            if (stepIndex === 1) {
                if (this.insertSchemaManually) {
                    // validates json structure, i.e commas, closed brackets, etc
                    if (!this.eventSchemaInputValid) {
                        this.$alert(this.$t('events.alerts.jsonContainsError'));
                        return false;
                    }
                    // validates inserted schema against json-schema standard
                    const jsonSchemaError = isJsonSchemaValid(this.eventSchemaInput);
                    if (jsonSchemaError) {
                        this.$alert(jsonSchemaError);
                        return false;
                    }
                } else {
                    if (this.$v.eventProperties.$each.$invalid) {
                        this.$alert(this.$t('events.alerts.propertyIsInvalid'));
                        return false;
                    }
                    // event properties names are unique
                    this.invalidPropertiesIds = checkNamesDuplicates(this.eventProperties);
                    if (this.invalidPropertiesIds.length > 0) {
                        this.$alert(this.$t('events.alerts.propertyNameAlreadyExists'));
                        return false;
                    }
                }
            }
            if (stepIndex === 2) {
                if (this.isInternalEventEdit) return true;

                this.$v.apiControlValues.$touch();
                if (this.$v.apiControlValues.$error) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('events.alerts.eventApiIsMissing'),
                        type: ALERT_TYPES.alert,
                    });
                    return false;
                }
            }
            return true;
        },
        async saveOrUpdateEvent() {
            this.$Progress.start();
            this.saveInProcess = true;

            try {
                if (this.isEditing) {
                    await updateEvent(this.currentEvent.toJson(this.$route.params.id, this.isInternalEvent));
                } else {
                    await createEvent(this.currentEvent.toJson());
                }
                this.$eventBus.$emit('showAlert', {
                    message: this.successSaveMessage,
                    type: ALERT_TYPES.success,
                });
                this.$Progress.finish();
                this.entityEditorMixin.successfullySaved = true;
                this.navigateToEventsDashboardPageWithTimeout();
            } catch (error: any) {
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: error.msg || this.$i18n.t('events.alerts.failedToSaveEvent'),
                    type: ALERT_TYPES.error,
                });
                this.$Progress.fail();
                this.saveInProcess = false;
            }
        },
        proceedNext() {
            const stepIsValid = this.validateStep(this.activeStepIndex);
            if (stepIsValid) {
                this.entityEditorMixin.dirtyFlag = true;
                this.$set(this.steps[this.activeStepIndex], 'isCompleted', true);
                this.$eventBus.$emit('closeAlertIfError');
                const isLastStep = this.activeStepIndex === this.steps.length - 1;
                if (isLastStep) {
                    this.saveOrUpdateEvent();
                } else {
                    if (this.activeStepIndex === 1 && this.insertSchemaManually) {
                        this.onSchemaInsertTypeChange(false);
                    }
                    this.activeStepIndex += 1;
                }
            }
        },
        onAddProperty() {
            const newCustomPropName = `${this.$i18n.t('generic.property')}_${this.eventProperties.length + 1}`;
            const newProperty = new EventProp({
                id: newCustomPropName + Date.now(),
                name: `${this.$i18n.t('generic.property')}_${this.eventProperties.length + 1}`,
                type: this.eventPropertyTypes[0],
                dataSource: this.$i18n.t('generic.none'),
                isNullable: true,
            });
            this.$set(this.eventProperties, this.eventProperties.length, newProperty);
            this.changeActiveEventProperty(newProperty.id);
        },
        onDeleteProperty(id: string) {
            // forbid deletion of last property
            if (this.eventProperties.length === 1) {
                this.$alert(this.$i18n.t('events.alerts.cantDeleteProperty'));
                return;
            }
            const confirmationButton = new Button({
                label: this.$i18n.t('generic.yes'),
                alertType: ALERT_TYPES.warning,
                id: 'onDeleteProperty',
            });
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alerts.areYouSure'),
                type: ALERT_TYPES.warning,
                buttons: [confirmationButton],
            });
            this.$eventBus.$once('buttonClicked', (buttonId: string) => {
                if (buttonId === confirmationButton.id) {
                    // remove property
                    this.eventProperties = this.eventProperties.filter((property: EventProp) => property.id !== id);
                    // remove tab from array of error indexes
                    this.invalidPropertiesIds = this.invalidPropertiesIds.filter(
                        (invalidId: string) => invalidId !== id,
                    );
                    this.changeActiveEventProperty(this.eventProperties[0].id);
                }
            });
        },
        onUpdateProperty(property: EventProp) {
            const editableProp = this.eventProperties.find((prop: EventProp) => prop.id === property.id);
            const indexOfEditableProperty = this.eventProperties.indexOf(editableProp);
            this.$set(this.eventProperties, indexOfEditableProperty, property);
        },
    },
};
