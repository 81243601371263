


















































































































































import Vue, { type PropType } from 'vue';
// vuex
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';

// components
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppRadioButton from '@/components/partials/inputs/AppRadioButton.vue';

// helpers
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import {
    DOCUMENT_CREATION_MODE,
    DocumentRequestDataFormParams,
} from '@/__new__/services/dno/documents/models/DocumentInterfaces';
import EventProp, { mapEventPropsDefaultValues } from '@/__new__/services/dno/events/models/EventProp';
import { type Event } from '@/__new__/services/dno/events/models/Event';
import { type TranslateResult } from 'vue-i18n';
import { getValidDocumentIdsFromEventProperties } from '@/common/documentsHelper';

type EventOptionLabelData = {
    label: string | TranslateResult;
    producers: string;
};

export type EventOption = Partial<Event> & EventOptionLabelData & Record<'documentIds', EventProp[]>;

export default Vue.extend({
    name: 'DocumentTemplateRequestDataForm',
    components: {
        AppIcon,
        AppInputV3,
        AppMultiselectV3,
        AppRadioButton,
    },

    inject: ['$v'],

    props: {
        formData: {
            type: Object as PropType<DocumentRequestDataFormParams>,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            DOCUMENT_CREATION_MODE,
            ICON_COLORS,
            ICON_TYPES,
            mode: DOCUMENT_CREATION_MODE.API as DOCUMENT_CREATION_MODE,
            event: undefined as EventOption | undefined,
            documentId: undefined as EventProp | undefined,
            callbackUrl: '' as string,
            isDataLoading: false as boolean,
            isToggleDisabled: false as boolean,
        };
    },

    computed: {
        ...mapGetters(Modules.events, { events: Getters.GET_MAPPED_EVENTS }),
        eventList(): EventOption[] {
            return (this.events as Event[]).reduce((events, event) => {
                const documentIds = getValidDocumentIdsFromEventProperties(event);
                // Filter out events without valid documentID
                if (documentIds.length) {
                    events.push({
                        ...event,
                        ...this.formatEventOptionLabelData(event),
                        documentIds,
                    });
                }
                return events;
            }, [] as EventOption[]);
        },
        documentIdList(): EventProp[] {
            return this.event?.documentIds || [];
        },
        isEventDriven(): boolean {
            return this.mode === DOCUMENT_CREATION_MODE.EVENT;
        },
        isModeDisabled(): boolean {
            return this.disabled || this.isDataLoading || this.isToggleDisabled;
        },
    },

    watch: {
        disabled() {
            this.mapFormData();
        },
    },

    async created() {
        await this.fetchEvents();
        this.mapFormData();
    },

    methods: {
        ...mapActions(Modules.events, [Actions.LOAD_ALL_EVENTS]),
        async fetchEvents(): Promise<void> {
            await this.$withProgressBar(
                async () => {
                    if (!this.events.length) {
                        this.isDataLoading = true;
                        await this[Actions.LOAD_ALL_EVENTS]();
                        this.isDataLoading = false;
                    }
                },
                {
                    errorHandler: () => {
                        this.isDataLoading = false;
                        this.$alert(this.$t('events.alerts.failedToLoadAllEvents'));
                    },
                },
            );
        },
        mapFormData() {
            if (!this.disabled && !this.isDataLoading && this.formData?.eventId) {
                this.mode = DOCUMENT_CREATION_MODE.EVENT;
                this.event = this.eventList.find(e => e.id === this.formData.eventId);
                this.documentId = this.documentIdList.find(e => e.name === this.formData.documentId);
                this.callbackUrl = this.formData.callbackUrl;
                this.isToggleDisabled = !!this.formData.version;
            }
        },
        onEventSelect(event: Event): void {
            const schema = mapEventPropsDefaultValues(event.properties);

            this.documentId = undefined;
            this.$emit('schema', schema);
            this.updateFormData();
        },
        formatEventOptionLabelData(event: Event): EventOptionLabelData {
            const producers = (event.producerProducts as unknown as string[]).filter(p => p !== 'Web/Mobile').join(',');

            return {
                label: producers ? `${event.name} (${producers})` : event.name,
                producers,
            };
        },
        updateFormData() {
            this.$emit('input', {
                mode: this.mode,
                eventId: this.event?.id,
                documentId: this.documentId?.name,
                callbackUrl: this.callbackUrl,
            });
        },
    },
});
